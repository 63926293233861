import Basic from './Basic'
import KPI from './KPI'
import s from './index.module.scss'

const Profile = (): JSX.Element => {
	return (
		<div className={s.container}>
			<Basic />
			{/* <KPI /> */}
		</div>
	)
}

export default Profile

export const LogoFullIcon = ({
	textColor = 'var(--mono100)',
	iconColor = 'var(--mono900)',
	width = '164',
	height = '26',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 164 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M26.6745 5.19922H29.6642V21.2042H26.6745V5.19922ZM18.3491 21.2042H15.3594V5.19922H18.3491V21.2042ZM26.9045 14.3449H18.0962V11.807H26.9045V14.3449Z"
			fill={textColor}
		/>
		<path
			d="M6.3475 21.431C5.09026 21.431 3.88669 21.2557 2.73678 20.9051C1.58687 20.5393 0.674613 20.0744 0 19.5104L1.03492 17.2011C1.67887 17.7041 2.47614 18.1233 3.42673 18.4586C4.37732 18.794 5.35091 18.9616 6.3475 18.9616C7.19076 18.9616 7.87304 18.8702 8.39433 18.6873C8.91563 18.5044 9.29893 18.2605 9.54424 17.9556C9.78956 17.6355 9.91221 17.2773 9.91221 16.881C9.91221 16.3932 9.7359 16.0045 9.38326 15.7149C9.03062 15.4101 8.57065 15.1738 8.00337 15.0061C7.45141 14.8232 6.83046 14.6556 6.14051 14.5031C5.4659 14.3507 4.78362 14.1754 4.09368 13.9772C3.41906 13.7638 2.79811 13.4971 2.23082 13.177C1.67887 12.8417 1.22657 12.3996 0.873931 11.8509C0.521292 11.3021 0.344973 10.601 0.344973 9.74737C0.344973 8.87853 0.574954 8.0859 1.03492 7.36949C1.51021 6.63784 2.22316 6.05861 3.17375 5.63181C4.13967 5.18977 5.35858 4.96875 6.83046 4.96875C7.79638 4.96875 8.75464 5.09069 9.70523 5.33458C10.6558 5.57846 11.4838 5.92905 12.189 6.38633L11.2461 8.69562C10.5255 8.26882 9.78189 7.95634 9.01529 7.75818C8.24868 7.54478 7.51274 7.43808 6.80746 7.43808C5.97953 7.43808 5.30491 7.53716 4.78362 7.73532C4.27766 7.93348 3.90202 8.1926 3.65671 8.5127C3.42673 8.8328 3.31174 9.19863 3.31174 9.61018C3.31174 10.098 3.48039 10.4943 3.8177 10.7991C4.17034 11.0887 4.62263 11.3174 5.17459 11.485C5.74188 11.6527 6.37049 11.8204 7.06044 11.9881C7.75039 12.1405 8.43266 12.3158 9.10728 12.5139C9.79722 12.7121 10.4182 12.9712 10.9701 13.2913C11.5374 13.6114 11.9897 14.0458 12.327 14.5946C12.6797 15.1433 12.856 15.8369 12.856 16.6752C12.856 17.5288 12.6183 18.3214 12.143 19.0531C11.6831 19.7695 10.9701 20.3487 10.0042 20.7908C9.03828 21.2176 7.81938 21.431 6.3475 21.431Z"
			fill={textColor}
		/>
		<ellipse
			cx="45.4726"
			cy="13.3909"
			rx="12.6835"
			ry="12.6096"
			fill={textColor}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M45.4689 21.32C49.9002 21.32 53.4925 17.7486 53.4925 13.3431C53.4925 8.93763 49.9002 5.36626 45.4689 5.36626C41.0376 5.36626 37.4453 8.93763 37.4453 13.3431C37.4453 17.7486 41.0376 21.32 45.4689 21.32ZM45.4689 22.8113C50.7287 22.8113 54.9925 18.5722 54.9925 13.3431C54.9925 8.11403 50.7287 3.875 45.4689 3.875C40.2092 3.875 35.9453 8.11403 35.9453 13.3431C35.9453 18.5722 40.2092 22.8113 45.4689 22.8113Z"
			fill={iconColor}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M49.8058 13.788C49.8058 11.4625 47.9095 9.5772 45.5703 9.5772V8.08594C48.7379 8.08594 51.3058 10.6389 51.3058 13.788C51.3058 16.9372 48.7379 19.4901 45.5703 19.4901V17.9989C47.9095 17.9989 49.8058 16.1136 49.8058 13.788Z"
			fill={iconColor}
		/>
		<path
			d="M38.7345 6.69498C35.0153 10.3925 35.0153 16.3874 38.7345 20.0849L52.203 6.69498C48.4838 2.99744 42.4537 2.99744 38.7345 6.69498Z"
			fill={iconColor}
		/>
		<path
			d="M149.695 20.9789V18.9895L160.091 6.36748L160.436 7.48792H149.856V4.97266H163.127V6.962L152.731 19.584L152.363 18.4636H163.426V20.9789H149.695Z"
			fill={textColor}
		/>
		<path
			d="M136.609 20.9789V4.97266H139.599V18.4636H148.017V20.9789H136.609Z"
			fill={textColor}
		/>
		<path
			d="M116.57 20.9789L123.815 4.97266H126.759L134.027 20.9789H130.899L124.666 6.55041H125.862L119.652 20.9789H116.57ZM119.905 17.2746L120.71 14.9422H129.404L130.209 17.2746H119.905Z"
			fill={textColor}
		/>
		<path
			d="M105.651 11.6267H113.655V14.0733H105.651V11.6267ZM105.881 18.4865H114.966V20.9789H102.891V4.97266H114.644V7.46505H105.881V18.4865Z"
			fill={textColor}
		/>
		<path
			d="M86.5312 20.9789V4.97266H93.1553C94.5813 4.97266 95.8003 5.20132 96.8123 5.65864C97.8396 6.11596 98.6293 6.77145 99.1813 7.62511C99.7333 8.47878 100.009 9.49251 100.009 10.6663C100.009 11.8401 99.7333 12.8538 99.1813 13.7075C98.6293 14.5459 97.8396 15.1938 96.8123 15.6511C95.8003 16.0932 94.5813 16.3142 93.1553 16.3142H88.1873L89.5213 14.9651V20.9789H86.5312ZM97.0653 20.9789L92.9943 15.1709H96.1913L100.285 20.9789H97.0653ZM89.5213 15.2852L88.1873 13.8675H93.0172C94.3359 13.8675 95.3249 13.5855 95.9843 13.0215C96.6589 12.4575 96.9963 11.6724 96.9963 10.6663C96.9963 9.64495 96.6589 8.85988 95.9843 8.3111C95.3249 7.76231 94.3359 7.48792 93.0172 7.48792H88.1873L89.5213 6.02449V15.2852Z"
			fill={textColor}
		/>
		<path
			d="M64.8204 20.9789L59.4844 4.97266H62.5894L67.3504 19.4468H65.8094L70.7544 4.97266H73.5144L78.3214 19.4468H76.8264L81.7024 4.97266H84.5544L79.2184 20.9789H76.0444L71.6514 7.92237H72.4794L68.0174 20.9789H64.8204Z"
			fill={textColor}
		/>
	</svg>
)

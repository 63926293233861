import React, { useEffect, useState } from 'react'
import {
	ConnectionState,
	ChatRoomConfig,
	ChatRoom,
} from 'amazon-ivs-chat-messaging'

type ReturnType = {
	connectionState: 'connecting' | 'connected' | 'disconnected'
	room: ChatRoom
}

export const useChatRoom = (config: ChatRoomConfig): ReturnType => {
	const [room] = useState(() => new ChatRoom(config))

	const [connectionState, setConnectionStateState] =
		React.useState<ConnectionState>('disconnected')

	useEffect(() => {
		const unsubscribeOnConnecting = room.addListener('connecting', () => {
			setConnectionStateState('connecting')
		})

		const unsubscribeOnConnected = room.addListener('connect', () => {
			setConnectionStateState('connected')
		})

		const unsubscribeOnDisconnected = room.addListener('disconnect', () => {
			setConnectionStateState('disconnected')
		})

		return () => {
			unsubscribeOnConnecting()
			unsubscribeOnConnected()
			unsubscribeOnDisconnected()
		}
	}, [room])

	return { room, connectionState }
}

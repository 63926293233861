import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useShallow } from 'zustand/react/shallow'
import { upload } from 'api/aws'
import {
	copyContent,
	createContentUpload,
	editContentAsset,
	replaceAsset,
} from 'api/content'
import { multiRelationUpdate } from 'api/relations'
import { setReferenceToContent } from 'api/chat'
import Sidebar from 'components/Sidebar'
import FileUpload from 'components/FileUpload'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Info from './components/Info'
import Video from './components/Video'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { t } from 'helpers'
import { getUser } from 'helpers/storage'
import { videoUploadWorkflowId } from 'helpers/constants'
import { getVideoDuration } from 'helpers/getVideoMetadata'
import { useAppStore, useCastingStore } from 'store'
import { RELATIONS } from 'types/enums'

const tabs = [
	{
		label: 'APP_UPLOAD_SCENE_FILE',
		value: 0,
	},
	{
		label: 'APP_CHOOSE_SCENE_FILE',
		value: 1,
	},
	// {
	// 	label: 'APP_RECORD_SCENE_FILE',
	// 	value: 2,
	// },
]

const SceneUploadSidebar = (): JSX.Element => {
	const [loading, setLoading] = useState(false)
	const { roleId } = useParams()
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(tabs[0].value)
	const documentViewerOpened = useAppStore(
		useShallow((state) => !!state.documentViewerSidebar?.link),
	)
	const [scene, uploadedContent, setOpenSidebar] = useCastingStore(
		useShallow((state) => [
			state.sceneUploadContent,
			state.uploadedSceneContent,
			state.setSceneUploadContent,
		]),
	)

	const { data: d } = useRelationsContent(
		roleId && !!scene ? [RELATIONS.CONTENT] : [],
		`${roleId}_Content_Access`,
		'Relationship',
	)

	const playlistContent = d?.[RELATIONS.CONTENT]?.find(
		(content) => content?.ContentOwnerId === getUser()?.['Custom:User:Id'],
	)

	const { data } = useRelationsContent([RELATIONS.BEST_PERFORMANCES])
	const showreelz = data?.[RELATIONS.BEST_PERFORMANCES] || []

	const onChange = async (
		file: File | string,
		duration?: number,
	): Promise<void> => {
		const sceneDuration = scene?.Duration ? +scene?.Duration : 0
		if (file instanceof File) {
			const duration = await getVideoDuration(file)
			const fileDuration = duration ? +duration : 0
			if (
				fileDuration > 0 &&
				sceneDuration > 0 &&
				fileDuration <= sceneDuration
			) {
				save(file, fileDuration)
			} else {
				toast.info(t('APP_SCENE_DURATION_FILE_ERROR'))
				const input = document.getElementById(
					'scenevideo_' + scene?.ContentId,
				) as HTMLInputElement
				if (input) input.value = ''
			}
		} else {
			if (
				duration &&
				duration > 0 &&
				sceneDuration > 0 &&
				duration <= sceneDuration
			) {
				save(file, duration)
			} else {
				toast.info(t('APP_SCENE_DURATION_FILE_ERROR'))
				const input = document.getElementById(
					'scenevideo_' + scene?.ContentId,
				) as HTMLInputElement
				if (input) input.value = ''
			}
		}
	}

	const save = async (
		file: File | string,
		duration?: number,
	): Promise<void> => {
		try {
			setLoading(true)
			if (!scene?.ContentId) return

			if (typeof file !== 'string') {
				const uploadedFile = await upload({
					fileObj: file,
					assetType: 'Content',
				})
				let content = null

				const uploadData = {
					...uploadedFile,
					originalTitle: file.name,
					workflowId: videoUploadWorkflowId,
					originalLanguage: 'en-US',
					localizations: {
						'en-US': { name: uploadedFile.originalFileName },
					},
					type: 'Video',
					originalFileName: uploadedFile.originalFileName,
					objectUrl: uploadedFile.fileAssetUrl,
					publicUrl: uploadedFile.cdnUrl,
					duration: duration ? duration * 1000 : 0,
					exhibitionWindow: {
						'--': {
							availableFrom:
								uploadedFile.availableFrom?.toISOString &&
								uploadedFile.availableFrom.toISOString(),
							availableUntil:
								uploadedFile.availableUntil?.toISOString &&
								uploadedFile.availableUntil.toISOString(),
						},
					},
					allowMinting: true,
					allowRemix: true,
					allowComments: true,
					published: true,
				}

				if (playlistContent?.ContentId && uploadedContent?.ContentId)
					content = await editContentAsset(
						{
							type: 'Video',
							isPublic: true,
							objectUrl: uploadedFile.fileAssetUrl,
							publicUrl: uploadedFile.cdnUrl,
							locale: 'en-US',
							originalFileName: uploadedFile.originalFileName,
							releaseDate: uploadedFile.availableFrom?.toISOString(),
							subType: '',
							uploadedByUser: true,
						},
						uploadedContent?.ContentId,
					)
				else content = await createContentUpload(uploadData)

				if (!uploadedContent) {
					setReferenceToContent('Reference', content.id, scene.ContentId)

					multiRelationUpdate(scene.ContentId, content.id)

					if (playlistContent)
						multiRelationUpdate(
							playlistContent?.ContentId,
							content.id,
							// RELATIONS.PLAYLIST,
							// scene['Globalizationen-USContentTitle'],
							// scene?.relationIndex,
						)
				}
			} else {
				if (uploadedContent?.ContentId) {
					await replaceAsset(uploadedContent.ContentId, file)
				} else {
					const content = await copyContent(file, {
						keepAssets: true,
						ownerId: uploadedContent?.ContentOwnerId || '',
					})
					if (content?.id) {
						setReferenceToContent('Reference', content.id, scene.ContentId)

						multiRelationUpdate(scene.ContentId, content?.id)

						if (playlistContent)
							multiRelationUpdate(
								playlistContent.ContentId,
								content?.id,
								// RELATIONS.PLAYLIST,
								// scene['Globalizationen-USContentTitle'],
								// scene?.relationIndex,
							)
					} else toast.info(t('APP_SCENE_UPLOADED_FAILED'))
				}
			}

			const { setUploadedSceneIds } = useCastingStore.getState()
			if (duration) setUploadedSceneIds(scene?.ContentId, duration)
			toast.info(t('APP_SCENE_UPLOADED_SUCCESSFULLY'))
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			setOpenSidebar(null)
			setActiveTab(0)
		}
	}

	useEffect(() => {
		if (!scene?.ContentId) setActiveTab(tabs?.[0].value)
	}, [scene?.ContentId])

	const onClose = (): void => {
		setOpenSidebar(null)
		const { setDocumentViewerSidebar } = useAppStore.getState()
		setDocumentViewerSidebar(null)
	}

	return (
		<Sidebar
			open={!!scene?.ContentId}
			onClose={onClose}
			iconClose={documentViewerOpened}
			header={{
				title: t('APP_SCENE_UPLOAD_SIDEBAR_TITLE').replace(
					'{SceneName}',
					scene?.['Globalizationen-USContentTitle'] || '',
				),
				description: 'APP_SCENE_UPLOAD_SIDEBAR_DESCRIPTION',
			}}
			loading={loading}
			buttons={[
				{
					children: t('APP_DONE'),
					onClick: onClose,
				},
			]}>
			<>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
					<Info scene={scene} />

					{uploadedContent ? <Video content={uploadedContent} /> : null}

					<SidebarTabs
						active={activeTab}
						options={tabs}
						onChange={(v) => {
							setActiveTab(v as number)
							swiperRef.current?.slideTo(v as number)
						}}
					/>
					{scene?.ContentId ? (
						<Swiper
							spaceBetween={10}
							onSwiper={(swiper: SwiperClass) => {
								swiperRef.current = swiper
							}}
							allowTouchMove={false}
							style={{ width: '100%', height: 'inherit' }}>
							<SwiperSlide>
								<FileUpload
									fileType="Video"
									label="APP_SCENE_VIDEO_LABEL"
									form="rectangle-h"
									direction="vertical"
									itemTitle=""
									hintText=""
									name="scene_video"
									formatsText="APP_SHOWREELS_FORMATS"
									qualityText="APP_SHOWREELS_QUALITY"
									fileInputProps={{
										id: 'scenevideo_' + scene?.ContentId,
										accept: 'video/*',
									}}
									value={''}
									onChange={(e) => {
										if (e.target.files && e.target.files[0])
											onChange(e.target.files[0])
									}}
								/>
							</SwiperSlide>
							<SwiperSlide>
								<div className="grid2column">
									{showreelz?.map((content) => (
										<div
											onClick={() =>
												onChange(
													content.ContentId,
													content.ContentDuration
														? +(+content.ContentDuration / 1000).toFixed(0)
														: 0,
												)
											}
											key={content.ContentId}
											style={{
												width: '100%',
												height:
													'calc((var(--sidebarWidth) - 88px) / 2 / var(--videoAspect))',
												cursor: 'pointer',
												overflow: 'hidden',
												borderRadius: '15px',
											}}>
											<img
												src={
													content.ContentSquareImageUrl || '/placeholder.png'
												}
												alt={content.ContentTitle}
												style={{ width: '100%', height: '100%' }}
											/>
										</div>
									))}
								</div>
							</SwiperSlide>
							{/* <SwiperSlide>
							{activeTab === 2 ? <RecordScene /> : null}
						</SwiperSlide> */}
						</Swiper>
					) : null}
				</div>
			</>
		</Sidebar>
	)
}

export default SceneUploadSidebar

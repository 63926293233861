export const AddPersonIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M9.375 11.75C7.43359 11.75 5.875 10.1914 5.875 8.25C5.875 6.33594 7.43359 4.75 9.375 4.75C11.2891 4.75 12.875 6.33594 12.875 8.25C12.875 10.1914 11.2891 11.75 9.375 11.75ZM10.7422 13.0625C13.3672 13.0625 15.5 15.1953 15.5 17.8203C15.5 18.3398 15.0625 18.75 14.543 18.75H4.17969C3.66016 18.75 3.25 18.3398 3.25 17.8203C3.25 15.1953 5.35547 13.0625 7.98047 13.0625H10.7422ZM20.0938 10.2188C20.4492 10.2188 20.75 10.5195 20.75 10.875C20.75 11.2578 20.4492 11.5312 20.0938 11.5312H18.7812V12.8438C18.7812 13.2266 18.4805 13.5 18.125 13.5C17.7422 13.5 17.4688 13.2266 17.4688 12.8438V11.5312H16.1562C15.7734 11.5312 15.5 11.2578 15.5 10.875C15.5 10.5195 15.7734 10.2188 16.1562 10.2188H17.4688V8.90625C17.4688 8.55078 17.7422 8.25 18.125 8.25C18.4805 8.25 18.7812 8.55078 18.7812 8.90625V10.2188H20.0938Z"
			fill={color}
		/>
	</svg>
)

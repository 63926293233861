import { toast } from 'react-toastify'
import { useShallow } from 'zustand/react/shallow'
import Sidebar from 'components/Sidebar'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import NetworkCard from 'components/NetworkCard'
import { t } from 'helpers'
import { useAppStore, useNetworkStore } from 'store'
import { User } from 'types/user'

const AddContactsSidebar = (): JSX.Element => {
	const [openSidebar, users, setOpenSidebar] = useNetworkStore(
		useShallow((state) => [
			state.addContactsSidebar.open,
			state.addContactsSidebar.users,
			state.setAddContactsSidebar,
		]),
	)
	const [addUserOpened, setUserContactSidebar] = useAppStore(
		useShallow((state) => [
			state.addUserContactSidebar.open,
			state.setUserContactSidebar,
		]),
	)

	const addUsers = (): void => {
		if (users?.length) {
			const { stepWizard } = useAppStore.getState()
			stepWizard?.nextStep()
			const { setParsedUsers } = useNetworkStore.getState()
			setParsedUsers(users)

			setOpenSidebar({ open: false, users: [] })
		} else toast.error('APP_FILL_ALL_FIELDS')
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar({ open: false, users: [] })}
			iconClose={addUserOpened}
			header={{
				title: 'APP_NETWORK_ADD_CONTACTS_SIDEBAR_TITLE',
				description: 'APP_NETWORK_ADD_CONTACTS_SIDEBAR_DESCRIPTION',
			}}
			buttons={[
				{
					onClick: addUsers,
					children: t('APP_ADD_CONTACTS_BUTTON'),
				},
			]}>
			<div className="grid2column">
				<CreateCardPlaceholder
					label="APP_ADD_USER_CONTACT_LABEL"
					onClick={() => {
						const { setAddContact } = useNetworkStore.getState()
						setUserContactSidebar({
							open: true,
							onAdd: (user) => setAddContact(user),
						})
					}}
				/>
				{users?.map((user) => (
					<NetworkCard
						key={user?.name + '-' + user?.email}
						data={
							{
								ContentTitle: user?.name,
								CustomUserGender: user?.gender,
								CustomerLocationCountry: user?.location,
								CustomUserEmail: user?.email,
								CustomerUserPhoneNumber: user?.phone,
							} as User
						}
					/>
				))}
			</div>
		</Sidebar>
	)
}

export default AddContactsSidebar

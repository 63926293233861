export const ShowreelsIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '25',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8.66406 6.125L5.16406 9.625H5V7.875C5 6.91797 5.76562 6.125 6.75 6.125H8.66406ZM13.9141 9.625H11.3711L14.8711 6.125H17.25C17.2773 6.125 17.332 6.15234 17.3867 6.15234L13.9141 9.625ZM9.53906 9.625H6.99609L10.4961 6.125H13.0391L9.53906 9.625ZM5 16.625V10.5H19V16.625C19 17.6094 18.207 18.375 17.25 18.375H6.75C5.76562 18.375 5 17.6094 5 16.625ZM10.6875 12.5508V16.3242C10.6875 16.4336 10.7422 16.543 10.8242 16.5977C10.8789 16.625 10.9062 16.625 10.9609 16.625C11.0156 16.625 11.0703 16.625 11.125 16.5703L14.0234 14.6836C14.1328 14.6289 14.1875 14.4922 14.1875 14.4375C14.1875 14.3555 14.1328 14.2461 14.0508 14.1914L11.125 12.3047C11.043 12.25 10.9062 12.25 10.8242 12.3047C10.7422 12.3594 10.6875 12.4414 10.6875 12.5508ZM18.5898 6.80859C18.8359 7.10938 19 7.46484 19 7.875V9.625H15.7461L18.5898 6.80859Z"
			fill={color}
		/>
	</svg>
)

export const MinusIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M17.3125 12.625H7.6875C7.19531 12.625 6.8125 12.2422 6.8125 11.75C6.8125 11.2852 7.19531 10.875 7.6875 10.875H17.3125C17.7773 10.875 18.1875 11.2852 18.1875 11.75C18.1875 12.2422 17.7773 12.625 17.3125 12.625Z"
			fill={color}
		/>
	</svg>
)

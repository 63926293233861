export const LinkedinIcon = ({
	color = 'var(--mono100)',
	width = '32',
	height = '33',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M25.3333 4.5C26.0406 4.5 26.7189 4.78095 27.219 5.28105C27.719 5.78115 28 6.45942 28 7.16667V25.8333C28 26.5406 27.719 27.2189 27.219 27.719C26.7189 28.219 26.0406 28.5 25.3333 28.5H6.66667C5.95942 28.5 5.28115 28.219 4.78105 27.719C4.28095 27.2189 4 26.5406 4 25.8333V7.16667C4 6.45942 4.28095 5.78115 4.78105 5.28105C5.28115 4.78095 5.95942 4.5 6.66667 4.5H25.3333ZM24.6667 25.1667V18.1C24.6667 16.9472 24.2087 15.8416 23.3936 15.0264C22.5784 14.2113 21.4728 13.7533 20.32 13.7533C19.1867 13.7533 17.8667 14.4467 17.2267 15.4867V14.0067H13.5067V25.1667H17.2267V18.5933C17.2267 17.5667 18.0533 16.7267 19.08 16.7267C19.5751 16.7267 20.0499 16.9233 20.3999 17.2734C20.75 17.6235 20.9467 18.0983 20.9467 18.5933V25.1667H24.6667ZM9.17333 11.9133C9.76742 11.9133 10.3372 11.6773 10.7573 11.2573C11.1773 10.8372 11.4133 10.2674 11.4133 9.67333C11.4133 8.43333 10.4133 7.42 9.17333 7.42C8.57571 7.42 8.00257 7.6574 7.57999 8.07999C7.1574 8.50257 6.92 9.07571 6.92 9.67333C6.92 10.9133 7.93333 11.9133 9.17333 11.9133ZM11.0267 25.1667V14.0067H7.33333V25.1667H11.0267Z"
			fill={color}
		/>
	</svg>
)

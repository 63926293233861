import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import Item from '../Item'
import { prepareMessageData, t } from 'helpers'
import { getUser } from 'helpers/storage'
import { useAppStore } from 'store'
import { Relation } from 'types/app'
import { CASTING_STATUS, MESSAGE_TYPE } from 'types/enums'
import { User } from 'types/user'
import styles from './index.module.scss'

interface Props {
	users: User[]
	relations?: Relation[]
	deleteCasting: (user: User, relation: Relation) => void
}

const List = ({ users, relations, deleteCasting }: Props): JSX.Element => {
	const navigate = useNavigate()

	const notifyOption = [CASTING_STATUS.FAVORITE, CASTING_STATUS.UPLOADING]

	const notifyUsers = (userIds: string[], status: CASTING_STATUS): void => {
		const { roleInfoSidebar, setRoleInfoSidebar } = useAppStore.getState()
		const types: {
			[key: string]: {
				messageType: MESSAGE_TYPE
				key: string
			}
		} = {
			[CASTING_STATUS.FAVORITE]: {
				messageType: MESSAGE_TYPE.NOTIFY_WINNER,
				key: 'NOTIFYWINNER',
			},
		}

		const type = types?.[status]

		const data = {
			userIds,
			sourceId: roleInfoSidebar,
			key: type?.key || 'NOTIFYUSERS',
			messageValue: encodeURIComponent(
				t(`APP_${type?.key || 'NOTIFYUSERS'}_MESSAGE_BODY`)?.replace(
					'[YourName]',
					getUser()?.['Custom:User:Name'] || '',
				),
			),
			redirect: window.location.pathname + window.location.hash,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: type?.messageType || MESSAGE_TYPE.CASTING,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})
		setRoleInfoSidebar('')
	}

	return relations?.length ? (
		<>
			<div className={cn(styles.container, 'hideScrollBar')}>
				{relations?.map((relation) => (
					<Item
						key={relation?.createdDate}
						deleteCasting={deleteCasting}
						user={users?.find((user) => user?.ContentId === relation.targetId)}
						relation={relation}
					/>
				))}
			</div>
			<span
				onClick={() =>
					notifyUsers(
						relations?.map((rel) => rel.targetId),
						relations?.[0]?.status as CASTING_STATUS,
					)
				}
				className={cn('body2-m', styles.notify)}>
				{notifyOption.includes(relations?.[0]?.status as CASTING_STATUS)
					? t('APP_NOTIFY_LABEL')
					: ''}
			</span>
		</>
	) : (
		<span>{t('APP_RESULT_NOT_FOUND')}</span>
	)
}

export default List

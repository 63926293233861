import { useEffect, useMemo, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import Loader from 'components/Loader'
import CastingOverlay from './components/CastingOverlay'
import CastingScene from './components/CastingScene'
import useContentRelation from 'hooks/api/useContentRelation'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useContentReferences from 'hooks/api/useContentReferences'
import { t } from 'helpers'
import { filterDuplicates, orderScenes } from 'helpers/propertiesParser'
import { useCastingStore, useUserStore } from 'store'
import { RELATIONS } from 'types/enums'
import { PATHS } from 'pages/Router'
import { Content } from 'types/app'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'

const CastingOverview = (): JSX.Element => {
	const { hash } = useLocation()
	const swiperRef = useRef<SwiperClass | null>(null)
	const { roleId, userId } = useParams()
	const director = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)
	const loading = useCastingStore(useShallow((state) => state.castingLoading))
	const { data, isLoading: relationLoading } = useRelationsContent(
		roleId ? [RELATIONS.CONTENT] : [],
		`${roleId}_Content_Access`,
		'Relationship',
	)
	const playlistContent = userId
		? data?.[RELATIONS.CONTENT]?.find(
				(content) => content?.ContentOwnerId === userId,
		  )
		: null

	const { contents: scenes, isLoading: contentLoading } = useContentRelation(
		playlistContent?.ContentId,
		RELATIONS.PLAYLIST,
	)

	const filteredScenes = useMemo(
		() =>
			scenes?.filter((scene) =>
				director ? true : scene?.ContentCastingHide !== 'true',
			),
		[director, scenes],
	)

	const { contents: originalContents } = useContentReferences(
		filteredScenes?.map((scene) => scene.ContentId),
	)

	const contents = orderScenes(
		[],
		filteredScenes?.map((scene, index) => ({
			...scene,
			relationExtraData:
				originalContents?.[index]?.[0]?.['Globalizationen-USContentTitle'] ||
				'Tape',
		})),
	)

	useEffect(() => {
		const { chatContentId, setChatContentId } = useCastingStore.getState()
		if (
			playlistContent?.ContentId &&
			(!chatContentId || chatContentId !== playlistContent?.ContentId)
		)
			setChatContentId(playlistContent.ContentId)
	}, [playlistContent?.ContentId])

	useEffect(() => {
		if (roleId) {
			const { setCustomCastingTabs } = useCastingStore.getState()
			setCustomCastingTabs(
				contents?.length
					? contents.map((content, index) => ({
							name: content?.relationExtraData || '',
							key: 'board',
							main: true,
							substeps: [
								{
									name: content?.relationExtraData || '',
									key: 'board',
									component: (<></>) as unknown as () => JSX.Element,
									index: index + 1,
									disabled: true,
								},
							],
					  }))
					: [],
			)
		} else {
			const { setCustomCastingTabs, setOpenCastingChat } =
				useCastingStore.getState()
			setCustomCastingTabs(null)
			setOpenCastingChat(false)
		}
	}, [roleId, contents])

	useEffect(() => {
		if (roleId && userId) {
			const sceneNumber = +hash?.slice(-1)
			swiperRef.current?.slideTo(sceneNumber - 1)
		}
	}, [hash, roleId, userId])

	useEffect(() => {
		return () => {
			const { setCustomCastingTabs } = useCastingStore.getState()
			setCustomCastingTabs(null)
		}
	}, [])

	const allLoading = loading || relationLoading || contentLoading

	return (
		<>
			<Loader loading={allLoading} adapt />
			<>
				{roleId && !allLoading ? (
					<div className={s.container}>
						<CastingOverlay />
						{contents?.length ? (
							<Swiper
								style={{ width: '100%', height: '100%' }}
								onSwiper={(e: SwiperClass) => {
									swiperRef.current = e
									const sceneNumber = +window.location.hash?.slice(-1)
									swiperRef.current?.slideTo(sceneNumber - 1)
								}}
								allowTouchMove={false}>
								{filterDuplicates<Content>(contents, 'ContentId')?.map(
									(content, index) => (
										<SwiperSlide key={content.ContentId}>
											<CastingScene
												content={content}
												active={+hash?.slice(-1) - 1 === index}
											/>
										</SwiperSlide>
									),
								)}
							</Swiper>
						) : (
							<NoContent />
						)}
					</div>
				) : null}
			</>
		</>
	)
}

const NoContent = (): JSX.Element => {
	useEffect(() => {
		const { setOpenCastingRoles } = useCastingStore.getState()
		setOpenCastingRoles(true)

		return () => {
			setOpenCastingRoles(false)
		}
	}, [])

	return (
		<div
			style={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			}}>
			<span>{t('APP_RESULT_NOT_FOUND')}</span>
		</div>
	)
}

const Wrapper = (): JSX.Element => {
	if (
		window.location.pathname.includes(PATHS.DETAILS) &&
		window.location.pathname.includes(CUSTOMER_ROLE.ACTOR)
	)
		return <></>

	return (
		<Card
			className="cardWrapperSizeNoControls"
			style={{
				padding: '0',
				width: '100%',
				overflow: 'hidden',
			}}>
			<CastingOverview />
		</Card>
	)
}

export default Wrapper

import { useShallow } from 'zustand/react/shallow'
import Select from 'components/UI/Select'
import useContentSearch from 'hooks/api/useContentSearch'
import { useAppStore } from 'store'
import { SelectOption } from 'types/app'

const ProjectSelect = (): JSX.Element => {
	const [localSearch, setSidebar] = useAppStore(
		useShallow((state) => [
			state.localSearchSidebar,
			state.setLocalSearchSidebar,
		]),
	)

	const { contents } = useContentSearch(true, 'Collection')

	const productions: SelectOption[] = contents
		? contents?.map((content) => ({
				value: content.ContentId,
				label: content['Globalizationen-USContentTitle'],
		  }))
		: []

	return productions?.length ? (
		<Select
			name="productions"
			options={productions}
			error=""
			label="APP_PRODUCTION_SELECT_LABEL"
			onChange={(e) => {
				setSidebar({ projectId: e.target.value })
			}}
			inputProps={{
				value: localSearch?.projectId,
			}}
		/>
	) : (
		<></>
	)
}

export default ProjectSelect

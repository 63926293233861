import classNames from 'classnames'
import { ArrowShortIcon } from 'assets/icons'
import InputLabel from '../InputLabel'
import { t } from 'helpers'
import { InputLabelStatuses } from 'types/components'
import s from './index.module.scss'

interface Props {
	label: string
	title: string
	status: InputLabelStatuses
	onClick: () => void
	image?: File | string | null
}

const CoverButtonLayout = ({
	label,
	title,
	status,
	onClick,
	image,
}: Props): JSX.Element => (
	<div className={s.container} onClick={onClick}>
		<InputLabel label={label} status={status} />
		<div className={classNames('card', s.card)}>
			<>
				<div className={s.title}>
					<span className="body1-b">{t(title)}</span>
					<ArrowShortIcon />
				</div>
				<div className={s.image}>
					<img
						src={
							image instanceof File
								? URL.createObjectURL(image)
								: image || '/picture-placeholder.png'
						}
						alt="cover-placeholder"
					/>
				</div>
			</>
		</div>
	</div>
)

export default CoverButtonLayout

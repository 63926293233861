export const DownloadIcon = ({
	color = 'var(--theme-primary)',
	width = '25',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M18.875 14.375C19.3398 14.375 19.75 14.7852 19.75 15.25V17.875C19.75 18.3672 19.3398 18.75 18.875 18.75H6.625C6.13281 18.75 5.75 18.3672 5.75 17.875V15.25C5.75 14.7852 6.13281 14.375 6.625 14.375H10.2617L11.4922 15.6328C11.8203 15.9609 12.2578 16.125 12.75 16.125C13.2148 16.125 13.6523 15.9609 13.9805 15.6328L15.2109 14.375H18.875ZM17.5625 17.2188C17.918 17.2188 18.2188 16.9453 18.2188 16.5625C18.2188 16.207 17.918 15.9062 17.5625 15.9062C17.1797 15.9062 16.9062 16.207 16.9062 16.5625C16.9062 16.9453 17.1797 17.2188 17.5625 17.2188ZM12.1211 15.0039L8.62109 11.5039C8.26562 11.1758 8.26562 10.6016 8.62109 10.2734C8.94922 9.91797 9.52344 9.91797 9.85156 10.2734L11.875 12.2695V5.625C11.875 5.16016 12.2578 4.75 12.75 4.75C13.2148 4.75 13.625 5.16016 13.625 5.625V12.2695L15.6211 10.2734C15.9492 9.91797 16.5234 9.91797 16.8516 10.2734C17.207 10.6016 17.207 11.1758 16.8516 11.5039L13.3516 15.0039C13.1875 15.168 12.9688 15.25 12.75 15.25C12.5039 15.25 12.2852 15.168 12.1211 15.0039Z"
			fill={color}
		/>
	</svg>
)

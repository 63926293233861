import { useCallback, useEffect, useState } from 'react'
import { contentAuthorize } from 'api/auth'
import useShakaPlayer from 'hooks/useShakaPlayer'
import { Content } from 'types/app'

interface Props {
	content: Content
}

const Video = ({ content }: Props): JSX.Element => {
	const [contentUrl, setContentUrl] = useState('')

	useShakaPlayer(contentUrl || '', 'rolescene_' + content?.ContentId)

	const fetchVideoUrl = useCallback(async (): Promise<void> => {
		try {
			const response = await contentAuthorize(content.ContentId)
			if (response?.Properties) {
				setContentUrl(
					response.Properties.find((value) => value.Name === 'Content:Url')
						?.Value || '',
				)
			}
		} catch (error) {
			console.log('error:', error)
		}
	}, [content.ContentId])

	useEffect(() => {
		if (!contentUrl) {
			fetchVideoUrl()
		}
	}, [contentUrl, fetchVideoUrl])

	return (
		<div
			style={{
				borderRadius: '15px',
				overflow: 'hidden',
				display: 'flex',
			}}>
			<video
				id={'rolescene_' + content?.ContentId}
				controls
				style={{ width: '100%', height: 'calc(392px / var(--videoAspect))' }}
			/>
		</div>
	)
}

export default Video

import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import { ArrowShortIcon } from 'assets/icons'
import InputLabel from 'components/UI/InputLabel'
import { useAppStore } from 'store'
import s from './index.module.scss'

const Instructions = (): JSX.Element => {
	const [instructions, openSidebar] = useAppStore(
		useShallow((state) => [
			state.addSceneSidebar?.scene?.instructions,
			state.addSceneSidebar.openInstructions,
		]),
	)

	return (
		<div
			className={s.container}
			onClick={() => {
				const { addSceneSidebar } = useAppStore.getState()
				addSceneSidebar.setOpenInstructions(true)
			}}>
			<InputLabel
				label="APP_INSTRUCTIONS_LABEL"
				status={
					openSidebar ? 'loading' : instructions?.length ? 'filled' : 'none'
				}
			/>
			<div className={cn('card', s.wrapper)}>
				<span
					style={{ color: 'var(--mono200)' }}
					className={`${openSidebar ? '' : 'textEllipsis'}`}>
					{instructions?.join(', ')}
				</span>
				<div style={{ display: 'flex' }}>
					<ArrowShortIcon color="var(--mono200)" />
				</div>
			</div>
		</div>
	)
}

export default Instructions

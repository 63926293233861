import classNames from 'classnames'
import { useEffect, useState } from 'react'
import Prev from './components/Prev'
import Repeat from './components/Repeat'
import PlayPause from './components/PlayPause'
import Next from './components/Next'
import Speed from './components/Speed'
import Mute from './components/Mute'
import Visibility from './components/Visibility'
import Volume from './components/Volume'
import TimeTrack from './components/TimeTrack'
import Actions from './components/Actions'
import Fullscreen from './components/Fullscreen'
import Time from './components/Time'
import useMarkers from 'hooks/api/useMarkers'
import { EventBus } from 'helpers/EventBus'
import s from './index.module.scss'
import { useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'
import { useShallow } from 'zustand/react/shallow'

interface Props {
	shakaInstance: shaka.Player | null
	active: boolean
	contentId?: string
	assetId?: string
}

const Controls = ({
	shakaInstance,
	active,
	contentId,
	assetId,
}: Props): JSX.Element => {
	const director = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)
	const { data, refresh } = useMarkers(
		active && contentId ? contentId : undefined,
	)
	const [videoElement, setVideoElement] = useState(
		shakaInstance?.getMediaElement(),
	)
	const [controlStates, setControlStates] = useState({
		playing: false,
		muted: false,
		speed: 1,
		volume: 0.5,
		visible: true,
		fullscreen: false,
	})

	const onControlChange = (v: {
		[key: string]: string | boolean | number
	}): void => setControlStates((prev) => ({ ...prev, ...v }))

	useEffect(() => {
		if (shakaInstance && !videoElement) {
			setTimeout(() => {
				setVideoElement(shakaInstance?.getMediaElement())
			}, 500)
		}
	}, [shakaInstance, videoElement])

	useEffect(() => {
		if (videoElement)
			videoElement.addEventListener('ended', () => {
				setControlStates((prev) => ({ ...prev, playing: false }))
			})
	}, [videoElement])

	useEffect(() => {
		EventBus.$on('refreshMarkers', () => {
			if (active) refresh()
		})

		return () => {
			EventBus.$off('refreshMarkers')
		}
	}, [refresh, active])

	useEffect(() => {
		if (videoElement && active) {
			onControlChange({ playing: true })
			videoElement.play()
		}
	}, [active, videoElement])

	useEffect(() => {
		if (videoElement && !active && controlStates?.playing) {
			videoElement.pause()
			onControlChange({ playing: false })
		}
	}, [active, videoElement, controlStates?.playing])

	useEffect(() => {
		if (videoElement && !active && controlStates?.playing) {
			videoElement.pause()
			onControlChange({ playing: false })
		}
	}, [active, videoElement, controlStates?.playing])

	useEffect(() => {
		const keyDownHandler = (event: KeyboardEvent): void => {
			const { code } = event
			const activeElement = document.activeElement

			if (
				activeElement?.tagName === 'INPUT' ||
				activeElement?.tagName === 'TEXTAREA'
			) {
				return
			}

			switch (code) {
				case 'KeyM':
					{
						if (videoElement) videoElement.muted = !controlStates.muted
						onControlChange({ muted: !controlStates.muted })
					}
					break
				case 'Space':
					{
						if (videoElement) {
							if (controlStates.playing) videoElement.pause()
							else videoElement.play()
						}
						onControlChange({ playing: !controlStates.playing })
					}
					break
				default:
					break
			}
		}
		document.addEventListener('keydown', keyDownHandler)

		return () => {
			document.removeEventListener('keydown', keyDownHandler)
		}
	}, [controlStates.muted, controlStates.playing, videoElement])

	return (
		<>
			{controlStates.visible ? null : <div className={s.hideContent} />}
			<div
				className={classNames(s.container, 'castingToolWrapper')}
				data-role="controls">
				{active && videoElement && shakaInstance ? (
					<TimeTrack videoElement={videoElement} markers={data} />
				) : null}
				<div className={classNames(s.wrapper, 'grid3column')}>
					<div className={s.group}>
						{director ? (
							<Actions contentId={contentId} assetId={assetId} />
						) : null}
					</div>

					<div className={s.group} style={{ justifyContent: 'center' }}>
						<Repeat videoElement={videoElement} onChange={onControlChange} />
						<Prev videoElement={videoElement} />
						<PlayPause
							videoElement={videoElement}
							playing={controlStates.playing}
							onChange={onControlChange}
						/>
						<Next videoElement={videoElement} />
						<Speed
							videoElement={videoElement}
							speed={controlStates.speed}
							onChange={onControlChange}
						/>
					</div>

					<div className={s.group} style={{ justifyContent: 'flex-end' }}>
						<Time videoElement={videoElement} />
						<Volume
							videoElement={videoElement}
							volume={controlStates.volume}
							onChange={onControlChange}
						/>
						<Mute
							videoElement={videoElement}
							muted={controlStates.muted}
							onChange={onControlChange}
						/>
						<Visibility
							videoElement={videoElement}
							visible={controlStates.visible}
							onChange={onControlChange}
						/>
						<Fullscreen
							videoElement={videoElement}
							fullscreen={controlStates.fullscreen}
							onChange={onControlChange}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default Controls

/* eslint-disable no-tabs */
import cn from 'classnames'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import { useAppStore } from 'store'
import s from './index.module.scss'
import StepWrapper from 'components/Wizard/StepWrapper'
import Boards from './components/Boards'
import Search from './components/Search'

export const inspirationSteps = {
	search: {
		name: 'APP_INSPIRATION_SEARCH',
		key: 'search',
		main: true,
		substeps: [
			{
				name: 'APP_INSPIRATION_SEARCH',
				key: 'search',
				component: Search,
				index: 0,
			},
		],
	},
	boards: {
		name: 'APP_SEARCH_BOARDS',
		key: 'boards',
		main: true,
		substeps: [
			{
				name: 'APP_SEARCH_BOARDS',
				key: 'boards',
				component: Boards,
				index: 0,
			},
		],
	},
}

const Inspiration = (): JSX.Element => {
	// const { hash } = useLocation()
	const navigate = useNavigate()
	// const stepWizard = useAppStore(useShallow((state) => state.stepWizard))

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname + '#' + inspirationSteps.search?.key + '-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	// const nextStep = (): void => {
	// 	stepWizard?.nextStep()
	// }

	// const prevStep = (): void => {
	// 	stepWizard?.previousStep()
	// }

	// const onStepChange = (tab: string): void => {
	// 	stepWizard?.goToNamedStep(tab)
	// }

	// const onSubmitStep = (): void => {
	// 	// const submitButton = document.getElementById(`next-${hash?.slice(1)}`)
	// 	// if (submitButton) submitButton.click()
	// 	// else nextStep()
	// 	nextStep()
	// }

	return (
		<div className={s.container}>
			<div className={cn(s.wrapper, 'flex')}>
				<StepWizard
					initialStep={0}
					instance={assignStepWizard}
					isHashEnabled={true}
					className={s.wizard}>
					{Object.values(inspirationSteps)
						?.map((step) => step.substeps)
						?.flat()
						?.map((step) => (
							<StepWrapper
								key={step.name + '_' + step.index}
								hashKey={step.key + '-' + step.index}
								stepName={step.key + '-' + step.index}>
								<step.component />
							</StepWrapper>
						))}
				</StepWizard>
			</div>
		</div>
	)
}

export default Inspiration

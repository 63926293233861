import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { CompleteIcon } from 'assets/icons'
import { ExtendedMessage } from 'types/chat'
import { PATHS } from 'pages/Router'
import s from './index.module.scss'
import { CSSProperties } from 'react'

interface Props {
	message: ExtendedMessage
	style?: CSSProperties
}

const NotificationMessage = ({ message, style }: Props): JSX.Element => {
	const hoursAgo = dayjs(Date.now()).diff(message.sendTime, 'h') >= 12 // 12 hours ago
	const navigate = useNavigate()

	return (
		<div
			className={s.notif}
			style={{
				cursor: message?.sender ? 'pointer' : 'default',
				...style,
			}}
			onClick={() => {
				if (message.sender?.ContentId)
					navigate(
						'/' + PATHS.PROFILE?.replace(':userId', message.sender.ContentId),
					)
			}}>
			<div
				className={s.image}
				style={{
					border: message?.sender
						? `2px solid var(--${message?.sender?.CustomerRole?.toLowerCase()})`
						: 'none',
				}}>
				{message?.sender ? (
					<img
						src={message.sender?.CustomerImageSquareUrl || '/placeholder.png'}
						alt={message?.sender?.ContentTitle}
					/>
				) : (
					<div
						className="notifInfoIcon"
						style={
							hoursAgo ? { backgroundColor: 'rgba(255, 255, 255, 0.1)' } : {}
						}>
						<CompleteIcon
							color={hoursAgo ? 'var(--mono300)' : 'var(--success)'}
						/>
					</div>
				)}
			</div>
			<div className="notifMessageClass">
				<span className="body1-m">
					{message?.sender ? message.sender?.ContentTitle : 'Notification'}
				</span>
				{message.sendTime ? (
					<span className="caption-r">
						{dayjs(message.sendTime).format('MMM D, HH:mm')}
					</span>
				) : null}
				{message.content.body ? (
					<p className="caption-s">{message.content.body}</p>
				) : null}
			</div>
		</div>
	)
}

export default NotificationMessage

export const CastingIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M13.9141 9.125H11.3711L14.8711 5.625H17.25C17.2773 5.625 17.332 5.65234 17.3867 5.65234L13.9141 9.125ZM9.53906 9.125H6.99609L10.4961 5.625H13.0391L9.53906 9.125ZM18.5898 6.30859C18.8359 6.60938 19 6.96484 19 7.375V9.125H15.7461L18.5898 6.30859ZM8.66406 5.625L5.16406 9.125H5V7.375C5 6.41797 5.76562 5.625 6.75 5.625H8.66406ZM5 16.125V10H19V16.125C19 17.1094 18.207 17.875 17.25 17.875H6.75C5.76562 17.875 5 17.1094 5 16.125Z"
			fill={color}
		/>
	</svg>
)

import { MouseEvent, useEffect, useRef, useState } from 'react'
import { MessageIcon } from 'assets/icons'
import { HHMMSSToMs, HHMMSSToSec, secToHHMMSS } from 'helpers/dateTimeConfig'
import { useCastingStore } from 'store'
import { Marker } from 'types/content'
import s from './index.module.scss'

interface Props {
	videoElement: HTMLMediaElement
	markers: Marker[] | undefined
}

const TimeTrack = ({ videoElement, markers }: Props): JSX.Element => {
	const track = useRef<HTMLDivElement>(null)
	const tooltip = useRef<HTMLDivElement>(null)
	const [currentTime, setCurrentTime] = useState(videoElement?.currentTime || 0)

	useEffect(() => {
		if (videoElement) {
			videoElement.addEventListener('timeupdate', (e) => {
				const target = e.target as HTMLVideoElement
				setCurrentTime(target.currentTime)
			})
		}
	}, [videoElement])

	const duration = videoElement?.duration || 0

	const calculatePosition = (
		e: MouseEvent<HTMLDivElement>,
	): { position: number; time: number } => {
		const target = track.current
		if (target) {
			const rect = target.getBoundingClientRect()
			const position = e.clientX - rect.left
			const positionPercent = ((e.clientX - rect.left) * 100) / rect.width

			return {
				position: position,
				time: (duration * positionPercent) / 100,
			}
		}

		return {
			position: 0,
			time: 0,
		}
	}

	const onMarkerClick = (marker: Marker): void => {
		const { setOpenCastingChat } = useCastingStore.getState()
		setOpenCastingChat(true)

		setTimeout(() => {
			const message = document.querySelector(
				`[data-timestamp=time_${HHMMSSToMs(marker.position)}]`,
			)
			if (message) {
				message.scrollIntoView({ behavior: 'smooth' })
				setTimeout(() => {
					message.classList.add('highlight')
					setTimeout(() => {
						message.classList.remove('highlight')
					}, 1500)
				}, 500)
			}
		}, 1000)
	}

	return (
		<div
			className={s.container}
			ref={track}
			onClick={(e) => {
				const { time } = calculatePosition(e)
				videoElement.currentTime = time
			}}
			onMouseMove={(e) => {
				const { position, time } = calculatePosition(e)
				if (tooltip.current) {
					tooltip.current.style.display = 'initial'
					tooltip.current.style.left = position - 20 + 'px'
					const span = tooltip.current.children?.[0] as HTMLSpanElement
					span.innerText = secToHHMMSS(time)
				}
			}}
			onMouseLeave={() => {
				if (tooltip.current) {
					tooltip.current.style.display = 'none'
					const span = tooltip.current.children?.[0] as HTMLSpanElement
					span.innerText = ''
				}
			}}>
			<div ref={tooltip} className={s.tooltip}>
				<span className="caption-r"></span>
			</div>
			{markers?.map((marker) => (
				<div
					key={marker.id}
					className={s.marker}
					style={{
						left: `${(HHMMSSToSec(marker.position) / duration) * 100}%`,
					}}>
					<div className={s.markerItem} onClick={() => onMarkerClick(marker)}>
						<MessageIcon color="var(--mono900)" />
					</div>
				</div>
			))}
			{/* <div
				className={s.thumb}
				style={{
					left: `${(currentTime / duration) * 100}%`,
				}}></div> */}
			<div
				className={s.progressBar}
				style={{
					background: `linear-gradient(to left, var(--mono400) ${
						((duration - currentTime) / duration) * 100
					}%, var(--mono100) ${((duration - currentTime) / duration) * 100}%)`,
				}}></div>
		</div>
	)
}

export default TimeTrack

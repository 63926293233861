import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Select from 'components/UI/Select'
import { t } from 'helpers'
import { usePortfolioStore } from 'store'
import { AttributeSelector } from 'types/app'
import { PORTFOLIO_TYPES } from 'types/enums'
import s from '../../index.module.scss'

interface Props {
	selector: AttributeSelector
	cardIndex?: number
}

const Selector = ({ selector, cardIndex }: Props): JSX.Element => {
	const mediaType = usePortfolioStore(
		useShallow((state) =>
			[PORTFOLIO_TYPES.PICTURE, PORTFOLIO_TYPES.SHOWREEL].includes(
				state.generalModalType,
			),
		),
	)
	const values = usePortfolioStore(
		useShallow((state) => {
			const propName =
				mediaType && cardIndex
					? state.generalModalType + cardIndex
					: state.getModalProp()

			return state.cardValues[propName] && state.cardValues[propName]
		}),
	)

	const value = useMemo(
		() =>
			selector?.options?.find(
				(option) =>
					values &&
					values[selector.name] &&
					values[selector.name] === option.value,
			),
		[selector.name, selector?.options, values],
	)

	return (
		<div className={s.selectorWrapper} key={selector.name}>
			<Select
				name={selector.name}
				options={selector.options}
				label={t(selector.label)}
				inputProps={{
					value: value?.value || '',
				}}
				onChange={(e) => {
					const { setCardValues, generalModalType, updateFields } =
						usePortfolioStore.getState()

					const media = [
						PORTFOLIO_TYPES.PICTURE,
						PORTFOLIO_TYPES.SHOWREEL,
					].includes(generalModalType)

					const cardName =
						mediaType && cardIndex ? generalModalType + cardIndex : ''

					updateFields([
						{
							Name: selector.name,
							Value: e.target.value,
						},
					])

					setCardValues(
						{
							[selector.name]: e.target.value,
						},
						cardName,
					)

					if (!media) {
						const { setGalleryType } = usePortfolioStore.getState()
						setGalleryType({
							value: e.target.value,
							label: selector.label,
						})
					}
				}}
			/>
		</div>
	)
}

export default Selector

export const PauseIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M15.0625 6.5C15.7734 6.5 16.375 7.10156 16.375 7.8125V15.6875C16.375 16.4258 15.7734 17 15.0625 17L14.1875 16.9727C13.4492 16.9727 12.875 16.3711 12.875 15.6602V7.78516C12.875 7.07422 13.4492 6.5 14.1875 6.5H15.0625ZM9.8125 6.5C10.5234 6.5 11.125 7.10156 11.125 7.8125V15.6875C11.125 16.4258 10.5234 17 9.8125 17H8.9375C8.19922 17 7.625 16.4258 7.625 15.6875V7.8125C7.625 7.07422 8.19922 6.5 8.9375 6.5H9.8125Z"
			fill={color}
		/>
	</svg>
)

import { useShallow } from 'zustand/react/shallow'
import { useCallback, useEffect } from 'react'
import { DownloadIcon, MagnifyingGlassIcon } from 'assets/icons'
import { addRelation } from 'api/relations'
import Sidebar from 'components/Sidebar'
import Button from 'components/UI/Button'
import useContentFullRelation from 'hooks/api/useContentFullRelation'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { EventBus } from 'helpers/EventBus'
import { t } from 'helpers'
import { useAppStore, useCastingStore } from 'store'
import { CASTING_STATUS, RELATIONS } from 'types/enums'

const ReviewNdaSidebar = (): JSX.Element => {
	const [openSidebar, setOpenSidebar] = useCastingStore(
		useShallow((state) => [
			state.openReviewNdaSidebar,
			state.setOpenReviewNdaSidebar,
		]),
	)
	const documentViewerOpened = useAppStore(
		useShallow((state) => !!state.documentViewerSidebar?.link),
	)

	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		openSidebar?.contentId,
		'Content',
		'/Lookup',
		true,
	)

	const prod = data?.[RELATIONS.PLAYLIST]?.[0]

	const ndaRequired = prod?.ContentLegalNDARequired === 'true'

	const { contents } = useContentFullRelation(
		ndaRequired ? prod?.ContentId : undefined,
		RELATIONS.PLAYLIST,
		true,
	)
	const { contents: playlist } = useContentFullRelation(
		ndaRequired ? openSidebar?.contentId : undefined,
		RELATIONS.PLAYLIST,
		true,
	)
	const prodFull = contents?.[0]
	const contFull = playlist?.[0]
	const prodNda = prodFull?.assets?.find((asset) =>
		asset.publicUrl?.endsWith('.pdf'),
	)
	const roleNda = contFull?.assets?.find((asset) =>
		asset.publicUrl?.endsWith('.pdf'),
	)

	const onClose = useCallback((): void => {
		openSidebar?.refresh()
		const { setDocumentViewerSidebar } = useAppStore.getState()
		setDocumentViewerSidebar(null)
		setOpenSidebar(null)
	}, [openSidebar, setOpenSidebar])

	const onApproveWaiting = useCallback(async (): Promise<void> => {
		await addRelation({
			sourceId: openSidebar?.contentId,
			targetId: openSidebar?.userId || '',
			type: 'Content',
			relation: RELATIONS.ACCESS,
			status: CASTING_STATUS.READY,
		})
		onClose()
	}, [onClose, openSidebar?.contentId, openSidebar?.userId])

	useEffect(() => {
		EventBus.$on('acceptNdaReview', async () => {
			onApproveWaiting()
		})

		return () => {
			EventBus.$off('acceptNdaReview')
		}
	}, [onApproveWaiting])

	return (
		<Sidebar
			open={!!openSidebar}
			onClose={onClose}
			iconClose={documentViewerOpened}
			header={{
				title: 'APP_CASTING_REVIEW_NDA_SIDEBAR_TITLE',
				description: 'APP_CASTING_REVIEW_NDA_SIDEBAR_DESCRIPTION',
			}}
			buttons={[
				{
					onClick: () => {
						const { setOpenRejectNdaSidebar } = useCastingStore.getState()
						setOpenRejectNdaSidebar(true)
					},
					children: t('APP_REJECT'),
				},
				{
					onClick: onApproveWaiting,
					children: t('APP_ACCEPT'),
				},
			]}>
			<>
				<Button
					variant="outlined"
					onClick={() => {
						const { setDocumentViewerSidebar } = useAppStore.getState()
						setDocumentViewerSidebar({
							link: roleNda?.publicUrl || '',
							buttonClick: () => {
								const a = document.createElement('a')
								a.href = roleNda?.publicUrl || ''
								a.download = 'true'
								a.target = '_blank'
								a.click()
							},
							buttonText: 'APP_NDA_DOWNLOAD_BUTTON',
						})
					}}
					style={{
						gap: '10px',
						color: 'var(--theme-primary)',
						borderColor: 'var(--theme-primary)',
					}}>
					<div style={{ display: 'flex', alignSelf: 'center' }}>
						<MagnifyingGlassIcon
							color="var(--theme-primary)"
							width="16"
							height="16"
						/>
					</div>
					{t('APP_NDA_OPEN_BUTTON')}
				</Button>

				{prodNda ? (
					<Button
						variant="outlined"
						onClick={(e) => {
							if (
								(e.target as HTMLElement).tagName?.toLowerCase() === 'button'
							) {
								const a = (e.target as HTMLButtonElement)
									.children?.[1] as HTMLAnchorElement
								if (a) a.click()
							}
						}}
						style={{
							gap: '10px',
							borderColor: 'var(--theme-primary)',
						}}>
						<div style={{ display: 'flex' }}>
							<DownloadIcon />
						</div>
						<a
							className="body1-b"
							href={prodNda?.publicUrl}
							target="_blank"
							download
							style={{
								textDecoration: 'none',
								textTransform: 'none',
								color: 'var(--theme-primary)',
							}}>
							{t('APP_DOWNLOAD_PRODUCTION_NDA')}
						</a>
					</Button>
				) : null}
				{roleNda ? (
					<Button
						variant="outlined"
						onClick={(e) => {
							if (
								(e.target as HTMLElement).tagName?.toLowerCase() === 'button'
							) {
								const a = (e.target as HTMLButtonElement)
									.children?.[1] as HTMLAnchorElement
								if (a) a.click()
							}
						}}
						style={{
							gap: '10px',
							borderColor: 'var(--theme-primary)',
						}}>
						<div style={{ display: 'flex' }}>
							<DownloadIcon />
						</div>
						<a
							className="body1-b"
							href={roleNda?.publicUrl}
							target="_blank"
							download
							style={{
								textDecoration: 'none',
								textTransform: 'none',
								color: 'var(--theme-primary)',
							}}>
							{t('APP_DOWNLOAD_ROLE_NDA')}
						</a>
					</Button>
				) : null}
			</>
		</Sidebar>
	)
}

export default ReviewNdaSidebar

import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import StepWrapper from 'components/Wizard/StepWrapper'
import Profession from './components/Profession'
import Questions from './components/Questions'
import Goals from './components/Goals'
import { useAppStore } from 'store'
import s from './index.module.scss'

export const onboardingSteps = {
	profession: {
		name: 'APP_ONBOARDING_PROFESSION',
		key: 'profession',
		main: true,
		substeps: [
			{
				name: 'APP_ONBOARDING_PROFESSION',
				key: 'profession',
				component: Profession,
				index: 0,
			},
		],
	},
	questions: {
		name: 'APP_ONBOARDING_QUESTIONS',
		key: 'questions',
		main: true,
		substeps: [
			{
				name: 'APP_ONBOARDING_QUESTIONS',
				key: 'questions',
				component: Questions,
				index: 0,
			},
		],
	},
	goals: {
		name: 'APP_ONBOARDING_GOALS',
		key: 'goals',
		main: true,
		substeps: [
			{
				name: 'APP_ONBOARDING_GOALS',
				key: 'goals',
				component: Goals,
				index: 0,
			},
		],
	},
}

const Onboarding = (): JSX.Element => {
	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	return (
		<div className={s.container}>
			<StepWizard
				initialStep={0}
				instance={assignStepWizard}
				isHashEnabled={true}
				className={s.wizard}>
				{Object.values(onboardingSteps)
					?.map((step) => step.substeps)
					?.flat()
					?.map((step) => (
						<StepWrapper
							key={step.name + '_' + step.index}
							hashKey={step.key + '-' + step.index}
							stepName={step.key + '-' + step.index}>
							<step.component />
						</StepWrapper>
					))}
			</StepWizard>
		</div>
	)
}

export default Onboarding

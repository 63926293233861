const isAgent = (userAgent: string): boolean => {
	return navigator.userAgent.indexOf(userAgent) !== -1
}

const isChromeOnMac = (): boolean => {
	return navigator.vendor.indexOf('Google') !== -1
}

export default function getBrowserType(): string {
	if (isAgent('Opera') || isAgent('OPR')) {
		return 'Opera'
	} else if (isAgent('Edg')) {
		return 'Edge'
	} else if (isAgent('Chrome') || isChromeOnMac()) {
		return 'Chrome'
	} else if (isAgent('Safari')) {
		return 'Safari'
	} else if (isAgent('Firefox')) {
		return 'Firefox'
	} else if (isAgent('MSIE')) {
		return 'IE'
	} else {
		return 'unknown'
	}
}

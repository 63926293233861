import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { useAppStore, usePortfolioStore, useUserStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import classNames from 'classnames'
import { ArrowLongIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import StepWrapper from 'components/Wizard/StepWrapper'
import Photos from '../steps/Photos'
import Basic from '../steps/Basic'
import Contact from '../steps/Contact'
import Personal from '../steps/Personal'
import Biography from '../steps/Biography'
import Tutorial from '../components/Tutorial'
import GallerySidebar from '../components/GallerySidebar/GallerySidebar'
import AttributeSidebar from '../components/AttributeSidebar'
import AdditionalSidebar from '../components/AdditionalSidebar'
import AgencySidebar from '../components/AgencySidebar'
import MediaZoomSidebar from '../components/MediaZoomSidebar'
import Card from 'components/Wizard/Card'
import { getDefaultUserPath, t } from 'helpers'
import {
	basicFields,
	biographyFields,
	contactFields,
	personalFields,
} from '../fields'
import { CUSTOMER_ROLE, User } from 'types/user'
import s from '../index.module.scss'

export const directorPortfolioSteps = {
	basic: {
		name: 'APP_PROFILE_BASIC',
		key: 'basic',
		main: true,
		substeps: [
			{
				name: 'APP_PROFILE_PHOTOS',
				key: 'basic',
				component: Photos,
				index: 0,
			},
			{
				name: 'APP_PROFILE_BASIC_INFO',
				key: 'basic',
				component: Basic,
				index: 1,
			},
			{
				name: 'APP_PROFILE_CONTACT',
				key: 'basic',
				component: Contact,
				index: 2,
			},
			{
				name: 'APP_PROFILE_PERSONAL',
				key: 'basic',
				component: Personal,
				index: 3,
			},
			{
				name: 'APP_PROFILE_BIOGRAPHY',
				key: 'basic',
				component: Biography,
				index: 4,
			},
		],
	},
}

const DirectorPortfolio = (): JSX.Element => {
	const { hash } = useLocation()
	const navigate = useNavigate()
	const [storeLoading, setStoreLoading] = useState(true)
	const portfolioWizard = useAppStore(useShallow((state) => state.stepWizard))

	const [currentCard] = usePortfolioStore(
		useShallow((state) => [state.cardData]),
	)

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname +
					window.location.search +
					'#' +
					directorPortfolioSteps?.basic?.key +
					'-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	const nextStep = (): void => {
		portfolioWizard?.nextStep()
	}

	const prevStep = (): void => {
		portfolioWizard?.previousStep()
	}

	// const onStepChange = (tab: string): void => {
	// 	portfolioWizard?.goToNamedStep(tab)
	// }

	const onSubmitStep = (): void => {
		// const submitButton = document.getElementById(`next-${hash?.slice(1)}`)
		// if (submitButton) submitButton.click()
		// else nextStep()
		nextStep()
	}

	const refreshUserData = useCallback(async () => {
		const { refreshUser } = useUserStore.getState()
		const user = await refreshUser()

		const { setCardValues, cardValues } = usePortfolioStore.getState()

		if (user) {
			const steps = [
				{ key: 'basic', fields: basicFields },
				{ key: 'contact', fields: contactFields },
				{ key: 'personal', fields: personalFields },
				{ key: 'biography', fields: biographyFields },
			]
			steps.forEach((step) => {
				setCardValues(
					{
						...Object.values(step.fields)
							?.map((field) => ({
								[field.name]:
									(user?.[
										field.name?.replaceAll(':', '') as keyof User
									] as string) || '',
							}))
							.reduce(
								(prev, next) => ({
									...prev,
									...next,
								}),
								{},
							),
					},
					step.key,
				)
			})

			if (user && !cardValues['Custom:User:BirthDate']) {
				setCardValues(
					{
						'Custom:User:BirthDate': user?.CustomUserBirthDate || '',
					},
					'basic',
				)
			}
		}

		setStoreLoading(false)
	}, [])

	useEffect(() => {
		refreshUserData()
	}, [refreshUserData])

	// useEffect(() => {
	// 	const { portfolioMode } = useAppStore.getState()
	// 	if (
	// 		!portfolioMode.active &&
	// 		window.location.pathname.includes(CUSTOMER_ROLE.AGENCY)
	// 	)
	// 		navigate(getDefaultUserPath(CUSTOMER_ROLE.AGENCY))
	// }, [navigate])

	return (
		<div className={s.container}>
			<div className={classNames(s.wrapper, 'flex')}>
				{storeLoading ? null : (
					<>
						<GallerySidebar />
						<AttributeSidebar cardData={currentCard} />
						<AdditionalSidebar />
						<AgencySidebar />
						<MediaZoomSidebar />
						<Card className={s.wizard}>
							<StepWizard
								initialStep={0}
								instance={assignStepWizard}
								isHashEnabled={true}>
								{Object.values(directorPortfolioSteps)
									?.map((step) => step.substeps)
									?.flat()
									?.map((step) => (
										<StepWrapper
											key={step.name + '_' + step.index + '-' + step.key}
											hashKey={step.key + '-' + step.index}
											stepName={step.key + '-' + step.index}>
											<step.component />
										</StepWrapper>
									))}
							</StepWizard>
						</Card>
					</>
				)}
				<Tutorial step={hash?.slice(1)} />
			</div>
			<div className={s.buttonsWrapper}>
				<Button
					onClick={prevStep}
					style={
						portfolioWizard?.currentStep === 1
							? {
									opacity: 0,
									visibility: 'hidden',
							  }
							: {}
					}>
					{t('APP_BACK_BUTTON')}
				</Button>
				<Button
					variant="themed"
					className={s.next}
					onClick={onSubmitStep}
					style={
						portfolioWizard?.currentStep === portfolioWizard?.totalSteps
							? {
									opacity: 0,
									visibility: 'hidden',
							  }
							: {}
					}>
					{t('APP_NEXT_BUTTON')}
					<ArrowLongIcon color="var(--theme-primary)" />
				</Button>
			</div>
		</div>
	)
}

export default DirectorPortfolio

export const FieldLoadingIcon = ({
	color = '#F9E10C',
	width = '12',
	height = '12',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M6.625 2.875C6.625 3.22656 6.33203 3.5 6 3.5C5.64844 3.5 5.375 3.22656 5.375 2.875V1.625C5.375 1.29297 5.64844 1 6 1C6.33203 1 6.625 1.29297 6.625 1.625V2.875ZM6.625 10.375C6.625 10.7266 6.33203 11 6 11C5.64844 11 5.375 10.7266 5.375 10.375V9.125C5.375 8.79297 5.64844 8.5 6 8.5C6.33203 8.5 6.625 8.79297 6.625 9.125V10.375ZM1 6C1 5.66797 1.27344 5.375 1.625 5.375H2.875C3.20703 5.375 3.5 5.66797 3.5 6C3.5 6.35156 3.20703 6.625 2.875 6.625H1.625C1.27344 6.625 1 6.35156 1 6ZM10.375 5.375C10.707 5.375 11 5.66797 11 6C11 6.35156 10.707 6.625 10.375 6.625H9.125C8.77344 6.625 8.5 6.35156 8.5 6C8.5 5.66797 8.77344 5.375 9.125 5.375H10.375ZM2.44531 2.46484C2.69922 2.23047 3.08984 2.23047 3.34375 2.46484L4.22266 3.36328C4.45703 3.59766 4.45703 3.98828 4.22266 4.24219C3.96875 4.47656 3.57812 4.47656 3.34375 4.24219L2.44531 3.36328C2.21094 3.10938 2.21094 2.71875 2.44531 2.46484ZM9.53516 8.65625C9.76953 8.91016 9.76953 9.30078 9.53516 9.53516C9.28125 9.78906 8.89062 9.78906 8.63672 9.53516L7.75781 8.65625C7.52344 8.42188 7.52344 8.01172 7.75781 7.77734C8.01172 7.52344 8.40234 7.52344 8.63672 7.77734L9.53516 8.65625ZM2.44531 9.53516C2.21094 9.30078 2.21094 8.91016 2.44531 8.65625L3.34375 7.77734C3.57812 7.52344 3.96875 7.52344 4.22266 7.77734C4.45703 8.01172 4.45703 8.42188 4.22266 8.65625L3.34375 9.53516C3.08984 9.78906 2.69922 9.78906 2.44531 9.53516ZM8.63672 2.46484C8.89062 2.23047 9.28125 2.23047 9.53516 2.46484C9.76953 2.71875 9.76953 3.10938 9.53516 3.36328L8.63672 4.24219C8.40234 4.47656 8.01172 4.47656 7.75781 4.24219C7.52344 3.98828 7.52344 3.59766 7.75781 3.36328L8.63672 2.46484Z"
			fill={color}
		/>
	</svg>
)

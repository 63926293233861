import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Section from '../Section'
import { FilterSection } from '../../FilterSidebar'
import { t } from 'helpers'
import { useFilterStore } from 'store'
import { AttributeSelector } from 'types/app'
import {
	attributeBasicFields,
	basicFields,
	contactFields,
	personalFields,
} from 'pages/Portfolio/fields'

const userStatusOptions = [
	{
		value: '',
		label: 'APP_ALL_STATUS',
	},
	{
		value: 'Active',
		label: 'APP_ACTIVE_STATUS',
	},
	{
		value: 'Invited',
		label: 'APP_INACTIVE_STATUS',
	},
]

const quickSections: FilterSection[] = [
	{
		title: '',
		fields: [
			{
				name: 'imdbLink',
				label: '',
				type: 'checkbox',
				optionName: 'IMDB|IMDb',
				options: [],
			},
			{ ...basicFields.name, name: 'userSearch' },
			{
				name: 'userStatus',
				label: 'APP_USER_STATUS_LABEL',
				type: 'select',
				options: userStatusOptions,
			},
			contactFields.email,
			basicFields.profession,
		],
	},
	{
		title: '',
		fields: [
			{
				name: 'Customer:Age',
				label: 'APP_CUSTOMER_AGE_LABEL',
				type: 'range',
				min: 0,
				max: 100,
				options: [],
			},
		],
	},
	{
		title: '',
		fields: [personalFields.gender],
	},
	{
		title: '',
		fields: [
			attributeBasicFields.body.selectors.find((selector) =>
				selector?.name?.includes('Type'),
			) as AttributeSelector,
		],
	},
	{
		title: '',
		fields: [basicFields.location],
	},
]

const Quick = (): JSX.Element => {
	const fieldsSearch = useFilterStore(useShallow((state) => state.fieldsSearch))

	const filters = useMemo(
		() =>
			fieldsSearch
				? quickSections?.map((section) => ({
						...section,
						fields: section?.fields?.filter((field) =>
							t(field.label)
								?.toLowerCase()
								?.includes(fieldsSearch?.toLowerCase()),
						),
				  }))
				: quickSections,
		[fieldsSearch],
	)

	return (
		<>
			{filters?.map((section) => (
				<Section
					section={section}
					key={section.title || section?.fields?.[0]?.name}
				/>
			))}
		</>
	)
}

export default Quick

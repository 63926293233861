export const CorrectIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 4.75C12.9844 4.75 13.8594 5.32422 14.2969 6.17188C15.1992 5.87109 16.2109 6.08984 16.9492 6.80078C17.6602 7.51172 17.8516 8.55078 17.5781 9.45312C18.4258 9.89062 19 10.7656 19 11.75C19 12.7617 18.4258 13.6367 17.5781 14.0742C17.8789 14.9766 17.6602 15.9883 16.9492 16.6992C16.2109 17.4102 15.1992 17.6289 14.2969 17.3555C13.8594 18.2031 12.9844 18.75 12 18.75C10.9883 18.75 10.1133 18.2031 9.67578 17.3555C8.77344 17.6289 7.76172 17.4102 7.02344 16.6992C6.3125 15.9883 6.12109 14.9766 6.39453 14.0742C5.54688 13.6367 5 12.7617 5 11.75C5 10.7656 5.54688 9.89062 6.39453 9.45312C6.09375 8.55078 6.3125 7.51172 7.02344 6.80078C7.76172 6.08984 8.77344 5.87109 9.67578 6.17188C10.1133 5.32422 10.9883 4.75 12 4.75ZM14.625 10.9023C14.8984 10.6562 14.8984 10.2461 14.625 9.97266C14.3789 9.72656 13.9688 9.72656 13.7227 9.97266L11.125 12.5977L10.0312 11.5039C9.78516 11.2578 9.375 11.2578 9.12891 11.5039C8.85547 11.7773 8.85547 12.1875 9.12891 12.4336L10.6602 13.9648C10.9062 14.2383 11.3164 14.2383 11.5625 13.9648L14.625 10.9023Z"
			fill={color}
		/>
	</svg>
)

export const HairIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.8672 13.0625C16.4922 13.0625 18.625 15.1953 18.625 17.8203C18.625 18.3398 18.1875 18.75 17.668 18.75H7.30469C6.78516 18.75 6.375 18.3398 6.375 17.8203C6.375 15.1953 8.48047 13.0625 11.1055 13.0625H13.8672ZM8.78125 10C8.04297 10 7.46875 9.42578 7.46875 8.6875V7.8125C7.46875 7.10156 8.04297 6.5 8.78125 6.5C9 6.5 9.19141 6.58203 9.38281 6.66406C9.95703 5.54297 11.1328 4.75 12.5 4.75C13.8398 4.75 15.0156 5.54297 15.5898 6.66406C15.7812 6.58203 15.9727 6.5 16.2188 6.5C16.9297 6.5 17.5312 7.10156 17.5312 7.8125V8.6875C17.5312 9.42578 16.9297 10 16.2188 10C16.0547 10 15.8906 9.97266 15.7539 9.91797C15.2617 11.2578 13.9766 12.1875 12.5 12.1875C10.9961 12.1875 9.71094 11.2578 9.21875 9.91797C9.08203 9.97266 8.91797 10 8.78125 10ZM10.3125 8.25V8.6875C10.3125 9.91797 11.2695 10.875 12.5 10.875C13.7031 10.875 14.6875 9.91797 14.6875 8.6875V8.25C14.6875 8.05859 14.6328 7.89453 14.6055 7.70312C14.4141 7.78516 14.2227 7.8125 14.0312 7.8125C13.3477 7.8125 12.7734 7.45703 12.5 6.91016C12.1992 7.45703 11.625 7.8125 10.9688 7.8125C10.75 7.8125 10.5586 7.78516 10.3672 7.70312C10.3398 7.89453 10.3125 8.05859 10.3125 8.25Z"
			fill={color}
		/>
	</svg>
)

import { ThemeProvider as StyledTheme } from 'styled-components'
import {
	BackgroundBlurProvider,
	LoggerProvider,
	MeetingProvider,
	darkTheme,
} from 'amazon-chime-sdk-component-library-react'
import { ConsoleLogger, LogLevel, Logger } from 'amazon-chime-sdk-js'
import Validation from './Validation'

const consoleLogger: Logger = new ConsoleLogger(
	'ChimeSDKReactComponent',
	LogLevel.ERROR,
)

const ChimeProviders = (): JSX.Element => (
	<StyledTheme theme={darkTheme}>
		<BackgroundBlurProvider>
			<LoggerProvider logger={consoleLogger}>
				<MeetingProvider {...{ logLevel: LogLevel.ERROR }}>
					<Validation />
				</MeetingProvider>
			</LoggerProvider>
		</BackgroundBlurProvider>
	</StyledTheme>
)

export default ChimeProviders

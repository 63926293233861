import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { LikeIcon, StarIcon } from 'assets/icons'
import { EventBus } from 'helpers/EventBus'
import s from './index.module.scss'

const iconSize = '125'

const actions: {
	[key: string]: {
		icon: JSX.Element
		color: string
	}
} = {
	like: {
		icon: (
			<LikeIcon width={iconSize} height={iconSize} color="var(--success)" />
		),
		color: 'var(--success)',
	},
	dislike: {
		icon: (
			<LikeIcon
				width={iconSize}
				height={iconSize}
				color="var(--error)"
				style={{ transform: 'rotate(180deg)' }}
			/>
		),
		color: 'var(--error)',
	},
	fav: {
		icon: (
			<StarIcon width={iconSize} height={iconSize} color="var(--warning)" />
		),
		color: 'var(--warning)',
	},
	lowstatus: {
		icon: <LikeIcon width={iconSize} height={iconSize} color="var(--error)" />,
		color: 'var(--error)',
	},
	midstatus: {
		icon: (
			<LikeIcon width={iconSize} height={iconSize} color="var(--warning)" />
		),
		color: 'var(--warning)',
	},
	fulstatus: {
		icon: (
			<LikeIcon width={iconSize} height={iconSize} color="var(--success)" />
		),
		color: 'var(--success)',
	},
}

const castingActions = ['Like', 'Dislike', 'Fav']
const portfolioActions = ['Lowstatus', 'Midstatus', 'Fulstatus']

const ActionAnimation = (): JSX.Element => {
	const [action, setAction] = useState<null | {
		icon: JSX.Element
		color: string
	}>(null)
	const [showAnimation, setShowAnimation] = useState(false)

	/* casting overview actions */
	useEffect(() => {
		castingActions.forEach((a) => {
			EventBus.$on(`casting${a}ActionAnimation`, () => {
				setAction(actions?.[a?.toLowerCase()])
				setTimeout(() => {
					setShowAnimation(true)
				}, 200)

				setTimeout(() => {
					setShowAnimation(false)
				}, 1200)
				setTimeout(() => {
					setAction(null)
				}, 1500)
			})
		})

		return () => {
			castingActions.forEach((a) => {
				EventBus.$off(`casting${a}ActionAnimation`)
			})
		}
	}, [])

	/* portfolio readiness */
	useEffect(() => {
		portfolioActions.forEach((a) => {
			EventBus.$on(`portfolio${a}ActionAnimation`, () => {
				setAction(actions?.[a?.toLowerCase()])

				setTimeout(() => {
					setShowAnimation(true)
				}, 200)

				setTimeout(() => {
					setShowAnimation(false)
				}, 1200)
				setTimeout(() => {
					setAction(null)
				}, 1500)
			})
		})

		return () => {
			portfolioActions.forEach((a) => {
				EventBus.$off(`portfolio${a}ActionAnimation`)
			})
		}
	}, [])

	return (
		<div
			className={classNames(s.container, { [s.show]: showAnimation })}
			style={{
				backgroundColor: `color-mix(in srgb, ${action?.color} 40%, transparent)`,
			}}>
			{action?.icon}
		</div>
	)
}

export default ActionAnimation

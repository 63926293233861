import screenfull from 'screenfull'
import { FullscreenIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import getBrowserType from 'helpers/getBrowserType'
import { CrossBrowserVideoElem } from 'types/content'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
	fullscreen: boolean
	onChange: (v: { [key: string]: boolean }) => void
}

const Fullscreen = ({
	videoElement,
	fullscreen,
	onChange,
}: Props): JSX.Element => {
	const onFullscreen = async (): Promise<void> => {
		if (videoElement) {
			const videoPlayerRef = videoElement as CrossBrowserVideoElem
			try {
				if (getBrowserType() !== 'Safari' /* || window.innerWidth > 768 */) {
					if (navigator.maxTouchPoints > 0) {
						videoElement.requestFullscreen()
					} else {
						if (!screenfull.isFullscreen) {
							screenfull.request(videoElement)
							onChange({ fullscreen: true })
						} else {
							screenfull.exit()
							onChange({ fullscreen: false })
						}
					}
				} else {
					/* Safari */
					if (!videoPlayerRef.webkitDisplayingFullscreen) {
						videoPlayerRef.webkitEnterFullScreen()
						onChange({ fullscreen: true })
					} else {
						videoPlayerRef.webkitExitFullScreen()
						onChange({ fullscreen: false })
					}
				}
			} catch (error) {
				console.error(`Fullscreen error: ${error}`)
			}
		}
	}

	return (
		<Tooltip title="APP_FULLSCREEN_CONTROL">
			<div className={s.control} onClick={onFullscreen}>
				<FullscreenIcon
					color={fullscreen ? 'var(--mono100) !important' : 'var(--mono300)'}
				/>
			</div>
		</Tooltip>
	)
}

export default Fullscreen

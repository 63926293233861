import { ArrowShortIcon } from 'assets/icons'
import { t } from 'helpers'
import { useAppStore } from 'store'
import { SummaryData } from '../../Summary'
import s from './index.module.scss'

const Line = ({
	label,
	filled,
	max,
	percent,
	hashKey,
}: SummaryData): JSX.Element => {
	const color =
		+percent >= 90
			? 'var(--success)'
			: +percent >= 60
			? 'var(--warning)'
			: 'var(--error)'

	const navigateToSection = (): void => {
		const { stepWizard } = useAppStore.getState()
		stepWizard?.goToNamedStep(hashKey || '')
	}

	return (
		<div className={s.container} onClick={navigateToSection}>
			<div className={s.wrapper}>
				<div className={s.titles}>
					<div className={s.label}>
						<span className="body1-b">{t(label) + ':'}</span>
						<span style={{ color: 'var(--mono200' }}>{percent + '%'}</span>
					</div>
					<span style={{ color: 'var(--mono200' }}>{`${filled} / ${max}`}</span>
				</div>
				<div className={s.lineWrapper}>
					<div style={{ backgroundColor: color, width: percent + '%' }} />
				</div>
			</div>
			<div className={s.arrow}>
				<ArrowShortIcon color="var(--theme-primary)" />
			</div>
		</div>
	)
}

export default Line

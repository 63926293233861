import { useCallback, useEffect, useRef } from 'react'
import * as Shaka from 'shaka-player/dist/shaka-player.compiled'
import { fetcher } from 'api'
import { getUser } from 'helpers/storage'
import { useAppStore } from 'store'
import { Content } from 'types/app'

const sendTime = 30

export default (
	contentData: Content | undefined,
	shakaInstance: Shaka.Player | null,
	sessionId?: string,
): {
	onPlay: () => void
	onPause: () => void
	onSeek: () => void
	onEnded: () => void
} => {
	const playTime = useRef(0)
	const intervalId = useRef<NodeJS.Timeout | null>(null)
	const content = useRef<Content>()
	const sessionIdRef = useRef('')

	useEffect(() => {
		if (contentData && !content?.current) content.current = contentData
	}, [contentData])

	useEffect(() => {
		if (sessionId && !sessionIdRef.current) sessionIdRef.current = sessionId
	}, [sessionId])

	const sendClientReport = useCallback(
		async (playTime: number): Promise<void> => {
			if (window.location.href.includes('localhost')) return

			if (playTime <= 0) return

			try {
				const video = shakaInstance?.getMediaElement()
				if (!video) return
				const user = getUser()
				const { endpoints } = useAppStore.getState()
				const url = endpoints?.CustomApplicationReporting

				const body = [
					{
						Name: 'Type',
						Value: 'ContentConsumptionContract',
					},
					{
						Name: 'ContentRef',
						Value: content?.current?.ContentId,
					},
					{
						Name: 'UserRef',
						Value: user?.['Custom:User:Id'] || '',
					},
					{
						Name: 'ContentToken',
						Value: content?.current?.ContentToken,
					},
					{
						Name: 'CollectionRef',
						Value: '',
					},
					{
						Name: 'UserToken',
						Value: user?.['Custom:User:Token'] || '',
					},
					{
						Name: 'ProjectRef',
						Value: 'e16f7af2-2fd3-4411-a1cc-4a88fc53500b',
					},
					{
						Name: 'Genre',
						Value: content?.current?.ContentGenre || '',
					},
					{
						Name: 'Category',
						Value: content?.current?.ContentCategory || '',
					},
					{
						Name: 'Date',
						Value: new Date().toISOString(),
					},
					{
						Name: 'SessionId',
						Value: sessionIdRef.current,
					},
					{
						Name: 'ObjectType',
						Value: 'Content',
					},
					{
						Name: 'CatalogRef',
						Value: '',
					},
					{
						Name: 'Position',
						Value: video?.currentTime,
					},
					{
						Name: 'Duration',
						Value: video?.duration,
					},
					{
						Name: 'Speed',
						Value: video?.playbackRate,
					},
					{
						Name: 'TimeSpent',
						Value: playTime,
					},
				]

				await fetcher(url + '/clientreport', {
					method: 'post',
					body: JSON.stringify({
						Properties: body,
					}),
				})
			} catch (err) {
				console.log('err:', err)
			}
		},
		[shakaInstance],
	)

	const trackPlayTime = (): void => {
		intervalId.current = setInterval(() => {
			playTime.current += 1
			if (playTime.current >= sendTime) {
				sendClientReport(playTime.current)
				playTime.current = 0
			}
		}, 1000)
	}

	const onPlay = (): void => {
		console.log('play')

		sendClientReport(playTime.current)

		if (!intervalId.current) {
			trackPlayTime()
		}
	}

	const onPause = (): void => {
		console.log('pause')
		sendClientReport(playTime.current)

		if (intervalId.current) clearInterval(intervalId.current)
		intervalId.current = null
	}

	const onSeek = (): void => {
		console.log('seek')
		sendClientReport(playTime.current)

		if (intervalId.current) clearInterval(intervalId.current)
		intervalId.current = null
	}

	const onEnded = (): void => {
		console.log('end')
		sendClientReport(playTime.current)

		if (intervalId.current) clearInterval(intervalId.current)
		intervalId.current = null
		playTime.current = 0
	}

	useEffect(() => {
		return () => {
			console.log('close')
			sendClientReport(playTime.current)
			if (intervalId.current) clearInterval(intervalId.current)
			intervalId.current = null
			playTime.current = 0
		}
	}, [sendClientReport])

	return {
		onPause,
		onPlay,
		onSeek,
		onEnded,
	}
}

import { createWithEqualityFn } from 'zustand/traditional'
import { UserStore } from 'types/store'
import { getUserData } from 'api/user'
import { getUser } from 'helpers/storage'

const useStore = createWithEqualityFn<UserStore>(
	(set, get) => ({
		userId: localStorage.getItem('UserData')
			? JSON.parse(localStorage.getItem('UserData') as string)['Custom:User:Id']
			: null,
		setUserId: (userId) => set({ userId }),
		user: null,
		setUser: (user) => set({ user }),
		refreshUser: async () => {
			try {
				const { setUser, userId } = get()
				const id = userId || getUser()?.['Custom:User:Id']
				if (id) {
					const user = await getUserData(id, true)
					if (user) {
						setUser(user)

						return user
					}
				}
			} catch (error) {
				console.log('error:', error)
			}
		},
		activeAttribute: '',
		setActiveAttribute: (attribute) => set({ activeAttribute: attribute }),
	}),
	Object.is,
)

export default useStore

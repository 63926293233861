import { CSSProperties } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import dayjs from 'dayjs'
import Card from 'components/Wizard/Card'
import StatusCircle from 'components/StatusCircle'
import { optionsParser, t } from 'helpers'
import { useProductionStore } from 'store'
import { Content } from 'types/app'
import { DROPDOWN_OPTIONS, ROLE_STATUS } from 'types/enums'
import s from './index.module.scss'

interface Props {
	content: Content | undefined
	type?: 'aside' | 'card' | 'small'
	onCardClick?: () => void
	showDescription?: boolean
	children?: JSX.Element
	style?: CSSProperties
}

const ProductionCard = ({
	content,
	onCardClick,
	children,
	showDescription,
	type = 'card',
	style,
}: Props): JSX.Element => {
	const { productionId } = useParams()
	const cover = useProductionStore(
		useShallow((state) => state.production.cover),
	)

	const localCover =
		productionId && cover instanceof File ? URL.createObjectURL(cover) : null

	const rehearsalDate = content?.ContentEventRehearsalDate?.includes('[')
		? (JSON.parse(content?.ContentEventRehearsalDate) as string[])
		: ''
	const filmingDate = content?.ContentEventFilmingDate?.includes('[')
		? (JSON.parse(content?.ContentEventFilmingDate) as string[])
		: ''
	const rehDateDiff = [
		dayjs(rehearsalDate[0]).diff(dayjs(), 'day'),
		dayjs(rehearsalDate[1]).diff(dayjs(), 'day'),
	]

	const rehearsalDateStatus =
		rehDateDiff[0] < 0 && rehDateDiff[1] < 0
			? { color: 'var(--error)', label: 'APP_EXPIRED_STATUS' }
			: rehDateDiff[0] < 0 && rehDateDiff[1] > 0
			? { color: 'var(--success)', label: 'APP_ACTIVE_STATUS' }
			: { color: 'var(--warning)', label: 'APP_UPCOMING_STATUS' }

	return (
		<Card
			className={cn(s.container, s[type], 'hoverWrapperParent', {
				cardWrapperSize: type === 'aside',
			})}
			color={content?.ContentColor}
			onClick={onCardClick}
			style={{
				background: `radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, ${content?.ContentColor} 75%, transparent) -50%, color-mix(in srgb, ${content?.ContentColor} 0%, transparent) 100%)`,
				...style,
			}}>
			<>
				{localCover || content?.['Globalizationen-USContentSquareImageUrl'] ? (
					<div
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							top: 0,
							left: 0,
							// background:
							// 	'radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, #000000 75%, transparent) -50%, color-mix(in srgb, #000000 0%, transparent) 100%)',
							background:
								'radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, rgb(0, 0, 0) 10%, transparent) 0%, color-mix(in srgb, rgb(0, 0, 0) 95%, transparent) 100%)',
							// background:
							// 	'radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, var(--mono900) 75%, transparent) -50%, color-mix(in srgb, var(--mono900) 50%, transparent) 100%)',
							zIndex: 1,
						}}
					/>
				) : null}
				<div
					style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: 0,
						left: 0,
						opacity: '0.08',
						zIndex: 1,
						// background: `linear-gradient(0deg, ${content?.ContentColor}, ${content?.ContentColor})`,
						background:
							'linear-gradient(0deg, rgb(108, 108, 112), rgb(108, 108, 112))',
					}}
				/>
				{children ? (
					<div className="hoverWrapper">
						<div className="hoverContent">
							<>
								{/* {content && showInfo ? (
									<div
										className="hoverButtonWrapper"
										onClick={() => {
											const { setRoleInfoSidebar } = useAppStore.getState()
											setRoleInfoSidebar(content?.ContentId)
										}}>
										<InfoFilledIcon />
										<span className="body1-m">{t('APP_INFO')}</span>
									</div>
								) : null} */}
								{children}
							</>
						</div>
					</div>
				) : null}
				{type === 'small' ? (
					<div className={s.smallWrapper}>
						<span
							className="body1-m"
							style={{
								marginTop: 'auto',
							}}>
							{content?.['Globalizationen-USContentTitle'] || ''}
						</span>
					</div>
				) : (
					<div className={s.wrapper}>
						<div className={s.topSection}>
							<div className={s.head}>
								<h3
									className="h3-m"
									style={{
										color: 'var(--mono200)',
									}}>
									{content?.['Globalizationen-USContentTitle'] || ''}
								</h3>
								<StatusCircle
									activeStatus={
										rehearsalDateStatus.label?.includes('EXPIRED')
											? ROLE_STATUS.CLOSED
											: rehearsalDateStatus?.label?.includes('ACTIVE')
											? ROLE_STATUS.OPENED
											: ROLE_STATUS.CASTING
									}
								/>
							</div>

							<div className={s.tags}>
								{content?.ContentProductionType ? (
									<div className={s.tag}>
										<span className="body2-b">
											{
												optionsParser(
													t(DROPDOWN_OPTIONS.PRODUCTION_TYPES),
												)?.find(
													(option) =>
														option.value === content?.ContentProductionType,
												)?.label
											}
										</span>
									</div>
								) : null}
								{content?.ContentLegalNDARequired === 'true' ? (
									<div className={s.tag}>
										<span className="body2-b">{t('APP_NDA_INPUT_LABEL')}</span>
									</div>
								) : null}
							</div>
						</div>

						{showDescription ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '12px',
								}}>
								<span className="body2-m">{t('APP_DESCRIPTION_LABEL')}</span>
								<h5
									className="textClamp"
									style={{
										WebkitLineClamp: 10,
										color: 'var(--mono200)',
									}}>
									{content?.['Globalizationen-USContentDescription']}
								</h5>
							</div>
						) : null}

						<div className={s.info}>
							<div className={s.director}>
								<span
									className="body2-m textEllipsis"
									style={{ color: 'var(--mono200)' }}>
									{t('APP_DIRECTOR_NAME_LABEL')}
								</span>
								<a
									className="h4-m"
									style={{
										color: 'var(--mono200)',
										cursor: 'pointer',
										textDecoration: 'none',
									}}
									href={content?.ContentImdbUrl}
									target="_blank">
									{content?.ContentDirectorName}
								</a>
							</div>
							<div className="grid2column">
								<div
									style={{
										display: 'flex',
										gap: '8px',
										flexDirection: 'column',
									}}>
									<span
										className="caption-m"
										style={{ color: 'var(--mono200)' }}>
										{t('APP_FILMING_DATE_LABEL')}
									</span>
									<span
										className="body1-m"
										style={{ color: 'var(--mono200)', marginTop: 'auto' }}>
										{filmingDate?.length
											? (filmingDate?.[0]
													? dayjs(filmingDate?.[0]).format('MM/DD/YYYY')
													: 'N/A') +
											  ' - ' +
											  (filmingDate?.[1]
													? dayjs(filmingDate?.[1]).format('MM/DD/YYYY')
													: 'N/A')
											: 'N/A'}
									</span>
								</div>
								<div
									style={{
										display: 'flex',
										gap: '8px',
										flexDirection: 'column',
									}}>
									<span
										className="caption-m"
										style={{ color: 'var(--mono200)' }}>
										{' '}
										{t('APP_REHEARSAL_DATE_LABEL')}
									</span>
									<span
										className="body1-m"
										style={{ color: 'var(--mono200)', marginTop: 'auto' }}>
										{rehearsalDate?.length
											? (rehearsalDate?.[0]
													? dayjs(rehearsalDate?.[0]).format('MM/DD/YYYY')
													: 'N/A') +
											  ' - ' +
											  (rehearsalDate?.[1]
													? dayjs(rehearsalDate?.[1]).format('MM/DD/YYYY')
													: 'N/A')
											: 'N/A'}
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
				{localCover || content?.['Globalizationen-USContentSquareImageUrl'] ? (
					<img
						src={
							localCover ||
							content?.['Globalizationen-USContentSquareImageUrl'] ||
							'/placeholder.png'
						}
						alt="prod card background"
					/>
				) : content?.ContentColor ? (
					<></>
				) : (
					<img src={'/placeholder.png'} alt="tutorial placeholder" />
				)}
			</>
		</Card>
	)
}

export default ProductionCard

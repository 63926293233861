import { useShallow } from 'zustand/react/shallow'
import Sidebar from 'components/Sidebar'
import ProjectSelect from './components/ProjectSelect'
import ProjectStatusSelect from './components/ProjectStatusSelect'
import Input from 'components/UI/Input'
import { t } from 'helpers'
import { useAppStore } from 'store'

const LocalSearchSidebar = (): JSX.Element => {
	const [localSearch, setSidebar] = useAppStore(
		useShallow((state) => [
			state.localSearchSidebar,
			state.setLocalSearchSidebar,
		]),
	)

	return (
		<Sidebar
			disableBlur
			open={!!localSearch?.open}
			onClose={() =>
				setSidebar({
					search: localSearch?.search || '',
					open: false,
				})
			}
			header={{
				title: 'APP_SEARCH_ITEMS_SIDEBAR_TITLE',
				description: 'APP_SEARCH_ITEMS_SIDEBAR_DESCRIPTION',
			}}>
			<>
				<Input
					name="search"
					label=""
					placeholder={t('APP_SEARCH_PLACEHOLDER')}
					value={localSearch?.search || ''}
					onChange={(e) => {
						setSidebar({
							open: true,
							search: e.target.value,
						})
					}}
				/>
				{localSearch?.type === 'castings' ? <ProjectSelect /> : null}
				{localSearch?.type === 'myproductions' ? <ProjectStatusSelect /> : null}
			</>
		</Sidebar>
	)
}

export default LocalSearchSidebar

import { useState } from 'react'
import Select from 'components/UI/Select'
import Button from 'components/UI/Button'
import Card from 'components/Wizard/Card'
import { optionsParser, t } from 'helpers'
import { useAppStore, usePortfolioStore } from 'store'
import { basicFields } from 'pages/Portfolio/fields'
import s from '../../index.module.scss'

const Profession = (): JSX.Element => {
	const [profession, setProfession] = useState('')

	const professionField = basicFields.profession

	return (
		<Card
			step="onboarding_profession"
			noHint
			noTitle
			style={{
				padding: '0',
			}}>
			<div className={s.onboardingWrapper}>
				<div className={s.contentWrapper}>
					<div className={s.titles}>
						<h1 className="h1-m">{t('APP_ONBOARDING_PROFESSION_TITLE')}</h1>
						<span className="body1-m">
							{t('APP_ONBOARDING_PROFESSION_DESC')}
						</span>
					</div>
					<div style={{ maxWidth: '300px', width: '100%' }}>
						<Select
							name={professionField.name}
							options={optionsParser(t(professionField.optionName || ''))}
							label={professionField.label}
							onChange={(e) => {
								const { updateFields } = usePortfolioStore.getState()
								setProfession(e.target.value)
								updateFields([
									{
										Name: professionField.name,
										Value: e.target.value,
									},
								])
							}}
							inputProps={{
								value: profession?.toString() || '',
							}}
						/>
					</div>
					<div style={{ maxWidth: '300px', width: '100%' }}>
						<Button
							variant="themed"
							disabled={!profession}
							style={{ width: '100%' }}
							onClick={() => {
								const { stepWizard } = useAppStore.getState()
								stepWizard?.nextStep()
							}}>
							{t('APP_NEXT')}
						</Button>
					</div>
				</div>
				<div className={s.imageWrapper}>
					<img src="/card-bg.png" />
				</div>
			</div>
		</Card>
	)
}

export default Profession

import { useNavigate } from 'react-router-dom'
import { PlusIcon } from 'assets/icons'
import { getDefaultUserPath, t } from 'helpers'
import { useAppStore, useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'

const PortfolioModeMenu = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
			<span className="body1-b" style={{ color: 'var(--theme-primary)' }}>
				{t('APP_PORTFOLIO_MODE_LABEL')}
			</span>
			<div
				style={{ display: 'flex', cursor: 'pointer', marginTop: '3px' }}
				onClick={() => {
					const { setPortfolioMode, portfolioMode } = useAppStore.getState()
					const { setUser, setUserId } = useUserStore.getState()
					if (portfolioMode.originalUser) {
						setUser(portfolioMode.originalUser)
						setUserId(portfolioMode.originalUser.ContentId)
					}
					setPortfolioMode({
						active: false,
						originalUser: undefined,
						asUserData: undefined,
					})
					navigate(getDefaultUserPath(CUSTOMER_ROLE.AGENCY))
				}}>
				<PlusIcon style={{ transform: 'rotate(45deg)' }} />
			</div>
		</div>
	)
}

export default PortfolioModeMenu

export const CircleFillIcon = ({
	colorDefault = 'var(--mono900)',
	colorFilled = 'var(--success)',
	width = '24',
	height = '24',
	strokeDefaultWidth = '2',
	strokeFilledWidth = '2',
	strokeFilledPercents = '0',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		style={{
			...props?.style,
			transform: 'rotate(-90deg)',
		}}>
		<circle
			cx={+width / 2}
			cy={+height / 2}
			r={+width / 2 - +strokeFilledWidth}
			fill="none"
			stroke={colorDefault}
			strokeWidth={strokeDefaultWidth}
		/>
		<circle
			strokeLinecap="round"
			strokeDashoffset={
				2 *
				3.14 *
				(+width / 2 - +strokeFilledWidth) *
				(1 - +strokeFilledPercents / 100)
			}
			strokeDasharray={2 * 3.14 * (+width / 2 - +strokeFilledWidth)}
			cx={+width / 2}
			cy={+height / 2}
			r={+width / 2 - +strokeFilledWidth}
			fill="none"
			stroke={strokeFilledPercents === '0' ? '' : colorFilled}
			strokeWidth={strokeFilledWidth}
		/>
	</svg>
)

import { Fragment, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import {
	LightbulbIcon,
	LogoutIcon,
	MessageIcon,
	ProfileIcon,
	QRCodeIcon,
	SettingsIcon,
} from 'assets/icons'
import useUser from 'hooks/api/useUser'
import { getUser, logout } from 'helpers/storage'
import { t } from 'helpers'
import { useAppStore } from 'store'
import { User } from 'types/user'
import { PATHS } from 'pages/Router'
import s from '../../index.module.scss'
import style from './index.module.scss'

interface Props {
	user: User
}

const Account = ({ user }: Props): JSX.Element => {
	const [showDropdown, setShowDropdown] = useState(false)

	return (
		<div
			className={s.headerDropdown}
			onMouseOver={() => setShowDropdown(true)}
			onMouseLeave={() => setShowDropdown(false)}>
			<div
				className={cn(s.icon, style.avatar)}
				style={{
					borderColor: user?.CustomerRole
						? `var(--${user.CustomerRole.toLowerCase()})`
						: '',
				}}>
				<img
					src={user.CustomerImageSquareUrl}
					alt="user avatar"
					onError={(e) => {
						const target = e.target as HTMLImageElement
						target.src = '/placeholder.png'
					}}
				/>
			</div>
			<MenuDropdown
				show={showDropdown}
				hideDropdown={() => setShowDropdown(false)}
			/>
		</div>
	)
}

export default Account

const MenuDropdown = ({
	show,
	hideDropdown,
}: {
	show: boolean
	hideDropdown: () => void
}): JSX.Element => {
	const navigate = useNavigate()
	const { user } = useUser(getUser()?.['Custom:User:Id'])

	const navigateToProfile = (): void => {
		hideDropdown()
		navigate('/user/' + user?.ContentId)
	}

	const navigateToSettings = (): void => {
		hideDropdown()
		navigate(PATHS.SETTINGS)
	}

	const navigateToTips = (): void => {
		hideDropdown()
		navigate(PATHS.TIPS)
	}

	const openQRSidebar = (): void => {
		const { setOpenQRSidebar } = useAppStore.getState()
		setOpenQRSidebar(true)
	}

	const openFeedbackSidebar = (): void => {
		const { setOpenFeedbackSidebar } = useAppStore.getState()
		setOpenFeedbackSidebar({ open: true })
	}

	const signOut = (): void => {
		hideDropdown()
		logout()
		navigate('/')
	}

	const items = [
		{
			icon: <ProfileIcon width="24" />,
			label: 'APP_PROFILE_DROPDOWN',
			onClick: navigateToProfile,
		},
		{
			icon: <SettingsIcon />,
			label: 'APP_SETTINGS_DROPDOWN',
			onClick: navigateToSettings,
		},
		{
			icon: <QRCodeIcon />,
			label: 'APP_QRCODE_DROPDOWN',
			onClick: openQRSidebar,
		},
		{
			icon: <MessageIcon />,
			label: 'APP_FEEDBACK_DROPDOWN',
			onClick: openFeedbackSidebar,
		},
		{
			icon: <LightbulbIcon style={{ padding: '0px 6px' }} />,
			label: 'APP_TIPS_DROPDOWN',
			onClick: navigateToTips,
		},
		{
			icon: <LogoutIcon />,
			label: 'APP_LOGOUT_DROPDOWN',
			onClick: signOut,
		},
	]

	return (
		<div className={cn(s.dropdown, { [s.show]: show })}>
			{items?.map((item, index, array) => (
				<Fragment key={item.label}>
					{index === array?.length - 1 ? (
						<div className={style.divider} />
					) : null}
					<div className={style.item} onClick={item.onClick}>
						<div>{item.icon}</div>
						<span>{t(item.label)}</span>
					</div>
				</Fragment>
			))}
		</div>
	)
}

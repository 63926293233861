import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import { ArrowShortIcon, CompleteIcon } from 'assets/icons'
import { optionsParser, t } from 'helpers'
import { usePortfolioStore } from 'store'
import { AttributeCardType, ValueLevel } from 'types/app'
import { PORTFOLIO_TYPES } from 'types/enums'
import s from './index.module.scss'

interface Props {
	generalType: PORTFOLIO_TYPES
	cardData: AttributeCardType
	type: string
}

const AttributeCard = ({ generalType, type, cardData }: Props): JSX.Element => {
	const { selectors, icon } = cardData
	const values = usePortfolioStore(
		useShallow((state) => state.cardValues[type] || {}),
	)

	const cardActive = !!Object.values(values)?.filter((v) =>
		Array.isArray(v) ? !!v.length && !Array.isArray(v?.[0]) : !!v,
	)?.length

	const completed =
		generalType === PORTFOLIO_TYPES.SKILL
			? Object.values(values).filter(
					(v) =>
						Array.isArray(v) &&
						!Array.isArray(v?.[0]) &&
						Object.values(v?.[0] || {})?.filter((v) => !!v)?.length >=
							(selectors?.length || 0),
			  )?.length
			: Object.values(values)?.filter((v) =>
					Array.isArray(v) ? !!v.length : !!v,
			  )?.length >= (selectors?.length || 0)

	const selectorsData = useMemo(
		() =>
			selectors?.map((select) => ({
				...select,
				options: optionsParser(t(select.optionName || '')),
			})),
		[selectors],
	)

	const parsed = useMemo(
		() =>
			Object.entries(values)
				.map(([key, value]) =>
					selectorsData
						?.find((data) => data.name === key)
						?.options?.filter((option) =>
							Array.isArray(value)
								? (value as string[])?.includes(option.value) ||
								  (value as ValueLevel[])?.find(
										(v) => v?.Value === option.value,
								  )
								: option.value === value,
						),
				)
				?.filter((v) => !!v)
				?.map((v) => v?.map((v) => v.label))
				.flat()
				?.join(', '),
		[selectorsData, values],
	)

	return (
		<div className={s.container}>
			<div
				className={cn(s.wrapper, {
					[s.active]: cardActive,
				})}
				onClick={() => {
					const { setModalType, setGeneralModalType, setCardData } =
						usePortfolioStore.getState()
					setGeneralModalType(generalType)
					setModalType(type)
					setCardData(cardData)
				}}>
				<div className={s.icon}>{icon}</div>
				<div style={{ marginRight: 'auto' }}>
					<span className="body1-m" style={{ whiteSpace: 'nowrap' }}>
						{t(`APP_${generalType.toUpperCase()}_${type.toUpperCase()}`)}
					</span>
				</div>
				{parsed ? (
					<span className="body1-m textEllipsis" style={{ marginLeft: '30px' }}>
						{parsed}
					</span>
				) : null}
				<div className={s.completed}>
					<CompleteIcon
						color={completed ? 'var(--success)' : 'var(--warning)'}
						style={!completed && !cardActive ? { display: 'none' } : {}}
					/>
					<ArrowShortIcon
						color={cardActive ? 'var(--mono100)' : 'var(--mono300)'}
					/>
				</div>
			</div>
		</div>
	)
}

export default AttributeCard

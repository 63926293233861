import Sidebar from 'components/Sidebar'
import { useCastingStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import Message from './components/Message'

const MessageToActorSidebar = (): JSX.Element => {
	const [openSidebar, setOpenSidebar] = useCastingStore(
		useShallow((state) => [
			state.openMessageToActorSidebar,
			state.setOpenMessageToActorSidebar,
		]),
	)

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			disableBlur
			header={{
				title: 'APP_MESSAGE_TO_ACTOR_SIDEBAR_TITLE',
				description: 'APP_MESSAGE_TO_ACTOR_SIDEBAR_DESCRIPTION',
			}}
			style={{
				height: '100%',
				paddingBottom: '0',
			}}>
			<>{openSidebar ? <Message /> : null}</>
		</Sidebar>
	)
}

export default MessageToActorSidebar

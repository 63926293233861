import { ChangeEvent } from 'react'
import cn from 'classnames'
import ColorSelectButton from '../ColorSelectButton'
import { t } from 'helpers'
import { SelectOption } from 'types/app'

interface Props {
	name: string
	label?: string
	value: string
	options: SelectOption[]
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const ColorSelect = ({
	name,
	label,
	value,
	options,
	onChange,
}: Props): JSX.Element => (
	<div style={{ display: 'flex', flexDirection: 'column' }}>
		{label ? (
			<span className={cn('inputLabel', 'caption-m')}>{t(label)}</span>
		) : null}
		<div
			style={{
				display: 'flex',
				gap: '8px',
				overflowX: 'scroll',
				width: '100%',
			}}
		>
			{options?.map((option) => (
				<ColorSelectButton
					key={option.value}
					{...option}
					checked={value === option.value}
					onChange={() =>
						onChange({
							target: {
								name: name,
								value: option.value,
							},
						} as ChangeEvent<HTMLInputElement>)
					}
				/>
			))}
		</div>
	</div>
)

export default ColorSelect

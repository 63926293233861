import { useShallow } from 'zustand/react/shallow'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Tab from '../Tab'
import TabsWrapper from '../TabsWrapper'
import { useCastingStore, useUserStore } from 'store'
import { PATHS } from 'pages/Router'

const CustomTabs = (): JSX.Element => {
	const customTabs = useCastingStore(
		useShallow((state) => state.customCastingTabs),
	)
	const navigate = useNavigate()
	const { hash } = useLocation()
	const { productionId, roleId, userId } = useParams()

	return (
		<TabsWrapper>
			<>
				{customTabs?.map((tab, index) => (
					<Tab
						key={tab?.name + index}
						tabName={tab?.name}
						onClick={() => {
							const { user } = useUserStore.getState()
							navigate(
								`/${user?.CustomerRole?.toLowerCase()}/${
									PATHS.CASTING
								}/${productionId}/${roleId}/${userId}` +
									`#${
										tab?.substeps?.[0]?.key + '-' + tab?.substeps?.[0]?.index
									}`,
							)
						}}
						active={
							tab?.substeps?.[0]?.key + '-' + tab?.substeps?.[0]?.index ===
							hash?.slice(1)
						}
					/>
				))}
			</>
		</TabsWrapper>
	)
}

export default CustomTabs

import { KeyedMutator } from 'swr'
import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { useAppStore } from 'store'
import { FetchReturn, RelationListResponse, Relation } from 'types/app'
import { RELATIONS } from 'types/enums'

type ReturnData = FetchReturn & {
	data: Relation[] | undefined
	refresh: KeyedMutator<RelationListResponse>
}

export default (
	relation: RELATIONS,
	targetOrSourceId: string,
	type = 'Content',
	query = '',
): ReturnData => {
	const { endpoints } = useAppStore.getState()
	let relationUrl = endpoints?.CustomApplicationV3ConnectionPostUrl

	if (relation && targetOrSourceId)
		relationUrl += '/' + targetOrSourceId + '/' + type + '/' + relation
	else relationUrl = ''

	const { data, isLoading, error, mutate } =
		useSwrImmutable<RelationListResponse>(
			relationUrl ? relationUrl + query : null,
			fetcher,
			{
				shouldRetryOnError: false,
			},
		)

	return {
		data: data ? data.pageContent : undefined,
		isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		refresh: mutate,
	}
}

export const HexagonIcon = ({
	color = 'var(--mono100)',
	width = '96',
	height = '106',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 96 106"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<g filter="url(#filter0_f_2194_56451)">
			<path
				d="M43.5379 4.20862C46.2991 2.59713 49.701 2.59713 52.4621 4.20862L87.5691 24.698C90.3303 26.3095 92.0313 29.2876 92.0313 32.5106V73.4894C92.0313 76.7124 90.3303 79.6905 87.5691 81.302L52.4621 101.791C49.701 103.403 46.2991 103.403 43.5379 101.791L8.43088 81.302C5.6697 79.6905 3.96875 76.7124 3.96875 73.4894V32.5106C3.96875 29.2876 5.6697 26.3095 8.43088 24.698L43.5379 4.20862Z"
				stroke={color}
				strokeWidth="2"
			/>
		</g>
		<defs>
			<filter
				id="filter0_f_2194_56451"
				x="0.96875"
				y="0"
				width="94.0625"
				height={height}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape"
				/>
				<feGaussianBlur
					stdDeviation="1"
					result="effect1_foregroundBlur_2194_56451"
				/>
			</filter>
		</defs>
	</svg>
)

import { useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import InvitesToPoolCarousel from './components/InvitesToPoolCarousel'
import TopPortfoliosCarousel from '../../../TopPortfoliosCarousel'
import CastingDirectorsCarousel from './components/CastingDirectorsCarousel'
import AgencyCarousel from './components/AgencyCarousel'

const tabs = [
	'APP_INVITES_TO_POOL',
	'APP_TOP_PORTFOLIOS_TAB',
	'APP_CASTING_DIRECTORS_TAB',
	'APP_AGENCIES_NETWORK_TAB',
]?.map((label, index) => ({
	label,
	value: index,
}))

const ActorCarousels = (): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(tabs?.[0].value)

	return (
		<>
			<SidebarTabs
				active={activeTab}
				options={tabs}
				onChange={(v) => {
					setActiveTab(v as number)
					swiperRef.current?.slideTo(v as number)
				}}
				type="fit"
			/>
			<Swiper
				allowTouchMove={false}
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper
				}}
				style={{ width: '100%' }}>
				<SwiperSlide>
					<InvitesToPoolCarousel />
				</SwiperSlide>
				<SwiperSlide>
					<TopPortfoliosCarousel />
				</SwiperSlide>
				<SwiperSlide>
					<CastingDirectorsCarousel />
				</SwiperSlide>
				<SwiperSlide>
					<AgencyCarousel />
				</SwiperSlide>
			</Swiper>
		</>
	)
}

export default ActorCarousels

export const LogoutIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.375 16.125C9.83984 16.125 10.25 16.5352 10.25 17C10.25 17.4922 9.83984 17.875 9.375 17.875H7.625C6.14844 17.875 5 16.7266 5 15.25V8.25C5 6.80078 6.14844 5.625 7.625 5.625H9.375C9.83984 5.625 10.25 6.03516 10.25 6.5C10.25 6.99219 9.83984 7.375 9.375 7.375H7.625C7.13281 7.375 6.75 7.78516 6.75 8.25V15.25C6.75 15.7422 7.13281 16.125 7.625 16.125H9.375ZM18.7266 11.1484C19.082 11.4766 19.082 12.0508 18.7266 12.3789L15.2266 15.8789C14.8984 16.2344 14.3242 16.2344 13.9961 15.8789C13.6406 15.5508 13.6406 14.9766 13.9961 14.6484L15.9922 12.625H10.25C9.75781 12.625 9.375 12.2422 9.375 11.75C9.375 11.2852 9.75781 10.875 10.25 10.875H15.9922L13.9961 8.87891C13.6406 8.55078 13.6406 7.97656 13.9961 7.64844C14.3242 7.29297 14.8984 7.29297 15.2266 7.64844L18.7266 11.1484Z"
			fill={color}
		/>
	</svg>
)

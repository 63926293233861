import { fetcher } from 'api'
import { getUser } from 'helpers/storage'
import { useAppStore } from 'store'
import { RelationListResponse } from 'types/app'
import { RELATIONS } from 'types/enums'

type Relation = {
	extraData: string | null
	relation: string
	sourceId: string
	targetId: string
	type: string
	createdDate: string
	status?: number
}

type RelationProps = {
	relation: RELATIONS
	targetId: string
	sourceId?: string
	index?: number
	type?: 'Content' | 'Customer' | 'Relationship'
	status?: string
	extraData?: string
}

export const addRelation = ({
	relation,
	targetId,
	sourceId,
	index,
	type = 'Content',
	status,
	extraData,
}: RelationProps): Relation | Promise<Relation> => {
	const user = getUser()
	// const coords = getGeolocation()
	const sourceID = sourceId || (user ? user['Custom:User:Id'] : null)
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationV3ConnectionPostUrl
	if (!url) {
		throw new Error('URL not found')
	}

	const data = {
		sourceId: sourceID,
		relation,
		targetId,
		type,
		index,
		status,
		extraData,
	}
	// if (coords) {
	// body.latitude = coords.latitude
	// body.longitude = coords.longitude
	// }

	return fetcher(`${url}`, {
		method: 'post',
		body: JSON.stringify(data),
	})
}

export const deleteRelation = (
	sourceId: string,
	type: 'Customer' | 'Content' | 'Relationship',
	relation: RELATIONS,
	targetId: string,
): Relation | Promise<Relation> => {
	const { endpoints } = useAppStore.getState()
	let url = endpoints?.CustomApplicationV3ConnectionPostUrl
	if (!url) {
		throw new Error('URL not found')
	}

	url += `/${sourceId}/${type}/${relation}/${targetId}`

	return fetcher(`${url}`, {
		method: 'delete',
	})
}

export const multiRelationUpdate = (
	sourceId: string,
	targetId: string,
	relation: RELATIONS = RELATIONS.PLAYLIST,
	extraData?: string,
	index?: number,
): Promise<Relation> => {
	const { endpoints } = useAppStore.getState()
	const url =
		endpoints?.CustomApplicationV3ConnectionPostUrl + '/MultiRelationshipUpdate'

	const data = {
		type: 'Content',
		relation,
		sourceId,
		status: '',
		updateStrategy: 'Append',
		extraData,
		index,
		targets: [
			{
				targetId,
			},
		],
	}

	return fetcher(`${url}`, {
		method: 'put',
		body: JSON.stringify(data),
	})
}

export const getRelations = (
	sourceId: string,
	type: 'Customer' | 'Content' | 'Relationship',
	relation: RELATIONS,
	targetId?: string,
): Promise<RelationListResponse> => {
	const { endpoints } = useAppStore.getState()
	let url = endpoints?.CustomApplicationV3ConnectionPostUrl
	if (!url) {
		throw new Error('URL not found')
	}

	url += `/${sourceId}/${type}/${relation}${targetId ? '/' + targetId : ''}`

	return fetcher(`${url}`, {
		method: 'get',
	})
}

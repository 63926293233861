import cn from 'classnames'
import { CorrectIcon } from 'assets/icons'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	label: string
	value: string
	active: string
	onClick: (v: string) => void
	icon?: JSX.Element
}

const RadioButton = ({ label, value, active, onClick }: Props): JSX.Element => (
	<div
		className={cn(s.container, { [s.active]: active === value })}
		onClick={() => onClick(value)}>
		<div className={s.wrapper}>
			<div className={cn(s.checkBox, { [s.checked]: active === value })}>
				<CorrectIcon width="40" height="40" color="var(--theme-primary)" />
			</div>
			<span className="body1-b textEllipsis">{t(label)}</span>
		</div>
	</div>
)

export default RadioButton

import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import ActorPortfolio from './ActorPortfolio'
import { CUSTOMER_ROLE } from 'types/user'
import DirectorPortfolio from './DirectorPortfolio'

const Portfolio = (): JSX.Element | null => {
	const { pathname } = useLocation()
	const userRole = pathname?.split('/')[1]

	const component = useMemo(() => {
		const components = {
			[CUSTOMER_ROLE.ACTOR]: <ActorPortfolio />,
			[CUSTOMER_ROLE.AGENCY]: <DirectorPortfolio />,
			[CUSTOMER_ROLE.CASTING_DIRECTOR]: <DirectorPortfolio />,
			[CUSTOMER_ROLE.CONTRIBUTOR]: <DirectorPortfolio />,
		}

		return userRole ? components[userRole as CUSTOMER_ROLE] : null
	}, [userRole])

	return component
}

export default Portfolio

import useSwrImmutable from 'swr/immutable'
import { multipleRelationFetcher } from './useRelationsContent'
import { useAppStore } from 'store'
import { Content } from 'types/app'
import { RELATIONS } from 'types/enums'

type Return = {
	isLoading: boolean
	contents: Content[][] | undefined
}

export default (contentIds: string[] | undefined): Return => {
	const { endpoints } = useAppStore.getState()
	const relationUrl = endpoints?.CustomApplicationV3ConnectionPostUrl

	const urls = contentIds?.map(
		(id) => relationUrl + `/${id}/Content/${RELATIONS.REFERENCE}`,
	)

	const { data, isLoading } = useSwrImmutable<Content[][]>(
		contentIds?.length ? urls : null,
		(url) => multipleRelationFetcher(url, false),
	)

	return {
		contents: data,
		isLoading,
	}
}

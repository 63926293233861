export const MagnifyingGlassIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '25',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M18.6719 17.521C19.082 17.9585 19.082 18.6421 18.6719 19.0796C18.2344 19.4897 17.5508 19.4897 17.1133 19.0796L13.8594 15.7983C12.7383 16.5366 11.3711 16.9194 9.89453 16.7007C7.37891 16.3726 5.35547 14.3218 5.02734 11.8335C4.5625 8.0874 7.67969 4.97021 11.4258 5.43506C13.9141 5.76318 15.9648 7.78662 16.3203 10.3022C16.5117 11.7788 16.1289 13.146 15.3906 14.2397L18.6719 17.521ZM12.875 11.7241C13.2305 11.7241 13.5312 11.4507 13.5312 11.0679C13.5312 10.7124 13.2305 10.4116 12.8477 10.4116H11.3164V8.88037C11.3164 8.5249 10.9883 8.22412 10.6602 8.22412C10.3047 8.22412 10.0312 8.5249 10.0312 8.88037V10.4116H8.47266C8.11719 10.4116 7.84375 10.7124 7.84375 11.0679C7.84375 11.4507 8.11719 11.7241 8.47266 11.7241H10.0312V13.2554C10.0312 13.6382 10.3047 13.9116 10.6875 13.9116C11.0156 13.9116 11.3164 13.6382 11.3164 13.2554V11.7241H12.875Z"
			fill={color}
		/>
	</svg>
)

import { useMemo } from 'react'
import RangeInput from 'components/UI/RangeInput'
import { t } from 'helpers'
import { usePortfolioStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import { ft, ib } from 'helpers/constants'

interface Props {
	name: string
	label: string
}

const RangeWrapper = ({ name, label }: Props): JSX.Element => {
	const value = usePortfolioStore(
		useShallow(
			(state) =>
				state.cardValues[state.modalType] && state.cardValues[state.modalType],
		),
	)

	const valueLabel = useMemo(() => {
		const weightType = name?.includes('Weight')
		const v = value[name] ? +value[name] : 0
		if (weightType) return `${v} kg / ${(v * ib).toFixed(1)} Ib`
		else return `${v} cm / ${(v * ft).toFixed(1)} ft`
	}, [value, name])

	return (
		<RangeInput
			label={t(label)}
			valueLabel={valueLabel}
			value={+value[name] || 0}
			name={name}
			min={0}
			max={name?.includes('Weight') ? 200 : 300}
			onBlur={(e) => {
				const { updateFields } = usePortfolioStore.getState()
				updateFields([
					{
						Name: name,
						Value: e.target.value,
					},
				])
			}}
			onChange={(e) => {
				const { setCardValues, setGalleryType } = usePortfolioStore.getState()
				setCardValues({
					[name]: e.target.value,
				})
				setGalleryType({
					label: label,
					value: e.target.value,
				})
			}}
		/>
	)
}

export default RangeWrapper

export const GoogleIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M19.545 10.5585C19.6384 11.0954 19.6849 11.6395 19.684 12.1845C19.684 14.6185 18.814 16.6765 17.3 18.0695H17.302C15.978 19.2925 14.158 20.0005 12 20.0005C9.87827 20.0005 7.84344 19.1576 6.34315 17.6573C4.84285 16.157 4 14.1222 4 12.0005C4 9.87875 4.84285 7.84391 6.34315 6.34362C7.84344 4.84333 9.87827 4.00048 12 4.00048C13.9857 3.97836 15.9032 4.72429 17.352 6.08248L15.068 8.36648C14.2422 7.57973 13.1404 7.14879 12 7.16648C9.913 7.16648 8.14 8.57448 7.508 10.4705C7.1735 11.4641 7.1735 12.5399 7.508 13.5335H7.511C8.146 15.4265 9.916 16.8345 12.003 16.8345C13.081 16.8345 14.007 16.5585 14.725 16.0705H14.722C15.139 15.7944 15.4957 15.4366 15.7705 15.0188C16.0453 14.6009 16.2326 14.1317 16.321 13.6395H12V10.5595L19.545 10.5585Z"
			fill={color}
		/>
	</svg>
)

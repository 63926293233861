import Modal from 'components/Modal'
import { useModalStore } from 'store'
import { useShallow } from 'zustand/react/shallow'

const GlobalModal = (): JSX.Element | null => {
	const [modal, setModal] = useModalStore(
		useShallow((state) => [state.modal, state.setModal]),
	)

	return modal?.show ? (
		<Modal onClose={() => (modal?.onClose ? modal.onClose() : setModal(null))}>
			{modal?.children || <></>}
		</Modal>
	) : null
}

export default GlobalModal

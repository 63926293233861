import { CSSProperties } from 'react'
import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip'
import { t } from 'helpers'
import s from './index.module.scss'

const Tooltip = ({
	title,
	children,
	offsetTop,
	wrapperStyles,
	...props
}: TooltipProps & {
	offsetTop?: number
	wrapperStyles?: CSSProperties
}): JSX.Element => (
	<MuiTooltip
		{...props}
		className={s.tooltip}
		componentsProps={{
			tooltip: {
				sx: {
					bgcolor: 'var(--elev400)',
					color: 'var(--mono200)',
					fontSize: '14px',
					fontFamily: 'Lato Regular',
				},
			},
		}}
		slotProps={{
			popper: {
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, offsetTop],
						},
					},
				],
			},
		}}
		title={t((title || '') as string)}>
		<div style={{ display: 'flex', ...wrapperStyles }}>{children}</div>
	</MuiTooltip>
)

export default Tooltip

export const UserIcon = ({
	color = 'var(--mono100)',
	width = '18',
	height = '21',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 18 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10.875 12.375C14.6641 12.375 17.75 15.4609 17.75 19.25C17.75 19.9531 17.1641 20.5 16.5 20.5H1.5C0.796875 20.5 0.25 19.9531 0.25 19.25C0.25 15.4609 3.29688 12.375 7.125 12.375H10.875ZM2.125 18.625H15.8359C15.5234 16.1641 13.4141 14.25 10.875 14.25H7.125C4.54688 14.25 2.4375 16.1641 2.125 18.625ZM9 10.5C6.22656 10.5 4 8.27344 4 5.5C4 2.76562 6.22656 0.5 9 0.5C11.7344 0.5 14 2.76562 14 5.5C14 8.27344 11.7344 10.5 9 10.5ZM9 2.375C7.24219 2.375 5.875 3.78125 5.875 5.5C5.875 7.25781 7.24219 8.625 9 8.625C10.7188 8.625 12.125 7.25781 12.125 5.5C12.125 3.78125 10.7188 2.375 9 2.375Z"
			fill={color}
		/>
	</svg>
)

export const InstagramIcon = ({
	color = 'var(--mono100)',
	width = '32',
	height = '33',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M10.3974 3.16602H21.5974C25.8641 3.16602 29.3307 6.63268 29.3307 10.8993V22.0993C29.3307 24.1504 28.516 26.1174 27.0657 27.5676C25.6154 29.0179 23.6484 29.8327 21.5974 29.8327H10.3974C6.13073 29.8327 2.66406 26.366 2.66406 22.0993V10.8993C2.66406 8.84834 3.47882 6.88134 4.9291 5.43106C6.37938 3.98078 8.34639 3.16602 10.3974 3.16602ZM10.1307 5.83268C8.85769 5.83268 7.63679 6.3384 6.73662 7.23857C5.83644 8.13874 5.33073 9.35964 5.33073 10.6327V22.366C5.33073 25.0193 7.4774 27.166 10.1307 27.166H21.8641C23.1371 27.166 24.358 26.6603 25.2582 25.7601C26.1583 24.86 26.6641 23.6391 26.6641 22.366V10.6327C26.6641 7.97935 24.5174 5.83268 21.8641 5.83268H10.1307ZM22.9974 7.83268C23.4394 7.83268 23.8633 8.00828 24.1759 8.32084C24.4885 8.6334 24.6641 9.05732 24.6641 9.49935C24.6641 9.94138 24.4885 10.3653 24.1759 10.6779C23.8633 10.9904 23.4394 11.166 22.9974 11.166C22.5554 11.166 22.1314 10.9904 21.8189 10.6779C21.5063 10.3653 21.3307 9.94138 21.3307 9.49935C21.3307 9.05732 21.5063 8.6334 21.8189 8.32084C22.1314 8.00828 22.5554 7.83268 22.9974 7.83268ZM15.9974 9.83268C17.7655 9.83268 19.4612 10.5351 20.7114 11.7853C21.9617 13.0355 22.6641 14.7312 22.6641 16.4993C22.6641 18.2675 21.9617 19.9632 20.7114 21.2134C19.4612 22.4636 17.7655 23.166 15.9974 23.166C14.2293 23.166 12.5336 22.4636 11.2833 21.2134C10.0331 19.9632 9.33073 18.2675 9.33073 16.4993C9.33073 14.7312 10.0331 13.0355 11.2833 11.7853C12.5336 10.5351 14.2293 9.83268 15.9974 9.83268ZM15.9974 12.4993C14.9365 12.4993 13.9191 12.9208 13.169 13.6709C12.4188 14.4211 11.9974 15.4385 11.9974 16.4993C11.9974 17.5602 12.4188 18.5776 13.169 19.3278C13.9191 20.0779 14.9365 20.4993 15.9974 20.4993C17.0583 20.4993 18.0757 20.0779 18.8258 19.3278C19.576 18.5776 19.9974 17.5602 19.9974 16.4993C19.9974 15.4385 19.576 14.4211 18.8258 13.6709C18.0757 12.9208 17.0583 12.4993 15.9974 12.4993Z"
			fill={color}
		/>
	</svg>
)

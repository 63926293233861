import { LightbulbIcon } from 'assets/icons'
import s from './index.module.scss'
import { CSSProperties } from 'react'

interface Props {
	hintText: string
	style?: CSSProperties
}

const Hint = ({ hintText, style }: Props): JSX.Element =>
	hintText ? (
		<div className={s.hint} style={style}>
			<div>
				<LightbulbIcon color="var(--mono400)" />
			</div>
			<p className="caption-r">{hintText}</p>
		</div>
	) : (
		<></>
	)

export default Hint

import { useShallow } from 'zustand/react/shallow'
import Input from 'components/UI/Input'
import RangeInput from 'components/UI/RangeInput'
import { secToHHMMSS } from 'helpers/dateTimeConfig'
import { useAppStore } from 'store'

const Details = (): JSX.Element => {
	const [scene, setScene] = useAppStore(
		useShallow((state) => [
			state.addSceneSidebar.scene,
			state.addSceneSidebar.setScene,
		]),
	)

	return (
		<>
			<Input
				name="title"
				label="APP_NEWSCENE_TITLE"
				value={scene?.title}
				onChange={(e) => setScene({ title: e.target.value })}
			/>
			<Input
				name="code"
				label="APP_NEWSCENE_CODE"
				value={scene?.code}
				onChange={(e) => setScene({ code: e.target.value })}
			/>
			<RangeInput
				name="duration"
				label="APP_NEWSCENE_DURATION"
				min={30}
				max={600}
				value={scene?.duration}
				valueLabel={
					scene?.duration + ' sec / ' + secToHHMMSS(scene?.duration) + ' min'
				}
				onChange={(e) => setScene({ duration: +e.target.value })}
			/>
		</>
	)
}

export default Details

export const ReplaceIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M17.7773 6.47412C18.2422 6.00928 19.0625 6.3374 19.0625 7.021V10.9858C19.0625 11.2866 18.8164 11.5054 18.543 11.5054H14.5234C13.8672 11.5054 13.5664 10.7124 14.0039 10.2476L15.2344 9.04443C14.4961 8.41553 13.5117 8.00537 12.5 8.00537C10.668 8.00537 9.05469 9.18115 8.50781 10.9038C8.37109 11.3687 7.87891 11.6147 7.41406 11.478C6.94922 11.3413 6.70312 10.8491 6.83984 10.3843C7.63281 7.92334 9.90234 6.25537 12.5 6.25537C14.0039 6.25537 15.3984 6.85693 16.4648 7.81396L17.7773 6.47412ZM17.5586 13.3101C18.0234 13.4468 18.2695 13.939 18.1055 14.4038C17.3125 16.8647 15.043 18.5054 12.4727 18.5054C10.9688 18.5054 9.57422 17.9312 8.50781 16.9741L7.19531 18.314C6.73047 18.7788 5.9375 18.4507 5.9375 17.7671V13.8022C5.9375 13.5015 6.15625 13.2827 6.42969 13.2554H10.4492C11.1055 13.2827 11.4062 14.0757 10.9688 14.5132L9.73828 15.7437C10.5039 16.3726 11.4609 16.7554 12.5 16.7554C14.3047 16.7554 15.918 15.6069 16.4648 13.8843C16.6016 13.4194 17.0938 13.1733 17.5586 13.3101Z"
			fill={color}
		/>
	</svg>
)

import cn from 'classnames'
import { t } from 'helpers'
import s from './index.module.scss'
import Tooltip from 'components/Tooltip'

interface Props {
	checked: boolean
	onChange: () => void
	label: string
	hint?: string
	color?: string
}

const ColorSelectButton = ({
	checked,
	onChange,
	label,
	hint,
	color,
}: Props): JSX.Element => (
	<Tooltip title={hint || ''}>
		<div
			className={cn(s.container, { [s.checked]: checked })}
			onClick={onChange}>
			<div className={s.wrapper}>
				<div className={s.color} style={{ backgroundColor: color }}></div>
				<span className="body2-m textEllipsis">{t(label)}</span>
			</div>
		</div>
	</Tooltip>
)

export default ColorSelectButton

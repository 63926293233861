import { useEffect, useMemo, useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { getPinnedMedia } from 'helpers'
import { usePortfolioStore, useUserStore } from 'store'
import { picturesFields } from '../../fields'
import { Content } from 'types/app'
import { PORTFOLIO_TYPES, RELATIONS } from 'types/enums'
import AttributeLargeCard from 'pages/Portfolio/components/AttributeLargeCard'

const Pictures = ({ index = 0 }: { index?: number }): JSX.Element => {
	const userId = useUserStore(useShallow((state) => state.userId))
	const { data } = useRelationsContent([RELATIONS.IMAGE], userId)

	const images = useMemo(() => (data ? data[RELATIONS.IMAGE] : []), [data])

	const dataLoadRef = useRef(false)

	useEffect(() => {
		if (images?.length && !dataLoadRef.current) {
			dataLoadRef.current = true

			const pinned = getPinnedMedia(
				picturesFields?.[0]?.selectors?.[0]?.name?.replaceAll(':', ''),
			)

			const { setCardValues } = usePortfolioStore.getState()
			images?.forEach((content) => {
				const field = picturesFields?.find(
					(f) => f.index === content?.relationIndex,
				)
				if (field) {
					const names = field.selectors?.map((sel) => sel.name)

					const values = names
						?.map((name) => ({
							[name]: name?.includes('Pinned')
								? pinned?.[field.index as number]
								: (content[name as keyof Content] as string) || '',
						}))
						?.reduce((prev, next) => ({ ...prev, ...next }), {})
					setCardValues(
						{
							[field.value]:
								content?.ContentSquareImageUrl || content?.ContentId,
							...values,
							contentId: content?.ContentId,
						},
						field.value,
					)
				}
			})
		}
	}, [images])

	return (
		<Card step="pictures" noHint>
			<>
				<div
					className="grid2column"
					style={{
						height: '100%',
					}}>
					{picturesFields?.slice(4 * index, 4 * index + 4)?.map((field) => (
						<AttributeLargeCard
							generalType={PORTFOLIO_TYPES.PICTURE}
							cardData={field}
							key={field.value}
							type={field.value}
						/>
					))}
				</div>
			</>
		</Card>
	)
}

export default Pictures

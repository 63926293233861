import cn from 'classnames'
import { CSSProperties } from 'react'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	label: string
	value: string
	checked: boolean
	onChange: (v: string) => void
	style?: CSSProperties
}

const Switch = ({
	checked,
	label,
	value,
	onChange,
	style,
}: Props): JSX.Element => {
	return (
		<div className={s.container} onClick={() => onChange(value)} style={style}>
			<span
				className={cn(s.label, 'body1-m')}
				style={{ color: checked ? 'var(--mono100)' : 'var(--mono400)' }}>
				{t(label)}
			</span>
			<div className={cn(s.switch, { [s.active]: checked })}>
				<div />
			</div>
		</div>
	)
}

export default Switch

import { lazy, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import { useShallow } from 'zustand/react/shallow'
import StepWrapper from 'components/Wizard/StepWrapper'
import WizardWrapper from 'components/Wizard/WizardWrapper'
import SceneUploadSidebar from './components/SceneUploadSidebar'
import Controls from 'components/Wizard/Controls'
import AcceptSidebar from './components/AcceptSidebar'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useUserStore } from 'store'
import { PATHS } from 'pages/Router'
import SubmitCastingSidebar from './components/SubmitCastingSidebar'
import UpdateRequestSidebar from './components/UpdateRequestSidebar'
import CastingOverview from '../components/CastingOverview'

const Board = lazy(() => import('./components/Board'))
const RoleDetails = lazy(() => import('./components/RoleDetails'))

export const actorCastingSteps = {
	board: {
		name: 'APP_CASTING_BOARD',
		key: 'board',
		main: true,
		hideSubstepsUntilActive: true,
		substeps: [
			{
				name: 'APP_CASTING_BOARD',
				key: 'board',
				component: Board,
				index: 0,
				disabled: true,
			},
			{
				name: 'APP_CASTING_ROLE',
				key: 'board',
				component: RoleDetails,
				index: 1,
				disabled: true,
			},
			{
				name: 'APP_CASTING_OVERVIEW',
				key: 'board',
				component: CastingOverview,
				index: 2,
				disabled: true,
			},
		],
	},
}

const ActorCasting = (): JSX.Element => {
	const navigate = useNavigate()
	const { roleId } = useParams()
	const wizard = useAppStore(useShallow((state) => state.stepWizard))

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname +
					window.location.search +
					'#' +
					Object.values(actorCastingSteps)?.[0]?.key +
					'-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	useEffect(() => {
		if (wizard?.currentStep && wizard.currentStep === 1 && roleId) {
			const { user } = useUserStore.getState()

			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}` +
					window.location.search +
					window.location.hash,
			)
		}
	}, [navigate, wizard?.currentStep, roleId])

	return (
		<WizardWrapper>
			<>
				<SceneUploadSidebar />
				<AcceptSidebar />
				<SubmitCastingSidebar />
				<UpdateRequestSidebar />
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<StepWizard
						initialStep={0}
						instance={assignStepWizard}
						isHashEnabled={true}>
						{Object.values(actorCastingSteps)
							?.map((step) => step.substeps)
							?.flat()
							?.map((step) => (
								<StepWrapper
									style={{
										height: 'auto',
										display: 'flex',
									}}
									key={step.name + '_' + step.index}
									hashKey={step.key + '-' + step.index}
									stepName={step.key + '-' + step.index}>
									<step.component />
								</StepWrapper>
							))}
					</StepWizard>

					{wizard?.currentStep === 2 ? (
						<Controls
							nextClick={() => EventBus.$emit('submitCastingScenes')}
							nextText="APP_SUBMIT"
							nextIcon={false}
							prevClick={() => {
								const { user } = useUserStore.getState()
								navigate(
									`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}`,
								)
								const { stepWizard } = useAppStore.getState()
								stepWizard?.previousStep()
							}}
							prevText="APP_BACK"
						/>
					) : null}
				</div>
			</>
		</WizardWrapper>
	)
}

export default ActorCasting

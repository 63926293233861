import ActionAnimation from 'components/ActionAnimation'
import Back from './components/Back'
import CastingChat from './components/CastingChat'
import CastingRoles from './components/CastingRoles/CastingRoles'
import CurrentRole from './components/CurrentRole'
import Status from './components/Status'
import s from './index.module.scss'

const CastingOverlay = (): JSX.Element => {
	return (
		<div className={s.container} id="casting-overlay-container">
			<CastingRoles />
			<ActionAnimation />
			<CurrentRole />
			<Status />
			<CastingChat />
			<Back />
		</div>
	)
}

export default CastingOverlay

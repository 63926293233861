import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import cn from 'classnames'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import Image1 from 'assets/images/home-car-1.png'
import Image5 from 'assets/images/home-car-5.png'
import CastingImage from 'assets/images/home-casting.png'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import { t } from 'helpers'
import { HOME_SECTIONS } from 'types/enums'
import s from './index.module.scss'

const tabs = [
	{
		label: 'APP_CASTINGDIRECTORS_CAROUSEL_TAB',
		value: 0,
		key: HOME_SECTIONS.CAROUSEL_CASTINGDIRECTOR,
	},
	{
		label: 'APP_ACTORS_CAROUSEL_TAB',
		value: 1,
		key: HOME_SECTIONS.CAROUSEL_ACTORS,
	},
	{
		label: 'APP_AGENCIES_CAROUSEL_TAB',
		value: 2,
		key: HOME_SECTIONS.CAROUSEL_AGENCIES,
	},
]

const Carousel = (): JSX.Element => {
	const { hash } = useLocation()
	const swiperRef = useRef<SwiperClass>()
	const [tab, setTab] = useState(1)

	useEffect(() => {
		const carousel = HOME_SECTIONS?.CAROUSEL_ACTORS?.split('-')?.[0]
		if (hash.includes(carousel)) {
			const tab = tabs?.find((t) => t.key === hash?.slice(1))
			if (tab) {
				setTab(tab.value)
				swiperRef.current?.slideTo(tab.value)
			}
		}
	}, [hash])

	return (
		<section
			className={s.container}
			id={HOME_SECTIONS.CAROUSEL_ACTORS?.split('-')?.[0]}>
			<h2 style={{ textAlign: 'center' }}>{t('APP_HOME_CAROUSEL_TITLE')}</h2>
			<SidebarTabs
				active={tab}
				onChange={(v) => {
					setTab(v as number)
					swiperRef.current?.slideTo(v as number)
				}}
				options={tabs}
				style={{
					overflow: 'auto',
					maxWidth: '100%',
				}}
			/>
			<Swiper
				spaceBetween={10}
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper
				}}
				initialSlide={1}
				allowTouchMove={false}
				style={{ width: '100%' }}>
				<SwiperSlide>
					<ItemsWrapper type={'castingdirector'?.toUpperCase()} />
				</SwiperSlide>
				<SwiperSlide>
					<ItemsWrapper type={'actors'.toUpperCase()} />
				</SwiperSlide>
				<SwiperSlide>
					<ItemsWrapper type={'agencies'?.toUpperCase()} />
				</SwiperSlide>
			</Swiper>
		</section>
	)
}

export default Carousel

const ItemsWrapper = ({ type }: { type: string }): JSX.Element => {
	return (
		<div className={s.itemWrapper}>
			<div className={s.titles}>
				<h3>{t(`APP_${type}_CAROUSEL_TITLE`)}</h3>
				<h4 style={{ color: 'var(--mono300)' }}>
					{t(`APP_${type}_CAROUSEL_DESCRIPTION`)}
				</h4>
			</div>
			<div className={cn(s.item, s.mainCard)}>
				<div className={s.content} style={{ margin: '0', gap: '16px' }}>
					<h3>{t(`APP_${type}_CARD2_TITLE`)}</h3>
					<img src={CastingImage} alt="home-carousel-2" />
					<span>{t(`APP_${type}_CARD2_DESCRIPTION`)}</span>
				</div>
			</div>
			<MobileCards type={type} />
			<div className={s.itemsLayout}>
				<div className={s.item}>
					<img src={Image1} alt="home-carousel-1" />
					<div className={s.content}>
						<h3>{t(`APP_${type}_CARD1_TITLE`)}</h3>
						<span>{t(`APP_${type}_CARD1_DESCRIPTION`)}</span>
					</div>
				</div>
				<div className={s.item}>
					<div className={s.content} style={{ margin: '0', gap: '16px' }}>
						<h3>{t(`APP_${type}_CARD2_TITLE`)}</h3>
						<span>{t(`APP_${type}_CARD2_DESCRIPTION`)}</span>
						<img src={CastingImage} alt="home-carousel-2" />
					</div>
				</div>
				<div className={s.item}>
					<div className={s.content}>
						<h3>{t(`APP_${type}_CARD3_TITLE`)}</h3>
						<span>{t(`APP_${type}_CARD3_DESCRIPTION`)}</span>
					</div>
				</div>
				<div className={s.item}>
					<div className={s.content}>
						<h3>{t(`APP_${type}_CARD4_TITLE`)}</h3>
						<span>{t(`APP_${type}_CARD4_DESCRIPTION`)}</span>
					</div>
				</div>
				<div className={s.item}>
					<img src={Image5} alt="home-carousel-5" style={{ height: '100%' }} />
					<div className={s.content}>
						<h3>{t(`APP_${type}_CARD5_TITLE`)}</h3>
						<span>{t(`APP_${type}_CARD5_DESCRIPTION`)}</span>
					</div>
				</div>
			</div>
		</div>
	)
}

const MobileCards = ({ type }: { type: string }): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()

	return (
		<Swiper
			spaceBetween={20}
			onSwiper={(swiper: SwiperClass) => {
				swiperRef.current = swiper
			}}
			slidesPerView={1.1}
			style={{ width: '100%' }}
			className={s.swiper}>
			<SwiperSlide>
				<div className={s.item}>
					<img src={Image1} alt="home-carousel-1" />
					<div className={s.content}>
						<h3>{t(`APP_${type}_CARD1_TITLE`)}</h3>
						<span>{t(`APP_${type}_CARD1_DESCRIPTION`)}</span>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className={s.item}>
					<div className={s.content}>
						<h3>{t(`APP_${type}_CARD3_TITLE`)}</h3>
						<span>{t(`APP_${type}_CARD3_DESCRIPTION`)}</span>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className={s.item}>
					<div className={s.content}>
						<h3>{t(`APP_${type}_CARD4_TITLE`)}</h3>
						<span>{t(`APP_${type}_CARD4_DESCRIPTION`)}</span>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className={s.item}>
					<img src={Image5} alt="home-carousel-5" style={{ height: '100%' }} />
					<div className={s.content}>
						<h3>{t(`APP_${type}_CARD5_TITLE`)}</h3>
						<span>{t(`APP_${type}_CARD5_DESCRIPTION`)}</span>
					</div>
				</div>
			</SwiperSlide>
		</Swiper>
	)
}

import { fetcher } from 'api'
import {
	contentParser,
	getQueryFilter,
	getQueryOrder,
} from 'helpers/propertiesParser'
import { getUser } from 'helpers/storage'
import { useAppStore } from 'store'
import { CollectionsType, PropertiesType } from 'types/app'
import {
	CASTING_STATUS,
	FILTER_CONDITION,
	RELATIONS,
	SORT_DIRECTION,
	SORT_FORMAT,
} from 'types/enums'
import { User } from 'types/user'

export const getUserData = async (
	userId: string,
	bag?: boolean,
): Promise<User | void> => {
	const { endpoints } = useAppStore.getState()

	const cacheUrl =
		userId && endpoints?.CustomApplicationV3CustomerUrl
			? endpoints?.CustomApplicationV3CustomerUrl.replace(
					'${customerId}',
					userId,
			  )
			: ''
	const bagUrl = userId
		? 'https://bgj.theatroo.ent360.blue/api/v2.1/Customer' +
		  '/' +
		  userId +
		  '/PropertyBag'
		: ''

	const url = bag ? bagUrl : cacheUrl
	const data = await fetcher<CollectionsType>(url, { method: 'get' }).catch(
		(error) => {
			return error
		},
	)

	const parsed =
		data &&
		data.Properties &&
		({
			...contentParser(data),
		} as User)

	return parsed
}

export const updateUser = (
	customerId: string,
	data: PropertiesType[],
): Promise<CollectionsType> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints
		? endpoints?.CustomApplicationCustomerV2?.replace(
				'{customerId}',
				customerId,
		  )
		: ''

	return fetcher(url, {
		method: 'put',
		body: JSON.stringify([
			...data,
			{
				Name: 'Customer:Portfolio:Updated',
				Value: new Date().toISOString(),
			},
		]),
	})
}

export const createCustomer = (
	type: 'Project' | 'Organization',
	name: string,
): Promise<CollectionsType> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationRegisterUrl || ''
	const todayISOString = new Date().toISOString()

	const payload = [
		{
			Name: 'Custom:User:Type',
			Value: type,
		},
		{
			Name: 'Custom:User:Name',
			Value: name,
		},
		{
			Name: 'Social:Auth:Provider',
			Value: 'None',
		},
		{
			Name: 'Custom:Device:UniqueId',
			Value: 'None',
		},
		{
			Name: 'Social:Auth:Token',
			Value: 'None',
		},
		{
			Name: 'Custom:License:Accept:Date',
			Value: todayISOString,
		},
		{
			Name: 'Custom:Policy:Accept:Date',
			Value: todayISOString,
		},
		{
			Name: 'Customer:Portfolio:Created',
			Value: todayISOString,
		},
	]

	return fetcher<CollectionsType>(url, {
		method: 'post',
		body: JSON.stringify(payload),
	})
}

export const searchUsers = ({
	sortBy,
	sortDir,
	sortFormat,
	filters,
}: {
	sortBy?: string
	sortDir?: SORT_DIRECTION
	sortFormat?: SORT_FORMAT
	filters?: {
		propName: string
		propValue: string
		cond?: FILTER_CONDITION
		format?: SORT_FORMAT
	}[]
}): Promise<CollectionsType> => {
	const order = sortBy
		? window.btoa(JSON.stringify(getQueryOrder(sortBy, sortDir, sortFormat)))
		: ''
	const filter = window.btoa(
		JSON.stringify(
			filters?.map((f) =>
				getQueryFilter(f.propName, f.propValue, f.cond, f.format),
			),
		),
	)

	const url = `https://bgj.theatroo.ent360.blue/api/v2.1/Customer/Query?o=${order}&f=${filter}&type=Creator&orgs=35ab2507-a3a1-4f9c-ae6d-4ef865655571`

	return fetcher<CollectionsType>(url, {
		method: 'get',
	})
}

export const removeCustomerSoft = (customerId: string): Promise<void> => {
	const url = `https://bgj.theatroo.ent360.blue/api/v2.1/Customer/${customerId}/1`

	return fetcher(url, { method: 'delete' })
}

export const usersBatchInvite = ({
	sourceId,
	customerIds,
	message,
	subject,
	type,
	relation,
	status,
}: {
	sourceId: string
	customerIds: string[]
	message: string
	subject: string
	type: string
	relation: RELATIONS
	status?: string
}): Promise<CollectionsType> => {
	const url = `https://nup.theatroo.ent360.blue/api/v3.0/Batch?emailTemplateId=GenericMessage&subject=${subject}&message=${encodeURIComponent(
		message,
	)}`

	const data = customerIds?.map((id) => ({
		sourceId,
		targetId: id,
		type,
		relation,
		status,
	}))

	return fetcher(url, { method: 'post', body: JSON.stringify(data) })
}

export const bindInvites = (roleId: string): Promise<CollectionsType> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationV3ConnectionPostUrl || ''

	const data = {
		type: 'Relationship',
		relation: 'Relationship',
		sourceId: getUser()?.['Custom:User:Id'],
		targetId: `${roleId}/Content/Access`,
	}

	return fetcher(url, { method: 'post', body: JSON.stringify(data) })
}

export const sendFeedback = (
	title: string,
	body: string,
): Promise<CollectionsType> => {
	// const { endpoints } = useAppStore.getState()
	const url =
		'https://oas.theatroo.ent360.blue/api/v1.0/Messaging/Message/Email/feedback%40' +
		window.location.host

	const data = {
		subject: title,
		body: body,
		htmlBody: true,
	}

	return fetcher(url, { method: 'post', body: JSON.stringify(data) })
}

import UAParser from 'ua-parser-js'

interface Return {
	deviceName: string
	deviceType: string
	deviceSubType: string
	deviceModel: string | undefined
}

const devices = {
	androidP: {
		deviceName: 'Android Phone',
		deviceType: 'MOBILE',
		deviceSubType: 'ANDROIDP',
	},
	androidT: {
		deviceName: 'Android Tablet',
		deviceType: 'TABLET',
		deviceSubType: 'ANDROIDT',
	},
	androidTV: {
		deviceName: 'Android TV',
		deviceType: 'TV',
		deviceSubType: 'ANDROIDTV',
	},
	fireTV: {
		deviceName: 'FireTV',
		deviceType: 'TV',
		deviceSubType: 'FIRETV',
	},
	roku: {
		deviceName: 'ROKU',
		deviceType: 'TV',
		deviceSubType: 'ROKU',
	},
	chromecast: {
		deviceName: 'Chromecast',
		deviceType: 'TV',
		deviceSubType: 'Chromecast',
	},
	iPhone: {
		deviceName: 'iPhone',
		deviceType: 'MOBILE',
		deviceSubType: 'IPHONE',
	},
	iPad: {
		deviceName: 'iPad',
		deviceType: 'TABLET',
		deviceSubType: 'IPAD',
	},
	tvOS: {
		deviceName: 'tvOS',
		deviceType: 'TV',
		deviceSubType: 'APPLETV',
	},
	desktop: {
		deviceName: 'Desktop Browser',
		deviceType: 'DESKTOP',
		deviceSubType: 'DESKTOP',
	},
	mobile: {
		deviceName: 'Mobile Browser',
		deviceType: 'DESKTOP',
		deviceSubType: 'MOBILE',
	},
}

export const isIOS = (): boolean => {
	const device = new UAParser().getDevice()
	const os = new UAParser().getOS()

	return (
		os?.name === 'iOS' ||
		(device?.type === 'mobile' && device?.model === 'iPhone') ||
		(device?.type === 'tablet' && device?.model === 'iPad') ||
		['tablet', 'mobile'].includes(deviceTypeOnSafari())
	)
}

export const deviceTypeOnSafari = (): 'desktop' | 'tablet' | 'mobile' => {
	const browser = new UAParser().getBrowser()?.name

	return browser?.toLowerCase()?.includes('safari') &&
		navigator.maxTouchPoints > 0
		? navigator.userAgent.includes('iPhone')
			? 'mobile'
			: 'tablet'
		: 'desktop'
}

export const getDeviceData = (): Return => {
	const data = new UAParser().getResult()
	let device = {
		deviceName: '',
		deviceType: '',
		deviceSubType: '',
	}
	let model = data.device.model

	if (data.device.type === 'mobile') {
		if (data.device.vendor === 'Apple') {
			device = devices.iPhone
			model = 'iPhone'
		} else device = devices.androidP
	} else if (data.device.type === 'tablet') {
		if (data.device.vendor === 'Apple') {
			device = devices.iPad
			model = 'iPad'
		} else device = devices.androidT
	} else if (data.device.type === 'smarttv') {
		if (data.device.vendor === 'Roku') {
			device = devices.roku
		} else if (data.os.name?.toLowerCase().includes('fire os')) {
			device = devices.fireTV
		} else if (data.device.vendor === 'Google') {
			device = devices.androidTV
		} else if (data.os.name?.toLowerCase().includes('android tv os')) {
			device = devices.androidTV
		} else if (data.device.vendor === 'Apple') {
			device = devices.tvOS
		}
	} else {
		if (
			navigator.maxTouchPoints > 0 &&
			navigator.userAgent.includes('Safari')
		) {
			model = 'iPad'
			device = devices.iPad
		} else device = devices.desktop
	}

	return { ...device, deviceModel: model }
}

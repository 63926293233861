import * as Shaka from 'shaka-player/dist/shaka-player.compiled'
import { useCallback, useEffect, useState } from 'react'

interface ReturnType {
	shakaInstance: Shaka.Player | null
	refreshInstance: () => void
	destroyInstance: () => void
}

const playerConfiguration = {
	abr: {
		defaultBandwidthEstimate: 500000,
		enabled: false,
		switchInterval: 6,
	},
	streaming: {
		bufferBehind: 30,
		bufferingGoal: 30,
		ignoreTextStreamFailures: false,
		rebufferingGoal: 2,
		retryParameters: {
			maxAttempts: 2,
			baseDelay: 1e3,
			backoffFactor: 2,
			fuzzFactor: 0.5,
			timeout: 0,
		},
	},
	manifest: {
		dash: {
			ignoreMinBufferTime: true,
		},
	},
}

const useShakaPlayer = (source: string | null, videoId: string): ReturnType => {
	const [shakaInstance, setShakaInstance] = useState<null | Shaka.Player>(null)
	const [refresh, setRefresh] = useState(false)

	const refreshInstance = useCallback((): void => setRefresh(true), [])

	const destroyInstance = useCallback((): void => {
		shakaInstance?.destroy()
		setShakaInstance(null)
	}, [shakaInstance])

	useEffect(() => {
		if ((source && !shakaInstance) || (source && refresh && shakaInstance)) {
			if (refresh) {
				shakaInstance?.destroy()
				setRefresh(false)
			}

			const element = document.getElementById(videoId) as HTMLVideoElement
			if (!element || !source) return

			const shakaInst = new Shaka.Player()
			shakaInst.attach(element)
			shakaInst.configure(playerConfiguration)

			shakaInst
				.load(source, null)
				.catch((err) => console.error('shaka failed to load:', err))

			setShakaInstance(shakaInst)
		} else if (refresh && !source) {
			shakaInstance?.destroy()
			setShakaInstance(null)
		}
	}, [source, videoId, refresh, shakaInstance])

	return {
		shakaInstance,
		refreshInstance,
		destroyInstance,
	}
}

export default useShakaPlayer

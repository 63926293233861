import { useEffect, useMemo, useState } from 'react'
import TagsItem from './components/TagsItem'
import SkillItem from './components/SkillItem'
import { User } from 'types/user'
import { SelectOption, ValueLevel } from 'types/app'
import { skillFields } from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	user: User
	place: 'sidebar' | 'default'
	skills: {
		value: ValueLevel
		label: string
		levelIndex: number
		levelsOptions: SelectOption[]
		valuesOptions: SelectOption[]
	}[]
}

const hexWidth = 106
const hexLayoutSpace = 20

const Skills = ({ user, place, skills }: Props): JSX.Element => {
	const [itemsPerRow, setItemsPerRow] = useState(0)

	useEffect(() => {
		const getSize = (): void => {
			const wrapper = document.getElementById('skill-hexagons-wrapper')
			if (wrapper) {
				const { width } = wrapper.getBoundingClientRect()
				const hexSize = hexWidth + hexLayoutSpace
				const hexPerRow = Math.floor(width / hexSize)
				setItemsPerRow(hexPerRow)
			}
		}
		setTimeout(() => {
			getSize()
		}, 1000)
		function onResize(): void {
			getSize()
		}
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', onResize)
		}

		return () => {
			if (typeof window !== 'undefined') {
				window.removeEventListener('resize', onResize)
			}
		}
	}, [])

	const nestedSkills = useMemo(() => {
		if (skills?.length && itemsPerRow) {
			const nestedCount = Math.ceil(skills?.length / itemsPerRow)

			return Array.from({
				length: nestedCount + 1,
			}).map((_, index) =>
				skills.slice(itemsPerRow * index, (index + 1) * itemsPerRow),
			)
		} else return []
	}, [itemsPerRow, skills])

	return (
		<div className={s.container}>
			<div className={s.wrapper} id="skill-hexagons-wrapper">
				{nestedSkills?.map((skills, index) => (
					<div
						key={index}
						className={s.row}
						style={
							index % 2
								? {
										transform: `translateX(calc(${
											hexWidth + hexLayoutSpace
										}px / 2))`,
								  }
								: {}
						}>
						{skills?.map((data, index) => (
							<SkillItem key={data.value + data.label + index} {...data} />
						))}
					</div>
				))}
			</div>

			<div className={place === 'sidebar' ? s.tags : 'grid2column'}>
				{[skillFields.specialSkill, skillFields.otherSkill].map((field) => (
					<TagsItem user={user} field={field} key={field.value} />
				))}
			</div>
		</div>
	)
}

export default Skills

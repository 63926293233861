import { useShallow } from 'zustand/react/shallow'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogoFullIcon } from 'assets/icons'
import useUser from 'hooks/api/useUser'
import { useAppStore, useUserStore } from 'store'
import { getDefaultUserPath } from 'helpers'
import { PATHS } from 'pages/Router'
import s from './index.module.scss'

const Logo = (): JSX.Element => {
	const navigate = useNavigate()
	const userId = useUserStore(useShallow((state) => state.userId))
	const portfolioMode = useAppStore(
		useShallow((state) => state.portfolioMode.active),
	)
	const { user } = useUser(userId)

	const logoNavigate = useCallback(() => {
		if (
			window.location.pathname === '/' ||
			window.location.pathname === '/home'
		) {
			navigate('/' + PATHS.HOME)
		} else if (user?.CustomerRole) {
			navigate(getDefaultUserPath(user.CustomerRole) + window.location.hash)
			const { stepWizard } = useAppStore.getState()
			stepWizard?.goToStep(1)
		} else navigate('/' + PATHS.JOIN + '/' + PATHS.LOGIN)
	}, [user?.CustomerRole, navigate])

	return (
		<div className={s.logo} onClick={portfolioMode ? () => null : logoNavigate}>
			<LogoFullIcon width={window.innerWidth < 600 ? '125' : undefined} />
		</div>
	)
}

export default Logo

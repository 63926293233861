import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { actionsParser } from 'helpers/propertiesParser'
import { ContentCollection, Endpoints, FetchReturn } from 'types/app'

type ReturnData = FetchReturn & {
	data: Endpoints | undefined
}

export default (): ReturnData => {
	const { data, isLoading, error } = useSwrImmutable<ContentCollection>(
		'https://cdn.theatroo.ent360.blue/api/v6/config/entity.json',
		fetcher,
	)

	return {
		data: data?.Actions ? actionsParser<Endpoints>(data.Actions) : undefined,
		isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
	}
}

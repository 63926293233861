export const ProfileIcon = ({
	color = 'var(--mono100)',
	width = '25',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.1465 11.75C11.2051 11.75 9.64648 10.1914 9.64648 8.25C9.64648 6.33594 11.2051 4.75 13.1465 4.75C15.0605 4.75 16.6465 6.33594 16.6465 8.25C16.6465 10.1914 15.0605 11.75 13.1465 11.75ZM14.5137 13.0625C17.1387 13.0625 19.2715 15.1953 19.2715 17.8203C19.2715 18.3398 18.834 18.75 18.3145 18.75H7.95117C7.43164 18.75 7.02148 18.3398 7.02148 17.8203C7.02148 15.1953 9.12695 13.0625 11.752 13.0625H14.5137Z"
			fill={color}
		/>
	</svg>
)

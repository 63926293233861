import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
} from 'react-share'
import { useShallow } from 'zustand/react/shallow'
import {
	FacebookIcon,
	LinkedinIcon,
	ShareIcon,
	TwitterIcon,
} from 'assets/icons'
import Sidebar from 'components/Sidebar'
import Loader from 'components/Loader'
import useQRCode from 'hooks/api/useQRCode'
import { optionsParser, t } from 'helpers'
import { useAppStore, useUserStore } from 'store'
import { PATHS } from 'pages/Router'
import { DROPDOWN_OPTIONS } from 'types/enums'
import s from './index.module.scss'
import Google from './google.png'
import Apple from './apple.png'

const QRCodeSidebar = (): JSX.Element => {
	const [openSidebar, setOpenSidebar] = useAppStore(
		useShallow((state) => [state.openQRSidebar, state.setOpenQRSidebar]),
	)
	const user = useUserStore(useShallow((state) => state.user))

	const { image, isLoading } = useQRCode(
		openSidebar && user?.ContentId
			? `/${PATHS.PROFILE?.replace(':userId', user?.ContentId)}`
			: '',
	)

	const genderOptions = optionsParser(DROPDOWN_OPTIONS.GENDERS)
	const professionOptions = optionsParser(DROPDOWN_OPTIONS.PROFESSION)
	const nationOptions = optionsParser(DROPDOWN_OPTIONS.NATION)

	const urlForShare = window.location.origin + '/user/' + user?.ContentId
	const titleForShare = 'Showrealz - Where the talent meets opportunity'

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => {
				setOpenSidebar(false)
			}}
			header={{
				title: 'APP_QRCODE_SIDEBAR_TITLE',
				description: 'APP_QRCODE_SIDEBAR_DESCRIPTION',
			}}
			buttons={[
				{
					children: (
						<>
							{t('APP_SHARE')}
							<ShareIcon /* color="var(--theme-primary)" */ />
						</>
					),
					style: {
						// color: 'var(--theme-primary)',
						fontFamily: 'Lato Bold',
						gap: '10px',
					},
					onClick: () => document.getElementById('qr-code-share-link')?.click(),
				},
			]}>
			<>
				<Loader adapt loading={isLoading} />
				{!image ? (
					<span className="body1-m">{t('APP_NODATA')}</span>
				) : (
					<>
						<div className={s.container}>
							<div className={s.wrapper}>
								<div className={s.avatar}>
									<img
										className="image"
										src={user?.CustomerImageSquareUrl || '/placeholder.png'}
										alt="avatar"
									/>
								</div>
								<h2 className="h2-m">{user?.ContentTitle}</h2>
								<div className={s.tags}>
									{user?.CustomUserGender ? (
										<div className={s.tag}>
											<span className="body2-b">
												{
													genderOptions.find(
														(option) => option.value === user?.CustomUserGender,
													)?.label
												}
											</span>
										</div>
									) : null}
									{user?.CustomerProfession ? (
										<div className={s.tag}>
											<span className="body2-b">
												{
													professionOptions.find(
														(option) =>
															option.value === user?.CustomerProfession,
													)?.label
												}
											</span>
										</div>
									) : null}
								</div>
								<div className={s.code}>
									<img src={URL.createObjectURL(image)} alt="qr-code" />
								</div>
								<div className={s.itemWrapper}>
									<div className={s.item}>
										<span className="body1-r">{t('APP_LOCATION_LABEL')}</span>
										<span className="body1-b">
											{[
												user?.CustomerLocationCity,
												user?.CustomerLocationCountry,
											]
												?.filter((v) => !!v)
												?.join(', ')}
										</span>
									</div>
									<div className={s.item}>
										<span className="body1-r">
											{t('APP_NATIONALITY_LABEL')}
										</span>
										<span className="body1-b">
											{nationOptions.find(
												(option) => option.value === user?.CustomerNationality,
											)?.label || '-'}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="grid3column">
							<div className={s.shareButton}>
								<FacebookShareButton url={urlForShare} title={titleForShare}>
									<FacebookIcon width="36" height="36" />
								</FacebookShareButton>
							</div>
							<div className={s.shareButton}>
								<TwitterShareButton url={urlForShare} title={titleForShare}>
									<TwitterIcon width="36" height="36" />
								</TwitterShareButton>
							</div>
							<div className={s.shareButton}>
								<LinkedinShareButton url={urlForShare} title={titleForShare}>
									<LinkedinIcon width="36" height="36" />
								</LinkedinShareButton>
							</div>
						</div>
						{user?.CustomerGoogleWalletUrl || user?.CustomerAppleWalletUrl ? (
							<div className="grid2column">
								{user?.CustomerGoogleWalletUrl ? (
									<div
										className={s.wallet}
										onClick={() => {
											window.open(user?.CustomerGoogleWalletUrl, '_blank')
										}}>
										<img src={Google} />
									</div>
								) : null}
								{user?.CustomerAppleWalletUrl ? (
									<div
										className={s.wallet}
										onClick={() => {
											window.open(user?.CustomerAppleWalletUrl, '_blank')
										}}>
										<img src={Apple} />
									</div>
								) : null}
							</div>
						) : null}
						{/* <Button
							variant="themed"
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}
							onClick={() => {
								document.getElementById('qr-code-share-link')?.click()
							}}>
							{t('APP_SHARE')}
							<ShareIcon color="var(--theme-primary)" />
						</Button> */}
						<a
							style={{ display: 'none' }}
							id="qr-code-share-link"
							href={`mailto://?body=${encodeURIComponent(
								t('APP_SHARE_BODY')
									?.replace('[ActorName]', user?.ContentTitle || '')
									?.replace(
										'[PortfolioLink]',
										window.location.origin +
											'/' +
											PATHS.PROFILE?.replace(':userId', user?.ContentId || ''),
									),
							)}`}></a>
					</>
				)}
			</>
		</Sidebar>
	)
}

export default QRCodeSidebar

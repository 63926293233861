export const ValidationIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M11 11.75C11 12.7344 10.207 13.5 9.25 13.5C8.26562 13.5 7.5 12.7344 7.5 11.75C7.5 10.793 8.26562 10 9.25 10C10.207 10 11 10.793 11 11.75ZM18 16.125C18.4648 16.125 18.875 16.5352 18.875 17C18.875 17.4922 18.4648 17.875 18 17.875H9.25C5.85938 17.875 3.125 15.1406 3.125 11.75C3.125 8.38672 5.85938 5.625 9.25 5.625C12.6133 5.625 15.375 8.38672 15.375 11.75C15.375 13.4727 14.6641 15.0312 13.5156 16.125H18ZM9.25 14.375C10.6992 14.375 11.875 13.1992 11.875 11.75C11.875 10.3008 10.6992 9.125 9.25 9.125C7.77344 9.125 6.625 10.3008 6.625 11.75C6.625 13.1992 7.77344 14.375 9.25 14.375Z"
			fill={color}
		/>
	</svg>
)

import classNames from 'classnames'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { EditIcon, PlusIcon, RepeatIcon } from 'assets/icons'
import { contentDelete, copyContent } from 'api/content'
import { multiRelationUpdate } from 'api/relations'
import Card from 'components/Wizard/Card'
import Select from 'components/UI/Select'
import RangeInput from 'components/UI/RangeInput'
import SceneCard from 'components/SceneCard'
import Button from 'components/UI/Button'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import CoverButtonLayout from 'components/UI/CoverButtonLayout'
import useCollection from 'hooks/api/useCollection'
import { optionsParser, t } from 'helpers'
import { orderScenes } from 'helpers/propertiesParser'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useModalStore, useProductionStore } from 'store'
import { Content } from 'types/app'
import { personalFields } from 'pages/Portfolio/fields'
import s from './index.module.scss'
import useContentAccess from 'hooks/useContentAccess'
import { PROJECT_ACCESS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'

const RoleScenes = (): JSX.Element => {
	const { productionRoleId, productionId } = useParams()

	const { collection: contents = [], refresh } = useCollection(
		{ ContentId: productionRoleId } as Content,
		true,
	)

	const { access, role: userRole } = useContentAccess(productionId)
	const disabled = access === PROJECT_ACCESS.READ
	const full = access === PROJECT_ACCESS.FULLACCESS

	const [role, onChangeEvent] = useProductionStore(
		useShallow((state) => [
			state.roles[state.currentRole],
			state.onChangeEventRole,
		]),
	)

	const genderSelector = personalFields.gender

	useEffect(() => {
		EventBus.$on('refreshScenes', () => {
			setTimeout(() => {
				refresh()
			}, 1000)
		})

		return () => {
			EventBus.$off('refreshScenes')
		}
	}, [refresh])

	return (
		<Card
			step="role_scenes"
			title={t('APP_ROLE_SCENES_TITLE')?.replace('{Name}', role?.title || '')}>
			<>
				<div className="grid2column">
					<div className={s.columnWrapper} style={{ gap: '20px' }}>
						<CoverButtonLayout
							label="APP_PRODUCTION_COVER_LABEL"
							status={role?.color ? 'filled' : 'none'}
							title="APP_CUSTOM_COVER_BUTTON"
							onClick={() => {
								if (disabled) return
								if (!productionRoleId) {
									toast.info(t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'))

									return
								}
								const { setOpenImagesSidebar, setCurrentRole } =
									useProductionStore.getState()
								setCurrentRole(productionRoleId)
								setOpenImagesSidebar(true)
							}}
							image={role?.avatar}
						/>
						<Select
							options={optionsParser(t(genderSelector.optionName || ''))}
							name={genderSelector.name}
							label={genderSelector.label}
							onChange={(e) => {
								if (productionRoleId)
									onChangeEvent('gender', e.target.value, productionRoleId)
								else
									toast.info(t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'))
							}}
							inputProps={{
								value: role?.gender || '',
								name: 'gender',
								disabled,
							}}
						/>
						<Select
							label="APP_SPOKENLANGUAGE_LABEL"
							name="language"
							options={optionsParser(t('APP_CHECKBOX_LANGUAGES'))}
							inputProps={{
								name: 'language',
								value: role?.language || '',
								disabled,
							}}
							onChange={(e) => {
								if (productionRoleId)
									onChangeEvent('language', e.target.value, productionRoleId)
								else
									toast.info(t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'))
							}}
						/>
						<RangeInput
							name="age"
							value={role?.age ? +role.age : 1}
							valueLabel={
								(role?.age ? +role.age : 1) + ` ${t('APP_AGE_LABEL')}`
							}
							min={1}
							max={100}
							label="APP_ROLE_AGE_LABEL"
							onChange={(e) => {
								if (productionRoleId)
									onChangeEvent('age', e.target.value, productionRoleId)
								else
									toast.info(t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'))
							}}
							disabled={disabled}
						/>
					</div>
					<div className={s.columnWrapper}>
						<div className={classNames('grid2column', s.scenesWrapper)}>
							{full || userRole === CUSTOMER_ROLE.CASTING_DIRECTOR ? (
								<CreateCardPlaceholder
									label="APP_INTRO_LABEL"
									onClick={() => {
										const { setOpenAddSceneSidebar } = useAppStore.getState()
										setOpenAddSceneSidebar(true)
									}}
								/>
							) : null}
							{orderScenes([], contents)?.map((scene) => (
								<SceneItem
									scene={scene}
									key={scene?.ContentId}
									disabled={disabled}
								/>
							))}
						</div>
					</div>
				</div>
			</>
		</Card>
	)
}

export default RoleScenes

const SceneItem = ({
	scene,
	disabled,
}: {
	scene: Content
	disabled?: boolean
}): JSX.Element => {
	const { productionRoleId, productionId } = useParams()

	const { access, role } = useContentAccess(productionId)
	const full = access === PROJECT_ACCESS.FULLACCESS

	const editScene = (content: Content): void => {
		const { setCurrentScene } = useProductionStore.getState()
		const { setOpenAddSceneSidebar, addSceneSidebar } = useAppStore.getState()
		setOpenAddSceneSidebar(true)
		setCurrentScene(content.ContentId)
		addSceneSidebar.setScene({
			title: content?.['Globalizationen-USContentTitle'],
			code: content?.ContentTapeType,
			dress: content?.ContentRoleDress,
			instructions: content?.ContentLabelInstruction?.split(',') || [],
			duration: content?.Duration,
		})
	}

	const deleteScene = (content: Content): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<Card
					style={{
						width: 'fit-content',
					}}>
					<>
						<h4>{t('APP_CONFIRM_SCENE_DELETE')}</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								onClick={async () => {
									const { setLoading } = useProductionStore.getState()
									try {
										await contentDelete(content.ContentId)
									} catch (error) {
										console.log('error:', error)
										toast.info(t('APP_DELETE_SCENE_ERROR'))
									} finally {
										setLoading(false)
										setModal(null)
										EventBus.$emit('refreshScenes')
									}
								}}>
								{t('APP_YES')}
							</Button>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</Card>
			),
		})
	}

	const copyScene = async (content: Content): Promise<void> => {
		const { setLoading } = useProductionStore.getState()
		setLoading(true)
		try {
			const response = await copyContent(content.ContentId, {
				keepAssets: true,
				ownerId: content?.ContentOwnerId || '',
			})
			if (response?.id) {
				multiRelationUpdate(productionRoleId || '', response.id)
			}
		} catch (error) {
			console.log('error:', error)
			toast.info(t('APP_COPY_SCENE_ERROR'))
		} finally {
			setLoading(false)
			EventBus.$emit('refreshScenes')
		}
	}

	return (
		<SceneCard
			content={scene}
			children={
				<>
					{disabled ? null : (
						<div
							className="hoverButtonWrapper hoverButtonIcon"
							onClick={() => editScene(scene)}>
							<EditIcon />
						</div>
					)}
					{full || role === CUSTOMER_ROLE.CASTING_DIRECTOR ? (
						<>
							<div
								className="hoverButtonWrapper hoverButtonIcon"
								onClick={() => copyScene(scene)}>
								<RepeatIcon />
							</div>
							<div
								className="hoverButtonWrapper hoverButtonIcon"
								onClick={() => deleteScene(scene)}>
								<PlusIcon style={{ transform: 'rotate(45deg)' }} />
							</div>
						</>
					) : undefined}
				</>
			}
		/>
	)
}

import { RepeatIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
	onChange: (v: { [key: string]: boolean }) => void
}

const Repeat = ({ videoElement, onChange }: Props): JSX.Element => {
	const onRepeat = (): void => {
		if (videoElement) {
			videoElement.currentTime = 0
			videoElement.play()
			onChange({ playing: true })
		}
	}

	return (
		<Tooltip title="APP_REPEAT_CONTROL">
			<div className={s.control} onClick={onRepeat}>
				<RepeatIcon />
			</div>
		</Tooltip>
	)
}

export default Repeat

import cn from 'classnames'
import SectionWrapper from '../SectionWrapper'
import NetworkCard from 'components/NetworkCard'
import Actions from './components/Actions'
import Info from './components/Info'
import useUser from 'hooks/api/useUser'
import { contactFields } from 'pages/Portfolio/fields'
import { User } from 'types/user'
import s from './index.module.scss'

interface Props {
	user: User | undefined
	place?: 'sidebar' | 'default'
}

const Header = ({ user, place }: Props): JSX.Element => {
	const agencyProperty = contactFields.agency.name

	const agencyId = user?.[
		agencyProperty?.replaceAll(':', '') as keyof User
	] as string

	const { user: agency } = useUser(agencyId)

	return (
		<SectionWrapper className={cn(s.container, s[place || ''])} type={place}>
			<>
				<div className={s.header}>
					<div
						className={s.avatar}
						style={{
							borderColor: `var(--${user?.CustomerRole?.toLowerCase()})`,
						}}>
						<img
							src={user?.CustomerImagePortraitUrl || '/placeholder.png'}
							alt={user?.ContentTitle}
						/>
					</div>
					<Info user={user} agency={agency} />
					<div className={s.actionsWrapper}>
						{user ? <Actions user={user} /> : null}
						{agency ? (
							<NetworkCard
								style={{
									width: 'fit-content',
									minHeight: 'auto',
								}}
								data={agency}
								type="agency"
								onClick={() => {
									window.open(
										window.location.origin + '/user/' + agencyId,
										'_blank',
									)
								}}
							/>
						) : null}
					</div>
				</div>
			</>
		</SectionWrapper>
	)
}

export default Header

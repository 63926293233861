import { useState } from 'react'
import Button from 'components/UI/Button'
import CheckboxButton from 'components/UI/CheckboxButton'

import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	handleAcceptClick: () => void
}

const Terms = ({ handleAcceptClick }: Props): JSX.Element => {
	const [terms, setTerms] = useState(false)
	const [privacy, setPrivacy] = useState(false)

	return (
		<div className={s.wrapper}>
			<div className={s.titles}>
				<h2 className="h2-m">{t('APP_JOIN_TERMS_TITLE')}</h2>
				<p className="body1-m">{t('APP_JOIN_TERMS_DESCRIPTION')}</p>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
				<CheckboxButton
					checked={terms}
					onChange={() => setTerms((prev) => !prev)}
					label="APP_JOIN_TERMS"
				/>
				<CheckboxButton
					checked={privacy}
					onChange={() => setPrivacy((prev) => !prev)}
					label="APP_JOIN_PRIVACY"
				/>
			</div>
			<Button
				variant="themed"
				onClick={handleAcceptClick}
				disabled={!terms || !privacy}>
				{t('APP_JOIN_ACCEPT')}
			</Button>
		</div>
	)
}

export default Terms

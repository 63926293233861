import { RefObject, TextareaHTMLAttributes, useState } from 'react'
import cn from 'classnames'
import InputLabel from '../InputLabel'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	label?: string
	inputRef?: RefObject<HTMLTextAreaElement>
	limit?: number
}

const Textarea = ({ label, inputRef, limit, ...props }: Props): JSX.Element => {
	const [fieldLoading, setFieldLoading] = useState(false)

	return (
		<div className={s.container}>
			<InputLabel
				label={label}
				status={fieldLoading ? 'loading' : props.value ? 'filled' : 'none'}
			/>
			<textarea
				className={cn(s.textarea, 'body1-m', props.className)}
				placeholder={props.placeholder}
				{...props}
				autoComplete="new-password"
				ref={inputRef}
				onFocus={() => {
					setFieldLoading(true)
				}}
				onBlur={(e) => {
					if (props.onBlur) props.onBlur(e)

					setFieldLoading(false)
				}}
			/>
			{limit ? (
				<span className={cn(s.limit, 'caption-r')}>
					{limit -
						(props.value?.toString()?.length || 0) +
						' ' +
						t('APP_LEFT_HINT')}
				</span>
			) : null}
		</div>
	)
}

export default Textarea

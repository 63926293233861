import { useRef, useState } from 'react'
import { SwiperClass } from 'swiper/react'
import classNames from 'classnames'
import { ArrowShortIcon, PlusIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import MainSlider from './components/MainSlider'
import Thumbs from './components/Thumbs'
import { Content } from 'types/app'
import 'swiper/css'
import 'swiper/css/thumbs'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import s from './index.module.scss'

interface Props {
	mediaItems: Content[]
	itemIndex: number
	onClose: () => void
}

const MediaModal = ({ mediaItems, onClose, itemIndex }: Props): JSX.Element => {
	const [activeSlide, setActiveSlide] = useState(itemIndex)
	const mainSwiper = useRef<SwiperClass | null>(null)

	return (
		<Card
			style={{
				maxWidth: 'calc(100dvw - 40px)',
				maxHeight: 'calc(100dvh - 40px)',
				position: 'relative',
			}}
			step={`profile_${
				mediaItems?.[activeSlide]?.ContentSubType === 'Video'
					? 'reelz'
					: 'photos'
			}_media`}
			noDesc
			noHint>
			<>
				<div className={s.controls}>
					<button
						id="profile-network-modal-prev"
						className={classNames(s.arrowLeft, s.arrow)}>
						<ArrowShortIcon style={{ transform: 'rotate(180deg) ' }} />
					</button>
					<button
						id="profile-network-modal-next"
						className={classNames(s.arrowRight, s.arrow)}>
						<ArrowShortIcon />
					</button>
					<div className={s.close} onClick={onClose}>
						<PlusIcon style={{ transform: 'rotate(45deg)' }} />
					</div>
				</div>

				<MainSlider
					initialSlide={itemIndex}
					mainSwiper={mainSwiper}
					items={mediaItems}
					activeSlide={activeSlide}
					setActiveSlide={setActiveSlide}
				/>
				<Thumbs
					mainSwiper={mainSwiper}
					activeSlide={activeSlide}
					items={mediaItems}
				/>
			</>
		</Card>
	)
}

export default MediaModal

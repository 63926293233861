export const LightbulbIcon = ({
	color = 'var(--mono100)',
	width = '12',
	height = '16',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 12 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3.5 14.2188V13H8.5L8.46875 14.2188C8.46875 14.375 8.40625 14.625 8.3125 14.75L7.78125 15.5625C7.625 15.8125 7.25 16 6.9375 16H5.03125C4.71875 16 4.34375 15.8125 4.1875 15.5625L3.65625 14.75C3.53125 14.5938 3.5 14.4062 3.5 14.2188ZM5.96875 0.03125C9 0 11.5 2.46875 11.5 5.5C11.5 6.90625 10.9688 8.15625 10.125 9.09375C9.59375 9.6875 8.8125 10.9375 8.5 11.9688C8.5 11.9688 8.5 11.9688 8.5 12H3.46875C3.46875 11.9688 3.46875 11.9688 3.46875 11.9688C3.15625 10.9375 2.375 9.6875 1.84375 9.09375C1 8.125 0.5 6.875 0.5 5.5C0.5 2.59375 2.78125 0.03125 5.96875 0.03125ZM6 3.03125C6.25 3.03125 6.5 2.8125 6.5 2.53125C6.5 2.28125 6.25 2.03125 6 2.03125C4.0625 2.03125 2.5 3.59375 2.5 5.5C2.5 5.78125 2.71875 6 3 6C3.25 6 3.5 5.78125 3.5 5.5C3.5 4.125 4.59375 3.03125 6 3.03125Z"
			fill={color}
		/>
	</svg>
)

import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { getUser } from 'helpers/storage'
import { useAppStore, useUserStore } from 'store'
import { FetchReturn, Relation } from 'types/app'
import { PROJECT_ACCESS, RELATIONS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'

type ReturnData = FetchReturn & {
	access: PROJECT_ACCESS | undefined
	role: CUSTOMER_ROLE | undefined
}

export default (productionId?: string): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const { user } = useUserStore.getState()
	const relationUrl =
		endpoints?.CustomApplicationV3ConnectionPostUrl +
		'/' +
		productionId +
		'/' +
		'Content' +
		'/' +
		RELATIONS.INVITE +
		'/' +
		getUser()?.['Custom:User:Id']

	const { data, isLoading, error } = useSwrImmutable<Relation>(
		productionId ? relationUrl : null,
		fetcher,
		{
			shouldRetryOnError: false,
		},
	)

	return {
		error,
		isLoading,
		role: user?.CustomerRole?.toLowerCase() as CUSTOMER_ROLE,
		access: data?.status as PROJECT_ACCESS,
	}
}

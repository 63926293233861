import cn from 'classnames'
import SectionWrapper from '../SectionWrapper'
import ItemWrapper from './components/ItemWrapper'
import { t, textParser } from 'helpers'
import { User } from 'types/user'
import {
	attributeBasicFields,
	attributeSpecialFields,
	skillFields,
} from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	user: User | undefined
	place: 'sidebar' | 'default'
}

const Personal = ({ user, place }: Props): JSX.Element | null => {
	const { body, voice, hair, eyes } = attributeBasicFields
	const { ability, bodyDone, voiceSpecials, bodyWilling } =
		attributeSpecialFields
	const { actingLanguage, driving, spokenLanguage } = skillFields

	const basics = [spokenLanguage, actingLanguage, driving]
		?.map((field, index) =>
			user?.[field.selectors[0]?.name?.replaceAll(':', '') as keyof User] ? (
				<ItemWrapper key={field.value + index} user={user} field={field} />
			) : null,
		)
		.filter((v) => !!v)

	// const bodies = [body, eyes, hair, voice, ability]
	// 	?.map((field) =>
	// 		field.selectors.map((selector) => ({
	// 			...field,
	// 			selectors: [selector],
	// 		})),
	// 	)
	// 	.flat()
	// 	?.map((field, index) =>
	// 		user?.[field.selectors[0]?.name?.replaceAll(':', '') as keyof User] ? (
	// 			<ItemWrapper key={field.value + index} user={user} field={field} />
	// 		) : null,
	// 	)
	// 	.filter((v) => !!v)

	const fields = [
		body,
		bodyDone,
		bodyWilling,
		eyes,
		hair,
		voice,
		voiceSpecials,
		ability,
	]
		?.map((field) =>
			field.selectors.map((selector) => ({
				...field,
				selectors: [selector],
			})),
		)
		.flat()

	const parsed = [
		[
			fields?.find((v) => v.selectors?.[0]?.name?.includes('Height')),
			fields?.find((v) => v.selectors?.[0]?.name?.includes('Weight')),
		],
		[
			fields?.find((v) => v.selectors?.[0]?.name?.includes('Body:Type')),
			fields?.find((v) => v.selectors?.[0]?.name?.includes('Hair:Type')),
			fields?.find((v) => v.selectors?.[0]?.name?.includes('Hair:Color')),
		],
		[
			fields?.find((v) => v.selectors?.[0]?.name?.includes('Skin:Color')),
			fields?.find((v) => v.selectors?.[0]?.name?.includes('Eye:Color')),
		],
		[
			fields?.find((v) => v.selectors?.[0]?.name?.endsWith('Vocal')),
			fields.find((v) => v.selectors?.[0].name.includes('Special')),
		],
		[],
		[],
		[fields?.find((v) => v.selectors?.[0]?.name?.endsWith('Modification'))],
		[fields?.find((v) => v.selectors?.[0]?.name?.endsWith('Willing'))],
		[fields?.find((v) => v.value === 'ability')],
	]

	return basics?.length || parsed?.length ? (
		<SectionWrapper style={{ gap: '24px', width: '100%' }}>
			<>
				<div className={cn(s.wrapper, s[place])}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '10px',
							gridColumn: '2 span',
						}}>
						<span className="body1-b" style={{ color: 'var(--mono200)' }}>
							{t('APP_BIOGRAPHY_TAB')}
						</span>
						{textParser(user?.CustomUserBiography || '').map((text) => (
							<p
								key={text}
								style={{ color: 'var(--mono200)' }}
								className="body2-s">
								{text}{' '}
							</p>
						))}
					</div>
					{basics?.length ? (
						<div
							style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
							<span className="body1-b" style={{ color: 'var(--mono200)' }}>
								{t('APP_BASIC_LABEL')}
							</span>
							{basics}
						</div>
					) : null}
				</div>
				{parsed?.length ? (
					<div
						style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
						<span className="body1-b" style={{ color: 'var(--mono200)' }}>
							{t('APP_BODY_LABEL')}
						</span>
						<div className={cn(s.wrapper, s[place])}>
							{parsed?.map((array, i) =>
								array?.length ? (
									<div className={`grid${array?.length}column`} key={i}>
										<>
											{array
												?.filter((f) => !!f)
												?.map((field, index) =>
													field && user ? (
														<ItemWrapper
															key={field?.value + index}
															user={user}
															field={field}
														/>
													) : null,
												)}
										</>
									</div>
								) : null,
							)}
						</div>
					</div>
				) : null}
				{/* {bodies?.length ? (
					<div
						style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
						<span className="body1-b" style={{ color: 'var(--mono200)' }}>
							{t('APP_BODY_LABEL')}
						</span>
						<div className="grid3column">{bodies}</div>
					</div>
				) : null} */}
			</>
		</SectionWrapper>
	) : null
}

export default Personal

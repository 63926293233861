export const PlusIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M18.1875 11.75C18.1875 12.2422 17.7773 12.6523 17.3125 12.6523H13.375V16.5898C13.375 17.0547 12.9648 17.4375 12.5 17.4375C12.0078 17.4375 11.625 17.0547 11.625 16.5898V12.6523H7.6875C7.19531 12.6523 6.8125 12.2422 6.8125 11.75C6.8125 11.2852 7.19531 10.9023 7.6875 10.9023H11.625V6.96484C11.625 6.47266 12.0078 6.0625 12.5 6.0625C12.9648 6.0625 13.375 6.47266 13.375 6.96484V10.9023H17.3125C17.7773 10.875 18.1875 11.2852 18.1875 11.75Z"
			fill={color}
		/>
	</svg>
)

import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { useEffect } from 'react'
import { deleteRelation } from 'api/relations'
import Sidebar from 'components/Sidebar'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import UserDynamicList from 'components/UserDynamicList'
import useRelations from 'hooks/api/useRelations'
import useUsers from 'hooks/api/useUsers'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useProductionStore } from 'store'
import { Relation } from 'types/app'
import { PROJECT_ACCESS, RELATIONS } from 'types/enums'

const ProjectAccessSidebar = (): JSX.Element => {
	const { productionId } = useParams()
	const [openSidebar, setOpenSidebar, addSidebar] = useProductionStore(
		useShallow((state) => [
			state.openAccessSidebar,
			state.setOpenAccessSidebar,
			state.openGrantAccessSidebar,
		]),
	)
	const additionalSidebar = useAppStore(
		useShallow(
			(state) =>
				state.addContactSidebar?.open || state.openMessageSidebar?.open,
		),
	)

	const { data, refresh } = useRelations(RELATIONS.INVITE, productionId || '')
	const relations = data as Relation[]

	useEffect(() => {
		EventBus.$on('refreshAccessListSidebar', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshAccessListSidebar')
		}
	}, [refresh])

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => {
				setOpenSidebar(false)
				EventBus.$emit('refreshAccessField')
			}}
			iconClose={!!addSidebar || !!additionalSidebar}
			header={{
				title: 'APP_PRODUCTION_ACCESS_SIDEBAR_TITLE',
				description: 'APP_PRODUCTION_ACCESS_SIDEBAR_DESCRIPTION',
			}}>
			<>
				{Object.values(PROJECT_ACCESS).map((access) => (
					<List
						key={access}
						access={access}
						relations={relations?.filter((rel) => rel.status === access)}
					/>
				))}
			</>
		</Sidebar>
	)
}

export default ProjectAccessSidebar

const List = ({
	access,
	relations,
}: {
	access: PROJECT_ACCESS
	relations?: Relation[]
}): JSX.Element => {
	const { users = [] } = useUsers(relations?.map((rel) => rel.targetId))
	const { productionId } = useParams()

	const removeAccess = async (userId: string): Promise<void> => {
		try {
			await deleteRelation(
				productionId || '',
				'Content',
				RELATIONS.INVITE,
				userId,
			)
			EventBus.$emit('refreshAccessListSidebar')
		} catch (error) {
			console.log('error:', error)
		}
	}

	return (
		<>
			<SidebarTabs
				active={0}
				onChange={() => null}
				type="fit"
				options={[
					{
						label: `APP_${access?.toUpperCase()}_ACCESS_TAB`,
						value: 0,
					},
				]}
			/>
			<UserDynamicList
				onAdd={() => {
					const { setOpenGrantAccessSidebar } = useProductionStore.getState()
					setOpenGrantAccessSidebar(access)
				}}
				onRemove={(user) => removeAccess(user?.ContentId)}
				users={[...users]}
			/>
		</>
	)
}

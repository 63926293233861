import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { ArrowShortIcon } from 'assets/icons'
import { t } from 'helpers'
import { useAppStore, useUserStore } from 'store'
import { PATHS } from 'pages/Router'
import s from './index.module.scss'

const Back = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<div
			className={classNames(s.container, 'castingToolWrapper')}
			data-role="casting-back"
			onClick={() => {
				const { stepWizard } = useAppStore.getState()
				const { user } = useUserStore.getState()
				stepWizard?.goToStep(1)
				navigate(`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}`)
			}}>
			<div className={s.wrapper}>
				<div style={{ display: 'flex' }}>
					<ArrowShortIcon style={{ transform: 'rotate(180deg)' }} />
				</div>
				<span>{t('APP_BACK')}</span>
			</div>
		</div>
	)
}

export default Back

import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import {
	contentDelete,
	getPlaylistItems,
	partialContentUpdate,
} from 'api/content'
import { CastingIcon, EditIcon, PlusIcon } from 'assets/icons'
import RoleCard from 'components/RoleCard'
import Button from 'components/UI/Button'
import Card from 'components/Wizard/Card'
import { prepareMessageData, t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { getUser } from 'helpers/storage'
import {
	useAppStore,
	useModalStore,
	useProductionStore,
	useUserStore,
} from 'store'
import { Content } from 'types/app'
import { MESSAGE_TYPE, PROJECT_ACCESS, ROLE_STATUS } from 'types/enums'
import { directorCastingSteps } from 'pages/Casting/CastingDirector/DirectorCasting'
import { PATHS } from 'pages/Router'
import useContentAccess from 'hooks/useContentAccess'
import { CUSTOMER_ROLE } from 'types/user'

interface Props {
	content: Content
}

const RoleItem = ({ content }: Props): JSX.Element => {
	const navigate = useNavigate()
	const { productionId } = useParams()

	const { access, role } = useContentAccess(productionId)

	const full = access === PROJECT_ACCESS.FULLACCESS

	const openRole = (content: Content): void => {
		const { user } = useUserStore.getState()
		const { stepWizard } = useAppStore.getState()
		const { setCurrentRole } = useProductionStore.getState()
		const { setRoles } = useProductionStore.getState()
		setRoles(content.ContentId, {
			title: content['Globalizationen-USContentTitle'],
			id: content.ContentId,
			character: content['Globalizationen-USContentDescription'],
			type: content.ContentRoleType,
			gender: content.ContentRoleGender,
			shootingDays: +(content.ContentShootingDays || 0) as unknown as string,
			language: content.ContentRoleDialect,
			deadline: content.ContentEventDeadline,
			age: +(content.ContentRoleAge || 0) as unknown as string,
		})
		setCurrentRole(content.ContentId)
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${
				PATHS.PROJECT
			}/${productionId}/${content.ContentId}`,
		)
		stepWizard?.nextStep()
	}

	const openMessage = (content: Content): void => {
		if (!content?.ContentCastingStatus) {
			const data = {
				properties: {
					'Content:Casting:Status': ROLE_STATUS.WORKING,
				},
			}
			partialContentUpdate(content.ContentId, data)
		}

		const { production } = useProductionStore.getState()
		const data = {
			key: 'ROLEINVITE',
			messageValue: t('APP_ROLEINVITE_MESSAGE_BODY')
				?.replace(
					'[RoleName]',
					content?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace('[ProductionTitle]', production?.name)
				?.replace(
					'[AuditionTitle]',
					content?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace(
					'[AuditionDate]',
					dayjs(content?.ContentEventDeadline || '').format('MM/DD/YYYY'),
				)
				?.replace('[AuditionNDA]', production?.nda ? 'Yes' : 'No')
				?.replace('[YourName]', getUser()?.['Custom:User:Name'] || ''),
			sourceId: content.ContentId,
			redirect: window.location.pathname + window.location.hash,
			openSidebar: true,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: MESSAGE_TYPE.CASTING,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})
	}

	const deleteRole = async (content: Content): Promise<void> => {
		const { setLoading } = useProductionStore.getState()
		setLoading(true)
		try {
			const res = await getPlaylistItems(content)
			await Promise.all(
				[
					...(res?.pageContent?.map((rel) => rel.targetId) || []),
					content.ContentId,
				].map((id) => contentDelete(id)),
			)
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
		}
	}

	const onDeleteRoleClick = (content: Content): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<Card
					style={{
						width: 'fit-content',
					}}>
					<>
						<h4>{t('APP_CONFIRM_ROLE_DELETE')}</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								onClick={async () => {
									await deleteRole(content)
									setModal(null)
									EventBus.$emit('refreshRoles')
								}}>
								{t('APP_YES')}
							</Button>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</Card>
			),
		})
	}

	const openCasting = (prodId: string, roleId: string): void => {
		const { user } = useUserStore.getState()
		const { stepWizard } = useAppStore.getState()
		stepWizard?.goToStep(2)
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${
				PATHS.CASTING
			}/${prodId}/${roleId}#` +
				directorCastingSteps.board.key +
				'-' +
				1,
		)
	}

	return (
		<RoleCard
			content={content}
			showInfo
			style={{
				minHeight: '200px',
			}}
			hoverContainerStyle={{
				gap: '10px',
			}}
			children={
				<>
					<div className="hoverButtonWrapper" onClick={() => openRole(content)}>
						<EditIcon />
						<span>{t('APP_EDIT_ROLE')}</span>
					</div>
					<div
						className="hoverButtonWrapper"
						onClick={() => openCasting(productionId || '', content.ContentId)}>
						<CastingIcon />
						<span>{t('APP_CASTINGS_HOVER_LABEL')}</span>
					</div>
					{full || role === CUSTOMER_ROLE.CASTING_DIRECTOR ? (
						<>
							<div
								className="hoverButtonWrapper"
								onClick={() => openMessage(content)}>
								<PlusIcon />
								<span>{t('APP_OPEN_INVITE')}</span>
							</div>
							<div
								className="hoverButtonWrapper"
								onClick={() => onDeleteRoleClick(content)}>
								<PlusIcon style={{ transform: 'rotate(45deg)' }} />
								<span>{t('APP_DELETE_ROLE')}</span>
							</div>
						</>
					) : null}
				</>
			}
		/>
	)
}

export default RoleItem

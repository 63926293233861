import cn from 'classnames'
import { ShowreelsIcon, TimeIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import { Content } from 'types/app'
import s from './index.module.scss'

interface Props {
	content: Content
	uploaded?: Content
	onClick?: () => void
	children?: JSX.Element
}

const SceneCard = ({
	content,
	uploaded,
	onClick,
	children,
}: Props): JSX.Element => {
	return (
		<Card
			className={cn(s.container, 'hoverWrapperParent')}
			onClick={onClick}
			wrapperStyle={{ gap: '0px' }}
			style={{ borderRadius: '15px' }}>
			<>
				{children ? (
					<div className="hoverWrapper">
						<div
							className="hoverContent hoverContentHorizontal"
							style={{ gap: '8px' }}>
							<>{children}</>
						</div>
					</div>
				) : null}

				<h4 className="textEllipsis" style={{ marginBottom: 'auto' }}>
					{content['Globalizationen-USContentTitle']}
				</h4>
				{content?.Duration ? (
					<div className={s.duration}>
						<TimeIcon width="24" height="24" />
						<span className="body2-m">{`${content?.Duration} sec / ${(
							+content.Duration / 60
						).toFixed(2)} min`}</span>
					</div>
				) : null}
				{uploaded?.ContentDuration ? (
					<div className={s.duration}>
						<ShowreelsIcon />
						<span className="body2-m">{`${(
							+uploaded?.ContentDuration / 1000
						).toFixed(0)} sec / ${(
							+uploaded.ContentDuration /
							1000 /
							60
						).toFixed(2)} min`}</span>
					</div>
				) : null}
			</>
		</Card>
	)
}

export default SceneCard

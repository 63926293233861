import { useShallow } from 'zustand/react/shallow'
import Sidebar from 'components/Sidebar'
import LevelCard from 'components/UI/LevelCard'
import { t } from 'helpers'
import { useFilterStore } from 'store'

const LevelSidebar = (): JSX.Element => {
	const [openSidebar, skillOption, options, onChange, setOpenSidebar] =
		useFilterStore(
			useShallow((state) => [
				state.levelSidebar.open,
				state.levelSidebar.skillOption,
				state.levelSidebar.options,
				state.levelSidebar.onChange,
				state.setLevelSidebar,
			]),
		)

	return (
		<Sidebar
			open={openSidebar}
			additional
			iconClose
			onClose={() =>
				setOpenSidebar({
					open: false,
					skillOption: null,
					onChange: null,
					options: [],
				})
			}
			header={{
				title: skillOption?.label + ' - ' + t('APP_LEVEL_LABEL'),
			}}>
			<>
				{onChange && skillOption
					? options?.map((option, index) => (
							<LevelCard
								key={option.value}
								option={option}
								optionLength={options?.length}
								index={index}
								onChange={() => onChange(option.value)}
								type="Level"
								skillValue={skillOption?.value}
							/>
					  ))
					: null}
			</>
		</Sidebar>
	)
}

export default LevelSidebar

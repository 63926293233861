/* eslint-disable no-tabs */
import { useCallback, useEffect, useState } from 'react'
import { ChatToken, SendMessageRequest } from 'amazon-ivs-chat-messaging'
import { connectIVSChatRoom } from 'api/chat'
import { useChatRoom } from 'hooks/useChatRoom'
import { t, uuidv4 } from 'helpers'
import { getUser } from 'helpers/storage'
import { EventBus } from 'helpers/EventBus'
import { Content } from 'types/app'
import * as config from '../../../../../../../ivs-config'
import s from './index.module.scss'
import Textarea from 'components/UI/Textarea'
import { useCastingStore } from 'store'

interface Props {
	chatData: Content
}

const Chat = ({ chatData }: Props): JSX.Element => {
	const [message, setMessage] = useState(
		t('APP_MESSAGE_FROM_ACTOR_DEFAULT_VALUE'),
	)

	const { room } = useChatRoom({
		regionOrUrl: config.CHAT_WEBSOCKET,
		tokenProvider: () =>
			tokenProvider(getUser()?.['Custom:User:Id'], true, {
				src: getUser()?.['Custom:User:Avatar'],
			}),
	})

	const tokenProvider = useCallback(
		async (
			selectedUsername: string | undefined,
			isModerator: boolean,
			avatarUrl: { src: string | undefined },
		): Promise<ChatToken> => {
			const uuid = uuidv4()
			const permissions = isModerator
				? ['SEND_MESSAGE', 'DELETE_MESSAGE', 'DISCONNECT_USER']
				: ['SEND_MESSAGE']

			const data = {
				userId:
					getUser()?.['Custom:User:Avatar'] || `${selectedUsername}.${uuid}`,
				arn: chatData.ContentChatArn,
				attributes: {
					username: `${selectedUsername}`,
					avatar: `${avatarUrl.src}`,
				},
				capabilities: permissions,
			}

			let token = {} as ChatToken

			try {
				const response = await connectIVSChatRoom(chatData.ContentId, data)

				token = {
					token: response.token,
					sessionExpirationTime: new Date(response.sessionExpiration),
					tokenExpirationTime: new Date(response.tokenExpiration),
				} as ChatToken
			} catch (error) {
				console.log('error:', error)
			}

			return token
		},
		[chatData.ContentChatArn, chatData.ContentId],
	)

	useEffect(() => {
		room.connect()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSendMessage = useCallback(
		async ({ attributes }: any): Promise<void> => {
			const request = new SendMessageRequest(JSON.stringify({ ...attributes }))
			try {
				await room.sendMessage(request)
				// setSendToActor(false)
			} catch (error: unknown) {
				console.log('error:', error)
			}
		},
		[room],
	)

	const sendMessage = useCallback(
		async (message: string): Promise<void> => {
			try {
				const content = `${message.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}`

				const attributes = {
					type: 'text',
					body: content,
				}
				handleSendMessage({ attributes })
			} catch (error) {
				console.log('error:', error)
			} finally {
				const { setOpenMessageToActorSidebar } = useCastingStore.getState()
				setOpenMessageToActorSidebar(false)
			}
		},
		[handleSendMessage],
	)

	useEffect(() => {
		EventBus.$on('sendActorCastingMessage', async () => {
			sendMessage(message)
		})

		return () => {
			EventBus.$off('sendActorCastingMessage')
		}
	}, [sendMessage, message])

	return (
		<div className={s.container}>
			<div style={{ display: 'flex' }}>
				<Textarea
					onChange={(e) => setMessage(e.target.value)}
					defaultValue={t('APP_MESSAGE_FROM_ACTOR_DEFAULT_VALUE')}
					rows={20}
				/>
			</div>
		</div>
	)
}

export default Chat

import dayjs from 'dayjs'

export const pickerDateFormat = (
	date: Date,
	format = 'ddd, MMM D -  h:mm A',
): string => {
	return dayjs(date).format(format)
}

export const getDuration = (duration: string): string =>
	dayjs(parseInt(duration, 10)).toISOString().slice(11, 19)

export const monhtAndDay = (date: string): string => dayjs(date).format('MMM D')

export const formatDate = (date: string): string =>
	dayjs(date).format('MMM D, YYYY')

export const msToHHMMSS = (ms = 0): string =>
	dayjs(ms).toISOString().slice(11, -5)

export const msToMMSS = (sec = 0): string =>
	dayjs(sec * 1000)
		.toISOString()
		.slice(14, -5)

export const secToHHMMSS = (sec = 0): string =>
	sec >= 60 * 60 ? msToHHMMSS(sec * 1000) : msToMMSS(sec)

export const HHMMSSToSec = (HHMMSS: string): number => {
	return (
		parseInt(HHMMSS.slice(0, 2), 10) * 3600 +
		parseInt(HHMMSS.slice(3, 5), 10) * 60 +
		parseInt(HHMMSS.slice(6), 10)
	)
}

export const HHMMSSOrMMSS = (time: string): string => {
	if (Number(time[0] + time[1]) === 0) {
		return time.slice(3)
	}

	return time
}

export const HHMMSSToMs = (HHMMSS: string): number | undefined => {
	if (!HHMMSS) return
	const [hours, minutes, seconds] = HHMMSS.split(':').map((time) =>
		parseInt(time, 10),
	)

	return Date.UTC(1970, 0, 1, hours, minutes, seconds)
}

export const isWeekDifference = (timestamp: Date): boolean => {
	const today = dayjs()
	const diffDays = 1

	const diff = today.diff(dayjs(timestamp), 'day')

	return diff >= diffDays
}

export const getDaysDifference = (timestamp: Date): number => {
	const today = dayjs()

	return today.diff(dayjs(timestamp), 'day')
}

export const getRangeTimeFormat = (seconds: number): string => {
	const minutes = Math.floor(seconds / 60)
	const remainingSeconds = seconds % 60

	let formattedTime = ''

	if (minutes > 0) {
		formattedTime += `${minutes} min `
	}

	if (remainingSeconds > 0) {
		formattedTime += `${remainingSeconds} sec`
	}

	if (formattedTime === '') {
		formattedTime = '0 sec'
	}

	return formattedTime
}

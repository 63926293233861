import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { FilterIcon } from 'assets/icons'
import Loader from 'components/Loader'
import Card from 'components/Wizard/Card'
import List from './components/List'
import AgencyCarousel from './components/AgencyCarousel'
import ActorCarousels from './components/ActorCarousels'
import Button from 'components/UI/Button'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import { ageFilterParser } from 'helpers/propertiesParser'
import { t } from 'helpers'
import { useFilterStore, useNetworkStore, useUserStore } from 'store'
import { FILTER_CONDITION, SORT_FORMAT } from 'types/enums'
import { CUSTOMER_ROLE, User } from 'types/user'
import { directorNetworkSteps } from '../../CastingDirector/DirectorNetwork'
import { actorNetworkSteps } from 'pages/Network/Actor/ActorNetwork'
import { basicFields } from 'pages/Portfolio/fields'

interface Props {
	source: string
}

const Content = ({ source }: Props): JSX.Element => {
	const { hash } = useLocation()
	const { profileId } = useParams()
	const navigate = useNavigate()
	const [activeLetter, setActiveLetter] = useState('A')
	const [agencyId, setAgencyId] = useState('')
	const filters = useFilterStore(useShallow((state) => state.filters))
	const [key, role] = source.split('-')

	const opennetwork = directorNetworkSteps.opennetwork.key === key

	const ageFilter = useMemo(
		() =>
			ageFilterParser([
				{
					propName: 'Customer:Age',
					propValue: filters?.['Customer:Age'] as string,
				},
			]),
		[filters],
	)
	const imdbFilter = useMemo(
		() =>
			Array.isArray(filters?.imdbLink) && filters?.imdbLink?.length
				? [
						{
							propName: 'Customer:ImdbUrl',
							propValue:
								Array.isArray(filters?.imdbLink) && filters?.imdbLink?.length
									? 'imdb'
									: '',
							cond: FILTER_CONDITION.EXISTS,
							format: SORT_FORMAT.TEXT,
						},
				  ]
				: [],
		[filters?.imdbLink],
	)

	const userStatus =
		(Object.entries(filters).find(
			([key]) => key === 'userStatus',
		)?.[1] as string) || ''
	const userSearch =
		(Object.entries(filters).find(
			([key]) => key === 'userSearch',
		)?.[1] as string) || ''

	const { data, isLoading } = useCustomerSearch({
		fetch: true,
		filters: [
			...Object.entries(filters)
				.filter(
					([key]) =>
						!key.endsWith('Age') &&
						!['userStatus', 'userSearch', 'imdbLink'].includes(key),
				)
				.filter(([_, value]) => !!value)
				.map(([name, value]) => {
					return {
						propName: name,
						propValue: value
							? typeof value === 'object'
								? JSON.stringify(value)
								: value?.toString()
							: '',
						format: JSON.stringify(value)?.startsWith('[{')
							? SORT_FORMAT.OBJECT
							: JSON.stringify(value)?.startsWith('[')
							? SORT_FORMAT.LIST
							: SORT_FORMAT.TEXT,
					}
				}),
			...ageFilter,
			...imdbFilter,
			{
				propName: 'Customer:Process:Status',
				propValue: userStatus ? 'Invited' : '',
				cond:
					userStatus === 'Active'
						? FILTER_CONDITION.NOT_EQUAL
						: FILTER_CONDITION.EQUAL,
			},
		],
		searchText: userSearch,
		organizationId: opennetwork
			? '35ab2507-a3a1-4f9c-ae6d-4ef865655571'
			: agencyId || undefined,
		nonOrg: opennetwork,
	})

	const letters = Array.from({ length: 26 }, (_, i) =>
		String.fromCharCode(65 + i),
	)

	const usersList = letters
		?.map((letter) => ({
			key: letter,
			data: data?.filter((user) =>
				user?.ContentTitle?.toLowerCase()?.startsWith(letter?.toLowerCase()),
			),
		}))
		?.filter((v) => v?.data?.length)

	const onUserChange = useCallback(
		async (u: User | null): Promise<void> => {
			const { userProfile } = useNetworkStore.getState()
			const users = usersList?.map((l) => l.data).flat() as User[]
			if (users?.length) {
				const { setUserProfile } = useNetworkStore.getState()
				setUserProfile({
					users,
					listSource: Object.values(filters)?.length
						? JSON.stringify(filters)
						: undefined,
				})
			}
			const path = await userProfile.onUserChange(u)
			navigate(path)
		},
		[navigate, usersList, filters],
	)

	useEffect(() => {
		const { setOpenFilterSidebar } = useFilterStore.getState()
		if (hash?.includes('my-0') && !profileId) {
			setOpenFilterSidebar(true)
		} else setOpenFilterSidebar(false)
	}, [hash, profileId])

	useEffect(() => {
		if (
			window.location.pathname?.includes(CUSTOMER_ROLE.CASTING_DIRECTOR) &&
			window.location.hash.includes(directorNetworkSteps.mynetwork.key)
		) {
			const { setFilters } = useFilterStore.getState()
			const { user } = useUserStore.getState()
			setFilters({
				[basicFields.location.name]: user?.[
					basicFields?.location?.name?.replaceAll(':', '') as keyof User
				] as string,
			})
		}
	}, [])

	useEffect(() => {
		return () => {
			const { setOpenFilterSidebar, resetFilters } = useFilterStore.getState()
			setOpenFilterSidebar(false)
			resetFilters()
		}
	}, [])

	return profileId ? (
		<></>
	) : (
		<Card
			step={
				hash?.includes(directorNetworkSteps.opennetwork.key)
					? 'ACTOR_OPENNETWORK'
					: 'MYNETWORK'
			}
			description={t(
				`APP_${
					hash?.includes(directorNetworkSteps.opennetwork.key)
						? 'ACTOR_OPENNETWORK'
						: 'MYNETWORK'
				}_DESCRIPTION`,
			)?.replace('{result}', (data?.length || 0)?.toString())}
			noHint
			style={{
				overflow: 'hidden',
			}}
			headerChildrens={
				<Button
					style={{
						gap: '8px',
						color: 'var(--mono100)',
						height: 'fit-content',
						marginLeft: 'auto',
					}}
					onClick={() => {
						const { setOpenFilterSidebar, openFilterSidebar } =
							useFilterStore.getState()
						setOpenFilterSidebar(!openFilterSidebar)
					}}>
					{t('APP_SHOW_FILTERS')}
					<FilterIcon
						color={
							Object.values(filters)?.filter((v) =>
								Array.isArray(v) ? !!v?.length : !!v,
							)?.length
								? 'var(--theme-primary)'
								: undefined
						}
					/>
				</Button>
			}
			wrapperStyle={{
				overflow: 'unset',
				minHeight: 'var(--contentCardHeight)',
			}}>
			<>
				<Loader loading={isLoading} visible />
				{hash?.includes(actorNetworkSteps?.opennetwork?.key) &&
				window.location?.pathname.includes(CUSTOMER_ROLE.ACTOR) ? (
					<ActorCarousels />
				) : null}
				{hash?.includes(directorNetworkSteps.mynetwork.key) &&
				role === CUSTOMER_ROLE.CASTING_DIRECTOR ? (
					<AgencyCarousel agencyId={agencyId} setAgencyId={setAgencyId} />
				) : null}
				{hash?.slice(1)?.startsWith(key) &&
				window.location.pathname.includes(role) ? (
					<List
						users={usersList}
						setUser={onUserChange}
						letters={letters}
						activeLetter={activeLetter}
						setActiveLetter={setActiveLetter}
					/>
				) : null}
			</>
		</Card>
	)
}

const MyNetwork = ({ source = '' }: Props): JSX.Element => {
	const { hash } = useLocation()
	const [key] = source.split('-')

	return hash?.includes(key) ? <Content source={source} /> : <></>
}

export default MyNetwork

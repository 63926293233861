import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import Select from 'components/UI/Select'
import Input from 'components/UI/Input'
import Datepicker from 'components/UI/Datepicker'
import CountrySelector from 'components/CountrySelector'
import Card from 'components/Wizard/Card'
import { optionsParser, t } from 'helpers'
import { usePortfolioStore, useUserStore } from 'store'
import { basicFields } from '../../fields'
import s from './index.module.scss'

const Basic = (): JSX.Element => {
	const user = useUserStore(useShallow((state) => state.user))
	const values = usePortfolioStore(useShallow((state) => state.cardValues))

	const birthName = 'Custom:User:BirthDate'

	const profession = values?.['basic']?.[basicFields.profession.name]
	const birthdate = values?.['basic']?.[birthName]
	const location = values?.['basic']?.[basicFields.location.name] as string

	return (
		<Card step="basic">
			<div className={cn(s.wrapper, 'grid2column')}>
				<div className={s.fields}>
					{Object.values(basicFields)?.map((field) =>
						field.name === 'Customer:Profession' && field?.optionName ? (
							<Select
								name={field.name}
								key={field.name}
								options={optionsParser(t(field?.optionName))}
								label={field.label}
								onChange={(e) => {
									const { updateFields, setCardValues } =
										usePortfolioStore.getState()
									setCardValues(
										{
											[field.name]: e.target.value,
										},
										'basic',
									)
									updateFields([
										{
											Name: field.name,
											Value: e.target.value,
										},
									])
								}}
								inputProps={{
									value: profession?.toString() || '',
								}}
							/>
						) : field.name === basicFields.location.name ? (
							<CountrySelector
								key={field.name}
								value={location}
								onChange={(e) => {
									const { updateFields, setCardValues } =
										usePortfolioStore.getState()
									setCardValues(
										{
											[field.name]: e.target.value,
										},
										'contact',
									)
									updateFields([{ Name: field.name, Value: e.target.value }])
								}}
							/>
						) : (
							<Input
								key={field.name}
								name={field.name}
								label={field.label}
								value={(values['basic'][field.name] as string) || ''}
								onChange={(e) => {
									const { setCardValues } = usePortfolioStore.getState()
									setCardValues(
										{
											[field.name]: e.target.value,
										},
										'basic',
									)
								}}
								onBlur={(e) => {
									if (
										e.target.value?.trim() !== '' &&
										e.target.value.trim() !== user?.CustomerName
									) {
										const { updateFields } = usePortfolioStore.getState()
										updateFields([
											{ Name: e.target.name, Value: e.target.value },
										])
									}
								}}
							/>
						),
					)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}>
					<span
						className={cn('caption-m', s.label)}
						style={{
							color: 'var(--mono200)',
							paddingBottom: '8px',
							paddingLeft: '24px',
						}}>
						{t('APP_BIRTHDATE_LABEL')}
					</span>
					<Datepicker
						value={(birthdate as string) || new Date().toISOString()}
						onChange={(date: string | null) => {
							if (date) {
								const { updateFields, setCardValues } =
									usePortfolioStore.getState()
								setCardValues(
									{
										[birthName]: date,
									},
									'basic',
								)
								updateFields([{ Name: birthName, Value: date }])
							}
						}}
					/>
				</div>
			</div>
		</Card>
	)
}

export default Basic

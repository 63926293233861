export const NotificationIcon = ({
	color = 'var(--mono100)',
	width = '25',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.5215 5.625V6.17188C15.5176 6.58203 17.0215 8.33203 17.0215 10.4375V10.957C17.0215 12.2422 17.4863 13.5 18.334 14.457L18.5254 14.6758C18.7715 14.9492 18.8262 15.3047 18.6895 15.6328C18.5527 15.9336 18.2246 16.125 17.8965 16.125H7.39648C7.04102 16.125 6.71289 15.9336 6.57617 15.6328C6.43945 15.3047 6.49414 14.9492 6.74023 14.6758L6.93164 14.457C7.7793 13.5 8.27148 12.2422 8.27148 10.957V10.4375C8.27148 8.33203 9.74805 6.58203 11.7715 6.17188V5.625C11.7715 5.16016 12.1543 4.75 12.6465 4.75C13.1113 4.75 13.5215 5.16016 13.5215 5.625ZM12.6465 18.75C12.1816 18.75 11.7168 18.5859 11.3887 18.2578C11.0605 17.9297 10.8965 17.4648 10.8965 17H14.3965C14.3965 17.4648 14.2051 17.9297 13.877 18.2578C13.5488 18.5859 13.084 18.75 12.6465 18.75Z"
			fill={color}
		/>
	</svg>
)

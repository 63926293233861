import { useNavigate } from 'react-router-dom'
import Button from 'components/UI/Button'
import { t } from 'helpers'

const NotFound = (): JSX.Element => {
	const navigate = useNavigate()

	const goHome = (): void => navigate('/')

	return (
		<div
			style={{
				position: 'absolute',
				maxWidth: '50dvw',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: '60px',
			}}
		>
			<h2 className="h2-m" style={{ textAlign: 'center' }}>
				{t('APP_NOT_FOUND_PAGE')}
			</h2>
			<img src="/404.png" alt="not found page" style={{ width: '100%' }} />
			<Button variant="themed" onClick={goHome}>
				{t('APP_BACK_HOME')}
			</Button>
		</div>
	)
}

export default NotFound

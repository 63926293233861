import { useNavigate } from 'react-router-dom'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { PATHS } from 'pages/Router'

const LaunchApp = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<Button
			onClick={() => {
				navigate(`${PATHS.JOIN}/${PATHS.LOGIN}`)
			}}
			variant="outlined"
			style={{
				gap: '10px',
				color: 'var(--mono900)',
				borderColor: 'var(--theme-primary)',
				backgroundColor: 'var(--theme-primary)',
			}}>
			{t('APP_LAUNCH_APP_LABEL')}
		</Button>
	)
}

export default LaunchApp

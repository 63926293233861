import { ButtonHTMLAttributes } from 'react'
import s from './index.module.scss'

const SidebarButton = ({
	children,
	...props
}: ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
	<button className={s.container} {...props}>
		{children}
	</button>
)

export default SidebarButton

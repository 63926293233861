import { useEffect, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useParams } from 'react-router-dom'
import Input from 'components/UI/Input'
import Textarea from 'components/UI/Textarea'
import Card from 'components/Wizard/Card'
import Select from 'components/UI/Select'
import Datepicker from 'components/UI/Datepicker'
import RangeInput from 'components/UI/RangeInput'
import CheckboxButton from 'components/UI/CheckboxButton'
import InputLabel from 'components/UI/InputLabel'
import useContent from 'hooks/api/useContent'
import { optionsParser, t } from 'helpers'
import { useProductionStore } from 'store'
import { Content } from 'types/app'
import { DROPDOWN_OPTIONS, PROJECT_ACCESS } from 'types/enums'
import useContentAccess from 'hooks/useContentAccess'

export type RolePropertyKey =
	| 'gender'
	| 'shootingDays'
	| 'type'
	| 'age'
	| 'deadline'
	| 'language'
	| 'title'
	| 'character'
	| 'color'

export const roleProperties: {
	[key in RolePropertyKey]: string
} = {
	gender: 'Content:Role:Gender',
	shootingDays: 'Content:ShootingDays',
	type: 'Content:Role:Type',
	age: 'Content:Role:Age',
	deadline: 'Content:Event:Deadline',
	language: 'Content:Role:Dialect',
	title: 'Globalizationen-USContentTitle',
	character: 'Globalizationen-USContentDescription',
	color: 'Content:Color',
}

const maxShootingDays = 200

const RoleBasic = (): JSX.Element => {
	const { productionRoleId, productionId } = useParams()
	const [loading, setLoading] = useState(true)
	const [moreDays, setMoreDays] = useState(false)
	const [role, onChange, onBlur, onChangeEvent] = useProductionStore(
		useShallow((state) => [
			state.roles[state.currentRole],
			state.onChangeRole,
			state.onBlurRole,
			state.onChangeEventRole,
		]),
	)
	const { content } = useContent(productionRoleId, 'bag')
	const dataLoadRef = useRef(false)

	const { access } = useContentAccess(productionId)
	const disabled = access === PROJECT_ACCESS.READ

	useEffect(() => {
		if (content && !dataLoadRef.current) {
			dataLoadRef.current = true
			const { setRoles, setCurrentRole } = useProductionStore.getState()
			setCurrentRole(content?.ContentId)
			Object.entries(roleProperties)
				.map(([key, value]) => ({ key, name: value }))
				?.forEach(({ key, name }) => {
					const value =
						(content[name?.replaceAll(':', '') as keyof Content] as string) ||
						''
					if (key === 'instructions') {
						setRoles(content?.ContentId, {
							[key]: value?.split(','),
						})
					} else
						setRoles(content?.ContentId, {
							[key]: value,
						})
				})

			setLoading(false)
		}
	}, [content])

	useEffect(() => {
		if (!productionRoleId) setLoading(false)
	}, [productionRoleId])

	useEffect(() => {
		if (!productionRoleId) {
			dataLoadRef.current = false
		}
	}, [productionRoleId])

	return loading ? (
		<></>
	) : (
		<Card
			step="role_basic"
			title={t('APP_ROLE_BASIC_TITLE')?.replace('{Name}', role?.title || '')}
			noHint
			wrapperStyle={{
				overflow: 'hidden',
			}}>
			<>
				<div className="grid2column">
					<div
						style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
						<Input
							value={role?.title || ''}
							label="APP_ROLE_NAME_LABEL"
							name="title"
							onChange={onChange}
							onBlur={(e) => onBlur(e, productionRoleId)}
							disabled={disabled}
						/>
						<Select
							options={optionsParser(DROPDOWN_OPTIONS.ROLE_STATUS)}
							name="type"
							label="APP_ROLE_TYPE_LABEL"
							onChange={(e) =>
								onChangeEvent('type', e.target.value, productionRoleId)
							}
							inputProps={{
								value: role?.type || '',
								// defaultValue:
								// 	roleTypeOptions.find((type) => type.value === role?.type)
								// 		?.label || '',
								name: 'type',
								disabled: disabled,
							}}
						/>

						<Textarea
							value={role?.character || ''}
							label="APP_CHARACTER_LABEL"
							name="character"
							onChange={onChange}
							onBlur={(e) => onBlur(e, productionRoleId)}
							rows={10}
							disabled={disabled}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '20px',
						}}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<RangeInput
								name="shootingDays"
								value={role?.shootingDays ? +role.shootingDays : 1}
								valueLabel={
									(role?.shootingDays ? +role.shootingDays : 1) +
									` ${t('APP_DAYS_LABEL')}`
								}
								min={1}
								max={maxShootingDays}
								label="APP_ROLE_SHOOTING_DAYS"
								onChange={onChange}
								onBlur={(e) => onBlur(e, productionRoleId)}
								containerStyle={{
									transition: 'var(--transition)',
									width:
										+role?.shootingDays === maxShootingDays ? '65%' : '100%',
								}}
								disabled={disabled}
							/>
							<div
								style={{
									display: 'flex',
									marginTop: '30px',
									transition: 'var(--transition)',
									width: +role?.shootingDays === maxShootingDays ? '35%' : '0',
									overflow: 'hidden',
								}}>
								<CheckboxButton
									label="APP_MORE_DAYS_LABEL"
									checked={moreDays}
									onChange={() => setMoreDays((prev) => !prev)}
									labelStyle={{
										whiteSpace: 'nowrap',
									}}
									disabled={disabled}
								/>
							</div>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<InputLabel
								label="APP_DEADLINE_LABEL"
								status={role?.deadline ? 'filled' : 'none'}
							/>
							<Datepicker
								disabled={disabled}
								value={
									role?.deadline
										? new Date(role?.deadline).toISOString()
										: new Date().toISOString()
								}
								onChange={(date) => {
									onChangeEvent('deadline', date as string, productionRoleId)
								}}
							/>
						</div>
					</div>
				</div>
			</>
		</Card>
	)
}

export default RoleBasic

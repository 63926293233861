export const AddNetworkIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M9.8125 5.625C10.5234 5.625 11.125 6.22656 11.125 6.9375V9.5625C11.125 10.3008 10.5234 10.875 9.8125 10.875H7.1875C6.44922 10.875 5.875 10.3008 5.875 9.5625V6.9375C5.875 6.22656 6.44922 5.625 7.1875 5.625H9.8125ZM9.8125 12.625C10.5234 12.625 11.125 13.2266 11.125 13.9375V16.5625C11.125 17.3008 10.5234 17.875 9.8125 17.875H7.1875C6.44922 17.875 5.875 17.3008 5.875 16.5625V13.9375C5.875 13.2266 6.44922 12.625 7.1875 12.625H9.8125ZM12.875 6.9375C12.875 6.22656 13.4492 5.625 14.1875 5.625H16.8125C17.5234 5.625 18.125 6.22656 18.125 6.9375V9.5625C18.125 10.3008 17.5234 10.875 16.8125 10.875H14.1875C13.4492 10.875 12.875 10.3008 12.875 9.5625V6.9375ZM15.5 12.1875C15.8555 12.1875 16.1562 12.4883 16.1562 12.8438V14.5938H17.9062C18.2617 14.5938 18.5625 14.8945 18.5625 15.25C18.5625 15.6328 18.2617 15.9062 17.9062 15.9062H16.1562V17.6562C16.1562 18.0391 15.8555 18.3125 15.5 18.3125C15.1172 18.3125 14.8438 18.0391 14.8438 17.6562V15.9062H13.0938C12.7109 15.9062 12.4375 15.6328 12.4375 15.25C12.4375 14.8945 12.7109 14.5938 13.0938 14.5938H14.8438V12.8438C14.8438 12.4883 15.1172 12.1875 15.5 12.1875Z"
			fill={color}
		/>
	</svg>
)

import { CSSProperties, InputHTMLAttributes, useState } from 'react'
import cn from 'classnames'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label?: string
	hint?: string | JSX.Element
	layoutStyle?: CSSProperties
	containerStyle?: CSSProperties
	source?: File | string
	type?: 'default' | 'portrait'
}

const DragDrop = ({
	label = '',
	hint = '',
	containerStyle,
	layoutStyle,
	source,
	type = 'default',
	...props
}: Props): JSX.Element => {
	const [status, setStatus] = useState('')

	const hintElement = typeof hint === 'string' ? <span>{t(hint)}</span> : hint

	return (
		<div className={s.container} style={containerStyle}>
			{label ? (
				<span className={cn('caption-m', 'inputLabel')}>{t(label)}</span>
			) : null}
			<div className={cn(s.layout, s[status], s[type])} style={layoutStyle}>
				{hintElement}
				{source ? (
					<img
						src={source instanceof File ? URL.createObjectURL(source) : source}
						alt="production cover"
					/>
				) : null}
			</div>
			<input
				className={s.input}
				{...props}
				type="file"
				onChange={(e) => {
					if (props.onChange) props.onChange(e)

					if (e.target.files?.[0]) setStatus('filled')
				}}
				onDragOver={() => {
					setStatus('loading')
				}}
				onDragLeave={() => {
					setStatus('')
				}}
				onDrop={() => {
					setStatus('')
				}}
			/>
		</div>
	)
}

export default DragDrop

import Card from 'components/Wizard/Card'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	contentKey: string
}

const ContentPageLayout = ({ contentKey }: Props): JSX.Element => {
	const text = t(`APP_${contentKey?.toUpperCase()}_DESCRIPTION`)
		?.replaceAll('h3', 'h4')
		?.replaceAll('h2', 'h3')
		?.replaceAll('h1', 'h2')

	return (
		<div className={s.container}>
			<Card style={{ backgroundColor: 'transparent' }}>
				<>
					<h1 className="h1-m" style={{ paddingBottom: '16px' }}>
						{t(`APP_${contentKey?.toUpperCase()}_TITLE`)}
					</h1>
					<div
						className={s.text}
						dangerouslySetInnerHTML={{
							__html: text,
						}}
					/>
				</>
			</Card>
		</div>
	)
}

export default ContentPageLayout

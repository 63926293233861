export const AppleIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M17.0511 20.28C16.0711 21.23 15.0011 21.08 13.9711 20.63C12.8811 20.17 11.8811 20.15 10.7311 20.63C9.29114 21.25 8.53114 21.07 7.67114 20.28C2.79114 15.25 3.51114 7.59 9.05114 7.31C10.4011 7.38 11.3411 8.05 12.1311 8.11C13.3111 7.87 14.4411 7.18 15.7011 7.27C17.2111 7.39 18.3511 7.99 19.1011 9.07C15.9811 10.94 16.7211 15.05 19.5811 16.2C19.0111 17.7 18.2711 19.19 17.0411 20.29L17.0511 20.28ZM12.0311 7.25C11.8811 5.02 13.6911 3.18 15.7711 3C16.0611 5.58 13.4311 7.5 12.0311 7.25Z"
			fill={color}
		/>
	</svg>
)

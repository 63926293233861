import { CSSProperties, HTMLAttributes, MutableRefObject } from 'react'
import cn from 'classnames'
import Hint from '../Hint'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props extends HTMLAttributes<HTMLDivElement> {
	className?: string
	children: JSX.Element
	style?: CSSProperties
	wrapperStyle?: CSSProperties
	step?: string
	title?: string
	description?: string
	noTitle?: boolean
	noDesc?: boolean
	noHint?: boolean
	containerRef?: MutableRefObject<null>
	headerChildrens?: JSX.Element
}

const Card = ({
	className,
	children,
	style,
	step,
	noTitle,
	noDesc,
	wrapperStyle,
	title,
	description,
	noHint,
	containerRef,
	headerChildrens,
	...props
}: Props): JSX.Element => (
	<div
		className={cn(s.container, className)}
		style={style}
		{...props}
		ref={containerRef}>
		<div className={cn(s.wrapper, 'hideScrollBar')} style={wrapperStyle}>
			<>
				{step && !noTitle ? (
					<div className={s.header}>
						<div className={s.titleWrapper}>
							<h3 className="h3-m">
								{title || t(`APP_${step.toUpperCase()}_TITLE`)}
							</h3>
							{noDesc ? null : (
								<p className="caption-m" style={{ color: 'var(--mono300)' }}>
									{description || t(`APP_${step.toUpperCase()}_DESCRIPTION`)}
								</p>
							)}
						</div>
						{headerChildrens}
					</div>
				) : null}
				{children}
				{step && !noHint ? (
					<Hint hintText={t(`APP_${step.toUpperCase()}_HINT`)} />
				) : null}
			</>
		</div>
	</div>
)

export default Card

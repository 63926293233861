export const MessageIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M12 5.625C15.8555 5.625 18.9727 8.19531 18.9727 11.3125C18.9727 14.4297 15.8828 17 12 17C10.9609 17 9.97656 16.7812 9.07422 16.4531C8.36328 17 7.05078 17.875 5.21875 17.875C5.13672 17.875 5.05469 17.8203 5.02734 17.7383C5 17.6562 5 17.5469 5.08203 17.4922C5.08203 17.4922 6.23047 16.2617 6.55859 14.8672C5.60156 13.9102 5 12.6523 5 11.3125C5 8.16797 8.11719 5.625 12 5.625ZM12.2188 13.5C12.5742 13.5 12.875 13.2266 12.875 12.8438C12.875 12.4883 12.5742 12.1875 12.2188 12.1875H9.15625C8.77344 12.1875 8.5 12.4883 8.5 12.8438C8.5 13.2266 8.77344 13.5 9.15625 13.5H12.2188ZM14.8438 10.875C15.1992 10.875 15.5 10.6016 15.5 10.2188C15.5 9.86328 15.1992 9.5625 14.8438 9.5625H9.15625C8.77344 9.5625 8.5 9.86328 8.5 10.2188C8.5 10.6016 8.77344 10.875 9.15625 10.875H14.8438Z"
			fill={color}
		/>
	</svg>
)

/* eslint-disable @typescript-eslint/ban-ts-comment */
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { ToastContainer } from 'react-toastify'
import { SWRConfig } from 'swr'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { datadogRum } from '@datadog/browser-rum'

// import '@splidejs/react-splide/css/core'
import 'react-toastify/dist/ReactToastify.css'
// import 'react-lazy-load-image-component/src/effects/blur.css'
import 'styles/index.scss'
import { ThemeProvider } from 'context/ThemeContext'

TimeAgo.addDefaultLocale(en)

if (!window.location.href.includes('localhost'))
	datadogRum.init({
		applicationId: 'e84bfed2-e857-46ec-b615-d44c6a7a2845',
		clientToken: 'pub21da81533fce21e70307d667f9bdb60c',
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: 'datadoghq.com',
		service: 'showrealz.com',
		env: '<ENV_NAME>',
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
	})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<BrowserRouter>
		<ThemeProvider>
			<SWRConfig
				value={{
					shouldRetryOnError: false,
					revalidateOnMount: true,
				}}>
				<ToastContainer
					bodyClassName="defaultToast"
					style={{
						zIndex: 'var(--toastIndex)',
					}}
					toastStyle={{
						backgroundColor: 'var(--elev400)',
					}}
					bodyStyle={{
						color: 'var(--theme-primary)',
					}}
					progressStyle={{
						backgroundColor: 'var(--theme-primary)',
					}}
				/>
				<App />
			</SWRConfig>
		</ThemeProvider>
	</BrowserRouter>,
)

reportWebVitals()

import cn from 'classnames'
import LevelGraph from './components/LevelGraph'
import { SelectOption } from 'types/app'
import s from './index.module.scss'

interface Props {
	type: 'Level' | 'Dialect'
	option: SelectOption
	skillValue: string
	onChange: (
		type: 'Level' | 'Dialect',
		skillValue: string,
		levelValue: string,
	) => void
	optionLength: number
	index: number
}

const LevelCard = ({
	type,
	option,
	skillValue,
	onChange,
	optionLength,
	index,
}: Props): JSX.Element => (
	<div
		className={cn('br', s.wrapper)}
		onClick={() => onChange(type, skillValue, option.value)}>
		<div className={s.titles}>
			<span className="body1-b">{option.label}</span>
			{option.hint ? (
				<span className="body1-r" style={{ color: 'var(--mono200)' }}>
					{option.hint}
				</span>
			) : null}
		</div>
		{type === 'Level' ? (
			<LevelGraph current={index} length={optionLength} />
		) : null}
	</div>
)

export default LevelCard

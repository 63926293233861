import { useCallback, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { partialContentUpdate } from 'api/content'
import StepContent from './StepContent'
import { usePortfolioStore } from 'store'
import { AttributeCardType, AttributeSelector, PropertiesType } from 'types/app'
import { PORTFOLIO_TYPES } from 'types/enums'

interface Props {
	cardData: AttributeCardType
	selectorsData: AttributeSelector[]
}

const AttributeSidebarContent = ({
	cardData,
	selectorsData,
}: Props): JSX.Element => {
	const [confirm, generalType] = usePortfolioStore(
		useShallow((state) => [state.confirmModal, state.generalModalType]),
	)

	const mediaType = [
		PORTFOLIO_TYPES.PICTURE,
		PORTFOLIO_TYPES.SHOWREEL,
	].includes(generalType)

	const findNextEmpty = useCallback((): void => {
		const max = 16
		const array = Array.from({ length: max })?.map((_, index) => index)
		const index = cardData?.index || 0
		const indexes = array.slice(index + 1, max).concat(array.slice(0, index))
		const { cardValues, generalModalType, setModalType, setCardData } =
			usePortfolioStore.getState()
		const nextEmptyIndex = indexes.find((ind) => {
			const propName = generalModalType + ind
			const values = cardValues[propName]

			return !values
		})
		if (nextEmptyIndex !== undefined) {
			setModalType(generalModalType + nextEmptyIndex)
			setCardData({
				...cardData,
				index: nextEmptyIndex,
			})
		} else setCardData(null)
	}, [cardData])

	const uploadFileWithTags = useCallback(async (): Promise<void> => {
		const { setLoading, setConfirmModal, setCardData } =
			usePortfolioStore.getState()

		try {
			const {
				cardValues,
				getModalProp,
				uploadFile,
				generalModalType,
				setCardValues,
			} = usePortfolioStore.getState()
			const propName = getModalProp()
			const values = cardValues[propName]
			// if (!values || Object.values(values)?.length < 3) {
			// 	toast.info(t('APP_PLEASE_FILL_ALL_FIELDS'))
			// }
			setLoading(true)
			const file = values && values[propName]
			let contentId: string | undefined = ''
			if (file && file instanceof File && values?.newUpload) {
				const mediaIndex = cardData?.index
				contentId = await uploadFile(
					file.name,
					file as File,
					generalModalType === PORTFOLIO_TYPES.SHOWREEL ? 'Video' : 'Image',
					mediaIndex,
				)
				setCardValues({
					newUpload: false,
					contentId: contentId || '',
				})
			}
			const id = values?.newUpload ? contentId : (values?.contentId as string)
			if (id)
				await partialContentUpdate(
					id,
					{
						labels: {
							Style: [
								Object.entries(values).find(([key]) =>
									key.toLowerCase().includes('style'),
								)?.[1],
							],
							Type: [
								Object.entries(values).find(([key]) =>
									key.toLowerCase().includes('type'),
								)?.[1],
							],
						},
					},
					'?strategy=0',
				)
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			setConfirmModal(false)
			const {
				prevSidebarData,
				setGeneralModalType,
				setModalType,
				setGalleryType,
				setPrevSidebarData,
			} = usePortfolioStore.getState()
			if (prevSidebarData) {
				setCardData(prevSidebarData?.cardData)
				setGeneralModalType(prevSidebarData.generalType)
				setModalType(prevSidebarData.modalType)
				setGalleryType(prevSidebarData.galleryType)
			} else {
				findNextEmpty()
			}
			setPrevSidebarData(null)
		}
	}, [cardData?.index, findNextEmpty])

	useEffect(() => {
		if (confirm && !mediaType) {
			const { cardValues, getModalProp } = usePortfolioStore.getState()
			const values = cardValues[getModalProp()]

			const parsed = Object.entries(values).map(([key, value]) => ({
				Name: key,
				Value: typeof value === 'string' ? value : JSON.stringify(value),
			}))

			const { updateFields } = usePortfolioStore.getState()
			updateFields(parsed as PropertiesType[])
		}
	}, [confirm, selectorsData, mediaType])

	useEffect(() => {
		if (confirm && mediaType) {
			uploadFileWithTags()
		}
	}, [confirm, uploadFileWithTags, mediaType])

	return (
		<StepContent
			selectorsData={selectorsData}
			cardData={cardData}
			mediaType={mediaType}
		/>
	)
}

export default AttributeSidebarContent

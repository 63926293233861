import { PrevIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
}

const Prev = ({ videoElement }: Props): JSX.Element => {
	const onPrevClick = (): void => {
		if (videoElement) videoElement.currentTime = videoElement.currentTime - 10
	}

	return (
		<Tooltip id="prev-control" title="APP_PREV_CONTROL">
			<div className={s.control} onClick={onPrevClick}>
				<PrevIcon />
			</div>
		</Tooltip>
	)
}

export default Prev

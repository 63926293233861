export const EyesIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M15.9375 5.625C18.5898 5.625 20.75 8.38672 20.75 11.75C20.75 15.1406 18.5898 17.875 15.9375 17.875C14.2969 17.875 12.8477 16.8633 12 15.2773C11.125 16.8633 9.67578 17.875 8.0625 17.875C5.38281 17.875 3.25 15.1406 3.25 11.75C3.25 8.38672 5.38281 5.625 8.0625 5.625C9.67578 5.625 11.125 6.66406 12 8.25C12.8477 6.66406 14.2969 5.625 15.9375 5.625ZM8.0625 16.125C9.70312 16.125 11.125 14.1289 11.125 11.75C11.125 9.39844 9.70312 7.375 8.0625 7.375C6.55859 7.375 5.27344 9.04297 5.02734 11.0938C5.27344 10.957 5.54688 10.875 5.875 10.875C6.83203 10.875 7.625 11.668 7.625 12.625C7.625 13.6094 6.83203 14.375 5.875 14.375C5.76562 14.375 5.71094 14.375 5.62891 14.375C6.17578 15.4414 7.07812 16.125 8.0625 16.125ZM15.9375 16.125C17.5781 16.125 19 14.1289 19 11.75C19 9.39844 17.5781 7.375 15.9375 7.375C14.4336 7.375 13.1484 9.04297 12.9023 11.0938C13.1484 10.9844 13.4492 10.875 13.75 10.875C14.707 10.875 15.5 11.668 15.5 12.625C15.5 13.6094 14.707 14.375 13.75 14.375C13.6406 14.375 13.5859 14.375 13.5039 14.375C14.0508 15.4414 14.9531 16.125 15.9375 16.125Z"
			fill={color}
		/>
	</svg>
)

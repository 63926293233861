import { ChangeEvent } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Select from 'components/UI/Select'
import CountryFlag from 'components/CountryFlag'
import { useAppStore } from 'store'

interface Props {
	name?: string
	label?: string
	value: string
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const CountrySelector = ({
	name,
	label,
	value,
	onChange,
}: Props): JSX.Element => {
	const countries = useAppStore(useShallow((state) => state.countries))
	const options = countries?.map((country) => ({
		value: `${country.name}`,
		// value: `${country.name}, ${country.iso2?.toUpperCase()}`,
		label: country.name,
		element: <CountryFlag code={country.iso2} />,
	}))

	return (
		<Select
			name={name || 'Customer:Location:Country'}
			label={label || 'APP_LOCATION_LABEL'}
			options={options}
			inputProps={{
				name: name || 'Customer:Location:Country',
				value,
			}}
			onChange={onChange}
		/>
	)
}

export default CountrySelector

export const VerifiedStatusIcon = ({
	mainColor = 'var(--success)',
	borderColor = 'var(--mono900)',
	width = '32',
	height = '32',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<mask
			id="path-1-outside-1_688_20171"
			maskUnits="userSpaceOnUse"
			x="5"
			y="5"
			width="22"
			height="23"
			fill="black"
		>
			<rect fill="white" x="5" y="5" width="22" height="23" />
			<path d="M7 16.25C7 11.293 11.0078 7.25 16 7.25C20.957 7.25 25 11.293 25 16.25C25 21.2422 20.957 25.25 16 25.25C11.0078 25.25 7 21.2422 7 16.25ZM20.043 14.7031C20.4297 14.3164 20.4297 13.7188 20.043 13.332C19.6562 12.9453 19.0586 12.9453 18.6719 13.332L14.875 17.1289L13.293 15.582C12.9062 15.1953 12.3086 15.1953 11.9219 15.582C11.5352 15.9688 11.5352 16.5664 11.9219 16.9531L14.1719 19.2031C14.5586 19.5898 15.1562 19.5898 15.543 19.2031L20.043 14.7031Z" />
		</mask>
		<path
			d="M7 16.25C7 11.293 11.0078 7.25 16 7.25C20.957 7.25 25 11.293 25 16.25C25 21.2422 20.957 25.25 16 25.25C11.0078 25.25 7 21.2422 7 16.25ZM20.043 14.7031C20.4297 14.3164 20.4297 13.7188 20.043 13.332C19.6562 12.9453 19.0586 12.9453 18.6719 13.332L14.875 17.1289L13.293 15.582C12.9062 15.1953 12.3086 15.1953 11.9219 15.582C11.5352 15.9688 11.5352 16.5664 11.9219 16.9531L14.1719 19.2031C14.5586 19.5898 15.1562 19.5898 15.543 19.2031L20.043 14.7031Z"
			fill={mainColor}
		/>
		<path
			d="M14.875 17.1289L13.4768 18.5589L14.8908 19.9415L16.2892 18.5431L14.875 17.1289ZM13.293 15.582L11.8788 16.9962L11.8867 17.0042L11.8947 17.012L13.293 15.582ZM9 16.25C9 12.3924 12.1175 9.25 16 9.25V5.25C9.89816 5.25 5 10.1935 5 16.25H9ZM16 9.25C19.8525 9.25 23 12.3975 23 16.25H27C27 10.1884 22.0616 5.25 16 5.25V9.25ZM23 16.25C23 20.1325 19.8576 23.25 16 23.25V27.25C22.0565 27.25 27 22.3518 27 16.25H23ZM16 23.25C12.1124 23.25 9 20.1376 9 16.25H5C5 22.3468 9.90324 27.25 16 27.25V23.25ZM21.4572 16.1173C22.6249 14.9496 22.6249 13.0856 21.4572 11.9178L18.6288 14.7462C18.2344 14.3519 18.2344 13.6832 18.6288 13.2889L21.4572 16.1173ZM21.4572 11.9178C20.2894 10.7501 18.4254 10.7501 17.2577 11.9178L20.0861 14.7462C19.6918 15.1406 19.0231 15.1406 18.6288 14.7462L21.4572 11.9178ZM17.2577 11.9178L13.4608 15.7147L16.2892 18.5431L20.0861 14.7462L17.2577 11.9178ZM16.2732 15.6989L14.6912 14.152L11.8947 17.012L13.4768 18.5589L16.2732 15.6989ZM14.7072 14.1678C13.5394 13.0001 11.6754 13.0001 10.5077 14.1678L13.3361 16.9962C12.9418 17.3906 12.2731 17.3906 11.8788 16.9962L14.7072 14.1678ZM10.5077 14.1678C9.33989 15.3356 9.33989 17.1996 10.5077 18.3673L13.3361 15.5389C13.7304 15.9332 13.7304 16.6019 13.3361 16.9962L10.5077 14.1678ZM10.5077 18.3673L12.7577 20.6173L15.5861 17.7889L13.3361 15.5389L10.5077 18.3673ZM12.7577 20.6173C13.9254 21.7851 15.7894 21.7851 16.9572 20.6173L14.1288 17.7889C14.5231 17.3946 15.1918 17.3946 15.5861 17.7889L12.7577 20.6173ZM16.9572 20.6173L21.4572 16.1173L18.6288 13.2889L14.1288 17.7889L16.9572 20.6173Z"
			fill={borderColor}
			mask="url(#path-1-outside-1_688_20171)"
		/>
	</svg>
)

import { useNavigate } from 'react-router-dom'
import { SwiperSlide } from 'swiper/react'
import NetworkCard from 'components/NetworkCard'
import RecentCarousel from 'components/RecentCarousel'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import { t } from 'helpers'
import { useNetworkStore } from 'store'
import { FILTER_CONDITION, SORT_DIRECTION } from 'types/enums'
import { User } from 'types/user'

const CastingDirectorsCarousel = (): JSX.Element => {
	const navigate = useNavigate()

	const { data } = useCustomerSearch({
		fetch: true,
		filters: [
			{
				propName: 'Customer:Role',
				propValue: 'CastingDirector',
				cond: FILTER_CONDITION.EQUAL,
			},
		],
		orders: [
			{
				sortBy: 'Customer:CreatedDate',
				sortDir: SORT_DIRECTION.RANDOM,
			},
		],
		type: 'ProjectOrganizer',
	})

	const onUserClick = async (user: User): Promise<void> => {
		const { userProfile, setUserProfile } = useNetworkStore.getState()
		const path = await userProfile.onUserChange(user)
		navigate(path)
		setUserProfile({ users: data, user, listSource: 'APP_RECENT_LIST_TITLE' })
	}

	return (
		<>
			{data?.length ? (
				<RecentCarousel name="">
					<>
						{data?.map((user) => (
							<SwiperSlide key={user?.ContentId}>
								<NetworkCard
									style={{ width: '100%' }}
									variant="small"
									data={user}
									onClick={() => onUserClick(user)}
								/>
							</SwiperSlide>
						))}
					</>
				</RecentCarousel>
			) : (
				<span>{t('APP_RESULT_NOT_FOUND')}</span>
			)}
		</>
	)
}

export default CastingDirectorsCarousel

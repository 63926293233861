import classNames from 'classnames'
import { t } from 'helpers'
import { StepComponent } from 'types/app'
import s from './index.module.scss'

interface Props {
	tabName: string
	active: boolean | StepComponent | undefined
	onClick: () => void
}

const Tab = ({ tabName, active, onClick }: Props): JSX.Element => (
	<div
		className={classNames(s.tab, {
			[s.active]: active,
		})}
		onClick={onClick}>
		<span className="body1-m">{t(tabName)}</span>
	</div>
)

export default Tab

/* eslint-disable no-tabs */
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ChatToken, SendMessageRequest } from 'amazon-ivs-chat-messaging'
import { addRelation } from 'api/relations'
import { connectIVSChatRoom } from 'api/chat'
import { createMarker } from 'api/content'
import CheckboxButton from 'components/UI/CheckboxButton'
import SidebarButton from 'components/Sidebar/SidebarButton'
import Textarea from 'components/UI/Textarea'
import { useChatRoom } from 'hooks/useChatRoom'
import { t, uuidv4 } from 'helpers'
import { getUser } from 'helpers/storage'
import { EventBus } from 'helpers/EventBus'
import { msToHHMMSS } from 'helpers/dateTimeConfig'
import { useCastingStore } from 'store'
import { Content } from 'types/app'
import { CASTING_STATUS, RELATIONS } from 'types/enums'
import * as config from '../../../../../../../ivs-config'
import s from './index.module.scss'

interface Props {
	chatData: Content
}

const Chat = ({ chatData }: Props): JSX.Element => {
	const [message, setMessage] = useState(
		t('APP_MESSAGE_TO_ACTOR_DEFAULT_VALUE'),
	)
	const [contactArtist, setContactArtist] = useState(false)
	const { roleId, userId } = useParams()
	// const { user } = useUser(userId)

	const { room } = useChatRoom({
		regionOrUrl: config.CHAT_WEBSOCKET,
		tokenProvider: () =>
			tokenProvider(getUser()?.['Custom:User:Id'], true, {
				src: getUser()?.['Custom:User:Avatar'],
			}),
	})

	const tokenProvider = useCallback(
		async (
			selectedUsername: string | undefined,
			isModerator: boolean,
			avatarUrl: { src: string | undefined },
		): Promise<ChatToken> => {
			const uuid = uuidv4()
			const permissions = isModerator
				? ['SEND_MESSAGE', 'DELETE_MESSAGE', 'DISCONNECT_USER']
				: ['SEND_MESSAGE']

			const data = {
				userId:
					getUser()?.['Custom:User:Avatar'] || `${selectedUsername}.${uuid}`,
				arn: chatData.ContentChatArn,
				attributes: {
					username: `${selectedUsername}`,
					avatar: `${avatarUrl.src}`,
				},
				capabilities: permissions,
			}

			let token = {} as ChatToken

			try {
				const response = await connectIVSChatRoom(chatData.ContentId, data)

				token = {
					token: response.token,
					sessionExpirationTime: new Date(response.sessionExpiration),
					tokenExpirationTime: new Date(response.tokenExpiration),
				} as ChatToken
			} catch (error) {
				console.log('error:', error)
			}

			return token
		},
		[chatData.ContentChatArn, chatData.ContentId],
	)

	useEffect(() => {
		room.connect()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const sendMessage = async (message: string): Promise<void> => {
		try {
			const content = `${message.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}`

			const video = document.querySelector(
				'video[data-active=true]',
			) as HTMLVideoElement
			const currentTime = video?.currentTime
			const contentId = video?.id?.split('_')?.[1]

			await createMarker({
				type: 'EasterEgg',
				contentId: contentId,
				position: msToHHMMSS(currentTime * 1000),
			})

			const attributes = {
				type: 'text',
				body: content,
				playerTimestamp: msToHHMMSS(currentTime * 1000),
				playerContentId: contentId,
			}
			handleSendMessage({ attributes })

			if (contactArtist && userId) {
				await addRelation({
					sourceId: roleId,
					targetId: userId,
					type: 'Content',
					relation: RELATIONS.ACCESS,
					status: CASTING_STATUS.UPLOADING,
				})
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			const { setOpenMessageToActorSidebar } = useCastingStore.getState()
			setOpenMessageToActorSidebar(false)
		}
	}

	const handleSendMessage = async ({ attributes }: any): Promise<void> => {
		const request = new SendMessageRequest(JSON.stringify({ ...attributes }))
		try {
			await room.sendMessage(request)
			// setSendToActor(false)
			EventBus.$emit('refreshMarkers')
		} catch (error: unknown) {
			console.log('error:', error)
		}
	}

	const send = (): void => {
		if (message) {
			sendMessage(message)
		}
	}

	return (
		<div className={s.container}>
			<div style={{ display: 'flex' }}>
				<Textarea
					onChange={(e) => setMessage(e.target.value)}
					defaultValue={t('APP_MESSAGE_TO_ACTOR_DEFAULT_VALUE')}
					rows={20}
				/>
			</div>
			<CheckboxButton
				style={{ paddingLeft: '0' }}
				label="APP_SEND_BACK_CHECK"
				checked={contactArtist}
				onChange={() => setContactArtist((prev) => !prev)}
			/>

			<SidebarButton
				children={t('APP_SEND_MESSAGE')}
				onClick={() => {
					send()
				}}
			/>
		</div>
	)
}

export default Chat

import { PauseIcon, PlayIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
	playing: boolean
	onChange: (v: { [key: string]: boolean }) => void
}

const PlayPause = ({ videoElement, playing, onChange }: Props): JSX.Element => {
	const onPlay = (): void => {
		if (videoElement) {
			videoElement.play()
			onChange({ playing: true })
		}
	}
	const onPause = (): void => {
		if (videoElement) {
			videoElement.pause()
			onChange({ playing: false })
		}
	}

	return (
		<Tooltip title={playing ? 'APP_PAUSE_CONTROL' : 'APP_PLAY_CONTROL'}>
			<div className={s.control} onClick={playing ? onPause : onPlay}>
				{playing ? <PauseIcon /> : <PlayIcon />}
			</div>
		</Tooltip>
	)
}

export default PlayPause

import { useCallback, useEffect, useMemo, useRef } from 'react'
import { ContentAuthorizeType } from 'types/content'
import s from './index.module.scss'

interface Props {
	videoData?: ContentAuthorizeType
	videoElement?: HTMLVideoElement | null
}

const Subtitles = ({ videoData, videoElement }: Props): JSX.Element => {
	const subtitle = useRef<HTMLHeadingElement>(null)
	// const subtitleItems = [{ title: 'Off', checked: true }]

	const subtitles = useMemo(
		() =>
			videoData?.ContentPlaybackSubtitleVtt
				? Object.values(videoData.ContentPlaybackSubtitleVtt).map((url) => {
						// subtitleItems.push({ title: langCode, checked: false })
						const langCode = url.slice(-9, -4)

						return { langCode, url }
				  })
				: null,
		[videoData?.ContentPlaybackSubtitleVtt],
	)

	const switchSubtitle = useCallback(
		(langCode: string): void => {
			const textTracks = videoElement?.textTracks
			if (textTracks) {
				for (let i = 0; i < textTracks.length; i++) {
					if (textTracks[i].id === langCode) {
						textTracks[i].mode = 'showing'
					} else {
						textTracks[i].mode = 'disabled'
					}
				}
			}
		},
		[videoElement],
	)

	const castSubtitles = (event: Event): void => {
		if (!subtitle.current) return

		const track = event.target as TextTrack
		const activeCue = track.activeCues?.[0] as VTTCue | undefined
		if (activeCue?.text) {
			subtitle.current.innerHTML = activeCue.text.replace(/\n/g, '<br>')
			subtitle.current.style.padding = '10px'
		} else {
			subtitle.current.innerHTML = ''
			subtitle.current.style.padding = '0px'
		}
	}

	const displaySubtitles = useCallback(
		(subtitleLang: string, textTrack: TextTrack): void => {
			if (!subtitleLang) return

			let newTextTrack = textTrack
			if (videoElement && videoElement?.textTracks.length > 0) {
				const video = videoElement
				for (let i = 0; i < video.textTracks.length; i++) {
					newTextTrack = video.textTracks[i]
					if (newTextTrack) {
						if (newTextTrack.id === subtitleLang) {
							newTextTrack.addEventListener('cuechange', castSubtitles, true)
							newTextTrack.mode = 'hidden'
						} else {
							newTextTrack.mode = 'disabled'
						}
					}
				}
			}
		},
		[videoElement],
	)

	// useEffect(() => {
	// 	// const selectedSubtitle = subtitleItems.find((item) => !!item.checked)
	// 	const selectedSubtitle = { title: subtitles?.[0]?.langCode || '' }
	// 	if (selectedSubtitle) {
	// 		// switchSubtitle(selectedSubtitle.title)
	// 		const textTrack: any = null
	// 		if (subtitle.current) {
	// 			subtitle.current.innerHTML = ''
	// 		}
	// 		if (selectedSubtitle.title !== 'Off') {
	// 			displaySubtitles(selectedSubtitle.title, textTrack)
	// 		}

	// 		return () => {
	// 			if (textTrack !== null)
	// 				textTrack.removeEventListener('cuechange', castSubtitles)
	// 		}
	// 	}
	// }, [displaySubtitles, subtitles, switchSubtitle])

	useEffect(() => {
		if (subtitles?.length && videoElement) {
			for (const sub of subtitles) {
				const track = document.createElement('track')
				track.id = sub.langCode
				track.kind = 'subtitles'
				track.src = sub.url
				track.srclang = sub.langCode.slice(0, 2)
				track.label = sub.langCode
				videoElement?.appendChild(track)
			}

			const selectedSubtitle = { title: subtitles?.[0]?.langCode || '' }
			if (selectedSubtitle) {
				// switchSubtitle(selectedSubtitle.title)
				const textTrack: any = null
				if (subtitle.current) {
					subtitle.current.innerHTML = ''
				}
				if (selectedSubtitle.title !== 'Off') {
					setTimeout(() => {
						displaySubtitles(selectedSubtitle.title, textTrack)
					}, 1000)
				}

				return () => {
					if (textTrack !== null)
						textTrack.removeEventListener('cuechange', castSubtitles)
				}
			}
		}
	}, [displaySubtitles, subtitles, videoElement])

	return (
		<div className={s.container}>
			<h4 ref={subtitle} />
		</div>
	)
}

export default Subtitles

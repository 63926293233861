import { lazy, useEffect } from 'react'
// import { useShallow } from 'zustand/react/shallow'
import { useLocation, useNavigate } from 'react-router-dom'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import cn from 'classnames'
import StepWrapper from 'components/Wizard/StepWrapper'
import AssignCastingSidebar from './components/AssignCastingSidebar'
import StepControls from '../components/StepControls'
import { useAppStore } from 'store'
import s from '../index.module.scss'

const AgencyBoard = lazy(() => import('./components/AgencyBoard'))
const CastingOverview = lazy(() => import('../components/CastingOverview'))

export const agencyCastingSteps = {
	board: {
		name: 'APP_CASTING_BOARD',
		key: 'board',
		main: true,
		substeps: [
			{
				name: 'APP_CASTING_BOARD',
				key: 'board',
				component: AgencyBoard,
				index: 0,
				disabled: true,
			},
			{
				name: 'APP_CASTING_BOARD',
				key: 'board',
				component: CastingOverview,
				index: 1,
				disabled: true,
				hide: true,
			},
		],
	},
}

const AgencyCasting = (): JSX.Element => {
	const { hash } = useLocation()
	const navigate = useNavigate()
	// const stepWizard = useAppStore(useShallow((state) => state.stepWizard))

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname +
					'#' +
					Object.values(agencyCastingSteps)?.[0]?.key +
					'-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	/* for the casting scenes reloading */
	useEffect(() => {
		const { stepWizard } = useAppStore.getState()
		const { pathname, hash, search } = window.location
		const board = hash?.includes(agencyCastingSteps?.board?.key)
		const index = +hash?.slice(-1)
		if (board && index > 1) {
			stepWizard?.goToStep(2)
			setTimeout(() => {
				navigate(
					pathname +
						search +
						`#${agencyCastingSteps?.board?.key + '-' + index}`,
				)
			}, 500)
		}
	}, [navigate])

	return (
		<div className={s.container}>
			<AssignCastingSidebar />
			<div className={cn(s.wrapper, 'flex')}>
				<StepWizard
					initialStep={0}
					instance={assignStepWizard}
					isHashEnabled={true}>
					{Object.values(agencyCastingSteps)
						?.map((step) => step.substeps)
						?.flat()
						?.map((step) => (
							<StepWrapper
								style={
									['', `#${agencyCastingSteps.board.key}-0`].includes(hash)
										? {
												height: 'auto',
												display: 'flex',
										  }
										: {
												display: 'flex',
										  }
								}
								key={step.name + '_' + step.index}
								hashKey={step.key + '-' + step.index}
								stepName={step.key + '-' + step.index}>
								<step.component />
							</StepWrapper>
						))}
				</StepWizard>
			</div>
			<StepControls />
		</div>
	)
}

export default AgencyCasting

export const CircleIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M19 11.75C19 15.6328 15.8555 18.75 12 18.75C8.11719 18.75 5 15.6328 5 11.75C5 7.89453 8.11719 4.75 12 4.75C15.8555 4.75 19 7.89453 19 11.75ZM12 6.0625C8.85547 6.0625 6.3125 8.63281 6.3125 11.75C6.3125 14.8945 8.85547 17.4375 12 17.4375C15.1172 17.4375 17.6875 14.8945 17.6875 11.75C17.6875 8.63281 15.1172 6.0625 12 6.0625Z"
			fill={color}
		/>
	</svg>
)

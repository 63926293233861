import { createWithEqualityFn } from 'zustand/traditional'
import { MessageStore } from 'types/store'

const initialState = {
	users: [],
	openUsersSearchSidebar: false,
}

const useStore = createWithEqualityFn<MessageStore>(
	(set) => ({
		...initialState,
		setUser: (user) =>
			set((state) => ({
				users: state.users?.find((u) => u.ContentId === user.ContentId)
					? state.users?.filter((u) => u?.ContentId !== user?.ContentId)
					: [...state.users, user],
			})),
		setValue: (v) =>
			set((state) => ({
				...state,
				...v,
			})),
		resetStore: () => set(initialState),
	}),
	Object.is,
)

export default useStore

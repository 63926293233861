import { useAppStore, useUserStore } from 'store'
import { getUser } from './storage'
import { MessageData, PropertiesType, SelectOption } from 'types/app'
import { PATHS } from 'pages/Router'
import { CUSTOMER_ROLE, User } from 'types/user'

export const uuidv4 = (): string => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		// eslint-disable-next-line
		var r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8

		return v.toString(16)
	})
}

export const getLanguage = (): string =>
	(navigator.languages.length &&
		navigator.languages.find((lang) => lang.includes('-'))) ||
	navigator.languages[0] ||
	navigator.language ||
	'en'

export const handleDataForCorrectDisplay = (
	array: PropertiesType[],
): { [key: string]: string } => {
	const normalizedObject: { [key: string]: string } = {}
	for (let i = 0; i < array?.length; i++) {
		const key = array[i]?.Name
		normalizedObject[key] = array[i]?.Value
	}

	return normalizedObject
}

export const isUserAuthorized = (): boolean | null => {
	const currentUser = getUser()

	return currentUser && !!currentUser['Custom:User:Token']
}

export const breakWords = (string: string): string =>
	string?.split(/(?=[A-Z])/)?.join(' ')

export const capitalize = (string: string): string =>
	string
		?.split(' ')
		?.map((s) => s?.slice(0, 1)?.toUpperCase() + s?.slice(1))
		?.join(' ')

export const textParser = (str: string): string[] =>
	str?.split('\n')?.filter((v) => !!v)

export const optionsParser = (str: string): SelectOption[] =>
	t(str)
		?.split('\n')
		?.map((string) => string?.split('|'))
		?.map(
			(option) =>
				({
					value: option[0],
					label: option[1],
					hint: option[2],
					color: option[3],
					related: option[4],
				} as SelectOption),
		)

export const t = (key?: string): string => {
	if (!key) return ''

	const locale = localStorage.getItem('locale') || 'en-US'
	const { locales } = useAppStore.getState()

	return locales && locales[locale] && locales[locale][key]
		? locales[locale][key]
		: key
}

export const getDefaultUserPath = (role?: string): string => {
	const { user } = useUserStore.getState()
	const userRole = role
		? role?.toLowerCase()
		: user?.CustomerRole?.toLowerCase()

	const pathes = {
		[CUSTOMER_ROLE.ACTOR]: PATHS.PORTFOLIO,
		[CUSTOMER_ROLE.AGENCY]: PATHS.NETWORK,
		[CUSTOMER_ROLE.CONTRIBUTOR]: PATHS.CASTING,
		[CUSTOMER_ROLE.CASTING_DIRECTOR]: PATHS.CASTING,
	}

	return userRole ? `/${userRole}/${pathes[userRole as CUSTOMER_ROLE]}` : ''
}

export const constructLink = (
	pathname: string,
	search?: string,
	hash?: string,
): string =>
	pathname + (search || window.location.search) + (hash || window.location.hash)

export const prepareMessageData = (data: MessageData): string =>
	'/' +
	PATHS.MESSAGE +
	'?key=' +
	data?.key +
	'&data=' +
	window.btoa(JSON.stringify(data))

export const getPinnedMedia = (
	name: string,
): { [key: number]: string } | null => {
	const { user } = useUserStore.getState()
	const value = user?.[name as keyof User] as string
	const pinned: { [key: number]: string } | null = value
		? Object.entries(JSON.parse(value) as { [key: string]: number })
				?.map(([key, value]) => ({
					[value]: key,
				}))
				?.reduce((prev, next) => ({ ...prev, ...next }), {})
		: null

	return pinned
}

export const wait = async (ms: number): Promise<void> =>
	await new Promise((resolve) => setTimeout(resolve, ms))

import { HTMLAttributes } from 'react'
import cn from 'classnames'
import s from './index.module.scss'

interface Props extends HTMLAttributes<HTMLDivElement> {
	children: JSX.Element
	type?: 'sidebar' | 'default'
}

const SectionWrapper = ({ children, type, ...props }: Props): JSX.Element => (
	<div
		{...props}
		className={cn('card', s.container, props.className, s[type || ''])}>
		{children}
	</div>
)

export default SectionWrapper

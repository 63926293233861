export const TwitterIcon = ({
	color = 'var(--mono100)',
	width = '32',
	height = '33',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M28.7812 29L18.7847 14.4295L18.8018 14.4432L27.8151 4H24.8031L17.4606 12.5L11.6297 4H3.73022L13.063 17.6034L13.0618 17.6023L3.21875 29H6.23078L14.394 19.5432L20.8818 29H28.7812ZM10.4362 6.27272L24.4621 26.7273H22.0752L8.03803 6.27272H10.4362Z"
			fill={color}
		/>
	</svg>
)

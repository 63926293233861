import { useMemo, useState } from 'react'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'react-toastify'
import { ArrowShortIcon, EditIcon, SearchIcon } from 'assets/icons'
import CheckboxButton from 'components/UI/CheckboxButton'
import Input from 'components/UI/Input'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { capitalize, optionsParser, t } from 'helpers'
import { usePortfolioStore } from 'store'
import { AttributeSelector, Content, SelectOption, ValueLevel } from 'types/app'
import { PORTFOLIO_TYPES, RELATIONS } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	skillFields,
} from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	selectors: AttributeSelector[]
}

const MultipleSelector = ({ selectors }: Props): JSX.Element => {
	const [fieldSearch, setFieldSearch] = useState('')

	const mainSelector = selectors?.[0]
	const levelSelector = selectors?.[1]

	const options = selectors[0]?.options?.filter((option) =>
		option.label?.toLowerCase()?.includes(fieldSearch?.toLowerCase()),
	)

	const { data } = useRelationsContent(
		[RELATIONS.IMAGE, RELATIONS.BEST_PERFORMANCES],
		undefined,
		undefined,
		'',
		false,
		true,
	)

	const images = useMemo(
		() =>
			data
				? Object.entries(data)
						?.filter(([key]) => RELATIONS.IMAGE === key)
						?.map(([_, value]) => value)
						.flat()
				: [],
		[data],
	)

	const videos = useMemo(
		() =>
			data
				? Object.entries(data)
						?.filter(([key]) => RELATIONS.BEST_PERFORMANCES === key)
						?.map(([_, value]) => value)
						.flat()
				: [],
		[data],
	)

	return (
		<>
			<Input
				value={fieldSearch}
				onChange={(e) => setFieldSearch(e.target.value)}
				placeholder={t('APP_SEARCH_FIELD_PLACEHOLDER')}
				variant="default"
				endAdornment={<SearchIcon color="var(--mono300)" />}
			/>
			{options?.map((option) => (
				<ExtendSelector
					key={option.value}
					option={option}
					mainSelector={mainSelector}
					levelSelector={levelSelector}
					mediaItems={[...images, ...videos]}
				/>
			))}
		</>
	)
}

export default MultipleSelector

export const ExtendSelector = ({
	option,
	mainSelector,
	levelSelector,
	mediaItems,
	noMedia,
}: {
	option: SelectOption
	mainSelector: AttributeSelector
	levelSelector?: AttributeSelector
	mediaItems?: Content[]
	noMedia?: boolean
}): JSX.Element => {
	const [expand, setExpand] = useState(true)
	const [cardValues, type] = usePortfolioStore(
		useShallow((state) => [state.cardValues, state.modalType]),
	)

	const values =
		(cardValues[type][mainSelector?.name] as unknown as ValueLevel[]) || []

	const subSelectors = useMemo(() => {
		if (mainSelector?.subSelectors?.length) {
			return mainSelector?.subSelectors?.map((select) => ({
				...select,
				options: optionsParser(t(select.optionName || '')),
			})) as AttributeSelector[]
		} else return null
	}, [mainSelector])

	const onValueChange = (option: SelectOption): void => {
		const { modalType, updateFields, setCardValues, setGalleryType } =
			usePortfolioStore.getState()
		setGalleryType(null)
		const value =
			values?.find((v) => v?.Value === option.value)?.Value === option.value

		const prev = (cardValues[modalType][mainSelector?.name] ||
			[]) as unknown as ValueLevel[]

		const newValue: ValueLevel = {
			Value: '',
			Level: '',
		}
		if (!value) {
			newValue['Value'] = option.value
			const { setAdditionalSidebar } = usePortfolioStore.getState()
			setAdditionalSidebar({
				type: 'Level',
				valueOption: option,
				selectorName: mainSelector?.name,
				options: levelSelector?.options || [],
			})
		}
		const newValues = [
			...prev.filter((v) => v?.Value !== option.value),
			newValue,
		]?.filter((v) => !!v && (!!v?.Level || !!v?.Value))

		if (!newValues?.find((value) => value.Value === option.value)) {
			const { setAdditionalSidebar } = usePortfolioStore.getState()
			setAdditionalSidebar(null)
		}

		updateFields([
			{
				Name: mainSelector?.name,
				Value: JSON.stringify(newValues),
			},
		])
		setCardValues({
			[mainSelector?.name]: newValues,
		})
	}

	const onLevelChange = (): void => {
		const {
			setCardValues,
			cardValues,
			modalType,
			setGalleryType,
			updateFields,
		} = usePortfolioStore.getState()
		const prev = (cardValues[modalType][mainSelector.name] as string[]) || []
		const index = prev.indexOf(option.value)
		setGalleryType(null)

		if (index > -1) {
			setCardValues({
				[mainSelector.name]: prev.filter((value) => value !== option.value),
			})
			updateFields([
				{
					Name: mainSelector.name,
					Value: JSON.stringify(prev.filter((value) => value !== option.value)),
				},
			])
		} else {
			if (mainSelector.max) {
				if (prev?.length < mainSelector.max)
					setCardValues({
						[mainSelector.name]: [...prev, option.value],
					})
				else
					toast.error(t('APP_MAX_VALUES_ERROR'), {
						style: {
							backgroundColor: 'var(--elev400)',
						},
						bodyStyle: {
							color: 'var(--theme-primary)',
						},
						progressStyle: {
							backgroundColor: 'var(--theme-primary)',
						},
					})
			} else
				setCardValues({
					[mainSelector.name]: [...prev, option.value],
				})

			updateFields([
				{
					Name: mainSelector.name,
					Value: JSON.stringify([...prev, option.value]),
				},
			])
		}
	}

	const selected =
		values?.find((v) => v?.Value === option.value) ||
		values?.includes(option?.value as unknown as ValueLevel)

	return (
		<div
			className={cn(s.valueWrapper, 'br', {
				[s.selected]: selected && !noMedia,
				[s.expand]: expand && !noMedia,
			})}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: '10px',
				}}>
				<CheckboxButton
					checked={!!selected}
					onChange={
						levelSelector ? () => onValueChange(option) : () => onLevelChange()
					}
					hint={!selected || expand ? option?.hint : ''}
					label={option.label}
				/>
				{selected && !noMedia ? (
					<div style={{ display: 'flex', alignItems: 'flex-start' }}>
						<ArrowShortIcon
							onClick={() => setExpand((prev) => !prev)}
							style={{
								transform: expand ? 'rotate(-90deg)' : 'rotate(90deg)',
								cursor: 'pointer',
							}}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
			{selected ? (
				<>
					{levelSelector ? (
						<Level
							levelSelector={levelSelector}
							mainSelectorName={mainSelector.name}
							valueOption={option}
							values={values}
						/>
					) : null}
					<SubSelector
						subSelectors={subSelectors}
						mainSelectorName={mainSelector.name}
						valueOption={option}
						values={values}
					/>
					{noMedia ? null : (
						<MediaAssign option={option} mediaItems={mediaItems} />
					)}
				</>
			) : null}
		</div>
	)
}

const Level = ({
	valueOption,
	values,
	mainSelectorName,
	levelSelector,
}: {
	valueOption: SelectOption
	values: ValueLevel[]
	mainSelectorName: string
	levelSelector: AttributeSelector
}): JSX.Element => {
	const value = levelSelector?.options?.find(
		(o) =>
			values?.find((v) => v?.Value === valueOption.value)?.Level === o.value,
	)

	const sidebarType = usePortfolioStore(
		useShallow((state) => state.additionalSidebar?.type),
	)

	return (
		<div
			className={s.additionalSelector}
			onClick={() => {
				const { setAdditionalSidebar, setGalleryType } =
					usePortfolioStore.getState()
				setGalleryType(null)
				setAdditionalSidebar({
					type: 'Level',
					valueOption,
					selectorName: mainSelectorName,
					options: levelSelector.options,
				})
			}}>
			<span style={{ color: 'var(--mono200)' }}>{t('APP_LEVEL_LABEL')}</span>
			<span style={{ marginLeft: 'auto', color: 'var(--mono300)' }}>
				{value?.label ||
					(sidebarType !== 'Level' && t('APP_CHOOSE_LABEL')) ||
					t('APP_PICKING_LABEL')}
			</span>
			{value ? <EditIcon color="var(--mono300)" /> : null}
		</div>
	)
}

const SubSelector = ({
	valueOption,
	values,
	mainSelectorName,
	subSelectors,
}: {
	valueOption: SelectOption
	values: ValueLevel[]
	mainSelectorName: string
	subSelectors: AttributeSelector[] | null
}): JSX.Element => {
	const accentSelectorOptions = subSelectors?.[0]?.options?.filter(
		(sub) => sub.related === valueOption.value,
	)

	const value = subSelectors?.[0]?.options?.find(
		(o) =>
			values?.find((v) => v?.Value === valueOption.value)?.Dialect === o.value,
	)

	const sidebarType = usePortfolioStore(
		useShallow((state) => state.additionalSidebar?.type),
	)

	return accentSelectorOptions?.length ? (
		<div
			className={s.additionalSelector}
			onClick={() => {
				const { setAdditionalSidebar, setGalleryType } =
					usePortfolioStore.getState()
				setGalleryType(null)
				setAdditionalSidebar({
					type: 'Dialect',
					valueOption,
					selectorName: mainSelectorName,
					options: accentSelectorOptions,
				})
			}}>
			<span style={{ color: 'var(--mono200)' }}>{t('APP_ACCENT_LABEL')}</span>
			<span
				className="textEllipsis"
				style={{ marginLeft: 'auto', color: 'var(--mono300)' }}>
				{value?.label ||
					(sidebarType !== 'Dialect' && t('APP_CHOOSE_LABEL')) ||
					t('APP_PICKING_LABEL')}
			</span>
			{value ? <EditIcon color="var(--mono300)" /> : null}
		</div>
	) : (
		<></>
	)
}

const MediaAssign = ({
	option,
	mediaItems,
}: {
	option: SelectOption
	mediaItems?: Content[]
}): JSX.Element => {
	const [type, galleryType, generalType, galleryValues] = usePortfolioStore(
		useShallow((state) => [
			state.modalType,
			state.galleryType,
			state.generalModalType,
			state.galleryValues,
		]),
	)

	const keyName = ('ContentLabel' + capitalize(generalType)) as keyof Content
	const valueName = capitalize(type) + ':' + option?.value

	const selected = mediaItems?.filter(
		(data) =>
			(data &&
				keyName in data &&
				(data[keyName] as string)?.includes(valueName)) ||
			galleryValues[valueName]?.includes(data?.ContentId),
	)

	return (
		<div
			className={s.additionalSelector}
			onClick={() => {
				const { setGalleryType, generalModalType, setAdditionalSidebar } =
					usePortfolioStore.getState()
				setAdditionalSidebar(null)
				// if (!galleryType)
				setGalleryType({
					value: option.value,
					label: option.label,
					defaultTab:
						generalModalType === PORTFOLIO_TYPES.SKILL ||
						[
							skillFields.driving.value,
							attributeSpecialFields.ability.value,
							attributeBasicFields.voice.value,
						].includes(type)
							? PORTFOLIO_TYPES.SHOWREEL
							: undefined,
				})
			}}>
			<span style={{ color: 'var(--mono200)' }}>
				{t('APP_ASSIGN_MEDIA_LABEL')}
			</span>
			{galleryType?.value === option?.value ? (
				<span style={{ color: 'var(--mono300)' }}>
					{t('APP_PICKING_LABEL')}
				</span>
			) : selected?.length ? (
				<div className={s.mediaAssigned}>
					{selected?.map((data) => (
						<div key={data.ContentId}>
							<img src={data?.ContentSquareImageUrl} />
						</div>
					))}
					<EditIcon color="var(--mono300)" />
				</div>
			) : (
				<span style={{ color: 'var(--mono300)' }}>{t('APP_CHOOSE_LABEL')}</span>
			)}
		</div>
	)
}

import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import dayjs from 'dayjs'
import {
	ArrowShortIcon,
	FemaleGenderIcon,
	MaleGenderIcon,
	OtherGenderIcon,
} from 'assets/icons'
import StatusCircle from 'components/StatusCircle'
import useContent from 'hooks/api/useContent'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { optionsParser, t, textParser } from 'helpers'
import { useAppStore } from 'store'
import { DROPDOWN_OPTIONS, RELATIONS, ROLE_STATUS } from 'types/enums'
import s from './index.module.scss'

const Info = (): JSX.Element => {
	const [roleId] = useAppStore(useShallow((state) => [state.roleInfoSidebar]))
	const [extendDesc, setExtendDesc] = useState(false)
	const [extendProdDesc, setExtendProdDesc] = useState(false)
	const [extendProd, setExtendProd] = useState(false)
	const { content } = useContent(roleId)

	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		content?.ContentId,
		'Content',
		'/Lookup',
		true,
	)

	const prod = data?.[RELATIONS.PLAYLIST]?.[0]

	const languageOptions = optionsParser(DROPDOWN_OPTIONS.LANGUAGES_CHECKBOX)
	const genderOptions = optionsParser(DROPDOWN_OPTIONS.GENDERS)
	const prodTypes = optionsParser(DROPDOWN_OPTIONS.PRODUCTION_TYPES)

	const rehearsalDate = prod?.ContentEventRehearsalDate?.includes('[')
		? (JSON.parse(prod?.ContentEventRehearsalDate) as string[])
		: ''
	const filmingDate = prod?.ContentEventFilmingDate?.includes('[')
		? (JSON.parse(prod?.ContentEventFilmingDate) as string[])
		: ''

	const activeStatus = (content?.ContentCastingStatus || '') as ROLE_STATUS

	return (
		<div className={s.wrapper}>
			<div className={s.info}>
				<div
					style={{
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					<h4>{content?.['Globalizationen-USContentTitle']}</h4>

					<StatusCircle
						activeStatus={activeStatus}
						style={{
							right: '0',
							top: '0',
							zIndex: 1,
						}}
					/>
				</div>
				<div className={s.gender}>
					{!content?.ContentRoleGender ? null : content?.ContentRoleGender ===
					  'MALE' ? (
						<MaleGenderIcon />
					) : content?.ContentRoleGender === 'FEMALE' ? (
						<FemaleGenderIcon />
					) : (
						<OtherGenderIcon />
					)}
					<span className="body2-m">
						{
							genderOptions?.find(
								(option) => option.value === content?.ContentRoleGender,
							)?.label
						}
					</span>
				</div>
			</div>
			<span className="body1-b">{t('APP_DESCRIPTION_LABEL')}</span>
			{content?.['Globalizationen-USContentDescription'] ? (
				<div
					className={s.desc}
					style={{
						height: extendDesc ? 'auto' : '34px',
						overflow: 'hidden',
					}}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '5px',
						}}>
						{textParser(content?.['Globalizationen-USContentDescription'])?.map(
							(text) => (
								<span
									key={text}
									className="body2-s"
									style={{
										position: 'relative',
									}}>
									{text}
								</span>
							),
						)}
					</div>
					<div
						style={{
							marginLeft: 'auto',
							display: 'flex',
							cursor: 'pointer',
							height: 'fit-content',
							transform: 'translateY(25%)',
						}}
						onClick={() => setExtendDesc((prev) => !prev)}>
						<ArrowShortIcon
							style={{
								transform: extendDesc
									? 'translateY(-50%) rotate(-90deg)'
									: 'translateY(-50%) rotate(90deg)',
							}}
						/>
					</div>
				</div>
			) : null}
			<div className="grid3column">
				<div className={s.item}>
					<span className="caption-m">{t('APP_ROLE_TYPE_LABEL')}</span>
					<span>{content?.ContentRoleType || 'N/A'}</span>
				</div>
				<div className={s.item}>
					<span className="caption-m">{t('APP_LANGUAGE_LABEL')}</span>
					<span>
						{languageOptions.find(
							(option) => option.value === content?.ContentRoleDialect,
						)?.label || 'N/A'}
					</span>
				</div>
				<div className={s.item}>
					<span className="caption-m">{t('APP_ROLE_SHOOTING_DAYS')}</span>
					<span>
						{content?.ContentShootingDays
							? `
        ${content?.ContentShootingDays} day`
							: 'N/A'}
					</span>
				</div>
			</div>

			{prod ? (
				<div
					className={s.wrapper}
					style={{
						height: extendProd ? 'auto' : '24px',
						overflow: 'hidden',
					}}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							cursor: 'pointer',
						}}
						onClick={() => setExtendProd((prev) => !prev)}>
						<span>{t('APP_PRODUCTION_DETAILS')}</span>
						<div
							style={{
								marginLeft: 'auto',
								display: 'flex',
								height: 'fit-content',
								transform: 'translateY(25%)',
							}}>
							<ArrowShortIcon
								style={{
									transform: extendProd
										? 'translateY(-50%) rotate(-90deg)'
										: 'translateY(-50%) rotate(90deg)',
								}}
							/>
						</div>
					</div>
					<h3>{prod?.['Globalizationen-USContentTitle']}</h3>
					<div
						className={s.desc}
						style={{
							height: extendProdDesc ? 'auto' : '34px',
							overflow: 'hidden',
						}}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '5px',
							}}>
							{textParser(prod?.['Globalizationen-USContentDescription'])?.map(
								(text) => (
									<span
										key={text}
										className="body2-r"
										style={{
											position: 'relative',
										}}>
										{text}
									</span>
								),
							)}
						</div>
						<div
							style={{
								marginLeft: 'auto',
								display: 'flex',
								cursor: 'pointer',
								height: 'fit-content',
								transform: 'translateY(25%)',
							}}
							onClick={() => setExtendProdDesc((prev) => !prev)}>
							<ArrowShortIcon
								style={{
									transform: extendProdDesc
										? 'translateY(-50%) rotate(-90deg)'
										: 'translateY(-50%) rotate(90deg)',
								}}
							/>
						</div>
					</div>
					<div className="grid3column">
						<div className={s.item}>
							<span className="caption-m">
								{t('APP_PRODUCTION_TYPE_LABEL')}
							</span>
							<span>
								{prodTypes.find(
									(option) => option.value === prod?.ContentProductionType,
								)?.label || 'N/A'}
							</span>
						</div>
						<div className={s.item}>
							<span className="caption-m">{t('APP_DIRECTOR_NAME_LABEL')}</span>
							<span>{prod?.ContentDirectorName || 'N/A'}</span>
						</div>
						<div className={s.item}>
							<span className="caption-m">{t('APP_LOCATION_LABEL')}</span>
							<span>{prod?.ContentLabelPlace || 'N/A'}</span>
						</div>
					</div>
					<div className="grid3column">
						<div className={s.item}>
							<span className="caption-m">{t('APP_FILMING_DATE_LABEL')}</span>
							<span>
								{filmingDate?.length
									? (filmingDate?.[0]
											? dayjs(filmingDate?.[0]).format('MM/DD/YYYY')
											: 'N/A') +
									  ' - ' +
									  (filmingDate?.[1]
											? dayjs(filmingDate?.[1]).format('MM/DD/YYYY')
											: 'N/A')
									: 'N/A'}
							</span>
						</div>
						<div className={s.item}>
							<span className="caption-m">{t('APP_REHEARSAL_DATE_LABEL')}</span>
							<span>
								{rehearsalDate?.length
									? (rehearsalDate?.[0]
											? dayjs(rehearsalDate?.[0]).format('MM/DD/YYYY')
											: 'N/A') +
									  ' - ' +
									  (rehearsalDate?.[1]
											? dayjs(rehearsalDate?.[1]).format('MM/DD/YYYY')
											: 'N/A')
									: 'N/A'}
							</span>
						</div>
					</div>
				</div>
			) : null}
		</div>
	)
}

export default Info

import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { ChangeEvent, useState } from 'react'
import Input from 'components/UI/Input'
import CheckboxButton from 'components/UI/CheckboxButton'
import Sidebar from 'components/Sidebar'
import { useAppStore, useProductionStore } from 'store'
import { SearchIcon } from 'assets/icons'
import { t } from 'helpers'

const LocationSidebar = (): JSX.Element => {
	const { productionId } = useParams()
	const [search, setSearch] = useState('')
	const countries = useAppStore(useShallow((state) => state.countries))
	const [openSidebar, setOpenSidebar, locations, onChange, onBlur] =
		useProductionStore(
			useShallow((state) => [
				state.openLocationSidebar,
				state.setOpenLocationSidebar,
				state.production.locations,
				state.onChange,
				state.onBlur,
			]),
		)

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			header={{
				title: 'APP_PRODUCTION_LOCATION_SIDEBAR_TITLE',
				description: 'APP_PRODUCTION_LOCATION_SIDEBAR_DESCRIPTION',
			}}>
			<>
				<Input
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder={t('APP_SEARCH_PLACEHOLDER')}
					variant="default"
					endAdornment={<SearchIcon color="var(--mono300)" />}
				/>
				{countries
					?.filter((country) =>
						country.name?.toLowerCase()?.includes(search?.toLowerCase()),
					)
					?.map((country) => (
						<CheckboxButton
							key={country?.iso2}
							label={country?.name}
							checked={locations?.includes(country?.name)}
							onChange={() => {
								const prevArray = locations
								const currentIndex = prevArray?.findIndex(
									(c) => c === country.name,
								)
								if (currentIndex >= 0) prevArray[currentIndex] = ''
								else prevArray.push(country.name)

								const event = {
									target: {
										name: 'locations',
										value: prevArray?.filter((v) => !!v) as unknown as string,
									},
								} as ChangeEvent<HTMLInputElement>
								onChange(event)
								if (productionId) onBlur(event, productionId)
							}}
						/>
					))}
			</>
		</Sidebar>
	)
}

export default LocationSidebar

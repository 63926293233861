export const PortfolioIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M18.125 5.625C19.082 5.625 19.875 6.41797 19.875 7.375V16.125C19.875 17.1094 19.082 17.875 18.125 17.875H5.875C4.89062 17.875 4.125 17.1094 4.125 16.125V7.375C4.125 6.41797 4.89062 5.625 5.875 5.625H18.125ZM8.9375 8.25C7.95312 8.25 7.1875 9.04297 7.1875 10C7.1875 10.9844 7.95312 11.75 8.9375 11.75C9.89453 11.75 10.6875 10.9844 10.6875 10C10.6875 9.04297 9.89453 8.25 8.9375 8.25ZM11.5625 15.25C11.7812 15.25 12 15.0586 12 14.8125C12 13.6094 11.0156 12.625 9.8125 12.625H8.0625C6.83203 12.625 5.875 13.6094 5.875 14.8125C5.875 15.0586 6.06641 15.25 6.3125 15.25H11.5625ZM17.6875 13.5C17.9062 13.5 18.125 13.3086 18.125 13.0625C18.125 12.8438 17.9062 12.625 17.6875 12.625H14.1875C13.9414 12.625 13.75 12.8438 13.75 13.0625C13.75 13.3086 13.9414 13.5 14.1875 13.5H17.6875ZM17.6875 11.75C17.9062 11.75 18.125 11.5586 18.125 11.3125C18.125 11.0938 17.9062 10.875 17.6875 10.875H14.1875C13.9414 10.875 13.75 11.0938 13.75 11.3125C13.75 11.5586 13.9414 11.75 14.1875 11.75H17.6875ZM17.6875 10C17.9062 10 18.125 9.80859 18.125 9.5625C18.125 9.34375 17.9062 9.125 17.6875 9.125H14.1875C13.9414 9.125 13.75 9.34375 13.75 9.5625C13.75 9.80859 13.9414 10 14.1875 10H17.6875Z"
			fill={color}
		/>
	</svg>
)

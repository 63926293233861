/* eslint-disable @typescript-eslint/no-explicit-any */
class _EventBus {
	bus: any

	constructor() {
		this.bus = {}
	}

	$off(target: string): void {
		delete this.bus[target]
	}

	$on(target: string, callback: () => void): void {
		this.bus[target] = callback
	}

	$emit(target: string, ...params: any): void {
		if (this.bus[target]) this.bus[target](...params)
	}
}

export const EventBus = new _EventBus()

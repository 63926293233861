import { CSSProperties } from 'react'
import { ROLE_STATUS } from 'types/enums'
import s from './index.module.scss'

interface Props {
	activeStatus: ROLE_STATUS
	style?: CSSProperties
}

const StatusCircle = ({ activeStatus, style }: Props): JSX.Element => {
	const statusProps: {
		[key: string]: string
	} = {
		[ROLE_STATUS.CASTING]: 'var(--warning)',
		[ROLE_STATUS.OPENED]: 'var(--success)',
		[ROLE_STATUS.CLOSED]: 'var(--error)',
	}

	return (
		<div className={s.statusWrapper} style={style}>
			<div className={s.status}>
				<div
					className={s.animation}
					style={{
						backgroundColor: `color-mix(in srgb, ${statusProps[activeStatus]} 80%, transparent)`,
					}}
				/>
				<div
					style={{
						backgroundColor: statusProps[activeStatus],
					}}
				/>
			</div>
		</div>
	)
}

export default StatusCircle

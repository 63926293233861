import dayjs from 'dayjs'
import { optionsParser, t, textParser } from 'helpers'
import { Content } from 'types/app'
import { DROPDOWN_OPTIONS } from 'types/enums'

interface Props {
	content: Content | undefined
	production: Content | undefined
}

const Details = ({ content, production }: Props): JSX.Element => {
	const languageOptions = optionsParser(DROPDOWN_OPTIONS.LANGUAGES_CHECKBOX)
	const genderOptions = optionsParser(DROPDOWN_OPTIONS.GENDERS)
	const filmingDate = production?.ContentEventFilmingDate?.includes('[')
		? (JSON.parse(production?.ContentEventFilmingDate) as string[])
		: ''
	const rehearsalDate = production?.ContentEventRehearsalDate?.includes('[')
		? (JSON.parse(production?.ContentEventRehearsalDate) as string[])
		: ''

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
				height: '100%',
			}}>
			<h4 className="h4-m">{content?.['Globalizationen-USContentTitle']}</h4>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '5px',
				}}>
				{content?.ContentRoleType ? (
					<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
						'APP_ROLE_TYPE_LABEL',
					)}: ${content?.ContentRoleType}`}</span>
				) : null}
				{content?.ContentRoleGender ? (
					<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
						'APP_GENDER_LABEL',
					)}: ${
						genderOptions.find(
							(option) => option.value === content?.ContentRoleGender,
						)?.label
					}`}</span>
				) : null}
				{content?.ContentRoleDialect ? (
					<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
						'APP_LANGUAGE_LABEL',
					)}: ${
						languageOptions.find(
							(option) => option.value === content?.ContentRoleDialect,
						)?.label
					}`}</span>
				) : null}
				{content?.ContentShootingDays ? (
					<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
						'APP_ROLE_SHOOTING_DAYS',
					)}: ${content?.ContentShootingDays} day`}</span>
				) : null}
			</div>

			{production ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '20px',
					}}>
					<h4>{t('APP_PRODUCTION_DETAILS')}</h4>

					<h3>{production?.['Globalizationen-USContentTitle']}</h3>

					{production?.['Globalizationen-USContentDescription'] ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '5px',
							}}>
							{textParser(
								production?.['Globalizationen-USContentDescription'],
							)?.map((text) => (
								<span
									key={text}
									className="body2-r"
									style={{
										position: 'relative',
									}}>
									{text}
								</span>
							))}
						</div>
					) : null}
					{production?.ContentDirectorName ? (
						<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
							'APP_DIRECTOR_NAME_LABEL',
						)}: ${production?.ContentDirectorName}`}</span>
					) : null}
					{production?.ContentImdbUrl ? (
						<a
							className="body1-m"
							style={{ color: 'var(--mono200)' }}
							target="_blank"
							href={production?.ContentImdbUrl}>{`${t('APP_IMDBLINK_LABEL')}: ${
							production?.ContentImdbUrl
						}`}</a>
					) : null}
					{filmingDate?.length ? (
						<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
							'APP_FILMING_DATE_LABEL',
						)}: ${
							dayjs(filmingDate?.[0]).format('MM/DD/YYYY') +
							' - ' +
							dayjs(filmingDate?.[1]).format('MM/DD/YYYY')
						}`}</span>
					) : null}
					{rehearsalDate?.length ? (
						<span className="body1-m" style={{ color: 'var(--mono200)' }}>{`${t(
							'APP_REHEARSAL_DATE_LABEL',
						)}: ${
							dayjs(rehearsalDate?.[0]).format('MM/DD/YYYY') +
							' - ' +
							dayjs(rehearsalDate?.[1]).format('MM/DD/YYYY')
						}`}</span>
					) : null}
				</div>
			) : null}
		</div>
	)
}

export default Details

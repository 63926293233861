import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import { useAppStore } from 'store'
import s from './index.module.scss'

const BlurBackground = (): JSX.Element => {
	const showBackground = useAppStore(
		useShallow((state) => state.showBackgroundBlur),
	)

	return <div className={cn(s.container, { [s.show]: showBackground })} />
}

export default BlurBackground

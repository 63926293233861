export const MaleGenderIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M17.8238 6C18.0699 6 18.2886 6.21875 18.2886 6.4375V9.52734C18.2886 9.91016 17.9605 10.1836 17.605 10.1836C17.4409 10.1836 17.2769 10.1289 17.1402 9.99219L16.3472 9.17188L14.7886 10.7305C16.1011 12.5898 15.9097 15.1875 14.2417 16.8555C13.2847 17.7852 12.0542 18.25 10.8238 18.25C9.56595 18.25 8.30813 17.7852 7.3511 16.7734C5.6011 14.9961 5.54641 12.0703 7.21438 10.2109C8.17141 9.14453 9.48391 8.625 10.7964 8.625C11.7534 8.625 12.7105 8.89844 13.5308 9.47266L15.0894 7.91406L14.2691 7.12109C13.8589 6.68359 14.1597 6 14.7339 6H17.8238ZM12.6831 15.2969C13.7222 14.2852 13.7222 12.6172 12.6831 11.6055C12.1636 11.0859 11.5074 10.8398 10.8238 10.8398C10.1402 10.8398 9.48391 11.0859 8.96438 11.6055C7.92532 12.6172 7.92532 14.2852 8.96438 15.2969C9.48391 15.8164 10.1402 16.0625 10.8238 16.0625C11.5074 16.0625 12.1636 15.8164 12.6831 15.2969Z"
			fill={color}
		/>
	</svg>
)

export const FemaleGenderIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M16.625 9.8125C16.625 12.1914 14.9023 14.1328 12.6875 14.543V15.5H14C14.2188 15.5 14.4375 15.6914 14.4375 15.9375V16.8125C14.4375 17.0312 14.2188 17.25 14 17.25H12.6875V18.5625C12.6875 18.7812 12.4688 19 12.25 19H11.375C11.1289 19 10.9375 18.7812 10.9375 18.5625V17.25H9.625C9.37891 17.25 9.1875 17.0312 9.1875 16.8125V15.9375C9.1875 15.6914 9.37891 15.5 9.625 15.5H10.9375V14.543C8.69531 14.1328 7 12.1914 7 9.8125C7 7.16016 9.13281 5 11.8125 5C14.4648 5 16.625 7.16016 16.625 9.8125ZM11.8125 12.4375C13.2344 12.4375 14.4375 11.2617 14.4375 9.8125C14.4375 8.39062 13.2344 7.1875 11.8125 7.1875C10.3633 7.1875 9.1875 8.39062 9.1875 9.8125C9.1875 11.2617 10.3633 12.4375 11.8125 12.4375Z"
			fill={color}
		/>
	</svg>
)

export const OtherGenderIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M11.8125 7C14.4648 7 16.625 9.16016 16.625 11.8125C16.625 14.4922 14.4648 16.625 11.8125 16.625C9.13281 16.625 7 14.4922 7 11.8125C7 9.16016 9.13281 7 11.8125 7ZM11.8125 14.4375C13.2344 14.4375 14.4375 13.2617 14.4375 11.8125C14.4375 10.3906 13.2344 9.1875 11.8125 9.1875C10.3633 9.1875 9.1875 10.3906 9.1875 11.8125C9.1875 13.2617 10.3633 14.4375 11.8125 14.4375Z"
			fill={color}
		/>
	</svg>
)

export const PendingStatusIcon = ({
	mainColor = 'var(--warning)',
	borderColor = 'var(--mono900)',
	width = '32',
	height = '32',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<mask
			id="path-1-outside-1_688_20148"
			maskUnits="userSpaceOnUse"
			x="5"
			y="5"
			width="22"
			height="23"
			fill="black"
		>
			<rect fill="white" x="5" y="5" width="22" height="23" />
			<path d="M16 25.25C11.0078 25.25 7 21.2422 7 16.25C7 11.293 11.0078 7.25 16 7.25C20.957 7.25 25 11.293 25 16.25C25 21.2422 20.957 25.25 16 25.25ZM15.1562 16.25C15.1562 16.5312 15.2969 16.8125 15.5078 16.9531L18.8828 19.2031C19.2695 19.4844 19.7969 19.3789 20.043 18.9922C20.3242 18.6055 20.2188 18.0781 19.832 17.7969L16.8438 15.8281V11.4688C16.8438 11.0117 16.457 10.625 15.9648 10.625C15.5078 10.625 15.1211 11.0117 15.1211 11.4688L15.1562 16.25Z" />
		</mask>
		<path
			d="M16 25.25C11.0078 25.25 7 21.2422 7 16.25C7 11.293 11.0078 7.25 16 7.25C20.957 7.25 25 11.293 25 16.25C25 21.2422 20.957 25.25 16 25.25ZM15.1562 16.25C15.1562 16.5312 15.2969 16.8125 15.5078 16.9531L18.8828 19.2031C19.2695 19.4844 19.7969 19.3789 20.043 18.9922C20.3242 18.6055 20.2188 18.0781 19.832 17.7969L16.8438 15.8281V11.4688C16.8438 11.0117 16.457 10.625 15.9648 10.625C15.5078 10.625 15.1211 11.0117 15.1211 11.4688L15.1562 16.25Z"
			fill={mainColor}
		/>
		<path
			d="M15.1562 16.25H17.1562V16.2426L17.1562 16.2353L15.1562 16.25ZM18.8828 19.2031L20.0592 17.5857L20.0262 17.5617L19.9922 17.539L18.8828 19.2031ZM20.043 18.9922L18.4255 17.8158L18.389 17.8661L18.3556 17.9184L20.043 18.9922ZM19.832 17.7969L21.0084 16.1794L20.971 16.1522L20.9323 16.1268L19.832 17.7969ZM16.8438 15.8281H14.8438V16.9055L15.7434 17.4982L16.8438 15.8281ZM15.1211 11.4688H13.1211V11.4761L13.1211 11.4835L15.1211 11.4688ZM16 23.25C12.1124 23.25 9 20.1376 9 16.25H5C5 22.3468 9.90324 27.25 16 27.25V23.25ZM9 16.25C9 12.3924 12.1175 9.25 16 9.25V5.25C9.89816 5.25 5 10.1935 5 16.25H9ZM16 9.25C19.8525 9.25 23 12.3975 23 16.25H27C27 10.1884 22.0616 5.25 16 5.25V9.25ZM23 16.25C23 20.1325 19.8576 23.25 16 23.25V27.25C22.0565 27.25 27 22.3518 27 16.25H23ZM13.1562 16.25C13.1562 17.1139 13.5631 18.0603 14.3984 18.6172L16.6172 15.289C16.8455 15.4412 16.9732 15.6228 17.0418 15.7599C17.1122 15.9008 17.1562 16.0651 17.1562 16.25H13.1562ZM14.3984 18.6172L17.7734 20.8672L19.9922 17.539L16.6172 15.289L14.3984 18.6172ZM17.7065 20.8206C19.0187 21.775 20.8602 21.4332 21.7303 20.0659L18.3556 17.9184C18.7335 17.3246 19.5203 17.1938 20.0592 17.5857L17.7065 20.8206ZM21.6604 20.1685C22.6462 18.8131 22.2111 17.0541 21.0084 16.1794L18.6557 19.4143C18.2264 19.1022 18.0022 18.3979 18.4255 17.8158L21.6604 20.1685ZM20.9323 16.1268L17.9441 14.158L15.7434 17.4982L18.7317 19.467L20.9323 16.1268ZM18.8438 15.8281V11.4688H14.8438V15.8281H18.8438ZM18.8438 11.4688C18.8438 9.85441 17.5081 8.625 15.9648 8.625V12.625C15.4059 12.625 14.8438 12.169 14.8438 11.4688H18.8438ZM15.9648 8.625C14.4032 8.625 13.1211 9.90715 13.1211 11.4688H17.1211C17.1211 12.1163 16.6124 12.625 15.9648 12.625V8.625ZM13.1211 11.4835L13.1563 16.2647L17.1562 16.2353L17.121 11.454L13.1211 11.4835Z"
			fill={borderColor}
			mask="url(#path-1-outside-1_688_20148)"
		/>
	</svg>
)

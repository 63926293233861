export const ShareIcon = ({
	color = 'var(--mono100)',
	width = '25',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M19.3398 10.957L14.5273 15.0859C14.1172 15.4414 13.4609 15.1406 13.4609 14.5938V12.2148C9.19531 12.2695 7.39062 13.3086 8.62109 17.2734C8.75781 17.7109 8.21094 18.0664 7.85547 17.793C6.65234 16.918 5.58594 15.25 5.58594 13.582C5.58594 9.42578 9.05859 8.52344 13.4609 8.46875V6.30859C13.4609 5.73438 14.1172 5.43359 14.5273 5.78906L19.3398 9.91797C19.6406 10.2188 19.6406 10.6836 19.3398 10.957Z"
			fill={color}
		/>
	</svg>
)

import { useShallow } from 'zustand/react/shallow'
import FileUploadCard from 'components/FileUploadCard'
import { usePortfolioStore } from 'store'
import { PORTFOLIO_TYPES } from 'types/enums'
import { imageAcceptFormats } from 'helpers/constants'

interface Props {
	cardIndex?: number
}

const Media = ({ cardIndex }: Props): JSX.Element => {
	const [generalType, type] = usePortfolioStore(
		useShallow((state) => [state.generalModalType, state.modalType]),
	)

	const uploadFormat = [
		PORTFOLIO_TYPES.PICTURE,
		PORTFOLIO_TYPES.SHOWREEL,
	].includes(generalType)

	return !uploadFormat ? (
		<></>
	) : (
		<FileUploadCard
			fileType={generalType === PORTFOLIO_TYPES.PICTURE ? 'Image' : 'Video'}
			label="APP_PROFILEPICTURE_LABEL"
			form={
				generalType === PORTFOLIO_TYPES.PICTURE ? 'portrait-v' : 'rectangle-h'
			}
			itemTitle=""
			hintText={''}
			// hintText={t(`APP_${generalType?.toUpperCase()}_HINT`)}
			name={cardIndex ? generalType + cardIndex : type}
			formatsText={
				generalType === PORTFOLIO_TYPES.PICTURE
					? 'APP_PHOTO_FORMATS'
					: 'APP_VIDEO_FORMATS'
			}
			qualityText={
				generalType === PORTFOLIO_TYPES.PICTURE
					? 'APP_PICTURES_QUALITY'
					: 'APP_SHOWREELS_QUALITY'
			}
			fileInputProps={{
				accept: generalType === 'picture' ? imageAcceptFormats : 'video/*',
				id: type,
			}}
			uploadImmediately={false}
			deleteItem
		/>
	)
}

export default Media

import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useParams } from 'react-router-dom'
import { FilterIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import Button from 'components/UI/Button'
import RoleItem from './components/RoleItem'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import useContent from 'hooks/api/useContent'
import useCollection from 'hooks/api/useCollection'
import { EventBus } from 'helpers/EventBus'
import { t } from 'helpers'
import { useAppStore, useProductionStore } from 'store'
import useContentAccess from 'hooks/useContentAccess'
import { PROJECT_ACCESS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'

const Roles = (): JSX.Element => {
	const [filter = ''] = useAppStore(
		useShallow((state) => [state.localSearchSidebar?.search]),
	)
	const [productionName] = useProductionStore(
		useShallow((state) => [state.production?.name]),
	)

	const { productionId } = useParams()
	const { content } = useContent(productionId)
	const { collection = [], refresh } = useCollection(content, true)

	const { access, role } = useContentAccess(productionId)

	const full = access === PROJECT_ACCESS.FULLACCESS

	const onAddRoleClick = (): void => {
		const { stepWizard } = useAppStore.getState()
		const { setCurrentRole, setRoles, resetRoles } =
			useProductionStore.getState()
		resetRoles()
		setTimeout(() => {
			const roleName = 'newRole'
			setRoles(roleName, {})
			setCurrentRole(roleName)
			stepWizard?.nextStep()
		}, 200)
	}

	useEffect(() => {
		EventBus.$on('refreshRoles', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshRoles')
		}
	}, [refresh])

	return (
		<Card
			step="production_characters"
			noHint
			title={t('APP_PRODUCTION_CHARACTERS_TITLE')?.replace(
				'{ProductionName}',
				productionName,
			)}
			headerChildrens={
				<Button
					style={{
						gap: '8px',
						color: 'var(--mono100)',
						height: 'fit-content',
					}}
					onClick={() => {
						const { setLocalSearchSidebar } = useAppStore.getState()
						setLocalSearchSidebar({
							open: true,
							search: '',
						})
					}}>
					{t('APP_SHOW_FILTERS')}
					<FilterIcon />
				</Button>
			}>
			<div className="grid3column">
				{full || role === CUSTOMER_ROLE.CASTING_DIRECTOR ? (
					<CreateCardPlaceholder
						label="APP_ADD_ROLE_LABEL"
						onClick={onAddRoleClick}
					/>
				) : null}
				{collection
					?.filter((content) =>
						content['Globalizationen-USContentTitle']
							?.toLowerCase()
							?.includes(filter?.toLowerCase()),
					)
					?.map((content) => (
						<RoleItem content={content} key={content.ContentId} />
					))}
			</div>
		</Card>
	)
}

export default Roles

import s from './index.module.scss'

const LevelGraph = ({
	current,
	length,
}: {
	current: number
	length: number
}): JSX.Element => (
	<div className={s.levelWrapper}>
		<div className={s.track} />
		<div
			className={s.line}
			style={{
				left: `calc(${((length - current) * 100) / length}% - 4px)`,
			}}
		/>
	</div>
)

export default LevelGraph

import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { PlusIcon, SearchIcon } from 'assets/icons'
import Loader from 'components/Loader'
import RoleCard from 'components/RoleCard'
import Sidebar from 'components/Sidebar'
import Input from 'components/UI/Input'
import Select from 'components/UI/Select'
import useCollection from 'hooks/api/useCollection'
import useContentSearch from 'hooks/api/useContentSearch'
import useAccessRelation from 'hooks/api/useAccessRelation'
import { prepareMessageData, t } from 'helpers'
import { useNetworkStore, useUserStore } from 'store'
import { Content, SelectOption } from 'types/app'
import { MESSAGE_TYPE } from 'types/enums'

const InviteToPoolSidebar = (): JSX.Element => {
	const [roleSearch, setRoleSearch] = useState('')
	const [prodSearch, setProdSearch] = useState('')
	const [openSidebar, setOpenSidebar] = useNetworkStore(
		useShallow((state) => [
			state.openInviteUserSidebar,
			state.setOpenInviteUserSidebar,
		]),
	)
	const { contents, isLoading } = useContentSearch(
		openSidebar,
		openSidebar ? 'Collection' : undefined,
	)

	const productions: SelectOption[] = contents
		? contents?.map((content) => ({
				value: content.ContentId,
				label: content['Globalizationen-USContentTitle'],
		  }))
		: []

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			header={{
				title: 'APP_NETWORK_INVITE_TO_POOL_SIDEBAR_TITLE',
				description: 'APP_NETWORK_INVITE_TO_POOL_SIDEBAR_DESCRIPTION',
			}}>
			<>
				<Loader loading={isLoading} adapt />
				<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
					<Input
						variant="default"
						name="filterSearch"
						placeholder={t('APP_SEARCH_ROLE_FILTER_PLACEHOLDER')}
						value={roleSearch}
						onChange={(e) => {
							setRoleSearch(e.target.value)
						}}
						endAdornment={<SearchIcon color="var(--mono300)" />}
					/>
					<Select
						name="productions"
						options={productions}
						error=""
						label="APP_PRODUCTION_SELECT_LABEL"
						onChange={(e) => {
							setProdSearch(e.target.value)
						}}
						inputProps={{
							value: prodSearch,
						}}
					/>
					{contents
						?.filter((content) =>
							prodSearch ? content?.ContentId === prodSearch : true,
						)
						?.map((content) => (
							<RoleList
								key={content.ContentId}
								production={content}
								roleSearch={roleSearch}
							/>
						))}
				</div>
			</>
		</Sidebar>
	)
}

export default InviteToPoolSidebar

const RoleList = ({
	production,
	roleSearch,
}: {
	production: Content
	roleSearch: string
}): JSX.Element => {
	const { collection } = useCollection(production)
	const { profileId } = useParams()
	const navigate = useNavigate()

	const openMessage = async (content: Content): Promise<void> => {
		const { user } = useUserStore.getState()

		const data = {
			userIds: [profileId || ''],
			sourceId: content?.ContentId,
			key: 'ROLEINVITE',
			messageValue: t('APP_ROLEINVITE_MESSAGE_BODY')
				?.replace(
					'[RoleName]',
					content?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace(
					'[ProductionTitle]',
					production?.['Globalizationen-USContentTitle'],
				)
				?.replace(
					'[AuditionTitle]',
					content?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace(
					'[AuditionDate]',
					dayjs(content?.ContentEventDeadline || '').format('MM/DD/YYYY'),
				)
				?.replace(
					'[AuditionNDA]',
					production?.ContentLegalNDARequired === 'true' ? 'Yes' : 'No',
				)
				?.replace('[YourName]', user?.ContentTitle || ''),
			redirect: window.location.pathname + window.location.hash,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: MESSAGE_TYPE.CASTING,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})

		const { setOpenInviteUserSidebar } = useNetworkStore.getState()
		setOpenInviteUserSidebar(false)
	}

	const { data } = useAccessRelation()

	const alreadyInvitedRoles = data
		?.filter((rel) => rel.targetId === profileId)
		?.map((rel) => rel.sourceId)

	return (
		<>
			{collection
				?.filter((content) =>
					content?.['Globalizationen-USContentTitle']
						?.toLowerCase()
						?.includes(roleSearch?.toLowerCase()),
				)
				?.map((content) => (
					<RoleCard
						key={content?.ContentId}
						content={content}
						type="horizontal"
						showProd
						children={
							<>
								{alreadyInvitedRoles?.includes(content.ContentId) ? (
									<div
										className="hoverButtonWrapper"
										style={{ cursor: 'default' }}>
										<PlusIcon style={{ transform: 'rotate(45deg)' }} />
										<span>{t('APP_ALREADY_INVITED_TO_ROLE')}</span>
									</div>
								) : (
									<div
										className="hoverButtonWrapper"
										onClick={() => openMessage(content)}>
										<PlusIcon />
										<span>{t('APP_INVITE_TO_ROLE')}</span>
									</div>
								)}
							</>
						}
					/>
				))}
		</>
	)
}

import cn from 'classnames'
import s from './index.module.scss'

interface Props {
	children: JSX.Element
	classname?: string
}

const TabsWrapper = ({ children, classname }: Props): JSX.Element => (
	<div className={cn(s.container, classname)}>
		<div className={cn(s.wrapper, 'hideScrollBar')}>{children}</div>
	</div>
)

export default TabsWrapper

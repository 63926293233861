export const HDIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M14.625 10.4375C15.3359 10.4375 15.9375 11.0391 15.9375 11.75C15.9375 12.4883 15.3359 13.0625 14.625 13.0625H13.75V10.4375H14.625ZM18.125 5.625C19.082 5.625 19.875 6.41797 19.875 7.375V16.125C19.875 17.1094 19.082 17.875 18.125 17.875H5.875C4.89062 17.875 4.125 17.1094 4.125 16.125V7.375C4.125 6.41797 4.89062 5.625 5.875 5.625H18.125ZM11.5625 13.7188V9.78125C11.5625 9.42578 11.2617 9.125 10.9062 9.125C10.5234 9.125 10.25 9.42578 10.25 9.78125V11.0938H8.5V9.78125C8.5 9.42578 8.19922 9.125 7.84375 9.125C7.46094 9.125 7.1875 9.42578 7.1875 9.78125V13.7188C7.1875 14.1016 7.46094 14.375 7.84375 14.375C8.19922 14.375 8.5 14.1016 8.5 13.7188V12.4062H10.25V13.7188C10.25 14.1016 10.5234 14.375 10.9062 14.375C11.2617 14.375 11.5625 14.1016 11.5625 13.7188ZM14.625 14.375C16.0469 14.375 17.25 13.1992 17.25 11.75C17.25 10.3281 16.0469 9.125 14.625 9.125H13.0938C12.7109 9.125 12.4375 9.42578 12.4375 9.78125V13.7188C12.4375 14.1016 12.7109 14.375 13.0938 14.375H14.625Z"
			fill={color}
		/>
	</svg>
)

import { useEffect, useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import AttributeCard from 'pages/Portfolio/components/AttributeCard'
import useUser from 'hooks/api/useUser'
import { usePortfolioStore, useUserStore } from 'store'
import { skillFields } from '../../fields'
import { PORTFOLIO_TYPES } from 'types/enums'
import { User } from 'types/user'

const Skills = (): JSX.Element => {
	const userId = useUserStore(useShallow((state) => state.userId))
	const { user } = useUser(userId)
	const dataLoadRef = useRef(false)

	useEffect(() => {
		if (user && !dataLoadRef.current) {
			dataLoadRef.current = true
			const { setCardValues } = usePortfolioStore.getState()
			Object.values(skillFields).forEach((field) =>
				field.selectors.forEach((selector) => {
					if (selector?.subSelectors) {
						selector?.subSelectors?.forEach((sel) => {
							let value =
								user[sel.name?.replaceAll(':', '') as keyof User]?.toString() ||
								''

							if (value && (sel.type === 'checkbox' || field?.type))
								value = JSON.parse(value)
							setCardValues(
								{
									[sel.name]: value,
								},
								field.value,
							)
						})
					}

					let value =
						(user[
							selector.name?.replaceAll(':', '') as keyof User
						] as string) || ''

					if (value && (selector.type === 'checkbox' || field?.type))
						value = JSON.parse(value)

					setCardValues(
						{
							[selector.name]: value,
						},
						field.value,
					)
				}),
			)
		}
	}, [user])

	return (
		<Card step="skills" noDesc>
			<div className="grid2column">
				{Object.values(skillFields)?.map((field) => (
					<AttributeCard
						generalType={PORTFOLIO_TYPES.SKILL}
						key={field.value}
						type={field.value}
						cardData={field}
					/>
				))}
			</div>
		</Card>
	)
}

export default Skills

import dayjs from 'dayjs'
import classNames from 'classnames'
import { ImdbIcon } from 'assets/icons'
import CountryFlag from 'components/CountryFlag'
import { optionsParser, t } from 'helpers'
import { useAppStore } from 'store'
import { User } from 'types/user'
import { basicFields } from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	user: User | undefined
	agency: User | undefined
}

const Info = ({ user, agency }: Props): JSX.Element => {
	const phoneNumber = agency ? '' : user?.CustomUserPhoneNumber
	const { countries } = useAppStore.getState()
	const country = countries?.find(
		(c) => c.name === user?.CustomerLocationCountry,
	)

	const phone = (
		phoneNumber?.toString()?.startsWith('+')
			? phoneNumber?.toString()?.slice(1)
			: phoneNumber?.toString()
	)
		?.replaceAll(' ', '')
		?.replace('(', '')
		?.replace(')', '')

	const profession = optionsParser(
		t(basicFields?.profession?.optionName || ''),
	)?.find((v) => v.value === user?.CustomerProfession)?.label

	const infoValues = [
		profession,
		(user?.CustomUserBirthDate &&
			dayjs().diff(dayjs(user?.CustomUserBirthDate), 'year')) ||
			user?.CustomerAge,
	]
		?.filter((v) => !!v)
		?.join(', ')

	const imdb = user?.CustomerImdbUrl && user?.CustomerImdbUrl?.includes('imdb')

	return (
		<div className={s.infoWrapper}>
			<div className={s.name}>
				<h3 className="h3-b">{user?.ContentTitle}</h3>
			</div>

			<div className={s.info}>
				<span className="body2-m" style={{ color: 'var(--mono300)' }}>
					{infoValues}
				</span>
			</div>
			<div className={s.info}>
				<CountryFlag code={country?.iso2} />
				<span className="body2-m" style={{ color: 'var(--mono300)' }}>
					{[user?.CustomerLocationCountry, user?.CustomerLocationCity]
						?.filter((v) => !!v)
						?.join(', ')}
				</span>
			</div>

			{phone ? (
				<a
					href={`tel:+${phone}`}
					className={classNames('body2-m', 'textEllipsis', s.contacts)}
					style={{ opacity: phone ? '1' : '0' }}>
					{'+' +
						phone?.slice(0, 2) +
						' ' +
						phone?.slice(2, 5) +
						' ' +
						phone?.slice(5, 7) +
						' ' +
						phone?.slice(7, 9) +
						' ' +
						phone?.slice(9)}
				</a>
			) : null}

			{!agency && user?.CustomUserEmail ? (
				<a
					href={`mailto:${user?.CustomUserEmail}`}
					className={classNames('body2-m', 'textEllipsis', s.contacts)}>
					{user?.CustomUserEmail}
				</a>
			) : null}
			{imdb ? (
				<a
					href={user?.CustomerImdbUrl}
					target="_blank"
					style={{ display: 'flex' }}>
					<ImdbIcon />
				</a>
			) : null}
		</div>
	)
}

export default Info

export const AthleticsIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '25',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.8672 13.5625C16.4922 13.5625 18.625 15.6953 18.625 18.3203C18.625 18.8398 18.1875 19.25 17.668 19.25H7.30469C6.78516 19.25 6.375 18.8398 6.375 18.3203C6.375 15.6953 8.48047 13.5625 11.1055 13.5625H13.8672ZM8.78125 10.5C8.04297 10.5 7.46875 9.92578 7.46875 9.1875V8.3125C7.46875 7.60156 8.04297 7 8.78125 7C9 7 9.19141 7.08203 9.38281 7.16406C9.95703 6.04297 11.1328 5.25 12.5 5.25C13.8398 5.25 15.0156 6.04297 15.5898 7.16406C15.7812 7.08203 15.9727 7 16.2188 7C16.9297 7 17.5312 7.60156 17.5312 8.3125V9.1875C17.5312 9.92578 16.9297 10.5 16.2188 10.5C16.0547 10.5 15.8906 10.4727 15.7539 10.418C15.2617 11.7578 13.9766 12.6875 12.5 12.6875C10.9961 12.6875 9.71094 11.7578 9.21875 10.418C9.08203 10.4727 8.91797 10.5 8.78125 10.5ZM10.3125 8.75V9.1875C10.3125 10.418 11.2695 11.375 12.5 11.375C13.7031 11.375 14.6875 10.418 14.6875 9.1875V8.75C14.6875 8.55859 14.6328 8.39453 14.6055 8.20312C14.4141 8.28516 14.2227 8.3125 14.0312 8.3125C13.3477 8.3125 12.7734 7.95703 12.5 7.41016C12.1992 7.95703 11.625 8.3125 10.9688 8.3125C10.75 8.3125 10.5586 8.28516 10.3672 8.20312C10.3398 8.39453 10.3125 8.55859 10.3125 8.75Z"
			fill={color}
		/>
	</svg>
)

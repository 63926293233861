import useSwrImmutable from 'swr/immutable'
import { KeyedMutator } from 'swr'
import { fetcher, multipleFetcher } from 'api'
import contentParser, { getQueryOrder } from 'helpers/propertiesParser'
import { useAppStore } from 'store'
import {
	CollectionsType,
	ContentCollection,
	Content,
	FetchReturn,
} from 'types/app'

type ReturnData = FetchReturn & {
	contents: Content[] | undefined
	refresh: KeyedMutator<ContentCollection>
}

export default (
	fetch: boolean,
	contentType: 'Collection' | 'Playlist' | undefined,
	searchText = '',
): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const contentUrl = endpoints?.CustomApplicationContentUrl

	const order = window.btoa(
		JSON.stringify(getQueryOrder('Customer:RegisteredDate')),
	)

	const { data, error, isLoading, mutate } = useSwrImmutable<ContentCollection>(
		contentUrl && fetch
			? contentUrl +
					'/Query' +
					`?o=${order}&f=ewogIOKAnE9yZGVyUnVsZXPigJ06IFsKICAgIHsKICAgICAgIkluZGV4IjogMSwKICAgICAgIlByb3BlcnR5TmFtZSI6ICJDb250ZW50OkNyZWF0ZWREYXRlIiwKICAgICAgIkRpcmVjdGlvbiI6ICJEZXNjIiwKICAgICAgIkZvcm1hdHRpbmciOiAiRGF0ZVRpbWUiCiAgICB9CiAgXQp9&type=${contentType}&q=${searchText}`
			: null,
		fetcher,
	)

	const contentLinks = data?.ChildItems?.map((child) =>
		child?.Actions?.length ? child.Actions[0]?.Url : null,
	)?.filter((v) => !!v)

	const { data: contents, isLoading: contentLoading } = useSwrImmutable<
		CollectionsType[]
	>(contentLinks?.length ? contentLinks : null, multipleFetcher)

	const parsed = contents?.map((content) => contentParser<Content>(content))

	return {
		isLoading: isLoading || contentLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		contents: parsed,
		refresh: mutate,
	}
}

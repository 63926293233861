export const SettingsIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M18.5352 9.31641C18.6445 9.5625 18.5625 9.80859 18.3711 10L17.1953 11.0664C17.2227 11.2852 17.25 11.5312 17.25 11.75C17.25 11.9961 17.2227 12.2422 17.1953 12.4609L18.3711 13.5273C18.5625 13.7188 18.6445 13.9648 18.5352 14.2109C18.4258 14.5391 18.2891 14.8398 18.125 15.1406L17.9883 15.3594C17.7969 15.6602 17.6055 15.9609 17.3867 16.2344C17.2227 16.4258 16.9492 16.4805 16.7031 16.3984L15.1992 15.9336C14.8164 16.207 14.4062 16.4258 13.9961 16.6172L13.6406 18.1758C13.5859 18.4219 13.3945 18.6133 13.1484 18.668C12.7656 18.7227 12.3828 18.75 11.9727 18.75C11.5898 18.75 11.207 18.7227 10.8242 18.668C10.5781 18.6133 10.3867 18.4219 10.332 18.1758L9.97656 16.6172C9.56641 16.4258 9.15625 16.207 8.77344 15.9336L7.26953 16.3984C7.02344 16.4805 6.75 16.4258 6.58594 16.2344C6.36719 15.9609 6.17578 15.6602 5.98438 15.3594L5.84766 15.1406C5.68359 14.8398 5.54688 14.5391 5.4375 14.2109C5.32812 13.9648 5.41016 13.7188 5.60156 13.5273L6.77734 12.4609C6.75 12.2422 6.75 11.9961 6.75 11.75C6.75 11.5312 6.75 11.2852 6.77734 11.0664L5.60156 10C5.41016 9.80859 5.32812 9.5625 5.4375 9.31641C5.54688 8.98828 5.68359 8.6875 5.84766 8.38672L5.98438 8.16797C6.17578 7.86719 6.36719 7.56641 6.58594 7.29297C6.75 7.10156 7.02344 7.04688 7.26953 7.12891L8.77344 7.59375C9.15625 7.32031 9.56641 7.07422 9.97656 6.91016L10.332 5.35156C10.3867 5.10547 10.5781 4.91406 10.8242 4.85938C11.207 4.80469 11.5898 4.75 12 4.75C12.3828 4.75 12.7656 4.80469 13.1484 4.85938C13.3945 4.91406 13.5859 5.10547 13.6406 5.35156L13.9961 6.91016C14.4062 7.07422 14.8164 7.32031 15.1992 7.59375L16.7031 7.12891C16.9492 7.04688 17.2227 7.10156 17.3867 7.29297C17.6055 7.56641 17.7969 7.86719 17.9883 8.16797L18.125 8.38672C18.2891 8.6875 18.4258 8.98828 18.5352 9.31641ZM12 13.9375C13.2031 13.9375 14.1875 12.9805 14.1875 11.75C14.1875 10.5469 13.2031 9.5625 12 9.5625C10.7695 9.5625 9.8125 10.5469 9.8125 11.75C9.8125 12.9805 10.7695 13.9375 12 13.9375Z"
			fill={color}
		/>
	</svg>
)

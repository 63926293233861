import classNames from 'classnames'
import { Navigation } from 'swiper/modules'
import { Swiper } from 'swiper/react'
import { ArrowShortIcon } from 'assets/icons'
import 'swiper/css'
import s from './index.module.scss'

interface Props {
	children: JSX.Element
	name: string
	perView?: number
}

const RecentCarousel = ({ children, name, perView }: Props): JSX.Element => {
	return (
		<div style={{ position: 'relative' }}>
			<Swiper
				slidesPerView={perView || 'auto'}
				spaceBetween={20}
				navigation={{
					nextEl: `#${name}_recent-next`,
					prevEl: `#${name}_recent-prev`,
				}}
				modules={[Navigation]}
				wrapperClass={s.slideWrapper}>
				{children}
			</Swiper>
			<button
				id={name + '_recent-prev'}
				className={classNames(s.arrowLeft, s.arrow)}>
				<ArrowShortIcon style={{ transform: 'rotate(180deg) ' }} />
			</button>
			<button
				id={name + '_recent-next'}
				className={classNames(s.arrowRight, s.arrow)}>
				<ArrowShortIcon />
			</button>
		</div>
	)
}

export default RecentCarousel

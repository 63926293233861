import {
	AthleticsIcon,
	BodyIcon,
	EyesIcon,
	HairIcon,
	MagicWandIcon,
	ProfileIcon,
	SoundIcon,
} from 'assets/icons'
import { AttributeCardType, AttributeSelector } from 'types/app'
import { DROPDOWN_OPTIONS } from 'types/enums'

export const basicFields: {
	[key in 'name' | 'profession' | 'location' | 'city']: AttributeSelector
} = {
	name: {
		name: 'Content:Title',
		label: 'APP_NAME_LABEL',
		type: 'input',
		options: [],
	},
	profession: {
		name: 'Customer:Profession',
		label: 'APP_PROFESSION_LABEL',
		optionName: DROPDOWN_OPTIONS.PROFESSION,
		type: 'select',
		options: [],
	},
	location: {
		name: 'Customer:Location:Country',
		label: 'APP_LOCATION_LABEL',
		type: 'country',
		options: [],
	},
	city: {
		name: 'Customer:Location:City',
		label: 'APP_CITY_LABEL',
		type: 'input',
		options: [],
	},
	// openPortfolio: {
	// 	label: 'APP_OPEN_PORTFOLIO_LABEL',
	// 	name: 'Customer:Network:Open',
	// 	optionName: DROPDOWN_OPTIONS.ALLOW_FREE_WORK_PORTFOLIO,
	// 	type: 'switch',
	// 	options: [],
	// },
}

export const contactFields: {
	[key in
		| 'agency'
		| 'email'
		| 'phone'
		| 'website'
		| 'travel'
		| 'imdb'
		| 'distance']: AttributeSelector
} = {
	agency: {
		name: 'Customer:Agency:Id',
		label: 'APP_AGENCY_LABEL',
		type: 'select',
		options: [],
	},
	phone: {
		name: 'Custom:User:PhoneNumber',
		label: 'APP_MOBILEPHONE_LABEL',
		type: 'phone',
		options: [],
	},
	email: {
		name: 'Custom:User:Email',
		label: 'APP_EMAIL_LABEL',
		type: 'input',
		options: [],
	},
	website: {
		name: 'Customer:WebsiteUrl',
		label: 'APP_WEBSITE_LABEL',
		type: 'input',
		options: [],
	},
	imdb: {
		name: 'Customer:ImdbUrl',
		label: 'APP_IMDBLINK_LABEL',
		type: 'input',
		options: [],
	},
	travel: {
		name: 'Customer:Travel:Willingness',
		label: 'APP_TRAVEL_WILLINGNESS_LABEL',
		type: 'checkbox',
		options: [],
		optionName: DROPDOWN_OPTIONS.TRAVEL_WILLINGNESS,
	},
	distance: {
		name: 'Customer:Travel:Distance',
		label: 'APP_DISTANCE_LABEL',
		type: 'range',
		min: 0,
		max: 1000,
		options: [],
	},
}

export const personalFields: {
	[key in
		| 'gender'
		| 'sex'
		| 'race'
		| 'disability'
		| 'nation']: AttributeSelector
} = {
	gender: {
		name: 'Custom:User:Gender',
		label: 'APP_GENDER_LABEL',
		optionName: DROPDOWN_OPTIONS.GENDERS,
		type: 'select',
		options: [],
	},
	sex: {
		name: 'Custom:User:Sexuality',
		label: 'APP_SEXUALITY_LABEL',
		optionName: DROPDOWN_OPTIONS.SEX,
		type: 'select',
		options: [],
	},
	race: {
		name: 'Custom:User:Race',
		label: 'APP_RACE_LABEL',
		optionName: DROPDOWN_OPTIONS.RACE,
		type: 'select',
		options: [],
	},
	disability: {
		name: 'Custom:User:Disability',
		label: 'APP_DISABILITY_LABEL',
		optionName: DROPDOWN_OPTIONS.DISABILITY,
		type: 'select',
		options: [],
	},
	nation: {
		name: 'Customer:Nationality',
		label: 'APP_NATIONALITY_LABEL',
		optionName: DROPDOWN_OPTIONS.NATION,
		type: 'select',
		options: [],
	},
}
export const biographyFields: { [key in 'biography']: AttributeSelector } = {
	biography: {
		name: 'Custom:User:Biography',
		label: 'APP_BIOGRAPHY_LABEL',
		type: 'input',
		options: [],
	},
}

export const attributeBasicFields: {
	[key in 'hair' | 'eyes' | 'voice' | 'body']: AttributeCardType
} = {
	hair: {
		value: 'hair',
		icon: <HairIcon />,
		selectors: [
			{
				label: 'APP_HAIRTYPE_LABEL',
				name: 'Customer:Attribute:Hair:Type',
				optionName: DROPDOWN_OPTIONS.HAIR_TYPE,
				type: 'select',
				options: [],
			},
			{
				label: 'APP_HAIRCOLOR_LABEL',
				name: 'Customer:Attribute:Hair:Color',
				optionName: DROPDOWN_OPTIONS.HAIR_COLOR,
				type: 'colors',
				options: [],
			},
		],
	},
	eyes: {
		value: 'eyes',
		icon: <EyesIcon />,
		selectors: [
			{
				label: 'APP_EYESCOLOR_LABEL',
				name: 'Customer:Attribute:Eye:Color',
				optionName: DROPDOWN_OPTIONS.EYE_COLOR,
				type: 'colors',
				options: [],
			},
		],
	},
	voice: {
		value: 'voice',
		icon: <SoundIcon />,
		selectors: [
			{
				label: 'APP_VOCALNO_LABEL',
				name: 'Customer:Attribute:Vocal:Ability',
				optionName: DROPDOWN_OPTIONS.VOCAL_NO,
				type: 'checkbox',
				options: [],
			},
			{
				label: 'APP_VOCAL_LABEL',
				name: 'Customer:Attribute:Vocal',
				optionName: DROPDOWN_OPTIONS.VOCAL,
				type: 'select',
				options: [],
			},
			// {
			// 	label: 'APP_VOCALTECHNIQUE_LABEL',
			// 	name: 'Customer:Attribute:Vocal:Technique',
			// 	optionName: DROPDOWN_OPTIONS.VOCAL_TECHNIQUE,
			// 	type: 'select',
			// 	options: [],
			// },
		],
	},
	body: {
		value: 'body',
		icon: <BodyIcon />,
		selectors: [
			{
				label: 'APP_HEIGHT_LABEL',
				name: 'Customer:Attribute:Body:Height',
				optionName: '',
				type: 'range',
				options: [],
			},
			{
				label: 'APP_WEIGHT_LABEL',
				name: 'Customer:Attribute:Body:Weight',
				optionName: '',
				type: 'range',
				options: [],
			},
			{
				label: 'APP_SKINCOLOR_LABEL',
				name: 'Customer:Attribute:Skin:Color',
				optionName: DROPDOWN_OPTIONS.SKIN_COLOR,
				type: 'colors',
				options: [],
			},
			{
				label: 'APP_TATTOO_LABEL',
				name: 'Customer:Attribute:Tattoo',
				optionName: DROPDOWN_OPTIONS.TATTOO,
				type: 'checkbox',
				options: [],
			},
			{
				label: 'APP_BODYTYPE_LABEL',
				name: 'Customer:Attribute:Body:Type',
				optionName: DROPDOWN_OPTIONS.BODY_TYPE,
				type: 'select',
				options: [],
			},
		],
	},
}

export const attributeSpecialFields: {
	[key in
		| 'ability'
		| 'specialFeatures'
		| 'bodyDone'
		| 'bodyWilling'
		| 'voiceSpecials']: AttributeCardType
} = {
	bodyDone: {
		value: 'bodyDone',
		icon: <BodyIcon />,
		selectors: [
			{
				label: 'APP_BODYMODIFICATION_LABEL',
				name: 'Customer:Attribute:Body:Modification',
				optionName: DROPDOWN_OPTIONS.BODY_MODIFY,
				type: 'checkbox',
				options: [],
			},
		],
	},
	bodyWilling: {
		value: 'bodyWilling',
		icon: <BodyIcon />,
		selectors: [
			{
				label: 'APP_BODYMODIFICATIONWILLING_LABEL',
				name: 'Customer:Attribute:Body:Modification:Willing',
				optionName: DROPDOWN_OPTIONS.BODY_MODIFY,
				type: 'checkbox',
				options: [],
			},
		],
	},
	voiceSpecials: {
		value: 'voiceSpecials',
		icon: <SoundIcon />,
		selectors: [
			{
				label: 'APP_VOCALSPECIALS_LABEL',
				name: 'Customer:Attribute:Vocal:Specials',
				optionName: DROPDOWN_OPTIONS.VOCAL_SPEC,
				type: 'checkbox',
				options: [],
			},
		],
	},
	ability: {
		value: 'ability',
		icon: <ProfileIcon />,
		selectors: [
			{
				label: 'APP_ABILITIES_LABEL',
				name: 'Customer:Attribute:Abilities',
				optionName: DROPDOWN_OPTIONS.PERSONAL_ABILITIES,
				type: 'checkbox',
				max: 10,
				sort: true,
				options: [],
			},
		],
	},
	specialFeatures: {
		value: 'specialFeatures',
		icon: <MagicWandIcon />,
		selectors: [
			{
				label: 'APP_SPECIALFEATURES_LABEL',
				name: 'Customer:Attribute:Specials',
				optionName: DROPDOWN_OPTIONS.SPECIAL_ATTRIBUTES,
				type: 'checkbox',
				options: [],
			},
		],
	},
}

export const skillFields: {
	[key in
		| 'athletics'
		| 'specialSports'
		| 'spokenLanguage'
		| 'actingLanguage'
		| 'singing'
		| 'dancing'
		| 'instruments'
		| 'driving'
		| 'otherSkill'
		| 'specialSkill']: AttributeCardType
} = {
	athletics: {
		value: 'athletics',
		icon: <AthleticsIcon />,
		selectors: [
			{
				label: 'APP_SPORTS_LABEL',
				name: 'Customer:Skill:Sports',
				optionName: DROPDOWN_OPTIONS.SPORTS,
				type: 'checkbox',
				options: [],
			},
			{
				label: 'APP_LEVEL_LABEL',
				name: 'Customer:Skill:Language',
				optionName: DROPDOWN_OPTIONS.SKILL_LEVELS,
				type: 'select',
				options: [],
			},
		],
		type: 'check-level',
	},
	specialSports: {
		value: 'specialSports',
		icon: <AthleticsIcon />,
		selectors: [
			{
				label: 'APP_SPECIALSPORT_LABEL',
				name: 'Customer:Skill:SpecialSports',
				optionName: DROPDOWN_OPTIONS.SPECIAL_SPORT,
				type: 'checkbox',
				options: [],
			},
			{
				label: 'APP_LEVEL_LABEL',
				name: 'Customer:Skill:Language',
				optionName: DROPDOWN_OPTIONS.SKILL_LEVELS,
				type: 'select',
				options: [],
			},
		],
		type: 'check-level',
	},
	spokenLanguage: {
		value: 'spokenLanguages',
		icon: <AthleticsIcon />,
		selectors: [
			{
				label: 'APP_SPOKENLANGUAGE_LABEL',
				name: 'Customer:Skill:Language:Speak',
				optionName: DROPDOWN_OPTIONS.LANGUAGES_CHECKBOX,
				type: 'checkbox',
				options: [],
			},
			{
				label: 'APP_LEVEL_LABEL',
				name: 'Customer:Skill:Language:Speak',
				optionName: DROPDOWN_OPTIONS.LANGUAGE_LEVELS,
				type: 'select',
				options: [],
			},
		],
		type: 'check-level',
	},
	actingLanguage: {
		value: 'actingLanguages',
		icon: <AthleticsIcon />,
		selectors: [
			{
				label: 'APP_ACTINGLANGUAGE_LABEL',
				name: 'Customer:Skill:Language:Acting',
				optionName: DROPDOWN_OPTIONS.LANGUAGES_CHECKBOX,
				type: 'checkbox',
				options: [],
				subSelectors: [
					{
						label: 'APP_DIALECTTYPE_LABEL',
						name: 'Customer:Skill:Language:Dialect',
						optionName: DROPDOWN_OPTIONS.DIALECTS,
						type: 'select',
						options: [],
					},
				],
			},
			{
				label: 'APP_LEVEL_LABEL',
				name: 'Customer:Skill:Language:Acting',
				optionName: DROPDOWN_OPTIONS.LANGUAGE_LEVELS,
				type: 'select',
				options: [],
			},
		],
		type: 'check-level',
	},
	singing: {
		value: 'singing',
		icon: <AthleticsIcon />,
		selectors: [
			{
				label: 'APP_SINGING_LABEL',
				name: 'Customer:Skill:Singing',
				optionName: DROPDOWN_OPTIONS.MUSIC_GENRE,
				type: 'checkbox',
				options: [],
			},
			{
				label: 'APP_LEVEL_LABEL',
				name: 'Customer:Skill:Singing',
				optionName: DROPDOWN_OPTIONS.SKILL_LEVELS,
				type: 'select',
				options: [],
			},
		],
		type: 'check-level',
	},
	dancing: {
		value: 'dancing',
		icon: <AthleticsIcon />,
		selectors: [
			{
				label: 'APP_DANCING_LABEL',
				name: 'Customer:Skill:Dancing',
				optionName: DROPDOWN_OPTIONS.DANCE_STYLES,
				type: 'checkbox',
				options: [],
			},
			{
				label: 'APP_LEVEL_LABEL',
				name: 'Customer:Skill:Dancing',
				optionName: DROPDOWN_OPTIONS.SKILL_LEVELS,
				type: 'select',
				options: [],
			},
		],
		type: 'check-level',
	},
	instruments: {
		value: 'instruments',
		icon: <AthleticsIcon />,
		selectors: [
			{
				label: 'APP_INSTRUMENTS_LABEL',
				name: 'Customer:Skill:Instruments',
				optionName: DROPDOWN_OPTIONS.INSTRUMENTS,
				type: 'checkbox',
				options: [],
			},
			{
				label: 'APP_LEVEL_LABEL',
				name: 'Customer:Skill:Instruments',
				optionName: DROPDOWN_OPTIONS.SKILL_LEVELS,
				type: 'select',
				options: [],
			},
		],
		type: 'check-level',
	},
	driving: {
		value: 'drivingLicense',
		icon: <ProfileIcon />,
		selectors: [
			{
				label: 'APP_DRIVINGLICENSE_LABEL',
				name: 'Customer:Attribute:DrivingLicense',
				optionName: DROPDOWN_OPTIONS.DRIVING_LICENSE,
				type: 'checkbox',
				options: [],
			},
		],
	},
	otherSkill: {
		value: 'other',
		icon: <AthleticsIcon />,
		selectors: [
			{
				label: 'APP_OTHER_LABEL',
				name: 'Customer:Skill:Others',
				optionName: DROPDOWN_OPTIONS.OTHER_SKILLS,
				type: 'checkbox',
				options: [],
			},
		],
	},
	specialSkill: {
		value: 'specialSkills',
		icon: <AthleticsIcon />,
		selectors: [
			{
				label: 'APP_SPECIAL_LABEL',
				name: 'Customer:Skill:Specials',
				optionName: DROPDOWN_OPTIONS.SPEC_SKILLS,
				type: 'checkbox',
				options: [],
			},
		],
	},
}

export const picturesFields: AttributeCardType[] = [
	...[...Array(16)].map((_, index) => ({
		value: 'picture' + index,
		icon: <ProfileIcon />,
		selectors: [
			{
				label: 'APP_MEDIA_PINNED_LABEL',
				name: 'Customer:Photo:Pinned',
				optionName: DROPDOWN_OPTIONS.MEDIA_PINNED,
				type: 'switch',
				options: [],
			},
			{
				label: 'APP_MEDIA_TYPE_LABEL',
				name: 'ContentLabelType',
				optionName: DROPDOWN_OPTIONS.PICTURE_TYPES,
				type: 'select',
				options: [],
			},
			{
				label: 'APP_MEDIA_STYLE_LABEL',
				name: 'ContentLabelStyle',
				optionName: DROPDOWN_OPTIONS.PICTURE_STYLES,
				type: 'select',
				options: [],
			},
		] as AttributeSelector[],
		index: index,
	})),
]

export const showreelsFields: AttributeCardType[] = [
	...[...Array(16)].map((_, index) => ({
		value: 'showreel' + index,
		icon: <ProfileIcon />,
		selectors: [
			{
				label: 'APP_MEDIA_PINNED_LABEL',
				name: 'Customer:Showreel:Pinned',
				optionName: DROPDOWN_OPTIONS.MEDIA_PINNED,
				type: 'switch',
				options: [],
			},
			{
				label: 'APP_MEDIA_TYPE_LABEL',
				name: 'ContentLabelType',
				optionName: DROPDOWN_OPTIONS.SHOWREEL_TYPES,
				type: 'select',
				options: [],
			},
			{
				label: 'APP_MEDIA_STYLE_LABEL',
				name: 'ContentLabelStyle',
				optionName: DROPDOWN_OPTIONS.SHOWREEL_STYLES,
				type: 'select',
				options: [],
			},
		] as AttributeSelector[],
		index: index,
	})),
]

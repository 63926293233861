export const ArrowShortIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10.75 17.875C10.5039 17.875 10.2852 17.793 10.1211 17.6289C9.76562 17.3008 9.76562 16.7266 10.1211 16.3984L14.7422 11.75L10.1211 7.12891C9.76562 6.80078 9.76562 6.22656 10.1211 5.89844C10.4492 5.54297 11.0234 5.54297 11.3516 5.89844L16.6016 11.1484C16.957 11.4766 16.957 12.0508 16.6016 12.3789L11.3516 17.6289C11.1875 17.793 10.9688 17.875 10.75 17.875Z"
			fill={color}
		/>
	</svg>
)

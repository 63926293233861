import { useEffect } from 'react'

const useStickyContainer = (
	containerId: string,
	onSticky: (e: HTMLDivElement) => void,
	onStatic: (e: HTMLDivElement) => void,
): void => {
	useEffect(() => {
		const handleSticky = (): void => {
			const stickyElement = document.getElementById(
				containerId,
			) as HTMLDivElement
			if (stickyElement) {
				const parent = stickyElement.parentNode as HTMLDivElement
				if (parent) {
					const parentRect = parent.getBoundingClientRect()
					const stickyRect = stickyElement.getBoundingClientRect()

					if (parentRect.top <= 0 && parentRect.bottom >= stickyRect.height) {
						onSticky(stickyElement)
					} else {
						onStatic(stickyElement)
					}
				}
			}
		}
		window.addEventListener('scroll', handleSticky)

		return () => {
			window.removeEventListener('scroll', handleSticky)
		}
	}, [containerId, onStatic, onSticky])
}

export default useStickyContainer

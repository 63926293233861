import { Fragment } from 'react'
import { StepWizardProps } from 'react-step-wizard'
import cn from 'classnames'
import RangeWrapper from './components/RangeWrapper'
import CheckboxWrapper from './components/CheckboxWrapper'
import ColorsSelector from './components/ColorsSelector'
import MultipleSelector from './components/MultipleSelector'
import Selector from './components/Selector'
import Switch from './components/Switch'
import Media from './components/Media'
import { AttributeCardType, AttributeSelector } from 'types/app'
import s from './index.module.scss'

interface Props {
	selectorsData: AttributeSelector[]
	cardData: AttributeCardType
	mediaType: boolean
	wizardProps?: StepWizardProps
	cardIndex?: number
}

const StepContent = ({
	selectorsData,
	cardData,
	mediaType,
	wizardProps,
	cardIndex,
}: Props): JSX.Element => (
	<StepWrapper
		className={cn(s.wrapper, { [s.uploadWrapper]: mediaType })}
		{...wizardProps}>
		<>
			<Media cardIndex={cardIndex} />
			<div className={s.fields} id="modal-content-fields">
				<div
					className={s.fieldsWrapper}
					style={{
						gap: cardData?.type && selectorsData?.length ? '16px' : '20px',
					}}>
					{cardData?.type && selectorsData?.length ? (
						<MultipleSelector selectors={selectorsData} />
					) : (
						selectorsData?.map((data) => (
							<Fragment key={data.name}>
								{data?.type === 'range' ? (
									<RangeWrapper name={data.name} label={data.label} />
								) : data?.type === 'checkbox' ? (
									<CheckboxWrapper selector={data} />
								) : data?.type === 'colors' ? (
									<ColorsSelector selector={data} />
								) : data?.type === 'switch' ? (
									<Switch selector={data} />
								) : data?.type === 'select' ? (
									<Selector selector={data} cardIndex={cardIndex} />
								) : null}
							</Fragment>
						))
					)}
				</div>
			</div>
		</>
	</StepWrapper>
)

export default StepContent

const StepWrapper: React.FC<
	StepWizardProps & { children: JSX.Element; className: string }
> = ({ children, className }) => <div className={className}>{children}</div>

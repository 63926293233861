import cn from 'classnames'
import s from './index.module.scss'

interface Props {
	loading: boolean
	compressed?: boolean
	adapt?: boolean
	visible?: boolean
}

const Loader = ({
	loading,
	compressed,
	adapt,
	visible,
}: Props): JSX.Element => (
	<div
		className={cn(s.container, {
			[s.show]: loading,
			[s.compressed]: compressed,
			[s.adapt]: adapt,
			[s.visible]: visible,
		})}>
		<div className={s.wrapper} />
	</div>
)

export default Loader

import { useShallow } from 'zustand/react/shallow'
import { useRef } from 'react'
import Sidebar from 'components/Sidebar'
import Textarea from 'components/UI/Textarea'
import { t } from 'helpers'
import { useAppStore } from 'store'

const MessageSidebar = (): JSX.Element => {
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const [openSidebar, key, value, onDone, setSidebar] = useAppStore(
		useShallow((state) => [
			state.openMessageSidebar?.open,
			state.openMessageSidebar?.key,
			state.openMessageSidebar?.value,
			state.openMessageSidebar?.onDone,
			state.setOpenMessageSidebar,
		]),
	)

	const onClose = (): void => setSidebar(false, '', () => null)

	return (
		<Sidebar
			open={!!openSidebar}
			onClose={onClose}
			header={{
				title: 'APP_MESSAGE_SIDEBAR_TITLE',
				description: 'APP_MESSAGE_SIDEBAR_DESCRIPTION',
			}}
			additional
			buttons={[
				{
					onClick: () => {
						if (textareaRef.current?.value?.length)
							onDone(textareaRef.current.value)
					},
					children: t('APP_DONE'),
				},
			]}>
			<>
				<Textarea
					label={`APP_${key}_DESC`}
					inputRef={textareaRef}
					defaultValue={value || t(`APP_${key}_DEFAULTMESSAGE_TEXT`)}
					rows={16}
				/>
			</>
		</Sidebar>
	)
}

export default MessageSidebar

import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import Hero from './components/Hero'
import Carousel from './components/Carousel'
import Ready from './components/Ready'
import HowItWorks from './components/HowItWorks'
import Footer from './components/Footer'
import { HOME_SECTIONS } from 'types/enums'
import s from './index.module.scss'

const Home = (): JSX.Element => {
	const { hash } = useLocation()

	useEffect(() => {
		const sectionId = hash?.slice(1)
		if (sectionId) {
			const carousel = HOME_SECTIONS?.CAROUSEL_ACTORS?.split('-')?.[0]
			const id = sectionId?.includes(carousel) ? carousel : sectionId
			const section = document.getElementById(id)
			section?.scrollIntoView({ behavior: 'smooth' })
		}
	}, [hash])

	return (
		<div className={s.container} id="home-page">
			<Hero />
			<Carousel />
			<Ready />
			<HowItWorks />
			<Footer />
		</div>
	)
}

export default Home

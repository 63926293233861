import Card from 'components/Wizard/Card'

const Search = (): JSX.Element => {
	return (
		<Card step="search">
			<></>
		</Card>
	)
}
export default Search

export const EditIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M18.3984 5.87109C19 6.47266 19 7.42969 18.3984 8.03125L17.5781 8.85156L14.8984 6.17188L15.7188 5.35156C16.3203 4.75 17.2773 4.75 17.8789 5.35156L18.3984 5.87109ZM9.70312 11.3672L14.2695 6.80078L16.9492 9.48047L12.3828 14.0469C12.2188 14.2109 12 14.3477 11.7812 14.4297L9.34766 15.2227C9.10156 15.3047 8.85547 15.25 8.69141 15.0586C8.5 14.8945 8.44531 14.6211 8.52734 14.4023L9.32031 11.9688C9.40234 11.75 9.53906 11.5312 9.70312 11.3672ZM10.25 6.5C10.7148 6.5 11.125 6.91016 11.125 7.375C11.125 7.86719 10.7148 8.25 10.25 8.25H7.625C7.13281 8.25 6.75 8.66016 6.75 9.125V16.125C6.75 16.6172 7.13281 17 7.625 17H14.625C15.0898 17 15.5 16.6172 15.5 16.125V13.5C15.5 13.0352 15.8828 12.625 16.375 12.625C16.8398 12.625 17.25 13.0352 17.25 13.5V16.125C17.25 17.5742 16.0742 18.75 14.625 18.75H7.625C6.14844 18.75 5 17.5742 5 16.125V9.125C5 7.67578 6.14844 6.5 7.625 6.5H10.25Z"
			fill={color}
		/>
	</svg>
)

export const MuteIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M10.25 13.5C10.4141 13.6914 10.4688 13.8828 10.4688 14.1016C10.4688 14.3203 10.3867 14.5664 10.2227 14.7305L6.44922 18.4766C6.28516 18.6406 6.06641 18.75 5.84766 18.75C5.38281 18.75 5 18.3945 5 17.875C5 17.6562 5.08203 17.4375 5.24609 17.2461L8.99219 13.5C9.18359 13.3359 9.40234 13.2539 9.62109 13.2539C9.83984 13.2539 10.0586 13.3359 10.25 13.5ZM10.4688 11.3125C10.4688 11.6953 10.168 11.9688 9.8125 11.9688C9.42969 11.9688 9.15625 11.6953 9.15625 11.3125C9.15625 10 10.2227 8.90625 11.5625 8.90625C12.875 8.90625 13.9688 10 13.9688 11.3125C13.9688 11.6953 13.668 11.9688 13.3125 11.9688C12.9297 11.9688 12.6562 11.6953 12.6562 11.3125C12.6562 10.7109 12.1641 10.2188 11.5625 10.2188C10.9336 10.2188 10.4688 10.7109 10.4688 11.3125ZM18.9727 5.625C18.9727 5.87109 18.8906 6.08984 18.7266 6.25391L17.6055 7.375C17.4414 7.56641 17.1953 7.64844 16.9766 7.64844C16.7578 7.64844 16.5391 7.56641 16.3477 7.375C16.1836 7.21094 16.1016 6.99219 16.1016 6.77344C16.1016 6.55469 16.1836 6.30859 16.375 6.14453L17.4688 4.99609C17.6328 4.83203 17.8516 4.75 18.0977 4.75C18.3164 4.75 18.5352 4.83203 18.6992 4.99609C18.8633 5.16016 18.9727 5.37891 18.9727 5.625ZM16.3477 11.3672C16.3477 11.9688 16.2656 13.9375 14.5977 15.6328C14.2969 18.3398 11.6719 18.7227 11.5625 18.7227C11.0703 18.7227 10.6875 18.3398 10.6875 17.8477C10.6875 17.4375 11.0156 17.0547 11.4258 16.9727C12.2461 16.8359 12.875 16.125 12.875 15.25V14.8945L13.3125 14.4297C13.5312 14.2109 14.5977 13.1172 14.5977 11.5312C14.5977 10.3281 13.75 8.25 11.5352 8.25C9.97656 8.25 8.69141 9.45312 8.5 10.957C8.44531 11.3945 8.11719 11.75 7.65234 11.75C7.16016 11.75 6.75 11.3672 6.75 10.9023C6.75 10.6289 7.35156 6.5 11.5625 6.5C15.1719 6.5 16.3477 9.78125 16.3477 11.3672Z"
			fill={color}
		/>
	</svg>
)

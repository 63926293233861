import Controls from './Controls'
import useShakaPlayer from 'hooks/useShakaPlayer'
import useContentHitContract from 'hooks/useContentHitContract'
import useContentConsumptionContract from 'hooks/useContentConsumptionContract'
import { Content } from 'types/app'
import s from './index.module.scss'
import { useCallback, useEffect, useState } from 'react'
import { propertiesParser } from 'helpers/propertiesParser'
import { contentAuthorize } from 'api/auth'
import { ContentAuthorizeType } from 'types/content'

interface Props {
	content: Content
	active: boolean
}

const CastingScene = ({ content, active }: Props): JSX.Element => {
	const [videoData, setVideoData] = useState<ContentAuthorizeType>()

	const { shakaInstance } = useShakaPlayer(
		videoData?.ContentUrl || '',
		'rolescene_' + content?.ContentId,
	)

	useContentHitContract(
		active && videoData?.ContentPlaybackSessionId ? content : undefined,
		videoData?.ContentPlaybackSessionId,
	)

	const { onEnded, onPause, onPlay } = useContentConsumptionContract(
		content,
		shakaInstance,
		videoData?.ContentPlaybackSessionId,
	)

	const fetchVideoUrl = useCallback(async (): Promise<void> => {
		try {
			const response = await contentAuthorize(content.ContentId)
			if (response?.Properties) {
				setVideoData(
					propertiesParser(response.Properties) as ContentAuthorizeType,
				)
			}
		} catch (error) {
			console.log('error:', error)
		}
	}, [content.ContentId])

	useEffect(() => {
		if (active && !videoData?.ContentUrl) {
			fetchVideoUrl()
		}
	}, [videoData?.ContentUrl, active, fetchVideoUrl])

	return (
		<>
			<div className={s.videoWrapper}>
				<video
					id={'rolescene_' + content?.ContentId}
					data-active={active}
					data-scene-video
					onEnded={onEnded}
					onPause={onPause}
					onPlay={onPlay}
				/>
			</div>
			<Controls
				shakaInstance={shakaInstance}
				active={active}
				contentId={content?.ContentId}
				assetId={videoData?.ContentDownloadOriginalAssetId}
			/>
		</>
	)
}

export default CastingScene

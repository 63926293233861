import { useShallow } from 'zustand/react/shallow'
import { useMemo, useState } from 'react'
import { SearchIcon } from 'assets/icons'
import Sidebar from 'components/Sidebar'
import Input from 'components/UI/Input'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Filter from './components/Filter'
import Quick from './components/Quick'
import CastingFilter from './components/CastingFilter'
import LevelSidebar from './components/LevelSidebar'
import { t } from 'helpers'
import { useFilterStore, useUserStore } from 'store'
import { AttributeSelector } from 'types/app'
import { CUSTOMER_ROLE } from 'types/user'

export type FilterSection = {
	title: string
	fields: AttributeSelector[]
	hideByDefault?: boolean
}

const tabs = ['quick', 'filters', 'casting']

const FilterSidebar = (): JSX.Element => {
	const director = useUserStore(
		useShallow(
			(state) =>
				state?.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)
	const [
		openSidebar,
		setOpenSidebar,
		resetFilters,
		setFieldsSearch,
		fieldsSearch,
	] = useFilterStore(
		useShallow((state) => [
			state.openFilterSidebar,
			state.setOpenFilterSidebar,
			state.resetFilters,
			state.setFieldsSearch,
			state.fieldsSearch,
		]),
	)
	const [activeTab, setActiveTab] = useState(tabs[0])

	const onClose = (): void => {
		setOpenSidebar(false)
	}

	const content = useMemo(() => {
		const component = {
			[tabs[0]]: <Quick />,
			[tabs[1]]: <Filter />,
			[tabs[2]]: <CastingFilter />,
		}

		return component[activeTab]
	}, [activeTab])

	return (
		<>
			{openSidebar ? <LevelSidebar /> : null}
			<Sidebar
				open={openSidebar}
				onClose={onClose}
				iconClose
				header={{
					title: 'APP_FILTER_SIDEBAR_TITLE',
				}}
				disableBlur>
				<>
					<SidebarTabs
						active={activeTab}
						onChange={(v) => {
							if ([v, activeTab].includes(tabs[2])) {
								resetFilters()
							}
							setActiveTab(v as string)
							setFieldsSearch('')
						}}
						options={tabs
							?.map((tab) => ({
								value: tab,
								label: `APP_${tab?.toUpperCase()}_TAB`,
							}))
							?.slice(0, director ? 3 : 2)}
					/>
					{activeTab === tabs[0] ? null : (
						<Input
							variant="default"
							name="filterSearch"
							placeholder={t('APP_SEARCH_FILTER_PLACEHOLDER')}
							value={fieldsSearch}
							onChange={(e) => setFieldsSearch(e.target.value)}
							endAdornment={<SearchIcon color="var(--mono300)" />}
						/>
					)}

					{content}
				</>
			</Sidebar>
		</>
	)
}

export default FilterSidebar

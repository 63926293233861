import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import classNames from 'classnames'
import { InfoOutlinedIcon } from 'assets/icons'
import useContent from 'hooks/api/useContent'
import useUser from 'hooks/api/useUser'
import { useAppStore, useCastingStore } from 'store'
import s from './index.module.scss'

const CurrentRole = (): JSX.Element | null => {
	const { productionId, roleId, userId } = useParams()
	const [rolesOpened] = useCastingStore(
		useShallow((state) => [state.openCastingRoles]),
	)

	const { content: production } = useContent(productionId)

	const { content: role } = useContent(roleId)

	const { user } = useUser(userId)

	return !user && !role && !production ? null : (
		<div
			className={classNames(s.container, 'castingToolWrapper', {
				[s.hide]: rolesOpened,
			})}
			data-role="current-role">
			<div className={s.wrapper}>
				<div className={s.avatar}>
					<img
						src={user?.CustomerImageSquareUrl || '/placeholder.png'}
						alt={user?.ContentTitle}
					/>
				</div>
				<div className={s.info}>
					<span className="body1-b">{user?.ContentTitle}</span>
					<span className="body2-b" style={{ color: 'var(--mono200)' }}>
						{role?.['Globalizationen-USContentTitle']}
					</span>
					<span className="body2-b" style={{ color: 'var(--mono200)' }}>
						{production?.['Globalizationen-USContentTitle']}
					</span>
				</div>
				{user ? (
					<div
						style={{ display: 'flex', cursor: 'pointer' }}
						onClick={() => {
							const { setUserInfoSidebar } = useAppStore.getState()
							setUserInfoSidebar(user?.ContentId)
						}}>
						<InfoOutlinedIcon />
					</div>
				) : null}
			</div>
		</div>
	)
}

export default CurrentRole

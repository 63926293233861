import { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useOutsideClick = (ref: any, handleClose: () => void): void => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent): void => {
			if (ref.current && !ref.current.contains(event.target)) {
				handleClose()
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [handleClose, ref])
}

export default useOutsideClick

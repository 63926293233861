export const PrevIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M8.97266 6.52734C9.46484 6.52734 9.875 6.91016 9.875 7.40234V16.125C9.875 16.6172 9.46484 17 9 17C8.50781 17 8.125 16.6172 8.125 16.125V7.40234C8.125 6.91016 8.48047 6.52734 8.97266 6.52734ZM15.4258 6.71875C16 6.25391 16.8477 6.63672 16.8477 7.375V16.125C16.8477 16.8633 16 17.2461 15.4258 16.7812L10.1758 12.4336C9.95703 12.2695 9.875 12.0234 9.875 11.75C9.875 11.5039 9.95703 11.2578 10.1758 11.0664L15.4258 6.71875Z"
			fill={color}
		/>
	</svg>
)

import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import Sidebar from 'components/Sidebar'
import NetworkCard from 'components/NetworkCard'
import useRelations from 'hooks/api/useRelations'
import useUsers from 'hooks/api/useUsers'
import { prepareMessageData, t } from 'helpers'
import { useCastingStore } from 'store'
import { CASTING_STATUS, MESSAGE_TYPE, RELATIONS } from 'types/enums'
import { User } from 'types/user'

const CloseCastingSidebar = (): JSX.Element => {
	const [openSidebar, setOpenSidebar] = useCastingStore(
		useShallow((state) => [
			state.openCloseCastingSidebar,
			state.setOpenCloseCastingSidebar,
		]),
	)
	const navigate = useNavigate()
	const [winner, setWinner] = useState<User>()
	const { roleId } = useParams()
	const { data } = useRelations(RELATIONS.ACCESS, roleId || '')

	const favorite = data?.filter((rel) => rel.status === CASTING_STATUS.FAVORITE)

	const { users } = useUsers(favorite?.map((f) => f.targetId))

	const sendMessage = async (): Promise<void> => {
		if (!winner || !roleId) return

		await addRelation({
			sourceId: roleId,
			targetId: winner?.ContentId || '',
			type: 'Content',
			relation: RELATIONS.ACCESS,
			status: CASTING_STATUS.SELECTED,
		})
		const data = {
			userIds: [winner?.ContentId || ''],
			sourceId: roleId || '',
			key: 'NOTIFYWINNER',
			// messageValue: t(
			// 	`APP_${type?.key || 'NOTIFYUSERS'}_MESSAGE_BODY`,
			// )?.replace('[YourName]', getUser()?.['Custom:User:Name'] || ''),
			redirect: window.location.pathname + window.location.hash,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: MESSAGE_TYPE.NOTIFY_WINNER,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})
		setOpenSidebar(false)
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			header={{
				title: 'APP_CLOSE_CASTING_SIDEBAR_TITLE',
				description: 'APP_CLOSE_CASTING_SIDEBAR_DESCRIPTION',
			}}
			buttons={[
				{
					children: t('APP_NOTIFY_WINNER_BUTTON'),
					onClick: sendMessage,
					disabled: !winner,
				},
			]}>
			<>
				<span>{t('APP_FAVORITE_CANDIDATES')}</span>
				{users?.length ? (
					<div className="grid2column">
						{users?.map((user) => (
							<NetworkCard
								key={user?.ContentId}
								data={user}
								onClick={() => setWinner(user)}
								style={{
									border: `2px solid ${
										winner?.ContentId === user?.ContentId
											? 'var(--theme-primary)'
											: 'transparent'
									}`,
								}}
							/>
						))}
					</div>
				) : (
					<span>{t('APP_RESULT_NOT_FOUND')}</span>
				)}
			</>
		</Sidebar>
	)
}

export default CloseCastingSidebar

import { useNavigate } from 'react-router-dom'
import Image from 'assets/images/home-hero.png'
import Spot from 'assets/images/home-spot.png'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { PATHS } from 'pages/Router'
import { HOME_SECTIONS } from 'types/enums'
import s from './index.module.scss'

const Hero = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<section className={s.container} id={HOME_SECTIONS.HERO}>
			<div className={s.wrapper}>
				<img src={Spot} />
				<div className={s.content}>
					<h1>{t('APP_HOME_TOP_TITLE')}</h1>
					<div className={s.image}>
						<img src={Image} alt="home top" />
					</div>
					<span>{t('APP_HOME_TOP_DESCRIPTION')}</span>
					<div className={s.buttons}>
						<Button
							onClick={() => {
								navigate(`/${PATHS.JOIN}/${PATHS.LOGIN}`)
							}}
							variant="outlined"
							style={{
								gap: '10px',
								color: 'var(--mono900)',
								borderColor: 'var(--theme-primary)',
								backgroundColor: 'var(--theme-primary)',
							}}>
							{t('APP_JOINFREE_LABEL')}
						</Button>
						<Button
							onClick={() => {
								navigate('#' + HOME_SECTIONS.HOW_IT_WORKS)
							}}
							variant="outlined"
							style={{
								gap: '10px',
								color: 'var(--theme-primary)',
								borderColor: 'var(--theme-primary)',
							}}>
							{t('APP_PLAYVIDEO_LABEL')}
						</Button>
					</div>
				</div>
				<div className={s.image}>
					<img src={Image} alt="home top" />
				</div>
			</div>
		</section>
	)
}

export default Hero

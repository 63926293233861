import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { useAppStore } from 'store'
import { ContentCollection, FetchReturn, Locales } from 'types/app'

type ReturnData = FetchReturn & {
	data: Locales | undefined
}

const checkLocalesExpired = (url: string): string | null => {
	const now = Date.now()
	const localesExpiry = localStorage.getItem('LocalesExpiry')

	if (!localesExpiry || now > +localesExpiry) return url
	else return null
}

export default (): ReturnData => {
	const { endpoints } = useAppStore.getState()

	const { data, isLoading, error } = useSwrImmutable<ContentCollection>(
		endpoints?.CustomApplicationLocalizationUrl
			? checkLocalesExpired(endpoints?.CustomApplicationLocalizationUrl)
			: null,
		fetcher,
	)

	const parsedLocalization = data?.ChildItems?.length
		? data?.ChildItems?.map((child) => {
				const locale = child?.Properties[0]?.CollectionName
				if (!locale) return

				return {
					[locale]: child?.Properties?.reduce(
						(prev, next) => ({
							...prev,
							[next?.Name]: next?.Value,
						}),
						{},
					),
				}
		  })?.reduce((prev, next) => ({ ...prev, ...next }), {})
		: undefined

	return {
		data:
			parsedLocalization && Object.keys(parsedLocalization)?.length
				? parsedLocalization
				: undefined,
		isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
	}
}

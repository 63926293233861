import { MagnifyingGlassIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { useAppStore } from 'store'
import { Content } from 'types/app'

interface Props {
	scene: Content | null
}

const Info = ({ scene }: Props): JSX.Element => (
	<div
		style={{
			display: 'flex',
			flexDirection: 'column',
			gap: '10px',
		}}>
		{scene?.assets?.map((asset) => (
			<Button
				variant="outlined"
				key={asset?.id}
				onClick={() => {
					const { setDocumentViewerSidebar } = useAppStore.getState()
					setDocumentViewerSidebar({
						link: asset.publicUrl,
						buttonClick: () => {
							const a = document.createElement('a')
							a.href = asset.publicUrl
							a.download = 'true'
							a.target = '_blank'
							a.click()
						},
						buttonText: 'APP_DOWNLOAD',
					})
				}}
				style={{
					gap: '10px',
					color: 'var(--theme-primary)',
					borderColor: 'var(--theme-primary)',
				}}>
				<div style={{ display: 'flex', alignSelf: 'center' }}>
					<MagnifyingGlassIcon
						color="var(--theme-primary)"
						width="16"
						height="16"
					/>
				</div>
				{t('APP_OPEN_SCRIPT_BUTTON')}
			</Button>
		))}
		{scene?.ContentLabelInstruction ? (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '12px',
				}}>
				<span className="body1-b" style={{ color: 'var(--mono200)' }}>
					{t('APP_INSTRUCTIONS_LABEL')}
				</span>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
						gap: '12px',
					}}>
					{scene?.ContentLabelInstruction?.split(',')?.map((instruction) => (
						<div
							key={instruction}
							style={{
								padding: '3px 12px 6px',
								backgroundColor: 'var(--elev400)',
								borderRadius: '15px',
							}}>
							<span className="caption-m" style={{ color: 'var(--mono200)' }}>
								{instruction}
							</span>
						</div>
					))}
				</div>
			</div>
		) : null}
		{scene?.ContentRoleDress ? (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '12px',
				}}>
				<span className="body1-b" style={{ color: 'var(--mono200)' }}>
					{t('APP_DRESS_LABEL')}
				</span>
				<span className="body2-s" style={{ color: 'var(--mono200)' }}>
					{scene?.ContentRoleDress}
				</span>
			</div>
		) : null}
		{scene?.Duration ? (
			<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
				<span>{`${t('APP_NEWSCENE_DURATION')} :`}</span>
				<span className="body2-s" style={{ color: 'var(--mono200)' }}>{`${
					scene?.Duration
				} sec / ${(+scene?.Duration / 60).toFixed(2)} min`}</span>
			</div>
		) : null}
	</div>
)

export default Info

import { fetcher } from 'api'
import { getUser } from 'helpers/storage'
import { useAppStore } from 'store'
import useSwrImmutable from 'swr/immutable'
import { Content } from 'types/app'

export default (
	contentData?: Content,
	sessionId?: string,
	objectType = 'Content',
): void => {
	// const { license, isLoading } = useUserLicense()

	const { error } = useSwrImmutable(
		contentData?.ContentId /* && !isLoading */ /* && sessionId */
			? contentData.ContentId
			: null,
		() =>
			clientReportFetcher(contentData, objectType, /* license, */ sessionId),
	)

	if (error) console.log('error:', error)
}

const clientReportFetcher = async (
	content: Content | undefined,
	// license?: License,
	objectType: string,
	sessionId?: string,
): Promise<void> => {
	try {
		if (window.location.href.includes('localhost')) return

		const user = getUser()
		const { endpoints } = useAppStore.getState()
		const url = endpoints?.CustomApplicationReporting

		const body = [
			{
				Name: 'Type',
				Value: 'ContentHitContract',
			},
			{
				Name: 'ContentRef',
				Value: content?.ContentId,
			},
			{
				Name: 'UserRef',
				Value: user?.['Custom:User:Id'] || '',
			},
			{
				Name: 'ContentToken',
				Value: content?.ContentToken,
			},
			{
				Name: 'CollectionRef',
				Value: '',
			},
			{
				Name: 'UserToken',
				Value: user?.['Custom:User:Token'] || '',
			},
			{
				Name: 'ProjectRef',
				Value: 'e16f7af2-2fd3-4411-a1cc-4a88fc53500b',
			},
			{
				Name: 'Genre',
				Value: content?.ContentGenre || '',
			},
			{
				Name: 'Category',
				Value: content?.ContentCategory || '',
			},
			{
				Name: 'Date',
				Value: new Date().toISOString(),
			},
			{
				Name: 'SessionId',
				Value: sessionId || '',
			},
			// {
			//   Name: 'ProductId',
			//   Value: license?.ProductId,
			// },
			{
				Name: 'ObjectType',
				Value: objectType,
			},
			{
				Name: 'CatalogRef',
				Value: '',
			},
		]

		await fetcher(url + '/clientreport', {
			method: 'post',
			body: JSON.stringify({
				Properties: body,
			}),
		})
	} catch (err) {
		console.log('err:', err)
	}
}

export const EmptyIcon = ({
	color = 'var(--mono400)',
	width = '20',
	height = '19',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M18.6484 0.601562C19.1055 1.02344 19.1055 1.76172 18.6484 2.18359L16.293 4.57422C17.2773 5.875 17.875 7.49219 17.875 9.25C17.875 13.6094 14.3242 17.125 10 17.125C8.20703 17.125 6.58984 16.5625 5.28906 15.5781L2.89844 17.9336C2.6875 18.1445 2.40625 18.25 2.125 18.25C1.80859 18.25 1.52734 18.1445 1.31641 17.9336C0.859375 17.5117 0.859375 16.7734 1.31641 16.3516L3.67188 13.9609C2.6875 12.6602 2.125 11.043 2.125 9.25C2.125 4.92578 5.64062 1.375 10 1.375C11.7578 1.375 13.375 1.97266 14.6758 2.95703L17.0664 0.601562C17.4883 0.144531 18.2266 0.144531 18.6484 0.601562ZM4.375 9.25C4.375 10.4102 4.69141 11.4648 5.28906 12.3789L13.0938 4.57422C12.1797 3.97656 11.125 3.625 10 3.625C6.87109 3.625 4.375 6.15625 4.375 9.25ZM15.625 9.25C15.625 8.125 15.2734 7.07031 14.6758 6.15625L6.87109 13.9609C7.78516 14.5586 8.83984 14.875 10 14.875C13.0938 14.875 15.625 12.3789 15.625 9.25Z"
			fill={color}
		/>
	</svg>
)

import { useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { ChatToken } from 'amazon-ivs-chat-messaging'
import { connectIVSChatRoom } from 'api/chat'
import { NotificationIcon } from 'assets/icons'
import NotificationMessage from 'components/NotificationMessage'
import useContent from 'hooks/api/useContent'
import { useChatRoom } from 'hooks/useChatRoom'
import useMessageHistory from 'hooks/api/useMessageHistory'
import { uuidv4 } from 'helpers'
import { getUser } from 'helpers/storage'
import { User } from 'types/user'
import { SIDEBARS } from 'types/enums'
import { Content } from 'types/app'
import { ExtendedMessage } from 'types/chat'
import s from '../../index.module.scss'
import * as config from '../../../../../../../ivs-config'

interface Props {
	user: User
}

const Notifications = ({ user }: Props): JSX.Element | null => {
	const [showDropdown, setShowDropdown] = useState(false)
	const navigate = useNavigate()
	const { content } = useContent(user?.CustomerChatId)

	return content ? (
		<div
			className={s.headerDropdown}
			onMouseOver={() => setShowDropdown(true)}
			onMouseLeave={() => setShowDropdown(false)}>
			<div
				className={s.icon}
				style={{ backgroundColor: 'unset' }}
				onClick={() => {
					navigate(
						window.location.pathname +
							`?sidebar=${SIDEBARS.NOTIFICATIONS}` +
							window.location.hash,
					)
				}}>
				<NotificationIcon color="var(--mono400)" />
			</div>
			<Preview
				show={showDropdown}
				hideDropdown={() => setShowDropdown(false)}
				chatData={content}
			/>
		</div>
	) : null
}

export default Notifications

const Preview = ({
	show,
	chatData,
}: {
	show: boolean
	hideDropdown: () => void
	chatData: Content
}): JSX.Element | null => {
	const [messages, setMessages] = useState<ExtendedMessage[]>([])
	useChatRoom({
		regionOrUrl: config.CHAT_WEBSOCKET,
		tokenProvider: () =>
			tokenProvider(getUser()?.['Custom:User:Id'], true, {
				src: getUser()?.['Custom:User:Avatar'],
			}),
	})
	const { data: messageHistory } = useMessageHistory(chatData.ContentId)

	const tokenProvider = useCallback(
		async (
			selectedUsername: string | undefined,
			isModerator: boolean,
			avatarUrl: { src: string | undefined },
		): Promise<ChatToken> => {
			const uuid = uuidv4()
			const permissions = isModerator
				? ['SEND_MESSAGE', 'DELETE_MESSAGE', 'DISCONNECT_USER']
				: ['SEND_MESSAGE']

			const data = {
				userId:
					getUser()?.['Custom:User:Avatar'] || `${selectedUsername}.${uuid}`,
				arn: chatData.ContentChatArn,
				attributes: {
					username: `${selectedUsername}`,
					avatar: `${avatarUrl.src}`,
				},
				capabilities: permissions,
			}

			let token = {} as ChatToken

			try {
				const response = await connectIVSChatRoom(chatData.ContentId, data)

				token = {
					token: response.token,
					sessionExpirationTime: new Date(response.sessionExpiration),
					tokenExpirationTime: new Date(response.tokenExpiration),
				} as ChatToken
			} catch (error) {
				console.log('error:', error)
			}

			return token
		},
		[chatData.ContentChatArn, chatData.ContentId],
	)

	useEffect(() => {
		if (messageHistory?.length && !messages?.length) {
			setMessages(messageHistory?.slice(0, 3))
		}
	}, [messageHistory, messages])

	return messages?.length ? (
		<div
			className={cn(s.dropdown, { [s.show]: show })}
			style={{
				right: '-54px',
				width: '440px',
				padding: '24px',
				boxSizing: 'border-box',
				gap: '16px',
				backgroundColor: 'var(--elev300)',
			}}>
			{messages?.map((message) => (
				<NotificationMessage
					key={message.id}
					message={message}
					style={{
						backgroundColor: 'var(--elev400)',
					}}
				/>
			))}
		</div>
	) : null
}

export const MagicWandIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.08203 8.85156C5.02734 8.82422 5 8.76953 5 8.6875C5 8.63281 5.02734 8.57812 5.08203 8.52344L6.44922 7.97656L7.02344 6.60938C7.05078 6.55469 7.10547 6.5 7.1875 6.5C7.16016 6.5 7.1875 6.5 7.1875 6.5C7.24219 6.52734 7.29688 6.55469 7.32422 6.60938L7.89844 7.94922V7.97656L9.26562 8.52344C9.32031 8.55078 9.34766 8.63281 9.34766 8.71484C9.34766 8.76953 9.32031 8.82422 9.26562 8.85156L7.89844 9.42578L7.32422 10.793C7.29688 10.8477 7.24219 10.875 7.16016 10.875C7.16016 10.875 7.1875 10.875 7.16016 10.875C7.10547 10.875 7.05078 10.8477 7.02344 10.793L6.44922 9.42578L5.08203 8.85156ZM18.9727 14.8125C18.9727 14.8945 18.9453 14.9492 18.8906 14.9766L17.5234 15.5508L16.9492 16.918C16.9219 16.9727 16.8672 17 16.8125 17C16.7305 17 16.6758 16.9727 16.6484 16.918L16.0742 15.5508L14.707 14.9766C14.6523 14.9492 14.625 14.8945 14.625 14.8125C14.625 14.7578 14.6523 14.7031 14.707 14.6758L16.0742 14.1016L16.6484 12.7344C16.6758 12.6797 16.7305 12.625 16.8125 12.625C16.8672 12.625 16.9219 12.6797 16.9492 12.7344L17.4961 14.1016L18.8633 14.6758C18.918 14.7031 18.9727 14.7578 18.9727 14.8125ZM10.6602 6.5L9.86719 6.17188C9.8125 6.14453 9.8125 6.11719 9.8125 6.0625C9.8125 6.03516 9.8125 6.00781 9.86719 5.98047L10.6602 5.625L11.0156 4.83203C11.043 4.77734 11.0156 4.75 11.0977 4.75C11.1523 4.75 11.1797 4.77734 11.207 4.83203L11.5352 5.625L12.3281 5.98047C12.3828 6.00781 12.4102 6.03516 12.4102 6.08984C12.4102 6.11719 12.3828 6.14453 12.3281 6.17188L11.5352 6.5L11.1797 7.32031C11.1797 7.375 11.0977 7.375 11.0977 7.375C11.0703 7.375 11.043 7.375 10.9883 7.32031L10.6602 6.5ZM15.9102 10V9.97266H15.3086L7.67969 18.4219C7.32422 18.8047 6.72266 18.7773 6.39453 18.4492L5.27344 17.3281C4.91797 16.9727 4.91797 16.3984 5.30078 16.0703L8.5 13.1719V12.1602C8.5 11.9414 8.69141 11.75 8.9375 11.75H10.0859L17.7148 4.88672C17.9062 4.72266 18.1523 4.72266 18.3164 4.88672L18.8086 5.40625C18.9727 5.59766 18.9727 5.84375 18.8359 6.00781L16.3203 8.79688V9.5625C16.3203 9.78125 16.1289 10 15.9102 10Z"
			fill={color}
		/>
	</svg>
)

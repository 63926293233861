import { t } from 'helpers'

const Title = ({
	text,
	wrapperClassName,
}: {
	text: string
	wrapperClassName?: string
}): JSX.Element => (
	<div className={wrapperClassName}>
		<span className="body1-b" style={{ color: 'var(--mono200)' }}>
			{t(text)}
		</span>
	</div>
)

export default Title

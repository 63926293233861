import useSwrImmutable from 'swr/immutable'
import { KeyedMutator } from 'swr'
import { fetcher } from 'api'
import { getUserData } from 'api/user'
import { useAppStore } from 'store'
import { FetchReturn, RelationListResponse } from 'types/app'
import { ExtendedMessage, Message } from 'types/chat'

type ReturnData = FetchReturn & {
	data: ExtendedMessage[] | undefined
	refreshData: KeyedMutator<ExtendedMessage[]>
}

export default (targetId: string, limit = 100): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const { data, isLoading, error, mutate } = useSwrImmutable<ExtendedMessage[]>(
		endpoints?.CustomApplicationV3DiscussionUrl
			? endpoints?.CustomApplicationV3DiscussionUrl.replace(
					'${targetType}',
					'Content',
			  ).replace('${targetId}', targetId) + `?allowed=true&itemLimit=${limit}`
			: null,
		messageHistoryFetcher,
	)

	return {
		data: data,
		refreshData: mutate,
		isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
	}
}

const messageHistoryFetcher = async <T>(url: string): Promise<T> => {
	const response = await fetcher<RelationListResponse<Message[]>>(url, {
		method: 'get',
	})

	const users = await Promise.all(
		[...new Set(response?.pageContent?.map((value) => value.customerId))]?.map(
			(value) => getUserData(value),
		),
	)

	if (response?.pageContent?.length > 0) {
		return response.pageContent.reverse().map((message) => {
			let content = {} as ExtendedMessage['content']
			try {
				content = JSON.parse(message.payload)
			} catch (error) {
				content = {} as ExtendedMessage['content']
			}

			return {
				sendTime: message.createdDate,
				content,
				id: message.id,
				sender: users?.find((user) => user?.ContentId === message?.customerId),
			}
		}) as T
	} else {
		return [] as T
	}
}

import Textarea from 'components/UI/Textarea'
import Card from 'components/Wizard/Card'
import { usePortfolioStore } from 'store'
import { biographyFields } from 'pages/Portfolio/fields'
import { useShallow } from 'zustand/react/shallow'

const Biography = (): JSX.Element => {
	const bio = biographyFields.biography
	const values = usePortfolioStore(
		useShallow((state) => state.cardValues['biography']),
	)

	return (
		<Card step="biography">
			<Textarea
				limit={1000}
				name={bio.name}
				value={values[bio.name] as string}
				onChange={(e) => {
					const { setCardValues } = usePortfolioStore.getState()
					setCardValues(
						{
							[bio.name]: e.target.value,
						},
						'biography',
					)
				}}
				onBlur={(e) => {
					const { updateFields } = usePortfolioStore.getState()
					updateFields([
						{
							Name: bio.name,
							Value: e.target.value,
						},
					])
				}}
				rows={17}
			/>
		</Card>
	)
}

export default Biography

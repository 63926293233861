import { useShallow } from 'zustand/react/shallow'
import {
	ReactNode,
	type Dispatch,
	type SetStateAction,
	useState,
	useEffect,
	createContext,
	useContext,
} from 'react'
import cn from 'classnames'
import { useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'

type Props = {
	children: ReactNode
}

type ThemeType = {
	theme: CUSTOMER_ROLE
	setTheme: Dispatch<SetStateAction<CUSTOMER_ROLE>>
}

const initialThemeState = {
	theme:
		localStorage.getItem('theme') &&
		(localStorage.getItem('theme') as string) in CUSTOMER_ROLE
			? (localStorage.getItem('theme') as CUSTOMER_ROLE)
			: CUSTOMER_ROLE.ACTOR,
	setTheme: (() => null) as Dispatch<SetStateAction<CUSTOMER_ROLE>>,
}

const ThemeContext = createContext(initialThemeState)

export const ThemeProvider = ({ children }: Props): JSX.Element => {
	const userRole = useUserStore(useShallow((state) => state.user?.CustomerRole))
	const [theme, setTheme] = useState(initialThemeState.theme)

	useEffect(() => {
		localStorage.setItem('theme', theme)
	}, [theme])

	useEffect(() => {
		if (userRole) {
			if (userRole !== theme) {
				setTheme(userRole?.toLowerCase() as CUSTOMER_ROLE)
			}
		} else {
			setTheme(initialThemeState.theme)
		}
	}, [userRole, theme])

	return (
		<ThemeContext.Provider value={{ theme, setTheme }}>
			<div className={cn('App', `theme-${theme}`)}>{children}</div>
		</ThemeContext.Provider>
	)
}

const useThemeContext = (): ThemeType => useContext(ThemeContext)

export default useThemeContext

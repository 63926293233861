import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import cn from 'classnames'
import StepWrapper from 'components/Wizard/StepWrapper'
import ContributorBoard from './components/ContributorBoard'
import CastingOverview from '../components/CastingOverview'
import MyProductions from '../components/MyProductions'
import ProductionInfo from '../components/ProductionInfo'
import CreateProduction from '../components/CreateProduction'
import Roles from '../components/Roles'
import RoleBasic from '../components/RoleBasic'
import RoleScenes from '../components/RoleScenes'
import { useAppStore, useProductionStore, useUserStore } from 'store'
import s from '../index.module.scss'
import { PATHS } from 'pages/Router'
import StepControls from '../components/StepControls'
import Aside from '../components/Aside'
import Loader from 'components/Loader'
import RoleCoverSidebar from '../components/RoleCoverSidebar'
import NdaSidebar from '../components/NdaSidebar'
import LocationSidebar from '../components/LocationSidebar'
import ProductionCoverSidebar from '../components/ProductionCoverSidebar'
import { useShallow } from 'zustand/react/shallow'

export const contributorCastingSteps = {
	board: {
		name: 'APP_CASTING_BOARD',
		key: 'board',
		main: true,
		substeps: [
			{
				name: 'APP_CASTING_BOARD',
				key: 'board',
				component: ContributorBoard,
				index: 0,
			},
			{
				name: 'APP_CASTING_BOARD',
				key: 'board',
				component: CastingOverview,
				index: 1,
				disabled: true,
				hide: true,
			},
		],
	},
	myproduction: {
		name: 'APP_PRODUCTIONS_ALL',
		key: 'prod',
		main: true,
		hideSubstepsUntilActive: true,
		substeps: [
			{
				name: 'APP_PRODUCTIONS_ALL',
				key: 'prod',
				component: MyProductions,
				index: 0,
			},
			{
				name: 'APP_PRODUCTION_BASIC',
				key: 'prod',
				component: CreateProduction,
				index: 1,
				// disabled: true,
			},
			{
				name: 'APP_PRODUCTION_DIRECTOR',
				key: 'prod',
				component: ProductionInfo,
				index: 2,
				// disabled: true,
			},
			{
				name: 'APP_PRODUCTIONS_ROLES',
				key: 'prod',
				component: Roles,
				index: 3,
				// disabled: true,
			},
		],
	},
	productionrole: {
		name: 'APP_ROLES_CREATE',
		key: 'prod',
		main: true,
		dynamic: true,
		substeps: [
			{
				name: 'APP_PRODUCTIONS_ROLE_BASIC',
				key: 'prod',
				component: RoleBasic,
				index: 4,
				// disabled: true,
			},
			{
				name: 'APP_PRODUCTIONS_ROLE_SCENES',
				key: 'prod',
				component: RoleScenes,
				index: 5,
				// disabled: true,
			},
		],
	},
}

const ContributorCasting = (): JSX.Element => {
	const navigate = useNavigate()
	const { productionId, productionRoleId } = useParams()
	const { hash, pathname } = useLocation()
	const wizard = useAppStore(useShallow((state) => state.stepWizard))
	const [loading] = useProductionStore(useShallow((state) => [state.loading]))

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname +
					'#' +
					Object.values(contributorCastingSteps)?.[0]?.key +
					'-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	useEffect(() => {
		if (wizard?.currentStep && wizard.currentStep === 1 && productionRoleId) {
			const { user } = useUserStore.getState()
			wizard.goToStep(+window.location.hash.slice(-1))

			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/` +
					productionRoleId +
					window.location.search +
					window.location.hash,
			)
		}
	}, [navigate, productionRoleId, wizard])

	/* remove role id */
	useEffect(() => {
		if (wizard?.currentStep && wizard.currentStep < 7 && productionRoleId) {
			const { user } = useUserStore.getState()

			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${
					PATHS.PROJECT
				}/${productionId}` +
					(window.location.search || '') +
					window.location.hash,
			)
		}
	}, [productionId, productionRoleId, navigate, wizard?.currentStep])

	/* remove production id */
	useEffect(() => {
		if (
			productionId &&
			wizard?.currentStep &&
			(wizard.currentStep === 3 || wizard.currentStep === 1)
		) {
			const { user } = useUserStore.getState()
			const { resetStore } = useProductionStore.getState()

			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}` +
					window.location.search +
					window.location.hash,
			)
			resetStore()
		}
	}, [navigate, hash, productionId, wizard?.currentStep])

	/* for the casting scenes reloading */
	useEffect(() => {
		const { stepWizard } = useAppStore.getState()
		const { pathname, hash, search } = window.location
		const board = hash?.includes(contributorCastingSteps?.board?.key)
		const index = +hash?.slice(-1)
		if (board && index > 1) {
			stepWizard?.goToStep(2)
			setTimeout(() => {
				navigate(
					pathname +
						search +
						`#${contributorCastingSteps?.board?.key + '-' + index}`,
				)
			}, 500)
		}
	}, [navigate])

	return (
		<div className={s.container}>
			<Loader loading={loading} visible />
			<RoleCoverSidebar />
			<NdaSidebar />
			<LocationSidebar />
			<ProductionCoverSidebar />
			<div className={cn(s.wrapper, 'flex')}>
				<StepWizard
					initialStep={0}
					instance={assignStepWizard}
					isHashEnabled={true}
					className={pathname?.includes(PATHS.PROJECT) ? cn(s.wizard) : ''}>
					{Object.values(contributorCastingSteps)
						?.map((step) => step.substeps)
						?.flat()
						?.map((step) => (
							<StepWrapper
								style={
									[
										`#${contributorCastingSteps.board.key}-0`,
										`#${contributorCastingSteps.myproduction.key}-0`,
										`#${contributorCastingSteps.myproduction.key}-3`,
									].includes(hash)
										? {
												height: 'auto',
												display: 'flex',
										  }
										: {
												display: 'flex',
										  }
								}
								key={step.name + '_' + step.index}
								hashKey={step.key + '-' + step.index}
								stepName={step.key + '-' + step.index}>
								<step.component />
							</StepWrapper>
						))}
				</StepWizard>
				<Aside />
			</div>
			<StepControls />
		</div>
	)
}

export default ContributorCasting

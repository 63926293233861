import { useShallow } from 'zustand/react/shallow'
import { partialContentUpdate } from 'api/content'
import { PlusIcon } from 'assets/icons'
import Textarea from 'components/UI/Textarea'
import InputLabel from 'components/UI/InputLabel'
import DragDrop from 'components/UI/DragDrop'
import InstructionsInput from '../InstructionsInput'
import { t } from 'helpers'
import { useAppStore } from 'store'
import { Content } from 'types/app'
import s from '../../index.module.scss'

interface Props {
	content: Content | undefined
}

const Instructions = ({ content }: Props): JSX.Element => {
	const [scene, setScene] = useAppStore(
		useShallow((state) => [
			state.addSceneSidebar.scene,
			state.addSceneSidebar.setScene,
		]),
	)

	return (
		<>
			<InstructionsInput />
			<Textarea
				value={scene?.dress || ''}
				label="APP_DRESS_LABEL"
				name="dress"
				onChange={(e) => setScene({ dress: e.target.value })}
				rows={4}
				limit={1000}
			/>
			<div className={s.attachments}>
				<InputLabel label="APP_SCRIPT_LABEL" />
				<DragDrop
					layoutStyle={{
						padding: '30px 20px',
					}}
					style={{
						padding: '30px 20px',
					}}
					hint={
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								gap: '4px',
							}}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<PlusIcon color="var(--mono400)" />
								<span className="body1-m" style={{ color: 'var(--mono400)' }}>
									{t('APP_UPLOAD_LABEL')}
								</span>
							</div>
							<span
								className="caption-b"
								style={{
									color: 'var(--mono400)',
									textAlign: 'center',
								}}>
								{t('APP_SCENE_DRAGDROP_HINT')}
							</span>
						</div>
					}
					name="scene-attachments"
					multiple
					accept=".pdf"
					onChange={(e) => {
						if (e.target.files?.length)
							setScene({
								attachments: [
									...scene.attachments,
									...Array.from(e.target.files || []).map((file) => file),
								],
							})
					}}
				/>
				{scene?.attachments?.length ? (
					<div className={s.files}>
						{scene?.attachments?.map((attachment) => (
							<div
								key={attachment.name}
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px',
								}}>
								<a
									className="body1-b"
									href={'url' in attachment ? (attachment.url as string) : ''}
									target="_blank"
									download
									style={{
										textDecoration: 'none',
										textTransform: 'none',
										color: 'var(--mono100)',
									}}>
									{attachment.name}
								</a>
								<div
									style={{ display: 'flex', cursor: 'pointer' }}
									onClick={() => {
										if (attachment?.contentId) {
											partialContentUpdate(attachment.contentId, {
												assets: content?.assets?.map((asset) => {
													if (asset.id === attachment.id) {
														return {
															...asset,
															isDeleted: true,
														}
													} else return asset
												}),
											})
										}
										setScene({
											attachments: scene.attachments?.filter(
												(file) => file.name !== attachment.name,
											),
										})
									}}>
									<PlusIcon
										style={{
											transform: 'rotate(45deg)',
											marginLeft: 'auto',
										}}
									/>
								</div>
							</div>
						))}
					</div>
				) : null}
			</div>
		</>
	)
}

export default Instructions

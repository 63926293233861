import useSwrImmutable from 'swr/immutable'
import { fetcher, multipleFetcher } from 'api'
import { FetchReturn, RelationListResponse, Relation } from 'types/app'
import { RELATIONS } from 'types/enums'
import { useAppStore } from 'store'
import { getUser } from 'helpers/storage'
import { KeyedMutator } from 'swr'

type ReturnData = FetchReturn & {
	data: Relation[] | undefined
	refresh: KeyedMutator<RelationListResponse[]>
}

export default (): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const relationUrl =
		endpoints?.CustomApplicationV3ConnectionPostUrl &&
		getUser()?.['Custom:User:Id']
			? endpoints?.CustomApplicationV3ConnectionPostUrl +
			  '/' +
			  getUser()?.['Custom:User:Id'] +
			  '/' +
			  RELATIONS.RELATIONSHIP +
			  '/' +
			  RELATIONS.RELATIONSHIP
			: null

	const { data, isLoading, error, mutate } = useSwrImmutable<
		RelationListResponse[]
	>(relationUrl ? relationUrl : null, relationshipFetcher, {
		shouldRetryOnError: false,
	})

	return {
		data: data?.length ? data?.map((rel) => rel.pageContent).flat() : undefined,
		isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		refresh: mutate,
	}
}

const relationshipFetcher = async <T>(url: string): Promise<T> => {
	const { endpoints } = useAppStore.getState()

	const response = await fetcher<RelationListResponse>(url, { method: 'get' })

	response.pageContent.forEach((content) => {
		if (content.relation === RELATIONS.RELATIONSHIP) {
			const newUrl =
				endpoints?.CustomApplicationV3ConnectionPostUrl +
				`/${content?.targetId}`
			fetcher(newUrl, {
				method: 'get',
			})
		}
	})

	if (response?.pageContent?.length > 0) {
		const res = await multipleFetcher<RelationListResponse[]>(
			response.pageContent.map(
				(item) =>
					endpoints?.CustomApplicationV3ConnectionPostUrl +
					`/${item?.targetId}`,
			),
			{},
		)

		return res as T
	} else {
		return [] as T
	}
}

import { useShallow } from 'zustand/react/shallow'
import Select from 'components/UI/Select'
import { useAppStore } from 'store'

const ProjectStatusSelect = (): JSX.Element => {
	const [localSearch, setSidebar] = useAppStore(
		useShallow((state) => [
			state.localSearchSidebar,
			state.setLocalSearchSidebar,
		]),
	)

	return (
		<Select
			name="productions"
			options={[
				{ label: 'APP_EXPIRED_STATUS', value: 'expired' },
				{ label: 'APP_ACTIVE_STATUS', value: 'active' },
				{ label: 'APP_UPCOMING_STATUS', value: 'upcoming' },
			]}
			error=""
			label="APP_PRODUCTION_STATUS_SELECT_LABEL"
			onChange={(e) => {
				setSidebar({ status: e.target.value })
			}}
			inputProps={{
				value: localSearch?.status,
			}}
		/>
	)
}

export default ProjectStatusSelect

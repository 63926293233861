export const QRCodeIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M10.3125 5.625C11.0234 5.625 11.625 6.22656 11.625 6.9375V9.5625C11.625 10.3008 11.0234 10.875 10.3125 10.875H7.6875C6.94922 10.875 6.375 10.3008 6.375 9.5625V6.9375C6.375 6.22656 6.94922 5.625 7.6875 5.625H10.3125ZM9.875 7.375H8.125V9.125H9.875V7.375ZM10.3125 12.625C11.0234 12.625 11.625 13.2266 11.625 13.9375V16.5625C11.625 17.3008 11.0234 17.875 10.3125 17.875H7.6875C6.94922 17.875 6.375 17.3008 6.375 16.5625V13.9375C6.375 13.2266 6.94922 12.625 7.6875 12.625H10.3125ZM9.875 14.375H8.125V16.125H9.875V14.375ZM13.375 6.9375C13.375 6.22656 13.9492 5.625 14.6875 5.625H17.3125C18.0234 5.625 18.625 6.22656 18.625 6.9375V9.5625C18.625 10.3008 18.0234 10.875 17.3125 10.875H14.6875C13.9492 10.875 13.375 10.3008 13.375 9.5625V6.9375ZM15.125 9.125H16.875V7.375H15.125V9.125ZM16 17H16.875V17.875H16V17ZM18.625 17.875H17.75V17H18.625V17.875ZM17.75 12.625H18.625V16.125H16V15.25H15.125V17.875H13.375V12.625H16V13.5H17.75V12.625Z"
			fill={color}
		/>
	</svg>
)

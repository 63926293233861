import { useLocation } from 'react-router-dom'
import Validation from './Validation'

const ValidationWrapper = (): JSX.Element => {
	const { hash } = useLocation()

	return hash === '#validation-0' ? <Validation /> : <></>
}

export default ValidationWrapper

export const FacebookIcon = ({
	color = 'var(--mono100)',
	width = '32',
	height = '33',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M29.3307 16.4993C29.3307 9.13935 23.3574 3.16602 15.9974 3.16602C8.6374 3.16602 2.66406 9.13935 2.66406 16.4993C2.66406 22.9527 7.25073 28.326 13.3307 29.566V20.4993H10.6641V16.4993H13.3307V13.166C13.3307 10.5927 15.4241 8.49935 17.9974 8.49935H21.3307V12.4993H18.6641C17.9307 12.4993 17.3307 13.0993 17.3307 13.8327V16.4993H21.3307V20.4993H17.3307V29.766C24.0641 29.0993 29.3307 23.4193 29.3307 16.4993Z"
			fill={color}
		/>
	</svg>
)

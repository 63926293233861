import { useRef } from 'react'
import s from './index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
	volume: number
	onChange: (v: { [key: string]: number }) => void
}

const Volume = ({
	videoElement,
	volume,
	onChange,
}: Props): JSX.Element | null => {
	const rangeInput = useRef<HTMLInputElement>(null)

	const onVolumeChange = (v: number): void => {
		if (videoElement) videoElement.volume = v
		onChange({ volume: v })
	}

	return window?.navigator && window?.navigator?.maxTouchPoints > 0 ? null : (
		<div className={s.container}>
			<input
				min={0}
				max={10}
				ref={rangeInput}
				type="range"
				value={volume * 10}
				onChange={(e) => onVolumeChange(+e.target.value / 10)}
			/>
		</div>
	)
}

export default Volume

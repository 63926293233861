import { MuteIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
	muted: boolean
	onChange: (v: { [key: string]: boolean }) => void
}

const Mute = ({ videoElement, muted, onChange }: Props): JSX.Element => {
	const onMute = (): void => {
		if (videoElement) videoElement.muted = !muted
		onChange({ muted: !muted })
	}

	return (
		<Tooltip title={muted ? 'APP_UNMUTE_CONTROL' : 'APP_MUTE_CONTROL'}>
			<div className={s.control} onClick={onMute}>
				<MuteIcon
					color={muted ? 'var(--mono100) !important' : 'var(--mono300)'}
				/>
			</div>
		</Tooltip>
	)
}

export default Mute

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import ProfileLayout from 'components/ProfileLayout'
import Navigation from './components/Navigation'
import Actors from './components/Actors'
import { useStickyContainer } from 'hooks'
import { EventBus } from 'helpers/EventBus'
import { useModalStore, useNetworkStore } from 'store'
import { User } from 'types/user'
import s from './index.module.scss'

interface Props {
	user: User
}

const Profile = ({ user }: Props): JSX.Element => {
	const navigate = useNavigate()
	const [setUser, users, listSource] = useNetworkStore(
		useShallow((state) => [
			state.userProfile.onUserChange,
			state.userProfile.users,
			state.userProfile.listSource,
		]),
	)
	const [animation, setAnimation] = useState<'left' | 'right' | null>(null)
	// const [prevUser, setPrevUser] = useState<User | null>(null)

	useStickyContainer(
		'network-profile-controls',
		(e) => {
			e.classList.add(s.sticky)
			const hidden = e.childNodes[1] as HTMLDivElement
			hidden?.classList.add(s.showShort)
			const wrapper = document.getElementById('network-profile-wrapper')
			if (wrapper) wrapper.style.marginTop = '94px'
		},
		(e) => {
			e.classList.remove(s.sticky)
			const hidden = e.childNodes[1] as HTMLDivElement
			hidden?.classList.remove(s.showShort)
			const wrapper = document.getElementById('network-profile-wrapper')
			if (wrapper) wrapper.style.marginTop = '0px'
		},
	)

	const currentIndex = useMemo(
		() => users?.findIndex((u) => u?.ContentId === user?.ContentId),
		[users, user?.ContentId],
	)
	const nextExist = useMemo(
		() => currentIndex !== undefined && !!users?.[currentIndex + 1],
		[users, currentIndex],
	)
	const prevExist = useMemo(
		() => currentIndex !== undefined && !!users?.[currentIndex - 1],
		[users, currentIndex],
	)

	const onBack = useCallback(async (): Promise<void> => {
		const path = await setUser(null)
		navigate(path)
		setAnimation(null)
	}, [navigate, setUser])

	const onNext = useCallback(async (): Promise<void> => {
		if (currentIndex !== undefined) {
			// setPrevUser(user)
			setAnimation('left')
			const path = await setUser(users?.[currentIndex + 1] || null)
			navigate(path)
			setTimeout(() => {
				setAnimation(null)
				// setPrevUser(null)
			}, 300)
		}
	}, [currentIndex, navigate, setUser, users])

	const onPrev = useCallback(async (): Promise<void> => {
		if (currentIndex !== undefined) {
			// setPrevUser(user)
			setAnimation('right')
			const path = await setUser(users?.[currentIndex - 1] || null)
			navigate(path)
			setTimeout(() => {
				setAnimation(null)
				// setPrevUser(null)
			}, 300)
		}
	}, [currentIndex, navigate, setUser, users])

	const onListUserClick = async (index: number, user: User): Promise<void> => {
		if (currentIndex !== undefined) {
			// setPrevUser(user)
			setAnimation(index < currentIndex ? 'right' : 'left')
			const path = await setUser(user)
			navigate(path)
			setTimeout(() => {
				setAnimation(null)
				// setPrevUser(null)
			}, 300)
		}
	}

	useEffect(() => {
		const keyDownHandler = (event: KeyboardEvent): void => {
			const activeElement = document.activeElement

			if (
				activeElement?.tagName === 'INPUT' ||
				activeElement?.tagName === 'TEXTAREA'
			) {
				return
			}
			const { modal } = useModalStore.getState()
			if (modal?.show) return

			const { code } = event

			switch (code) {
				case 'ArrowRight':
					{
						if (nextExist) onNext()
					}
					break
				case 'ArrowLeft':
					{
						if (prevExist) onPrev()
					}
					break
				default:
					break
			}
		}
		document.addEventListener('keydown', keyDownHandler)

		return () => {
			document.removeEventListener('keydown', keyDownHandler)
		}
	}, [nextExist, onNext, prevExist, onPrev])

	useEffect(() => {
		EventBus.$on('checkProfileOpened', () => {
			setTimeout(() => {
				onBack()
			}, 200)
		})

		return () => {
			EventBus.$off('checkProfileOpened')
		}
	}, [onBack])

	useEffect(() => {
		return () => {
			const { setUserProfile } = useNetworkStore.getState()
			setUserProfile({
				user: null,
				users: undefined,
				listSource: undefined,
			})
		}
	}, [])

	useEffect(() => {
		window.scrollTo({ top: 0 })
	}, [user?.ContentId])

	return (
		<>
			<div className={s.container}>
				<Navigation
					user={user}
					onBack={onBack}
					onNext={nextExist ? onNext : null}
					onPrev={prevExist ? onPrev : null}
				/>

				<div className={s.wrapper} id="network-profile-wrapper">
					{users?.length ? (
						<Actors
							user={user}
							users={users}
							source={listSource}
							onListUserClick={onListUserClick}
						/>
					) : null}
					<div
						style={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
						<div
							className={cn(s.animationWrapper, {
								[s.moveToRight]: animation === 'right',
								[s.moveToLeft]: animation === 'left',
							})}
							style={{
								position: animation ? 'absolute' : 'relative',
							}}>
							<ProfileLayout user={user} />
						</div>
						{animation ? (
							<div
								className={cn(
									{
										[s.moveFromRight]: animation === 'left',
										[s.moveFromLeft]: animation === 'right',
									},
									s.animationWrapper,
								)}
								style={{
									position: 'relative',
								}}>
								<ProfileLayout user={user} />
							</div>
						) : null}
					</div>
				</div>
			</div>
		</>
	)
}

export default Profile

import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { FetchReturn, Relation } from 'types/app'
import { RELATIONS } from 'types/enums'
import { useAppStore } from 'store'
import { KeyedMutator } from 'swr'

type ReturnData = FetchReturn & {
	relation: Relation | undefined
	refresh: KeyedMutator<Relation>
}

export default (
	relation: RELATIONS,
	targetId: string,
	sourceId: string,
	type = 'Content',
	query = '',
): ReturnData => {
	const { endpoints } = useAppStore.getState()
	let relationUrl = endpoints?.CustomApplicationV3ConnectionPostUrl

	if (relation && targetId && sourceId)
		relationUrl += '/' + sourceId + '/' + type + '/' + relation + '/' + targetId
	else relationUrl = ''

	const { data, isLoading, error, mutate } = useSwrImmutable<Relation>(
		relationUrl ? relationUrl + query : null,
		fetcher,
		{
			shouldRetryOnError: false,
		},
	)

	return {
		relation: data,
		isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		refresh: mutate,
	}
}

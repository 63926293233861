import { useShallow } from 'zustand/react/shallow'
import { useLocation } from 'react-router-dom'
import Notifications from './components/Notifications'
import Account from './components/Account'
import HomeMenu from './components/HomeMenu'
import PortfolioModeMenu from './components/PortfolioModeMenu'
import useUser from 'hooks/api/useUser'
import { getUser } from 'helpers/storage'
import { useAppStore } from 'store'
import { PATHS } from 'pages/Router'
import s from './index.module.scss'

const Menu = (): JSX.Element => {
	const { pathname } = useLocation()
	const { user } = useUser(getUser()?.['Custom:User:Id'], true)
	const portfolioMode = useAppStore(
		useShallow((state) => state.portfolioMode.active),
	)

	const home = pathname === '/' || pathname.includes(PATHS.HOME)

	return (
		<>
			{home ? (
				<div className={s.icons}>
					<HomeMenu />
				</div>
			) : null}
			{portfolioMode && !home ? (
				<div className={s.icons}>
					{' '}
					<PortfolioModeMenu />
				</div>
			) : null}
			{user && !home && !portfolioMode ? (
				<div className={s.icons}>
					<Notifications user={user} />
					<Account user={user} />
				</div>
			) : null}
		</>
	)
}

export default Menu

import { useShallow } from 'zustand/react/shallow'
import { useEffect, useRef } from 'react'
import Card from 'components/Wizard/Card'
import FileUploadCard from 'components/FileUploadCard'
import { t } from 'helpers'
import { usePortfolioStore, useUserStore } from 'store'
import { User } from 'types/user'
import s from './index.module.scss'
import { imageAcceptFormats } from 'helpers/constants'

const Photos = (): JSX.Element => {
	const user = useUserStore(useShallow((state) => state.user))
	const dataLoadRef = useRef(false)

	useEffect(() => {
		if (user && !dataLoadRef.current) {
			dataLoadRef.current = true
			const { setCardValues } = usePortfolioStore.getState()
			const names = ['Customer:Image:SquareUrl', 'Customer:Image:PortraitUrl']
			names.forEach((name) => {
				setCardValues(
					{
						[name]:
							user[name?.replaceAll(':', '') as keyof User]?.toString() || '',
					},
					name,
				)
			})
		}
	}, [user])

	return (
		<div className={s.container}>
			<Card>
				<>
					<FileUploadCard
						fileType="Image"
						label={t('APP_PROFILEPICTURE_LABEL')}
						form="circle"
						itemTitle={t('APP_PROFILEPICTURE_TITLE')}
						hintText={t('APP_PROFILEPICTURE_HINT')}
						name="Customer:Image:SquareUrl"
						formatsText="APP_PHOTO_FORMATS"
						qualityText="APP_PROFILEPICTURE_QUALITY"
						update
						fileInputProps={{
							accept: imageAcceptFormats,
						}}
					/>
					<FileUploadCard
						fileType="Image"
						label={t('APP_MAINHEADSHOT_LABEL')}
						form="rectangle"
						itemTitle={t('APP_MAINHEADSHOT_TITLE')}
						hintText={t('APP_MAINHEADSHOT_HINT')}
						name="Customer:Image:PortraitUrl"
						formatsText="APP_PHOTO_FORMATS"
						qualityText="APP_MAINHEADSHOT_QUALITY"
						update
						fileInputProps={{
							accept: imageAcceptFormats,
						}}
					/>
				</>
			</Card>
		</div>
	)
}

export default Photos

export const CheckIcon = ({
	color = 'var(--mono100)',
	width = '25',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M18.6016 7.64844C18.957 7.97656 18.957 8.55078 18.6016 8.87891L11.6016 15.8789C11.2734 16.2344 10.6992 16.2344 10.3711 15.8789L6.87109 12.3789C6.51562 12.0508 6.51562 11.4766 6.87109 11.1484C7.19922 10.793 7.77344 10.793 8.10156 11.1484L10.9727 14.0195L17.3711 7.64844C17.6992 7.29297 18.2734 7.29297 18.6016 7.64844Z"
			fill={color}
		/>
	</svg>
)

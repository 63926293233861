import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import { CastingIcon, NetworkIcon, PortfolioIcon } from 'assets/icons'
import { t } from 'helpers'
import { useAppStore, useUserStore } from 'store'
import { PATHS } from 'pages/Router'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'

const Nav = (): JSX.Element => {
	const navigate = useNavigate()
	const [extend, setExtend] = useState(false)
	const { pathname } = useLocation()
	const userRole = useUserStore(
		useShallow((state) => state.user?.CustomerRole?.toLowerCase()),
	)
	const portfolioMode = useAppStore(
		useShallow((state) => state.portfolioMode.active),
	)

	const hideNav =
		pathname.includes('home') ||
		pathname.includes('join') ||
		pathname.includes('user')

	const activeNav =
		(pathname?.includes(PATHS.ACTOR) ||
			pathname?.includes(PATHS.CONTRIBUTOR) ||
			pathname?.includes(PATHS.AGENCY) ||
			pathname?.includes(PATHS.CASTING_DIRECTOR)) &&
		pathname?.split('/')[2]

	const actorsNav = useMemo(
		() => [
			{
				icon: <PortfolioIcon />,
				label: t('APP_NAV_PORTFOLIO'),
				link: PATHS.PORTFOLIO,
			},
			{
				icon: <CastingIcon />,
				label: t('APP_NAV_CASTING'),
				link: PATHS.CASTING,
			},
			{
				icon: <NetworkIcon />,
				label: t('APP_NAV_NETWORK'),
				link: PATHS.NETWORK,
			},
		],
		[],
	)

	const contributorsNav = useMemo(
		() => [
			{
				icon: <PortfolioIcon />,
				label: t('APP_NAV_PORTFOLIO'),
				link: PATHS.PORTFOLIO,
			},
			{
				icon: <CastingIcon />,
				label: t('APP_NAV_CASTING'),
				link: PATHS.CASTING,
			},
		],
		[],
	)

	const castingDirectorNav = useMemo(
		() => [
			{
				icon: <PortfolioIcon />,
				label: t('APP_NAV_PORTFOLIO'),
				link: PATHS.PORTFOLIO,
			},
			{
				icon: <CastingIcon />,
				label: t('APP_NAV_CASTING'),
				link: PATHS.CASTING,
			},
			{
				icon: <NetworkIcon />,
				label: t('APP_NAV_NETWORK'),
				link: PATHS.NETWORK,
			},
		],
		[],
	)

	const agencyNav = useMemo(
		() => [
			{
				icon: <PortfolioIcon />,
				label: t('APP_NAV_PORTFOLIO'),
				link: PATHS.PORTFOLIO,
			},
			{
				icon: <CastingIcon />,
				label: t('APP_NAV_CASTING'),
				link: PATHS.CASTING,
			},
			{
				icon: <NetworkIcon />,
				label: t('APP_NAV_NETWORK'),
				link: PATHS.NETWORK,
			},
		],
		[],
	)

	const navs = useMemo(() => {
		const navigation = {
			[CUSTOMER_ROLE.ACTOR]: actorsNav,
			[CUSTOMER_ROLE.CASTING_DIRECTOR]: castingDirectorNav,
			[CUSTOMER_ROLE.CONTRIBUTOR]: contributorsNav,
			[CUSTOMER_ROLE.AGENCY]: agencyNav,
		}

		return userRole ? navigation[userRole as CUSTOMER_ROLE] : []
	}, [userRole, actorsNav, contributorsNav, castingDirectorNav, agencyNav])

	return hideNav || !navs?.length ? (
		<></>
	) : (
		<div
			className={cn(s.container, 'cardWrapperSize')}
			onMouseOver={() => setExtend(true)}
			onMouseLeave={() => setExtend(false)}>
			{portfolioMode ? null : (
				<div className={cn(s.wrapper, { [s.full]: extend })}>
					{navs?.map(({ label, icon, link }) => (
						<div
							key={label}
							className={cn(s.item, { [s.active]: link === activeNav })}
							onClick={() => {
								navigate(`${userRole?.toLowerCase()}/${link}`)
								setExtend(false)

								const { stepWizard } = useAppStore.getState()
								stepWizard?.firstStep()
							}}>
							<div>{icon}</div>
							<span className="body1-m">{label}</span>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default Nav

import classNames from 'classnames'
import dayjs from 'dayjs'
import { ArrowShortIcon } from 'assets/icons'
import CountryFlag from 'components/CountryFlag'
import { optionsParser, t } from 'helpers'
import { useAppStore } from 'store'
import { User } from 'types/user'
import { basicFields } from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	user: User
	onBack: () => void
	onPrev: (() => void) | null
	onNext: (() => void) | null
}

const Navigation = ({ user, onBack, onPrev, onNext }: Props): JSX.Element => {
	const { countries } = useAppStore.getState()

	const iso2 = countries?.find(
		(country) => user?.CustomerLocationCountry === country.name,
	)?.iso2

	const profession = optionsParser(
		t(basicFields?.profession?.optionName || ''),
	)?.find((v) => v.value === user?.CustomerProfession)?.label

	const infoValues = [
		profession,
		(user?.CustomUserBirthDate &&
			dayjs().diff(dayjs(user?.CustomUserBirthDate), 'year')) ||
			user?.CustomerAge,
	]
		?.filter((v) => !!v)
		?.join(', ')

	return (
		<div className={s.controls} id="network-profile-controls">
			<div className={s.nav} onClick={onBack}>
				<div style={{ display: 'flex' }}>
					<ArrowShortIcon style={{ transform: 'rotate(180deg)' }} />
				</div>
				<span>{t('APP_BACK')}</span>
			</div>
			<div className={s.shortData}>
				<div className={s.avatar}>
					<img
						src={user.CustomerImageSquareUrl || '/placeholder.png'}
						alt={user?.ContentTitle}
					/>
				</div>
				<div>
					<h4 className="h4-b">{user?.ContentTitle}</h4>
					<div className={s.info}>
						<CountryFlag code={iso2} />
						<span>{infoValues}</span>
					</div>
				</div>
			</div>
			<div className={s.navWrapper}>
				<div
					className={classNames(s.nav, { [s.hide]: !onPrev })}
					onClick={() => {
						if (onPrev) onPrev()
					}}>
					<div style={{ display: 'flex' }}>
						<ArrowShortIcon style={{ transform: 'rotate(180deg)' }} />
					</div>
				</div>
				<div
					className={classNames(s.nav, { [s.hide]: !onNext })}
					onClick={() => {
						if (onNext) onNext()
					}}>
					<div style={{ display: 'flex' }}>
						<ArrowShortIcon />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Navigation

export const BodyIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M14.25 6.5C14.25 7.48438 13.457 8.25 12.5 8.25C11.5156 8.25 10.75 7.48438 10.75 6.5C10.75 5.54297 11.5156 4.75 12.5 4.75C13.457 4.75 14.25 5.54297 14.25 6.5ZM11.4883 9.42578C11.8164 9.50781 12.1445 9.5625 12.5 9.5625C13.5938 9.5625 14.6055 9.01562 15.207 8.11328L15.6992 7.34766C15.9453 6.9375 16.4922 6.82812 16.9023 7.10156C17.3125 7.34766 17.4219 7.89453 17.1484 8.30469L16.6562 9.07031C16.1641 9.83594 15.4805 10.4375 14.6875 10.8203V17.875C14.6875 18.3672 14.2773 18.75 13.8125 18.75C13.3203 18.75 12.9375 18.3672 12.9375 17.875V15.25H12.0625V17.875C12.0625 18.3672 11.6523 18.75 11.1875 18.75C10.6953 18.75 10.3125 18.3672 10.3125 17.875V10.8203C9.51953 10.4375 8.83594 9.86328 8.34375 9.09766L7.82422 8.30469C7.55078 7.89453 7.66016 7.375 8.07031 7.10156C8.45312 6.82812 9 6.9375 9.27344 7.34766L9.82031 8.14062C10.2031 8.71484 10.7773 9.15234 11.4062 9.39844C11.4336 9.39844 11.4609 9.39844 11.4883 9.42578Z"
			fill={color}
		/>
	</svg>
)

import { useShallow } from 'zustand/react/shallow'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
	CircleIcon,
	DownloadIcon,
	LikeIcon,
	MessageIcon,
	PlusIcon,
	StarIcon,
	VisibilityIcon,
} from 'assets/icons'
import { addRelation } from 'api/relations'
import { getDownloadUrl, partialContentUpdate } from 'api/content'
import Tooltip from 'components/Tooltip'
import useContent from 'hooks/api/useContent'
import useRelation from 'hooks/api/useRelation'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useCastingStore, useUserStore } from 'store'
import { CASTING_STATUS, RELATIONS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'
import s from '../../index.module.scss'

const Action = styled('div')<{ color: string; active: boolean }>(
	({ active, color }) => ({
		'& > svg > path': {
			fill: active ? `${color} !important` : 'inherit',
		},
		'&:hover': {
			'& > svg > path': {
				fill: `${color} !important`,
			},
			backgroundColor: `color-mix(in srgb, ${color} 15%, transparent) !important`,
		},
	}),
)

interface Props {
	assetId?: string
	contentId?: string
}

const Actions = ({ contentId, assetId }: Props): JSX.Element => {
	const { roleId, userId } = useParams()
	const { relation, refresh } = useRelation(
		RELATIONS.ACCESS,
		userId || '',
		roleId || '',
	)
	const director = useUserStore(
		useShallow(
			(state) =>
				state?.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)

	const status = relation?.status

	const onActionClick = async (status: CASTING_STATUS): Promise<void> => {
		try {
			await addRelation({
				sourceId: roleId,
				targetId: userId || '',
				type: 'Content',
				relation: RELATIONS.ACCESS,
				status: status,
			})
			await refresh()
		} catch (error) {
			console.log('error:', error)
		} finally {
			EventBus.$emit('refreshCastingCandidates')
		}
	}

	const openMessageSidebar = (): void => {
		const { setOpenMessageToActorSidebar } = useCastingStore.getState()
		setOpenMessageToActorSidebar(true)
	}

	const downloadAsset = async (
		contentId: string,
		assetId: string,
	): Promise<void> => {
		try {
			const assetUrl = await getDownloadUrl(
				contentId,
				assetId,
				// 'ad226f4b-4474-4931-ab40-6cbd2461e048',
			)
			const a = document.createElement('a')
			a.href = assetUrl
			a.download = 'true'
			a.target = '_blank'
			a.click()
		} catch (error) {
			console.log('error:', error)
		}
	}

	const liked = status === CASTING_STATUS.LIKED
	const disliked = status === CASTING_STATUS.DISLIKED
	const fav = status === CASTING_STATUS.FAVORITE
	const neutral = status === CASTING_STATUS.NEUTRAL

	const showAnimation = (type: string): void =>
		EventBus.$emit(`casting${type}ActionAnimation`)

	return (
		<>
			<Tooltip
				title={
					disliked
						? 'APP_REMOVE_FROM_DISLIKED_TOOLTIP'
						: 'APP_ADD_TO_DISLIKED_TOOLTIP'
				}>
				<Action
					color="var(--error)"
					active={disliked}
					className={s.control}
					onClick={() => {
						onActionClick(
							disliked ? CASTING_STATUS.NEUTRAL : CASTING_STATUS.DISLIKED,
						)
						if (!disliked) showAnimation('Dislike')
					}}>
					<LikeIcon
						color="var(--error)"
						style={{ transform: 'rotate(180deg)' }}
					/>
				</Action>
			</Tooltip>
			<Tooltip
				title={
					neutral
						? 'APP_REMOVE_FROM_NEUTRAL_TOOLTIP'
						: 'APP_ADD_TO_NEUTRAL_TOOLTIP'
				}
				wrapperStyles={{ position: 'relative' }}>
				<>
					<Action
						active={neutral}
						color="var(--mono100)"
						className={s.control}
						onClick={() =>
							neutral ? null : onActionClick(CASTING_STATUS.NEUTRAL)
						}>
						<CircleIcon />
					</Action>
					<div
						style={{
							width: '16px',
							height: '16px',
							borderRadius: '50%',
							overflow: 'hidden',
							position: 'absolute',
							backgroundColor: neutral ? 'var(--mono100)' : 'transparent',
							transition: 'var(--transition)',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
						}}
					/>
				</>
			</Tooltip>
			<Tooltip
				title={
					liked ? 'APP_REMOVE_FROM_LIKED_TOOLTIP' : 'APP_ADD_TO_LIKED_TOOLTIP'
				}>
				<Action
					active={liked}
					color="var(--success)"
					className={s.control}
					onClick={() => {
						onActionClick(liked ? CASTING_STATUS.NEUTRAL : CASTING_STATUS.LIKED)
						if (!liked) showAnimation('Like')
					}}>
					<LikeIcon />
				</Action>
			</Tooltip>
			<Tooltip
				title={
					fav
						? 'APP_REMOVE_FROM_FAVORITE_TOOLTIP'
						: 'APP_ADD_TO_FAVORITE_TOOLTIP'
				}>
				<Action
					active={fav}
					color="var(--warning)"
					className={s.control}
					onClick={() => {
						onActionClick(
							fav ? CASTING_STATUS.NEUTRAL : CASTING_STATUS.FAVORITE,
						)
						if (!fav) showAnimation('Fav')
					}}>
					<StarIcon />
				</Action>
			</Tooltip>

			<Tooltip title="APP_CASTING_FEEDBACK_TOOLTIP">
				<div className={s.control} onClick={openMessageSidebar}>
					<MessageIcon />
				</div>
			</Tooltip>
			<Tooltip title="APP_CALLBACK">
				<div
					className={s.control}
					onClick={() => {
						const { setOpenAddSceneSidebar } = useAppStore.getState()
						setOpenAddSceneSidebar(true)
					}}>
					<PlusIcon />
				</div>
			</Tooltip>
			{contentId && assetId ? (
				<Tooltip title="APP_DOWNLOAD_ASSET">
					<div
						className={s.control}
						onClick={() => downloadAsset(contentId, assetId)}>
						<DownloadIcon />
					</div>
				</Tooltip>
			) : null}
			{director && contentId ? <HideContent contentId={contentId} /> : null}
		</>
	)
}

export default Actions

const HideContent = ({ contentId }: { contentId: string }): JSX.Element => {
	const { content, refreshData } = useContent(contentId, 'bag')

	const hided = content?.ContentCastingHide === 'true'

	const hideContent = async (contentId: string): Promise<void> => {
		try {
			await partialContentUpdate(contentId, {
				properties: {
					'Content:Casting:Hide': hided ? false : true,
				},
			})
			setTimeout(() => {
				refreshData()
			}, 1000)
		} catch (error) {
			console.log('error:', error)
		}
	}

	return (
		<Tooltip title="APP_HIDE_CONTENT_TOOLTIP">
			<div className={s.control} onClick={() => hideContent(contentId)}>
				<VisibilityIcon
					color={!hided ? 'var(--mono100) !important' : 'var(--mono300)'}
				/>
			</div>
		</Tooltip>
	)
}

import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { FetchReturn, RelationListResponse, Relation } from 'types/app'
import { RELATIONS } from 'types/enums'
import { useAppStore } from 'store'
import { getUser } from 'helpers/storage'
import { KeyedMutator } from 'swr'

type ReturnData = FetchReturn & {
	data: Relation[] | undefined
	refresh: KeyedMutator<RelationListResponse>
}

export default (query = '', userId = '', stopFetch = false): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const relationUrl = endpoints?.CustomApplicationV3ConnectionPostUrl
		? endpoints?.CustomApplicationV3ConnectionPostUrl +
		  '/' +
		  (userId || getUser()?.['Custom:User:Id']) +
		  '/Content'
		: null

	const url = relationUrl + '/' + RELATIONS.ACCESS + query

	const { data, isLoading, error, mutate } =
		useSwrImmutable<RelationListResponse>(
			url && !stopFetch ? url : null,
			fetcher,
			{
				shouldRetryOnError: false,
			},
		)

	return {
		data: data?.pageContent?.length ? data.pageContent : undefined,
		isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		refresh: mutate,
	}
}

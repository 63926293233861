import { fetcher, fileFetcher } from 'api'
import { useAppStore } from 'store'
import {
	CollectionsType,
	Content,
	CreateContent,
	GenerateImages,
	PropertiesType,
	RelationListResponse,
} from 'types/app'

export const createContent = (data: {
	[key: string]:
		| string
		| boolean
		| {
				[key: string]:
					| string
					| string[]
					| {
							[key: string]:
								| string
								| {
										[key: string]: string
								  }
					  }
		  }
}): Promise<{ id: string }> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationContentUrl
		? endpoints?.CustomApplicationContentUrl
		: ''

	return fetcher(url, {
		method: 'post',
		body: JSON.stringify({
			...data,
			originalLanguage: 'en-US',
		}),
	})
}

export const createMarker = (data: {
	type: string
	position: string
	contentId: string
}): Promise<{ id: string }> => {
	const url = 'https://qbg.theatroo.ent360.blue/api/v1.0/Marker'

	return fetcher(url, {
		method: 'post',
		body: JSON.stringify(data),
	})
}

export const createContentUpload = (
	data: CreateContent,
): Promise<
	CreateContent & {
		id: string
	}
> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationContentUrl
		? endpoints?.CustomApplicationContentUrl + '/Ingest'
		: ''

	return fetcher(url, { method: 'post', body: JSON.stringify(data) })
}

export const copyContent = (
	contentId: string,
	data: {
		ownerId: string
		keepAssets: boolean
		nameSuffix?: string
		namePrefix?: string
		keepRelationships?: boolean
	},
): Promise<
	CreateContent & {
		id: string
	}
> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationContentUrl
		? endpoints?.CustomApplicationContentUrl + '/' + contentId + '/Copy'
		: ''

	return fetcher(url, { method: 'post', body: JSON.stringify(data) })
}

export const createContentAsset = (
	data: {
		[key: string]:
			| string
			| Date
			| {
					[key: string]:
						| string
						| {
								[key: string]: string
						  }
			  }
	},
	contentId: string,
): Promise<
	CreateContent & {
		id: string
	}
> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationContentUrl
		? endpoints?.CustomApplicationContentUrl + '/' + contentId + '/Asset/Ingest'
		: ''

	return fetcher(url, { method: 'post', body: JSON.stringify(data) })
}

export const editContentAsset = (
	data: {
		originalFileName: string
		objectUrl: string
		isPublic: boolean
		publicUrl: string
		releaseDate: string
		subType: string
		type: string
		locale: string
		uploadedByUser: boolean
	},
	contentId: string,
): Promise<{
	id: string
}> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationContentUrl
		? endpoints?.CustomApplicationContentUrl + '/' + contentId + '/Reingest'
		: ''

	return fetcher(url, {
		method: 'post',
		body: JSON.stringify(data),
	})
}

export const partialContentUpdate = (
	id: string,
	data: {
		[key: string]: any
	},
	query = '',
	strategy = '',
): Promise<Content> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationContentUrl
		? endpoints?.CustomApplicationContentUrl +
		  '/' +
		  id +
		  '/Partial' +
		  query +
		  strategy
		: ''

	return fetcher(url, { method: 'put', body: JSON.stringify(data) })
}

export const contentUpdate = (
	id: string,
	data: {
		[key: string]:
			| string
			| number
			| {
					[key: string]:
						| string
						| {
								[key: string]: string
						  }
			  }
	},
): Promise<Content> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationContentUrl
		? endpoints?.CustomApplicationContentUrl + '/' + id
		: ''

	return fetcher(url, { method: 'put', body: JSON.stringify(data) })
}

export const contentDelete = (id: string, query = ''): Promise<void> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationContentUrl
		? endpoints?.CustomApplicationContentUrl + '/' + id + query
		: ''

	return fetcher(url, { method: 'delete' })
}

export const generateAIImages = (keyWord: string): Promise<GenerateImages> => {
	const url = 'https://ygv.theatroo.ent360.blue/api/v1.0/Image/Generate/Images'

	return fetcher(url, {
		method: 'post',
		body: JSON.stringify({ text: keyWord }),
	})
}

export const batchUpload = (
	file: File,
	orgId: string,
	message: string,
): Promise<string> => {
	const url =
		'https://bgj.theatroo.ent360.blue/api/v2.1/Customer/Batch/File' +
		`?userType=Creator&role=Actor&organizationId=${orgId}&emailTemplateId=GenericMessage&message=${encodeURIComponent(
			message,
		)}&subject=[YourName] invited to join the Showrealz`

	const formData = new FormData()
	formData.append('file', file)

	return fileFetcher(url, {
		method: 'post',
		body: formData,
	})
}

export const batchListUpload = (
	users: PropertiesType[][],
	orgId: string,
	message: string,
): Promise<string> => {
	const url =
		'https://bgj.theatroo.ent360.blue/api/v2.1/Customer/Batch' +
		`?userType=Creator&role=Actor&organizationId=${orgId}&emailTemplateId=GenericMessage&message=${encodeURIComponent(
			message,
		)}&subject=[YourName] invited to join the Showrealz`

	return fetcher(url, {
		method: 'post',
		body: JSON.stringify(users),
	})
}

export const getPlaylistItems = async (
	content: Content,
): Promise<RelationListResponse> => {
	const url = `https://nup.theatroo.ent360.blue/api/v3.0/${content?.ContentId}/content/playlist`

	return fetcher(url, { method: 'get' })
}

export const getContent = async (
	contentId: string,
): Promise<CollectionsType> => {
	const { endpoints } = useAppStore.getState()

	const url =
		endpoints?.CustomApplicationV3ContentUrl.replace(
			'${contentId}',
			contentId,
		) || ''

	return fetcher(url, { method: 'get' })
}

export const replaceAsset = async (
	targetId: string,
	sourceId: string,
): Promise<CreateContent> => {
	const url = `https://ane.theatroo.ent360.blue/api/v4.0/Content/${targetId}/Replace/${sourceId}`

	return fetcher(url, { method: 'post' })
}

export const getDownloadUrl = async (
	contentId: string,
	assetId: string,
): Promise<string> => {
	const { endpoints } = useAppStore.getState()
	const url =
		endpoints?.CustomApplicationContentUrl +
		`/${contentId}/Asset/${assetId}/Download`

	return fetcher(url, { method: 'get' })
}

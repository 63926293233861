import { VisibilityIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
	visible: boolean
	onChange: (v: { [key: string]: boolean }) => void
}

const Visibility = ({ visible, onChange }: Props): JSX.Element => {
	const onVisible = (): void => {
		onChange({ visible: !visible })
	}

	return (
		<Tooltip
			title={
				visible ? 'APP_VISIBILITY_OFF_CONTROL' : 'APP_VISIBILITY_ON_CONTROL'
			}>
			<div className={s.control} onClick={onVisible}>
				<VisibilityIcon
					color={visible ? 'var(--mono300)' : 'var(--mono100) !important'}
				/>
			</div>
		</Tooltip>
	)
}

export default Visibility

import { useEffect, useState } from 'react'
import cn from 'classnames'
import LaunchApp from '../LaunchApp'
import HomeTabs from '../../../Tabs/HomeTabs'
import { EventBus } from 'helpers/EventBus'
import s from './index.module.scss'

const HomeMenu = (): JSX.Element => {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

	useEffect(() => {
		EventBus.$on('closeHomeMenu', () => {
			setIsMobileMenuOpen(false)
		})

		return () => {
			EventBus.$off('closeHomeMenu')
		}
	}, [])

	useEffect(() => {
		if (isMobileMenuOpen) document.body.style.overflow = 'hidden'
		else document.body.style.overflow = 'unset'
	}, [isMobileMenuOpen])

	return (
		<div className={s.container}>
			<div className={s.launch}>
				<LaunchApp />
			</div>
			<div
				className={cn(s.burger, { [s.open]: isMobileMenuOpen })}
				onClick={() => setIsMobileMenuOpen((prev) => !prev)}>
				<div />
				<div />
				<div />
			</div>
			<div className={cn(s.homeNav, { [s.openMenu]: isMobileMenuOpen })}>
				<div className={s.menu}>
					<HomeTabs classname={s.menuTabs} />
				</div>
			</div>
		</div>
	)
}

export default HomeMenu

import ReactTimeAgo from 'react-time-ago'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { TimeIcon } from 'assets/icons'
import {
	HHMMSSToMs,
	HHMMSSToSec,
	isWeekDifference,
} from 'helpers/dateTimeConfig'
import { textParser } from 'helpers'
import { ExtendedMessage } from 'types/chat'
import s from './index.module.scss'

interface Props {
	message: ExtendedMessage
}

const Message = ({ message }: Props): JSX.Element => {
	const navigate = useNavigate()

	const seek = (): void => {
		const videos = document.querySelectorAll(
			'video[data-scene-video]',
		) as unknown as HTMLVideoElement[]

		const index = [...videos].findIndex(
			(v) => v?.id === 'rolescene_' + message?.content?.playerContentId,
		)
		const video = videos[index]
		if (
			video?.hasAttribute('[data-active=true]') &&
			message?.content?.playerTimestamp
		) {
			video.currentTime = HHMMSSToSec(message.content.playerTimestamp)
		} else {
			navigate(
				window.location.pathname +
					(window.location.hash?.slice(0, -1) + (index + 1)),
			)
			if (message?.content?.playerTimestamp)
				video.currentTime = HHMMSSToSec(message.content.playerTimestamp)
		}
	}

	return (
		<div
			className={s.container}
			data-timestamp={
				message.content.playerTimestamp
					? 'time_' + HHMMSSToMs(message.content?.playerTimestamp?.toString())
					: ''
			}>
			<div className={s.header}>
				<div
					className={s.avatar}
					style={{
						border: `1px solid var(--${message?.sender?.CustomerRole?.toLowerCase()})`,
					}}>
					<img
						src={message?.sender?.CustomerImageSquareUrl || '/placeholder.png'}
						alt={message?.sender?.ContentTitle}
					/>
				</div>
				<span className="body2-m" style={{ marginRight: 'auto' }}>
					{message?.sender?.ContentTitle}
				</span>

				{message.content.playerTimestamp ? (
					<div className={s.time} onClick={seek}>
						<TimeIcon width="22" height="22" color="var(--theme-primary)" />
						<span className="body2-b" style={{ color: 'var(--theme-primary)' }}>
							{message.content.playerTimestamp?.toString()}
						</span>
					</div>
				) : null}
			</div>
			{textParser(message.content.body)?.map((text) => (
				<span
					className="body2-m"
					style={{ color: 'var(--mono200)' }}
					key={text}>
					{text}
				</span>
			))}
			<span className="caption-m" style={{ color: 'var(--mono400' }}>
				{isWeekDifference(new Date(message.sendTime)) ? (
					dayjs(message.sendTime).format('MM/DD')
				) : (
					<ReactTimeAgo
						date={new Date(message.sendTime)}
						timeStyle="twitter"
						locale="en-US"
					/>
				)}
			</span>
		</div>
	)
}

export default Message

import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import cn from 'classnames'
import { InfoOutlinedIcon } from 'assets/icons'
import ListWrapper from '../ListWrapper'
import useContent from 'hooks/api/useContent'
import useCollection from 'hooks/api/useCollection'
import { optionsParser } from 'helpers'
import { filterDuplicates } from 'helpers/propertiesParser'
import { useAppStore, useCastingStore, useUserStore } from 'store'
import { DROPDOWN_OPTIONS } from 'types/enums'
import { Content } from 'types/app'
import { PATHS } from 'pages/Router'
import s from './index.module.scss'

const Roles = (): JSX.Element => {
	const { productionId, roleId } = useParams()
	const navigate = useNavigate()
	const [search, setSearch] = useState('')
	const [filter, setFilter] = useState('')

	const { content, isLoading: loading } = useContent(productionId)
	const { collection = [], isLoading } = useCollection(content)

	const tabs = [
		{
			label: optionsParser(DROPDOWN_OPTIONS.ROLE_STATUS)?.[0]?.label,
			value: optionsParser(DROPDOWN_OPTIONS.ROLE_STATUS)?.[0]?.value,
		},
		{
			label: optionsParser(DROPDOWN_OPTIONS.ROLE_STATUS)?.[1]?.label,
			value: optionsParser(DROPDOWN_OPTIONS.ROLE_STATUS)?.[1]?.value,
		},
		{
			label: optionsParser(DROPDOWN_OPTIONS.ROLE_STATUS)?.[2]?.label,
			value: optionsParser(DROPDOWN_OPTIONS.ROLE_STATUS)?.[2]?.value,
		},
	]

	const onClick = (content: Content): void => {
		const { setCastingLoading, setOpenCastingRoles } =
			useCastingStore.getState()
		setCastingLoading(true)
		setOpenCastingRoles(false)
		const { user } = useUserStore.getState()
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${productionId}/${
				content.ContentId
			}` + window.location.hash,
		)
		setTimeout(() => {
			setCastingLoading(false)
		}, 500)
	}

	const list = useMemo(
		() =>
			filterDuplicates<Content>(collection || [], 'ContentId')
				?.filter((content) =>
					filter ? content?.ContentRoleType === filter : content,
				)
				?.filter((content) =>
					content?.['Globalizationen-USContentTitle']
						?.toLowerCase()
						.includes(search.toLowerCase()),
				) as unknown as Content[],
		[search, collection, filter],
	)

	return (
		<ListWrapper
			type="roles"
			tabs={tabs}
			setSearch={setSearch}
			filter={filter}
			setFilter={setFilter}
			style={{
				backgroundColor: 'color-mix(in srgb, var(--mono000) 40%, transparent)',
			}}>
			<>
				{!loading && !isLoading
					? list?.map((content) => (
							<div
								className={cn(s.role, {
									[s.active]: roleId === content.ContentId,
								})}
								key={content?.ContentId}>
								<div className={s.avatar} onClick={() => onClick(content)}>
									<img
										src={content?.ContentSquareImageUrl || '/placeholder.png'}
										alt={content?.ContentTitle}
									/>
								</div>
								<div className={s.info} onClick={() => onClick(content)}>
									<span className="body1-m textEllipsis">
										{content?.['Globalizationen-USContentTitle']}
									</span>
									<span
										className="body2-r"
										style={{ color: 'var(--mono200)' }}></span>
								</div>
								{roleId === content.ContentId ? (
									<div
										onClick={() => {
											const { setRoleInfoSidebar } = useAppStore.getState()
											setRoleInfoSidebar(content.ContentId)
										}}
										style={{
											display: 'flex',
											cursor: 'pointer',
											marginLeft: 'auto',
										}}>
										<InfoOutlinedIcon />
									</div>
								) : null}
							</div>
					  ))
					: null}
			</>
		</ListWrapper>
	)
}

export default Roles

import { capitalize, optionsParser, t } from 'helpers'
import { useUserStore } from 'store'
import { User } from 'types/user'
import { AttributeCardType } from 'types/app'
import s from './index.module.scss'

interface Props {
	user: User
	field: AttributeCardType
}

const TagsItem = ({ user, field }: Props): JSX.Element => {
	const values = JSON.parse(
		(user?.[
			field?.selectors[0]?.name?.replaceAll(':', '') as keyof User
		] as string) || '[]',
	) as string[]

	const options = optionsParser(field?.selectors[0].optionName || '')

	return values?.length ? (
		<div className={s.tagsWrapper}>
			<span className="body1-b" style={{ color: 'var(--mono200)' }}>
				{t(field.selectors[0].label)}
			</span>
			<div className={s.tags}>
				{values?.map((value, index) => (
					<div
						key={index}
						className={s.tag}
						onClick={() => {
							const { setActiveAttribute } = useUserStore.getState()
							setActiveAttribute(
								`${capitalize(field.value)}:${
									options?.find((option) => option.value === value)?.value
								}`,
							)
						}}>
						<span>
							{options?.find((option) => option.value === value)?.label}
						</span>
					</div>
				))}
			</div>
		</div>
	) : (
		<></>
	)
}
export default TagsItem

import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import Select from 'components/UI/Select'
import { optionsParser, t } from 'helpers'
import { usePortfolioStore, useUserStore } from 'store'
import { personalFields } from '../../fields'
import { CUSTOMER_ROLE } from 'types/user'

const Personal = (): JSX.Element => {
	const values = usePortfolioStore(
		useShallow((state) => state.cardValues['personal']),
	)
	const actor = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.ACTOR,
		),
	)

	return (
		<Card step="personal">
			<div className="grid2column">
				{Object.values(personalFields)
					?.filter((field) =>
						!actor &&
						[
							personalFields.disability.name,
							personalFields.race.name,
							personalFields.sex.name,
						].includes(field.name)
							? false
							: true,
					)
					?.map((field) => (
						<Select
							key={field.name}
							options={optionsParser(t(field.optionName || ''))}
							name={field.name}
							label={field.label}
							onChange={(e) => {
								const { setCardValues } = usePortfolioStore.getState()
								setCardValues(
									{
										[field.name]: e.target.value,
									},
									'personal',
								)
								const { updateFields } = usePortfolioStore.getState()
								updateFields([
									{
										Name: field.name,
										Value: e.target.value,
									},
								])
							}}
							inputProps={{
								value: (values[field.name] as string) || '',
							}}
						/>
					))}
			</div>
		</Card>
	)
}

export default Personal

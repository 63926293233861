import s from './index.module.scss'

const WizardWrapper = ({
	children,
}: {
	children: JSX.Element
}): JSX.Element => (
	<div className={s.container}>
		<div className={s.wrapper}>{children}</div>
	</div>
)

export default WizardWrapper

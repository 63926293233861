import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { Document, Page, pdfjs } from 'react-pdf'
import Sidebar from 'components/Sidebar'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { useAppStore } from 'store'
import s from './index.module.scss'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const DocumentViewerSidebar = (): JSX.Element => {
	const [numPages, setNumPages] = useState(0)
	const [sidebar, setSidebar] = useAppStore(
		useShallow((state) => [
			state.documentViewerSidebar,
			state.setDocumentViewerSidebar,
		]),
	)

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
		setNumPages(numPages)
	}

	const getPageWidth = (): number | undefined => {
		const maxSize = 1180
		if (window.innerWidth < maxSize) {
			return window.innerWidth - 480 - 88
		}

		return undefined
	}

	return (
		<Sidebar
			open={!!sidebar}
			onClose={() => setSidebar(null)}
			additional
			style={{
				gap: '12px',
				height: '100%',
			}}
			iconClose
			containerStyle={{
				maxWidth: 'calc(786px + 88px)',
				width: 'fit-content',
				transform: sidebar
					? 'translateX(calc(var(--sidebarWidth) *-1))'
					: 'translateX(100%)',
			}}>
			<div className={s.container}>
				<Document file={sidebar?.link} onLoadSuccess={onDocumentLoadSuccess}>
					{numPages ? (
						Array.from({ length: numPages }).map((_, index) => (
							<Page
								pageNumber={index + 1}
								width={sidebar ? getPageWidth() : undefined}
								key={index}
							/>
						))
					) : (
						<></>
					)}
				</Document>
				{sidebar?.buttonClick ? (
					<div className={s.buttonWrapper}>
						<Button
							variant="filled"
							theme="white"
							onClick={() => {
								if (sidebar.buttonClick) sidebar.buttonClick()
							}}>
							{t(sidebar?.buttonText || '')}
						</Button>
					</div>
				) : null}
			</div>
		</Sidebar>
	)
}

export default DocumentViewerSidebar

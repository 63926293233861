import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import {
	CastingIcon,
	EditIcon,
	FilterIcon,
	PeopleIcon,
	PlusIcon,
} from 'assets/icons'
import { contentDelete, getPlaylistItems } from 'api/content'
import Card from 'components/Wizard/Card'
import ProductionCard from 'components/ProductionCard'
import Button from 'components/UI/Button'
import Loader from 'components/Loader'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import Recent from './components/Recent'
import useContentSearch from 'hooks/api/useContentSearch'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useCollection from 'hooks/api/useCollection'
import useRelations from 'hooks/api/useRelations'
import useContents from 'hooks/api/useContents'
import { t, wait } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useModalStore, useUserStore } from 'store'
import { RELATIONS } from 'types/enums'
import { Content } from 'types/app'
import { CUSTOMER_ROLE } from 'types/user'
import { PATHS } from 'pages/Router'
import { directorCastingSteps } from '../../CastingDirector/DirectorCasting'
import s from './index.module.scss'

const ContentWrapper = (): JSX.Element => {
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const { hash } = useLocation()
	const reloadProjects = useRef(false)
	const [search, status] = useAppStore(
		useShallow((state) => [
			state.localSearchSidebar?.search,
			state.localSearchSidebar?.status,
		]),
	)
	const [director, userId] = useUserStore(
		useShallow((state) => [
			state.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
			state.userId,
		]),
	)
	const { contents, refresh, isLoading } = useContentSearch(
		director,
		'Collection',
		search,
	)

	const { data } = useRelationsContent(
		director ? [RELATIONS.FAVCOLLECTION] : [],
		undefined,
		'Customer',
		'?reverse=true&orderBy=UpdatedDate&itemLimit=10',
	)
	const favContent = data?.[RELATIONS.FAVCOLLECTION]

	const { data: invites } = useRelations(
		RELATIONS.INVITE,
		!director ? userId || '' : '',
		'/Lookup',
	)
	const { contents: invitedProjects } = useContents(
		Array.isArray(invites) ? invites?.map((rel) => rel.sourceId) : [],
	)

	useEffect(() => {
		if (
			!reloadProjects?.current &&
			userId &&
			contents?.length &&
			contents?.some((content) => content.ContentOwnerId !== userId)
		) {
			reloadProjects.current = true
			refresh()
		}
	}, [contents, userId, refresh])

	useEffect(() => {
		if (hash?.includes(directorCastingSteps.myproduction.key + '-0'))
			EventBus.$emit('refreshMyProduction')
		else {
			const { localSearchSidebar, setLocalSearchSidebar } =
				useAppStore.getState()
			if (localSearchSidebar?.search) setLocalSearchSidebar(null)
		}
	}, [hash])

	useEffect(() => {
		EventBus.$on('refreshMyProduction', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshMyProduction')
		}
	}, [refresh])

	const openProduction = (prod: Content): void => {
		if (prod?.ContentSubType === 'Collection') {
			const { user } = useUserStore.getState()
			const { stepWizard } = useAppStore.getState()
			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${
					PATHS.PROJECT
				}/${prod.ContentId}`,
			)
			stepWizard?.nextStep()

			addRelation({
				relation: RELATIONS.FAVCOLLECTION,
				targetId: prod.ContentId,
				type: 'Customer',
			})
		}
	}

	const openRoles = (prod: Content): void => {
		if (prod?.ContentSubType === 'Collection') {
			const { user } = useUserStore.getState()
			const { stepWizard } = useAppStore.getState()
			stepWizard?.goToStep(stepWizard.currentStep + 3)
			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${
					PATHS.PROJECT
				}/${prod.ContentId}#` +
					directorCastingSteps.myproduction?.substeps?.[3]?.key +
					'-' +
					directorCastingSteps.myproduction?.substeps?.[3]?.index,
			)

			addRelation({
				relation: RELATIONS.FAVCONTENT,
				targetId: prod.ContentId,
				type: 'Customer',
			})
		}
	}

	const openProductionCreation = (): void => {
		const { user } = useUserStore.getState()
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${PATHS.PROJECT}`,
		)
		const { stepWizard } = useAppStore.getState()
		stepWizard?.nextStep()
	}

	const openCasting = (prodId: string, roleId: string): void => {
		const { user } = useUserStore.getState()
		const { stepWizard } = useAppStore.getState()
		stepWizard?.goToStep(2)
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${
				PATHS.CASTING
			}/${prodId}/${roleId}#` +
				directorCastingSteps.board.key +
				'-' +
				1,
		)
	}

	return (
		<Card
			step="myproductions"
			noHint
			headerChildrens={
				<Button
					style={{
						gap: '8px',
						color: 'var(--mono100)',
						height: 'fit-content',
						paddingRight: '26px',
					}}
					onClick={() => {
						const { setLocalSearchSidebar, localSearchSidebar } =
							useAppStore.getState()
						setLocalSearchSidebar({
							open: true,
							search: localSearchSidebar?.search || '',
							type: 'myproductions',
						})
					}}>
					{t('APP_SHOW_FILTERS')}
					<FilterIcon
						color={search || status ? 'var(--theme-primary)' : undefined}
					/>
				</Button>
			}
			wrapperStyle={{
				minHeight: 'var(--contentCardHeight)',
			}}>
			<>
				<Loader loading={isLoading || loading} adapt />
				{favContent?.length ? (
					<Recent
						contents={favContent}
						openCasting={openCasting}
						openProduction={openProduction}
					/>
				) : null}
				<span className="body1-b" style={{ paddingLeft: '20px' }}>
					{t('APP_PRODUCTIONS_LIST')}
				</span>
				<div className={s.gridWrapper}>
					{director ? (
						<CreateCardPlaceholder
							onClick={openProductionCreation}
							label="APP_ADD_PROJECT_LABEL"
						/>
					) : null}
					{(director ? contents : invitedProjects)?.length ? (
						(director ? contents : invitedProjects)
							?.filter((content) => {
								if (status) {
									const rehearsalDate =
										content?.ContentEventRehearsalDate?.includes('[')
											? (JSON.parse(
													content?.ContentEventRehearsalDate,
											  ) as string[])
											: ''

									const rehDateDiff = [
										dayjs(rehearsalDate[0]).diff(dayjs(), 'day'),
										dayjs(rehearsalDate[1]).diff(dayjs(), 'day'),
									]

									if (status === 'expired')
										return rehDateDiff[0] < 0 && rehDateDiff[1] < 0
									else if (status === 'active')
										return rehDateDiff[0] < 0 && rehDateDiff[1] > 0
									else if (status === 'upcoming')
										return rehDateDiff[0] > 0 && rehDateDiff[1] > 0
								} else return true
							})
							?.map((content) => (
								<ProductionItem
									key={content.ContentId}
									content={content}
									openProduction={openProduction}
									openCasting={openCasting}
									openRoles={openRoles}
									setLoading={setLoading}
								/>
							))
					) : !director ? (
						<span>{t('APP_RESULT_NOT_FOUND')}</span>
					) : null}
				</div>
			</>
		</Card>
	)
}

const MyProductions = (): JSX.Element => {
	const { hash } = useLocation()

	return hash?.slice(1).includes(directorCastingSteps.myproduction.key) ? (
		<ContentWrapper />
	) : (
		<></>
	)
}

export default MyProductions

const ProductionItem = ({
	content,
	openProduction,
	openCasting,
	openRoles,
	setLoading,
}: {
	content: Content
	openProduction: (c: Content) => void
	openCasting: (prodId: string, roleId: string) => void
	openRoles: (c: Content) => void
	setLoading: (lod: boolean) => void
}): JSX.Element => {
	const { collection = [], refresh } = useCollection(content, true)
	const role = collection?.[0]

	const deleteProduction = async (): Promise<void> => {
		setLoading(true)
		try {
			const scenes = await Promise.all(
				collection?.map((content) => getPlaylistItems(content)),
			)
			const sceneIds = scenes
				?.map((relRes) => relRes.pageContent.map((rel) => rel.targetId))
				.flat()
			const roleIds = collection?.map((c) => c.ContentId)
			await Promise.all(
				[...sceneIds, ...roleIds, content.ContentId].map((id) =>
					contentDelete(id),
				),
			)
			await wait(1000)
			EventBus.$emit('refreshMyProduction')
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
		}
	}

	const onDeleteClick = (): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<Card
					style={{
						width: 'fit-content',
					}}>
					<>
						<h4>{t('APP_CONFIRM_PRODUCTION_DELETE')}</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								onClick={async () => {
									await deleteProduction()
									setModal(null)
								}}>
								{t('APP_YES')}
							</Button>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</Card>
			),
		})
	}

	useEffect(() => {
		EventBus.$on('refreshProdRoles', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshProdRoles')
		}
	}, [refresh])

	return (
		<ProductionCard
			key={content.ContentId}
			content={content}
			children={
				<>
					<div
						className="hoverButtonWrapper"
						onClick={() => openProduction(content)}>
						<EditIcon />
						<span>{t('APP_OPEN_HOVER_LABEL')}</span>
					</div>
					{/* <div
						className="hoverButtonWrapper"
						onClick={() => {
							const { setProdInfoSidebar } = useAppStore.getState()
							setProdInfoSidebar(content.ContentId)
						}}>
						<InfoFilledIcon />
						<span>{t('APP_DETAILS_HOVER_LABEL')}</span>
					</div> */}
					<div
						className="hoverButtonWrapper"
						onClick={() => openCasting(content?.ContentId, role?.ContentId)}>
						<CastingIcon />
						<span>{t('APP_CASTINGS_HOVER_LABEL')}</span>
					</div>
					<div
						className="hoverButtonWrapper"
						onClick={() => openRoles(content)}>
						<PeopleIcon />
						<span>{t('APP_ROLES_HOVER_LABEL')}</span>
					</div>
					<div className="hoverButtonWrapper" onClick={onDeleteClick}>
						<PlusIcon style={{ transform: 'rotate(45deg)' }} />
						<span>{t('APP_DELETE_HOVER_LABEL')}</span>
					</div>
				</>
			}
		/>
	)
}

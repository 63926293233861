import { CSSProperties } from 'react'
import { StepWizardProps } from 'react-step-wizard'

const StepWrapper: React.FC<
	StepWizardProps & { children: JSX.Element; style?: CSSProperties }
> = ({ children, style }) => (
	<div
		className="cardWrapperSize"
		style={{
			// overflow: 'scroll',
			...style,
		}}>
		{children}
	</div>
)

export default StepWrapper

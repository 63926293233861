import { useState, useEffect } from 'react'

const useIntersectionObserver = (
	elementId: string,
	rootMargin: string,
	threshold: number,
): boolean => {
	const [isVisible, setVisible] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			const element = document.getElementById(elementId)
			const observer = new IntersectionObserver(
				([entry]) => {
					setVisible(entry.isIntersecting)
				},
				{ rootMargin, threshold },
			)

			element && observer.observe(element)

			if (element) return () => observer.unobserve(element)
		}, 1000)
	}, [elementId, rootMargin, threshold])

	return isVisible
}

export default useIntersectionObserver

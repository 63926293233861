export const PlayIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M17.1211 10.6289C17.5039 10.875 17.75 11.3125 17.75 11.75C17.75 12.2148 17.5039 12.6523 17.1211 12.8711L9.24609 17.6836C8.83594 17.9297 8.31641 17.957 7.90625 17.7109C7.49609 17.4922 7.25 17.0547 7.25 16.5625V6.9375C7.25 6.47266 7.49609 6.03516 7.90625 5.81641C8.31641 5.57031 8.83594 5.57031 9.24609 5.84375L17.1211 10.6289Z"
			fill={color}
		/>
	</svg>
)

import { useRef } from 'react'
import { getTrackBackground, Range } from 'react-range'
import { MinusIcon, PlusIcon } from 'assets/icons'
import InputLabel from '../InputLabel'
import s from '../RangeInput/index.module.scss'

interface Props {
	values: number[]
	label: string
	min: number
	max: number
	step: number
	onChange: (values: number[]) => void
	valueEnd?: string
}

const RangeSlider = ({
	values,
	min,
	max,
	step,
	onChange,
	label,
	valueEnd,
}: Props): JSX.Element => {
	const rangeInput = useRef<Range>(null)

	return (
		<div className={s.container}>
			{!label ? null : (
				<div className={s.labelWrapper}>
					<InputLabel
						label={label}
						status={values?.length > 1 ? 'filled' : 'none'}
					/>
					<span className="caption-m">
						{values?.join(' - ') + `${valueEnd ? ' ' + valueEnd : ''}`}
					</span>
				</div>
			)}
			<div className={s.wrapper}>
				<div
					onClick={() => {
						onChange([values?.[0] - 1, values[1]])
					}}>
					<MinusIcon color="var(--theme-primary)" />
				</div>
				<Range
					values={values}
					step={step}
					min={min}
					max={max}
					ref={rangeInput}
					onChange={(values) => {
						onChange(values)
					}}
					renderTrack={({ props, children }) => (
						<div
							onMouseDown={props.onMouseDown}
							onTouchStart={props.onTouchStart}
							style={{
								...props.style,
								height: '34px',
								display: 'flex',
								width: '100%',
								padding: '0',
							}}>
							<div
								ref={props.ref}
								style={{
									height: '6px',
									width: '100%',
									borderRadius: '25px',
									background: getTrackBackground({
										values,
										colors: [
											'var(--mono400)',
											'var(--theme-primary)',
											'var(--mono400)',
										],
										min: min,
										max: max,
									}),
									alignSelf: 'center',
								}}>
								{children}
							</div>
						</div>
					)}
					renderThumb={({ props }) => (
						<div
							{...props}
							key={props.key}
							style={{
								...props.style,
								height: '20px',
								width: '20px',
								borderRadius: '50%',
								backgroundColor: 'var(--theme-primary)',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}></div>
					)}
				/>
				<div
					onClick={() => {
						onChange([values?.[0], values[1] + 1])
					}}>
					<PlusIcon color="var(--theme-primary)" />
				</div>
			</div>
		</div>
	)
}

export default RangeSlider

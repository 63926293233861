import { useShallow } from 'zustand/react/shallow'
import {
	Route,
	Routes,
	useLocation,
	Navigate,
	Outlet,
	To,
} from 'react-router-dom'
import Layout from 'components/Layout'
import { getUser } from 'helpers/storage'
import { useAppStore, useUserStore } from 'store'
import Home from 'pages/Home'
import Profile from 'pages/Profile'
import Login from 'pages/Login'
import { AboutUs, ContactUs, Cookies, Privacy, Terms } from './Legal'
import Casting from 'pages/Casting'
import Network from 'pages/Network'
// import Validation from 'pages/Portfolio/components/ValidationWrapper/Validation'
// const Portfolio = lazy(() => import('pages/Portfolio'))
import Portfolio from 'pages/Portfolio'
import Inspiration from 'pages/Inspiration'
import Settings from 'pages/Settings'
import NotFound from 'pages/NotFound'
import Message from 'pages/Message'
import Tips from 'pages/Tips'
import { getDefaultUserPath } from 'helpers'

export enum PATHS {
	HOME = 'home',
	ACTOR = 'actor',
	CONTRIBUTOR = 'contributor',
	CASTING_DIRECTOR = 'castingdirector',
	AGENCY = 'agency',
	PROFILE = 'user/:userId',
	JOIN = 'join',
	LOGIN = 'login',
	ACCEPT = 'accept',
	ONBOARDING = 'onboarding',
	PORTFOLIO = 'portfolio',
	SHOWREELS = 'showreels',
	VALIDATION = 'validation',
	NETWORK = 'network',
	CASTING = 'casting',
	PROJECT = 'project',
	INSPIRATION = 'inspiration',
	PRIVACY = 'legal/privacy',
	TERMS = 'legal/terms',
	COOKIES = 'legal/cookies',
	ABOUT = 'about-us',
	CONTACT = 'contact-us',
	SETTINGS = 'settings',
	TIPS = 'tips',
	USER_ROLE = ':userRole',
	MESSAGE = 'message',
	NOT_FOUND = '*',
	DETAILS = 'details',
	OVERVIEW = 'overview',
}

type CustomRoutes = {
	path: PATHS
	element?: JSX.Element | null
	childrens?: CustomRoutes[]
	protected?: boolean
}

const Router = (): JSX.Element => {
	const routes: CustomRoutes[] = [
		{ path: PATHS.HOME, element: <Home /> },
		{
			path: PATHS.JOIN,
			childrens: [
				{ path: PATHS.LOGIN, element: <Login /> },
				{ path: PATHS.ACCEPT, element: <Login /> },
				{ path: PATHS.ONBOARDING, element: <Login /> },
			],
		},
		{
			path: PATHS.ACTOR,
			childrens: [
				{ path: PATHS.PORTFOLIO, element: <Portfolio />, protected: true },
				// { path: PATHS.VALIDATION, element: <Validation />, protected: true },
				{ path: PATHS.NETWORK, element: <Network />, protected: true },
				{
					path: (PATHS.NETWORK + '/:profileId') as PATHS,
					element: <Network />,
					protected: true,
				},
				{ path: PATHS.CASTING, element: <Casting />, protected: true },
				{
					path: (PATHS.CASTING + '/' + PATHS.DETAILS + '/:roleId') as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING + '/:productionId' + '/:roleId') as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING +
						'/:productionId' +
						'/:roleId' +
						'/:userId') as PATHS,
					element: <Casting />,
					protected: true,
				},
			],
		},
		{
			path: PATHS.CASTING_DIRECTOR,
			childrens: [
				{ path: PATHS.PORTFOLIO, element: <Portfolio />, protected: true },
				{ path: PATHS.NETWORK, element: <Network />, protected: true },
				{
					path: (PATHS.NETWORK + '/:profileId') as PATHS,
					element: <Network />,
					protected: true,
				},
				{ path: PATHS.CASTING, element: <Casting />, protected: true },
				{
					path: (PATHS.CASTING + '/:productionId' + '/:roleId') as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING +
						'/:productionId' +
						'/:roleId' +
						'/:userId') as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING +
						'/' +
						PATHS.PROJECT +
						'/:productionId') as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING + '/' + PATHS.PROJECT) as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING +
						'/' +
						PATHS.PROJECT +
						'/:productionId' +
						'/:productionRoleId') as PATHS,
					element: <Casting />,
					protected: true,
				},
				{ path: PATHS.INSPIRATION, element: <Inspiration />, protected: true },
			],
		},
		{
			path: PATHS.AGENCY,
			childrens: [
				{ path: PATHS.PORTFOLIO, element: <Portfolio />, protected: true },
				{ path: PATHS.NETWORK, element: <Network />, protected: true },
				{
					path: (PATHS.NETWORK + '/:profileId') as PATHS,
					element: <Network />,
					protected: true,
				},
				{ path: PATHS.CASTING, element: <Casting />, protected: true },
				{
					path: (PATHS.CASTING + '/:productionId' + '/:roleId') as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING +
						'/:productionId' +
						'/:roleId' +
						'/:userId') as PATHS,
					element: <Casting />,
					protected: true,
				},
			],
		},
		{
			path: PATHS.CONTRIBUTOR,
			childrens: [
				{ path: PATHS.PORTFOLIO, element: <Portfolio />, protected: true },
				{ path: PATHS.CASTING, element: <Casting />, protected: true },
				{
					path: (PATHS.CASTING + '/:productionId' + '/:roleId') as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING +
						'/:productionId' +
						'/:roleId' +
						'/:userId') as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING +
						'/' +
						PATHS.PROJECT +
						'/:productionId') as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING + '/' + PATHS.PROJECT) as PATHS,
					element: <Casting />,
					protected: true,
				},
				{
					path: (PATHS.CASTING +
						'/' +
						PATHS.PROJECT +
						'/:productionId' +
						'/:productionRoleId') as PATHS,
					element: <Casting />,
					protected: true,
				},
			],
		},
		{ path: PATHS.MESSAGE, element: <Message /> },
		{ path: PATHS.PROFILE, element: <Profile /> },
		{ path: PATHS.SETTINGS, element: <Settings /> },
		{ path: PATHS.ABOUT, element: <AboutUs /> },
		{ path: PATHS.CONTACT, element: <ContactUs /> },
		{ path: PATHS.COOKIES, element: <Cookies /> },
		{ path: PATHS.PRIVACY, element: <Privacy /> },
		{ path: PATHS.TERMS, element: <Terms /> },
		{ path: PATHS.TIPS, element: <Tips /> },
	]

	return (
		<Routes>
			<Route element={<Layout />} errorElement={<NotFound />}>
				<Route index element={<Root />} />
				{routes?.map(
					({ path, element, childrens, protected: protectedRoute }) =>
						childrens ? (
							<Route key={path} path={path}>
								{childrens?.map((route) =>
									route.protected ? (
										<Route key={route.path} element={<ProtectedRouteWrapper />}>
											<Route path={route.path} element={route.element} />
										</Route>
									) : (
										<Route
											key={route.path}
											path={route.path}
											element={route.element}
										/>
									),
								)}
							</Route>
						) : protectedRoute ? (
							<Route key={path} element={<ProtectedRouteWrapper />}>
								<Route path={path} element={element} />
							</Route>
						) : (
							<Route key={path} path={path} element={element} />
						),
				)}
			</Route>
			<Route path="*" element={<NotFound />} />

			{/* <Route element={<ProtectedRoute />}></Route> */}
		</Routes>
	)
}

export default Router

const Root = (): JSX.Element => <Navigate to={PATHS.HOME} />

const ProtectedRouteWrapper = (): JSX.Element => {
	const currentUser = getUser()
	const { pathname, search, hash } = useLocation()
	// const {
	// 	portfolioMode: { active },
	// } = useAppStore.getState()
	const userRole = useUserStore(
		useShallow((state) => state?.user?.CustomerRole?.toLowerCase()),
	)
	const pathRole = pathname?.split('/')?.[1]
	const defaultPath = (getDefaultUserPath(userRole) +
		window.location.hash) as To

	return !currentUser?.['Custom:User:Id'] ? (
		<Navigate
			to={`/${PATHS.JOIN}/${PATHS.LOGIN}?redirectUrl=${
				pathname + search + hash
			}`}
			replace
		/>
	) : userRole && pathRole && userRole !== pathRole ? (
		<Navigate to={defaultPath} replace />
	) : (
		<Outlet />
	)
}

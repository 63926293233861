export const CHAT_WEBSOCKET = 'wss://edge.ivschat.eu-central-1.amazonaws.com'

// Chat API URL
// The Amazon IVS Chat backend endpoint. You must deploy the serverless backend to get this value.
// export const API_URL =
// 	'https://b9zcl2nqol.execute-api.eu-central-1.amazonaws.com/Prod'

// export const TOKEN =
// 	'AQICAHhXoLOILaBZssrAK_tnWqb0x0qRB09F9Q-WJE7jwbAx9gHfq4Rm8ZQxDz2S5PNMWRn2AAACFzCCAhMGCSqGSIb3DQEHBqCCAgQwggIAAgEAMIIB-QYJKoZIhvcNAQcBMB4GCWCGSAFlAwQBLjARBAyVfswmwQyEId0mHQkCARCAggHKStpI788DFjKVH1n6Ub3lkJaVUfziCEiupAxx-v62YG4liJurGm26Gwj_BOrlffIYmDctQfrIWD8v_auhDqnlpQsVEC2HxO7weUMldCepYHEg-LI_PCQO43hiz_SCJA19Xq9FgPrepoAFA8oD-xhQ6-8iySbYRQV6dpwPMIib-BN55aFQIuGatxzFft1ahrzlmS4RH7K3hrQRUiI5LerZWi1SmxvffvYCRQ63tbnWrP3M-fh8XX168IR_BTu0vNc08RliwsZelhpfrRT5n5bcfid1W7-aF2R2wrspenXRnrA5-jDsLmKgL6EoTQKRAWufeNTO7kEtbYJMhYKNpgVPpwks8E9H4eoOb6U3ikL05RPQJ6v8dYBzmuVr0-bo2q2pBGCQS9xDumRrHRVU4875LE3FYtGn4AK_zuY9acolRJLUHBmyIjHcxkMYDkpEKaN4sZRrcnLOVZdfFwnY5esRRcRbIBKRbwmWguuxQRVSfmj5KBimGuJlDxGy9ZQxAt3OqL3cRgWmkLno3dDG0pBpWdfDbutxTcdGHH3ScjL3r_2iJEWOVcgJp-4YJzuWkqp36th0TBTTiEaL1sO4bHH7vR5NVLMA233ErL4!#0'

// Values between 1 and 180 are supported.
// export const TOKEN_EXPIRATION_IN_MINUTES = 180

// Token refresh delay
// This client app will attempt to obtain a new token for the user 0.5 minutes
// before it expires.
// export const TOKEN_REFRESH_IN_MINUTES = TOKEN_EXPIRATION_IN_MINUTES - 0.5

export const SearchIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M18.7812 17.6289C19.0547 17.9023 19.0547 18.3125 18.7812 18.5586C18.6719 18.6953 18.5078 18.75 18.3438 18.75C18.1523 18.75 17.9883 18.6953 17.8516 18.5586L14.1875 14.8945C13.2031 15.6875 11.9727 16.125 10.6602 16.125C7.54297 16.125 5 13.582 5 10.4375C5 7.32031 7.51562 4.75 10.6602 4.75C13.7773 4.75 16.3477 7.32031 16.3477 10.4375C16.3477 11.7773 15.9102 13.0078 15.1172 13.9648L18.7812 17.6289ZM6.3125 10.4375C6.3125 12.8711 8.25391 14.8125 10.6875 14.8125C13.0938 14.8125 15.0625 12.8711 15.0625 10.4375C15.0625 8.03125 13.0938 6.0625 10.6875 6.0625C8.25391 6.0625 6.3125 8.03125 6.3125 10.4375Z"
			fill={color}
		/>
	</svg>
)

import Loader from 'components/Loader'
import useContentRelation from 'hooks/api/useContentRelation'
import { useCastingStore } from 'store'
import { RELATIONS } from 'types/enums'
import { useShallow } from 'zustand/react/shallow'
import Chat from '../Chat'

const Message = (): JSX.Element => {
	const chatContentId = useCastingStore(
		useShallow((state) => state.chatContentId),
	)
	const { contents: voiceChats } = useContentRelation(
		chatContentId,
		RELATIONS.CHAT,
	)
	const voiceChat = voiceChats?.[0]

	const { contents: textChats } = useContentRelation(
		voiceChat?.ContentId,
		RELATIONS.REFERENCE,
	)

	const textChat = textChats?.[0]

	return textChat ? <Chat chatData={textChat} /> : <Loader loading adapt />
}

export default Message

import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { ArrowShortIcon } from 'assets/icons'
import CheckboxButton from 'components/UI/CheckboxButton'
import { optionsParser, t } from 'helpers'
import { useFilterStore } from 'store'
import { AttributeSelector } from 'types/app'
import s from './index.module.scss'

interface Props {
	skillField: AttributeSelector
	levelField: AttributeSelector
}

const FilterTable = ({ skillField, levelField }: Props): JSX.Element => {
	const [values, setValue, search, levelSidebar] = useFilterStore(
		useShallow((state) => [
			(state.filters[skillField.name] || []) as Array<{
				[key: string]: string
			}>,
			state.setFilters,
			state.fieldsSearch,
			state.levelSidebar,
		]),
	)

	const onChange = (
		targetValue: string,
		type: 'Value' | 'Level',
		value?: string,
	): void => {
		const currentValue = values?.find(
			(v) => v['Value'] === (type === 'Value' ? targetValue : value),
		)
		if (currentValue) {
			const filtered = values?.filter(
				(v) => v['Value'] !== (type === 'Value' ? targetValue : value),
			)
			const newValues =
				type === 'Value'
					? filtered
					: [
							...filtered,
							{
								['Value']: value || '',
								['Level']: targetValue,
							},
					  ]
			setValue({ [skillField.name]: newValues })
		} else {
			const newValue =
				type === 'Value'
					? {
							['Value']: targetValue,
							['Level']: '',
					  }
					: {
							['Value']: value || '',
							['Level']: targetValue,
					  }
			setValue({
				[skillField.name]: [...values, newValue],
			})
		}
	}

	const levelOptions = optionsParser(t(levelField.optionName || ''))

	return (
		<div className={s.container}>
			{optionsParser(t(skillField.optionName || ''))?.map((option) => (
				<div
					key={option.value}
					className={cn('grid2column', {
						[s.highlight]:
							search &&
							option?.label?.toLowerCase().includes(search?.toLowerCase()),
					})}>
					<CheckboxButton
						checked={!!values?.find((v) => v['Value'] === option.value)}
						label={option.label}
						onChange={() => onChange(option.value, 'Value')}
						style={{
							alignItems: 'center',
						}}
					/>
					<div
						className={s.levelLabel}
						onClick={() => {
							const { setLevelSidebar } = useFilterStore.getState()
							if (!levelSidebar.open)
								setLevelSidebar({
									open: true,
									skillOption: option,
									onChange: (e) => {
										onChange(e, 'Level', option.value)
										setLevelSidebar({
											open: false,
											skillOption: null,
											onChange: null,
											options: [],
										})
									},
									options: levelOptions,
								})
						}}>
						<span
							className="textEllipsis body1-m"
							style={{
								color:
									levelSidebar.open &&
									levelSidebar.skillOption?.value === option.value
										? 'var(--mono100)'
										: 'var(--mono300)',
							}}>
							{levelOptions?.find(
								(opt) =>
									opt.value ===
									values?.find((v) => v['Value'] === option.value)?.['Level'],
							)?.label || t('APP_LEVEL_LABEL')}
						</span>
						<div className={s.arrow}>
							<ArrowShortIcon
								color={
									levelSidebar.open &&
									levelSidebar.skillOption?.value === option.value
										? 'var(--mono100)'
										: 'var(--mono300)'
								}
								style={{
									transform:
										levelSidebar.open &&
										levelSidebar.skillOption?.value === option.value
											? 'rotate(-90deg)'
											: 'rotate(90deg)',
								}}
							/>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}

export default FilterTable

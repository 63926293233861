import { useEffect, useState } from 'react'
import { secToHHMMSS } from 'helpers/dateTimeConfig'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
}

const Time = ({ videoElement }: Props): JSX.Element => {
	const [currentTime, setCurrentTime] = useState(0)

	useEffect(() => {
		if (videoElement) {
			videoElement.addEventListener('timeupdate', (e) => {
				const target = e.target as HTMLVideoElement
				setCurrentTime(target.currentTime)
			})
		}
	}, [videoElement])

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '5px',
			}}>
			<span className="body2-s">{secToHHMMSS(currentTime)}</span>
			<span className="body2-s" style={{ color: 'var(--mono300)' }}>
				/
			</span>
			<span className="body2-s" style={{ color: 'var(--mono300)' }}>
				{videoElement ? secToHHMMSS(videoElement?.duration || 0) : ''}
			</span>
		</div>
	)
}

export default Time

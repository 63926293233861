import cn from 'classnames'
import { PlusIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import { User } from 'types/user'
import s from './index.module.scss'

interface Props {
	onAdd: () => void
	onRemove: (user: User) => void
	users?: User[]
}

const UserDynamicList = ({ onAdd, onRemove, users }: Props): JSX.Element => (
	<div className={s.wrapper}>
		<div className={s.addButton} onClick={onAdd}>
			<PlusIcon />
		</div>
		<div className={cn(s.users, 'hideScrollBar')}>
			{users?.map((user, index) => (
				<div
					key={user.ContentId}
					className={s.userWrapper}
					style={
						index === 0
							? {
									border: `1px solid var(--${user?.CustomerRole?.toLowerCase()})`,
							  }
							: {
									border: `1px solid var(--${user?.CustomerRole?.toLowerCase()})`,
									transform: `translateX(-${15 * index}px)`,
							  }
					}>
					<div className={s.remove} onClick={() => onRemove(user)}>
						<PlusIcon style={{ transform: 'rotate(45deg)' }} />
					</div>
					<Tooltip title={user?.ContentTitle} placement="top">
						<div className={s.user}>
							<img
								src={user?.CustomerImageSquareUrl}
								alt={user?.ContentTitle}
							/>
						</div>
					</Tooltip>
				</div>
			))}
		</div>
	</div>
)

export default UserDynamicList

import { KeyedMutator } from 'swr'
import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { propertiesParser, actionsParser } from 'helpers/propertiesParser'
import { useAppStore } from 'store'
import { ContentCollection, Content, FetchReturn, ContentFull } from 'types/app'

type ReturnData = FetchReturn & {
	content: Content | undefined
	refreshData: KeyedMutator<ContentCollection>
}

export default (
	id: string | undefined | null,
	type: 'bag' | 'cache' | 'full' = 'cache',
): ReturnData => {
	const { endpoints } = useAppStore.getState()

	const cacheUrl = id
		? endpoints?.CustomApplicationV3ContentUrl.replace('${contentId}', id)
		: null
	const bagUrl = id
		? endpoints?.CustomApplicationContentUrl + '/' + id + '/PropertyBag'
		: null
	const fullUrl = id ? endpoints?.CustomApplicationContentUrl + '/' + id : null
	const contentUrl =
		type === 'bag' ? bagUrl : type === 'full' ? fullUrl : cacheUrl

	const { data, error, isLoading, mutate } = useSwrImmutable<ContentCollection>(
		contentUrl ? contentUrl : null,
		fetcher,
	)

	const content =
		data && type !== 'full' && data.Properties
			? ({
					...propertiesParser(data.Properties),
					Actions: actionsParser(data.Actions),
			  } as Content)
			: data && (data as unknown as ContentFull)?.properties && type === 'full'
			? ({
					...Object.entries((data as unknown as ContentFull).properties)
						.map(([key, value]) => ({
							[key?.replaceAll(':', '')]: value,
						}))
						.reduce((prev, next) => ({ ...prev, ...next }), {}),
					'Globalizationen-USContentTitle': Object.values(
						(data as unknown as ContentFull).localizations,
					)?.[0]?.name,
					'Globalizationen-USContentDescription': Object.values(
						(data as unknown as ContentFull).localizations,
					)?.[0]?.description,
					ContentId: (data as unknown as ContentFull).id,
					assets: (data as unknown as ContentFull).assets,
			  } as Content)
			: undefined

	return {
		isLoading: isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		content,
		refreshData: mutate,
	}
}

export const VisibilityIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M7.35156 7.29297C8.58203 6.36328 10.1133 5.625 12 5.625C14.1875 5.625 15.9648 6.63672 17.25 7.83984C18.5352 9.01562 19.3828 10.4375 19.793 11.4219C19.875 11.6406 19.875 11.8867 19.793 12.1055C19.4375 12.9805 18.6992 14.2383 17.6055 15.332L20.4766 17.6016C20.7773 17.8203 20.832 18.2305 20.5859 18.5039C20.3672 18.8047 19.957 18.8594 19.6836 18.6133L3.49609 5.92578C3.19531 5.70703 3.14062 5.29688 3.38672 5.02344C3.60547 4.72266 4.01562 4.66797 4.28906 4.91406L7.35156 7.29297ZM9.34766 8.85156L11.8086 10.793C11.918 10.5469 12 10.3008 12 10C12 9.69922 11.8906 9.39844 11.7539 9.15234C11.8359 9.15234 11.918 9.125 12 9.125C13.4492 9.125 14.625 10.3008 14.625 11.75C14.625 12.1328 14.543 12.4883 14.3789 12.8164L15.4453 13.6367C15.7461 13.0898 15.9375 12.4336 15.9375 11.75C15.9375 9.58984 14.1602 7.8125 12 7.8125C10.9609 7.8125 10.0312 8.22266 9.34766 8.85156ZM12 17.875C9.78516 17.875 8.00781 16.8906 6.72266 15.6875C5.4375 14.4844 4.58984 13.0625 4.17969 12.1055C4.09766 11.8867 4.09766 11.6406 4.17969 11.4219C4.45312 10.793 4.89062 10 5.51953 9.17969L8.08984 11.2031C8.0625 11.3945 8.0625 11.5859 8.0625 11.75C8.0625 13.9375 9.8125 15.6875 12 15.6875C12.4922 15.6875 12.9844 15.6055 13.4492 15.4141L15.4453 17C14.4336 17.5469 13.2852 17.875 12 17.875Z"
			fill={color}
		/>
	</svg>
)

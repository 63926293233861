export const SoundIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.375 7.375C9.83984 7.375 10.25 7.78516 10.25 8.25V15.25C10.25 15.7422 9.78516 16.125 9.34766 16.125C8.88281 16.125 8.5 15.7422 8.5 15.25V8.25C8.5 7.78516 8.88281 7.375 9.375 7.375ZM4.125 10.875C4.58984 10.875 5 11.2852 5 11.75C5 12.2422 4.53516 12.625 4.09766 12.625C3.63281 12.625 3.25 12.1875 3.25 11.75C3.25 11.2852 3.63281 10.875 4.125 10.875ZM12 4.75C12.4648 4.75 12.875 5.16016 12.875 5.625V17.875C12.875 18.3672 12.4102 18.75 11.9727 18.75C11.5078 18.75 11.125 18.3672 11.125 17.875V5.625C11.125 5.16016 11.5078 4.75 12 4.75ZM6.75 10C7.21484 10 7.625 10.4102 7.625 10.875V12.625C7.625 13.1172 7.16016 13.5 6.72266 13.5C6.25781 13.5 5.875 13.1172 5.875 12.625V10.875C5.875 10.4102 6.25781 10 6.75 10ZM19.875 10.875C20.3398 10.875 20.75 11.3398 20.75 11.7773C20.75 12.2422 20.3398 12.625 19.875 12.625C19.3828 12.625 19 12.1875 19 11.75C19 11.2852 19.3828 10.875 19.875 10.875ZM14.625 8.25C15.0898 8.25 15.5 8.66016 15.5 9.125V14.375C15.5 14.8672 15.0352 15.25 14.5977 15.25C14.1328 15.25 13.75 14.8672 13.75 14.375V9.125C13.75 8.66016 14.1328 8.25 14.625 8.25ZM17.25 6.5C17.7148 6.5 18.125 6.91016 18.125 7.375V16.125C18.125 16.6172 17.7148 17 17.2227 17C16.7578 17 16.375 16.6172 16.375 16.125V7.375C16.375 6.91016 16.7578 6.5 17.25 6.5Z"
			fill={color}
		/>
	</svg>
)

import { createWithEqualityFn } from 'zustand/traditional'
import { addRelation } from 'api/relations'
import { PATHS } from 'pages/Router'
import { RELATIONS } from 'types/enums'
import { NetworkStore } from 'types/store'
import { NetworkCardType } from 'types/app'

const useStore = createWithEqualityFn<NetworkStore>(
	(set, get) => ({
		loading: false,
		setLoading: (loading) => set({ loading }),
		uploadedFile: null,
		setUploadedFile: (file) => set({ uploadedFile: file }),
		addContactsSidebar: {
			open: false,
			users: [],
		},
		setAddContactsSidebar: (data) =>
			set((state) => ({
				addContactsSidebar: { ...state.addContactsSidebar, ...data },
			})),
		setAddContact: (data) =>
			set((state) => ({
				addContactsSidebar: {
					...state.addContactsSidebar,
					users: [...state.addContactsSidebar.users, data],
				},
			})),
		parsedUsers: null,
		setParsedUsers: (user) => set({ parsedUsers: user }),
		openInviteUserSidebar: false,
		setOpenInviteUserSidebar: (open) => set({ openInviteUserSidebar: open }),
		userProfile: {
			user: null,
			onUserChange: async (user) => {
				const { setUserProfile } = get()
				setUserProfile({ user })
				const role = window.location.pathname?.split('/')[1]

				if (user) {
					await addRelation({
						relation: RELATIONS.FAVCUSTOMER,
						targetId: user.ContentId,
						type: 'Customer',
					})

					return (
						`/${role}/${PATHS.NETWORK}/${user.ContentId}` + window.location.hash
					)
				} else {
					return `/${role}/${PATHS.NETWORK}` + window.location.hash
				}
			},
		},
		setUserProfile: (data) =>
			set((state) => ({
				userProfile: {
					...state.userProfile,
					...data,
				},
			})),
		acceptInviteToPoolSidebar: '',
		setAcceptInviteToPoolSidebar: (id) =>
			set({ acceptInviteToPoolSidebar: id }),
	}),
	Object.is,
)

export default useStore

export const RepeatIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M17.7969 5.65234C18.1797 5.65234 18.5625 5.92578 18.5625 6.39062V10.3828C18.5625 10.6562 18.3164 10.9023 18.0156 10.9023H14.0508C13.5859 10.9023 13.3125 10.5195 13.3125 10.1641C13.3125 9.97266 13.3672 9.78125 13.5312 9.64453L14.7617 8.38672C13.9961 7.75781 13.0117 7.40234 11.9727 7.40234C9.56641 7.40234 7.625 9.37109 7.625 11.7773C7.625 14.1836 9.56641 16.125 11.9727 16.125C13.9961 16.125 14.4336 15.0859 15.1445 15.0859C15.6367 15.0859 15.9922 15.4961 15.9922 15.9609C15.9922 16.918 13.7773 17.875 11.9727 17.875C8.60938 17.875 5.875 15.1406 5.875 11.7773C5.875 8.38672 8.60938 5.65234 12 5.65234C13.4766 5.65234 14.8984 6.19922 15.9922 7.15625L17.3047 5.87109C17.4414 5.70703 17.6328 5.65234 17.7969 5.65234Z"
			fill={color}
		/>
	</svg>
)

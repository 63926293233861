import { SwiperSlide } from 'swiper/react'
import { CastingIcon, EditIcon } from 'assets/icons'
import ProductionCard from 'components/ProductionCard'
import RecentCarousel from 'components/RecentCarousel'
import useCollection from 'hooks/api/useCollection'
import { t } from 'helpers'
import { Content } from 'types/app'

interface Props {
	contents: Content[]
	openProduction: (c: Content) => void
	openCasting: (prodId: string, roleId: string) => void
}

const Recent = ({
	contents,
	openProduction,
	openCasting,
}: Props): JSX.Element => {
	return (
		<>
			<span className="body1-b" style={{ paddingLeft: '20px' }}>
				{t('APP_RECENT_PRODUCTIONS')}
			</span>
			<RecentCarousel name="myproductionrecent">
				<>
					{contents?.map((content) => (
						<SwiperSlide key={content.ContentId}>
							<RecentItem
								content={content}
								openCasting={openCasting}
								openProduction={openProduction}
							/>
						</SwiperSlide>
					))}
				</>
			</RecentCarousel>
		</>
	)
}

export default Recent

const RecentItem = ({
	content,
	openCasting,
	openProduction,
}: {
	content: Content
	openProduction: (c: Content) => void
	openCasting: (prodId: string, roleId: string) => void
}): JSX.Element => {
	const { collection = [] } = useCollection(content)
	const role = collection?.[0]

	return (
		<ProductionCard
			content={content}
			type="small"
			children={
				<>
					<div
						className="hoverButtonWrapper hoverButtonIcon"
						onClick={() => openProduction(content)}>
						<EditIcon />
					</div>
					{role?.ContentId ? (
						<div
							className="hoverButtonWrapper hoverButtonIcon"
							onClick={() => openCasting(content?.ContentId, role?.ContentId)}>
							<CastingIcon />
						</div>
					) : null}
				</>
			}
		/>
	)
}

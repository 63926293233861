export const FullscreenIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M9.875 5.625C10.3398 5.625 10.75 6.03516 10.75 6.5C10.75 6.99219 10.3398 7.375 9.875 7.375H8.125V9.125C8.125 9.61719 7.71484 10 7.25 10C6.75781 10 6.375 9.61719 6.375 9.125V6.5C6.375 6.03516 6.75781 5.625 7.25 5.625H9.875ZM17.75 5.625C18.2148 5.625 18.625 6.03516 18.625 6.5V9.125C18.625 9.61719 18.2148 10 17.75 10C17.2578 10 16.875 9.61719 16.875 9.125V7.375H15.125C14.6328 7.375 14.25 6.99219 14.25 6.5C14.25 6.03516 14.6328 5.625 15.125 5.625H17.75ZM9.875 16.125C10.3398 16.125 10.75 16.5352 10.75 17C10.75 17.4922 10.3398 17.875 9.875 17.875H7.25C6.75781 17.875 6.375 17.4922 6.375 17V14.375C6.375 13.9102 6.75781 13.5 7.25 13.5C7.71484 13.5 8.125 13.9102 8.125 14.375V16.125H9.875ZM17.75 13.5C18.2148 13.5 18.625 13.9102 18.625 14.375V17C18.625 17.4922 18.2148 17.875 17.75 17.875H15.125C14.6328 17.875 14.25 17.4922 14.25 17C14.25 16.5352 14.6328 16.125 15.125 16.125H16.875V14.375C16.875 13.9102 17.2578 13.5 17.75 13.5Z"
			fill={color}
		/>
	</svg>
)

import { CompleteIcon } from 'assets/icons'
import { toast, ToastOptions, Id } from 'react-toastify'

type Types = 'info' /* | 'success' |  'warning' | 'error' | 'loading' */

export const notif = (
	type: Types,
	message: string | string[],
	options?: ToastOptions,
): Id =>
	toast[type](
		Array.isArray(message) ? (
			<div className="notifMessageClass">
				{message[0] ? <span className="body1-m">{message[0]}</span> : null}
				{message[1] ? <span className="caption-r">{message[1]}</span> : null}
				{message[2] ? <p className="caption-s">{message[2]}</p> : null}
			</div>
		) : message?.includes('/>') ? (
			<span dangerouslySetInnerHTML={{ __html: message }} />
		) : (
			message
		),
		config(type, options),
	)

// export const noticeUpdate = (
// 	id: Id,
// 	type: Types,
// 	message: string,
// 	options?: UpdateOptions,
// ): void =>
// 	toast.update(id, {
// 		...options,
// 		render: message,
// 		type: type as TypeOptions,
// 		isLoading: false,
// 		autoClose: 2000,
// 		...config(type, options as ToastOptions),
// })

const config = (type: Types, options?: ToastOptions): ToastOptions => {
	return {
		bodyClassName: 'notifBodyClass',
		className: 'notifClass',
		...options,
		...defaultOptions[type],
		autoClose: false,
		style: {
			borderRadius: '12px',
			...defaultOptions[type].style,
			...options?.style,
		},
		progressStyle: {
			height: '2px',
			...defaultOptions[type].progressStyle,
			...options?.progressStyle,
		},
	}
}

export const defaultOptions: {
	[key: string]: ToastOptions
} = {
	// success: {
	// 	icon: () => <CheckIcon color="var(--green)" />,
	// 	bodyStyle: {
	// 		color: 'var(--green)',
	// 	},
	// 	progressStyle: {
	// 		backgroundColor: 'var(--green)',
	// 	},
	// },
	info: {
		icon: () => (
			<div className="notifInfoIcon">
				<CompleteIcon color="var(--success)" />
			</div>
		),
		style: {
			backgroundColor: 'var(--elev300)',
		},
		bodyStyle: {
			color: 'var(--mono200)',
		},
		progressStyle: {
			backgroundColor: 'var(--theme-primary)',
		},
	},
	// loading: {
	// 	icon: () => <InfoFilledIcon color="var(--blue)" />,
	// 	bodyStyle: {
	// 		color: 'var(--blue)',
	// 	},
	// 	progressStyle: {
	// 		backgroundColor: 'var(--blue)',
	// 	},
	// },
	// warning: {
	// 	icon: () => <InfoFilledIcon color="var(--yellow)" />,
	// 	bodyStyle: {
	// 		color: 'var(--yellow)',
	// 	},
	// 	progressStyle: {
	// 		backgroundColor: 'var(--yellow)',
	// 	},
	// },
	// error: {
	// 	icon: () => <InfoFilledIcon color="var(--red)" />,
	// 	bodyStyle: {
	// 		color: 'var(--red)',
	// 	},
	// 	progressStyle: {
	// 		backgroundColor: 'var(--red)',
	// 	},
	// },
}

import { handleDataForCorrectDisplay } from './'
import { useUserStore } from 'store'
import { PropertiesType } from 'types/app'
import { UserData } from 'types/user'

type GeoCoords = {
	latitude: number
	longitude: number
}

export const getUser = (): UserData | null => {
	const user = localStorage.getItem('UserData')

	return user ? JSON.parse(user) : null
}

export const setUser = (data: PropertiesType[]): void => {
	const parsed = handleDataForCorrectDisplay(data)
	localStorage.setItem('UserData', JSON.stringify(parsed))
}

export const removeUser = (): void => localStorage.removeItem('UserData')

export const logout = (): void => {
	removeUser()
	const { setUser, setUserId } = useUserStore.getState()
	setUser(null)
	setUserId('')
	localStorage.setItem('theme', '')
}

export const setGeolocation = (coords: GeoCoords): void =>
	localStorage.setItem('geolocation', JSON.stringify(coords))

export const getGeolocation = (): GeoCoords | null => {
	const coords = localStorage.getItem('geolocation')

	return coords ? JSON.parse(coords) : null
}

export const updateUserToken = (data: PropertiesType[]): void => {
	const user = localStorage.getItem('UserData')
	if (user) {
		const oldData = JSON.parse(user) as UserData
		const newData = handleDataForCorrectDisplay(data) as UserData
		const updated = Object.assign(oldData, newData)
		localStorage.setItem('UserData', JSON.stringify(updated))
	}
}

export const setStorageLocales = (locales: {
	[key: string]: { [key: string]: string }
}): void => {
	localStorage.setItem('Locales', JSON.stringify(locales))
}

export const getStorageLocales = (): {
	[key: string]: { [key: string]: string }
} | null => {
	const locales = localStorage.getItem('Locales')
	if (locales) return JSON.parse(locales)
	else return null
}

export const setRedirectUrl = (url: string): void => {
	localStorage.setItem('redirectUrl', url)
}

export const getRedirectUrl = (): string | null =>
	localStorage.getItem('redirectUrl')

import AWS from 'aws-sdk'
import { fetcher } from './'
import { useAppStore } from 'store'

type UploadType = {
	assetType: string
	fileObj: File
}

type IngestAccess = {
	credentials: {
		accessKeyId: string
		secretAccessKey: string
		sessionToken: string
	}
	fileAssetUrl: string
	bucketName: string
	assetId: string
	cdnUrl: string
	key: string
	region: string
}

export type IngestAsset = {
	fileAssetUrl: string
	cdnUrl: string
	key: string
	assetId: string
	originalFileName: string
	availableFrom: Date
	availableUntil: Date
}

const createIngestAccess = async (
	fileName: string,
	assetType: string,
): Promise<IngestAccess> => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationAssetUrl
		? `${endpoints?.CustomApplicationAssetUrl}/Asset/Upload/${assetType}/${fileName}?isPublic=true`
		: ''

	return fetcher(url, {
		method: 'get',
	})
}

export const upload = ({
	fileObj,
	assetType = 'FileAsset',
}: UploadType): Promise<IngestAsset> =>
	new Promise((resolve, reject) => {
		createIngestAccess(encodeURIComponent(fileObj.name), assetType)
			.then((ingestAccess) => {
				const {
					credentials: { accessKeyId, secretAccessKey, sessionToken },
					fileAssetUrl,
					bucketName,
					assetId,
					cdnUrl,
					key,
					region,
				} = ingestAccess

				AWS.config.credentials = new AWS.Credentials(
					accessKeyId,
					secretAccessKey,
					sessionToken,
				)

				const s3 = new AWS.S3({
					region,
				})
				const params = {
					Bucket: bucketName,
					ACL: 'private',
					Body: fileObj,
					Key: key,
					CacheControl: 'max-age=604800',
					ContentType: fileObj?.type,
				}

				const options = { partSize: 20 * 1024 * 1024, queueSize: 1 }

				s3.upload(params, options)
					.on('httpUploadProgress', (event) => null)
					.send((err: string) => {
						// console.log('UPLOAD ERROR:', err)

						if (err) reject(err)

						return resolve({
							key,
							assetId,
							originalFileName: fileObj.name,
							fileAssetUrl,
							cdnUrl,
							availableFrom: new Date(),
							availableUntil: new Date('01/01/2030'),
						})
					})
			})
			.catch(reject)
	})

import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { partialContentUpdate } from 'api/content'
import useContent from 'hooks/api/useContent'
import { t } from 'helpers'
import { useCastingStore, useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'
import { ROLE_STATUS } from 'types/enums'
import s from './index.module.scss'

const Status = (): JSX.Element => {
	const { roleId } = useParams()
	const director = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)

	const { content, refreshData } = useContent(roleId, 'bag')

	const activeStatus = (content?.ContentCastingStatus || '') as ROLE_STATUS

	const statusProps: {
		[key: string]: {
			color: string
			activeLabel: string
		} | null
	} = {
		[ROLE_STATUS.CASTING]: {
			color: 'var(--warning)',
			activeLabel: 'APP_CASTING_INPROGRESS_STATUS',
		},
		[ROLE_STATUS.OPENED]: {
			color: 'var(--success)',
			activeLabel: 'APP_CASTING_OPENED_STATUS',
		},
		[ROLE_STATUS.CLOSED]: {
			color: 'var(--error)',
			activeLabel: 'APP_CASTING_CLOSED_STATUS',
		},
	}

	const onClick = async (status: ROLE_STATUS): Promise<void> => {
		try {
			if (!roleId) return

			if (status === ROLE_STATUS.CLOSED) {
				const { setOpenCloseCastingSidebar } = useCastingStore.getState()
				setOpenCloseCastingSidebar(true)
			}

			await partialContentUpdate(roleId, {
				properties: {
					'Content:Casting:Status': status,
				},
			})
		} catch (error) {
			console.log('error:', error)
		} finally {
			refreshData()
		}
	}

	return director && statusProps?.[activeStatus] ? (
		<div
			className={classNames(s.container, 'castingToolWrapper')}
			data-role="casting-status">
			<div className={s.wrapper}>
				<div className={s.status}>
					<div
						className={s.animation}
						style={{
							backgroundColor: statusProps?.[activeStatus]?.color,
						}}
					/>
					<div
						style={{
							backgroundColor: statusProps?.[activeStatus]?.color,
						}}
					/>
				</div>
				<span
					style={{
						color: statusProps?.[activeStatus]?.color,
					}}>
					{t(statusProps?.[activeStatus]?.activeLabel)}
				</span>
			</div>
			{[ROLE_STATUS.CASTING, ROLE_STATUS.OPENED, ROLE_STATUS.CLOSED]
				?.filter((status) => status !== activeStatus)
				?.map((status) => (
					<div
						className={s.wrapper}
						key={status}
						onClick={() => onClick(status)}>
						<div className={s.status}>
							<div
								className={s.animation}
								style={{
									backgroundColor: statusProps?.[status]?.color,
								}}
							/>
							<div
								style={{
									backgroundColor: statusProps?.[status]?.color,
								}}
							/>
						</div>
						<span
							style={{
								color: statusProps?.[status]?.color,
							}}>
							{t(`APP_CASTING_${status?.toUpperCase()}_STATUS`)}
						</span>
					</div>
				))}
		</div>
	) : (
		<></>
	)
}

export default Status

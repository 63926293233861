import { useLocation } from 'react-router-dom'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import Logo from './Logo'
import StepTabs from './Tabs/StepTabs'
import Menu from './Menu'
import CustomTabs from './Tabs/CustomTabs'
import HomeTabs from './Tabs/HomeTabs'
import { useCastingStore } from 'store'
import { PATHS } from 'pages/Router'
import s from './index.module.scss'

const Header = (): JSX.Element => {
	const { pathname } = useLocation()
	const showOnlyLogo = pathname.includes(PATHS.JOIN)
	const collapse =
		pathname?.includes(PATHS.LOGIN) || pathname?.includes(PATHS.ACCEPT)
	const customTabs = useCastingStore(
		useShallow((state) => state.customCastingTabs),
	)

	const home = pathname === '/' || pathname.includes(PATHS.HOME)

	return (
		<>
			<header
				id="main-header"
				className={cn(s.header, {
					[s.hide]: showOnlyLogo,
					[s.logoAlign]: collapse,
					[s.sticky]: home,
				})}>
				<Logo />
				{home ? (
					<HomeTabs classname={s.homeTabs} />
				) : customTabs ? (
					<CustomTabs />
				) : (
					<StepTabs />
				)}
				<Menu />
			</header>
		</>
	)
}

export default Header

import { CSSProperties, useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import cn from 'classnames'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import s from './index.module.scss'

interface Props {
	value: string | null | undefined
	rangeValues?: string[] | null
	defaultValue?: string
	onChange: (date: string | null) => void
	type?: 'input' | 'range'
	style?: CSSProperties
	disabled?: boolean
}

const Datepicker = ({
	value,
	rangeValues,
	defaultValue,
	type = 'input',
	style,
	onChange,
	disabled,
}: Props): JSX.Element => {
	const getTimestamp = (date: string): number => {
		const [month, day, year] = date.split('/').map(Number)

		return new Date(year, month - 1, day).getTime()
	}

	const calculateRangeStyles = useCallback(() => {
		if (type === 'range' && rangeValues?.length && value) {
			const [from, to] = [
				dayjs(rangeValues[0]).format('MM/DD/YYYY'),
				dayjs(rangeValues[1]).format('MM/DD/YYYY'),
			]

			const fromTs = getTimestamp(from)
			const toTs = getTimestamp(to)

			if (toTs > fromTs) {
				const days = document.querySelectorAll(
					'button[role=gridcell][data-timestamp]',
				)
				days.forEach((el) => {
					const timestamp = Number(el.getAttribute('data-timestamp'))
					if (timestamp && timestamp > fromTs && timestamp < toTs) {
						const button = el as HTMLButtonElement
						button.style.backgroundColor =
							'color-mix(in srgb, var(--theme-primary) 20%, transparent)'
					}
				})
			}
		}
	}, [type, rangeValues, value])

	useEffect(() => {
		calculateRangeStyles()
	}, [calculateRangeStyles])

	return (
		<div className={s.container} style={style}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DateCalendar
					defaultValue={dayjs(defaultValue)}
					value={dayjs(value)}
					className={cn(s.picker)}
					disabled={disabled}
					onChange={(v) => {
						onChange(dayjs(v).toISOString())
						setTimeout(() => {
							calculateRangeStyles()
						}, 300)
					}}
					minDate={
						type === 'range' && rangeValues?.findIndex((v) => v === value) === 1
							? dayjs(rangeValues[0]).add(1, 'day')
							: undefined
					}
					onMonthChange={() => {
						setTimeout(() => {
							calculateRangeStyles()
						}, 300)
					}}
				/>
			</LocalizationProvider>
		</div>
	)
}

export default Datepicker

import { useEffect, useState } from 'react'
import cn from 'classnames'
import { PauseIcon, PlayIcon } from 'assets/icons'
import { t } from 'helpers'
import { HOME_SECTIONS } from 'types/enums'
import s from './index.module.scss'

const HowItWorks = (): JSX.Element => {
	const [playing, setPlaying] = useState(false)

	useEffect(() => {
		const video = document.getElementById(
			'how-it-works-player',
		) as HTMLVideoElement
		if (video) {
			if (playing) video.play()
			else video.pause()
		}
	}, [playing])

	return (
		<section className={s.container} id={HOME_SECTIONS.HOW_IT_WORKS}>
			<div className={s.wrapper}>
				<div className={s.header}>
					<h2>{t('APP_HOW_IT_WORKS_TITLE')}</h2>
					<h4 className="h4-b">{t('APP_HOW_IT_WORKS_DESCRIPTION')}</h4>
				</div>
				<div className={cn(s.player, { [s.playing]: playing })}>
					<div
						className={s.control}
						onClick={() => setPlaying((prev) => !prev)}>
						{playing ? <PauseIcon /> : <PlayIcon />}
					</div>
					<video id="how-it-works-player" src={'/howitworks.mp4'} />
				</div>
			</div>
		</section>
	)
}

export default HowItWorks

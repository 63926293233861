import { PrevIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
}

const Next = ({ videoElement }: Props): JSX.Element => {
	const onNextClick = (): void => {
		if (videoElement) videoElement.currentTime = videoElement.currentTime + 10
	}

	return (
		<Tooltip id="next-control" title="APP_NEXT_CONTROL">
			<div className={s.control} onClick={onNextClick}>
				<PrevIcon style={{ transform: 'rotate(180deg)' }} />
			</div>
		</Tooltip>
	)
}

export default Next

export const CompleteIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5 11.75C5 7.89453 8.11719 4.75 12 4.75C15.8555 4.75 19 7.89453 19 11.75C19 15.6328 15.8555 18.75 12 18.75C8.11719 18.75 5 15.6328 5 11.75ZM15.1445 10.5469C15.4453 10.2461 15.4453 9.78125 15.1445 9.48047C14.8438 9.17969 14.3789 9.17969 14.0781 9.48047L11.125 12.4336L9.89453 11.2305C9.59375 10.9297 9.12891 10.9297 8.82812 11.2305C8.52734 11.5312 8.52734 11.9961 8.82812 12.2969L10.5781 14.0469C10.8789 14.3477 11.3438 14.3477 11.6445 14.0469L15.1445 10.5469Z"
			fill={color}
		/>
	</svg>
)

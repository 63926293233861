export const getVideoDuration = async (file: File): Promise<string> => {
	const url = URL.createObjectURL(file)
	const mediaElement = document.createElement('video')

	mediaElement.src = url

	await new Promise((resolve) => {
		mediaElement.addEventListener('loadedmetadata', resolve, { once: true })
	})
	const duration = mediaElement.duration.toFixed(2)
	URL.revokeObjectURL(url)

	return duration
}

export const EnterIcon = ({
	color = 'var(--mono400)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M8.74609 16.7539L5.24609 13.2539C4.89062 12.9258 4.89062 12.3516 5.24609 12.0234L8.74609 8.55078C9.07422 8.19531 9.64844 8.19531 9.97656 8.55078C10.332 8.87891 10.332 9.42578 9.97656 9.78125L7.98047 11.75H16.8125C17.0312 11.75 17.25 11.5586 17.25 11.3125V6.5C17.25 6.03516 17.6328 5.625 18.125 5.625C18.5898 5.625 19 6.03516 19 6.5V11.3125C19 12.543 18.0156 13.5 16.8125 13.5H7.98047L9.97656 15.5234C10.1406 15.6875 10.25 15.9062 10.25 16.125C10.25 16.3164 10.1406 16.5625 9.97656 16.7266C9.64844 17.1094 9.07422 17.1094 8.74609 16.7539Z"
			fill={color}
		/>
	</svg>
)

import { ChangeEvent, InputHTMLAttributes } from 'react'
import cn from 'classnames'
import { FileIcon, HDIcon } from 'assets/icons'
import FileInput from 'components/UI/FileInput'
import Hint from 'components/Wizard/Hint'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	direction?: 'vertical' | 'horizontal'
	form: 'circle' | 'rectangle' | 'square' | 'rectangle-h'
	label: string
	itemTitle: string
	hintText: string
	formatsText: string
	qualityText: string
	name: string
	fileType?: 'Image' | 'Video'
	fileInputProps?: InputHTMLAttributes<HTMLInputElement>
	value: File | string | null
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const FileUpload = ({
	label,
	itemTitle,
	form,
	hintText,
	formatsText,
	qualityText,
	fileType = 'Image',
	name,
	fileInputProps,
	value,
	onChange,
	direction = 'horizontal',
}: Props): JSX.Element => {
	return (
		<div className={cn(s.itemWrapper, s[direction])}>
			<div className={s.fileWrapper}>
				<FileInput
					{...fileInputProps}
					form={form}
					file={value}
					label={label}
					fileType={fileType}
					name={name}
					onChange={onChange}
				/>
			</div>
			<div className={s.info}>
				<h3 className="h3-m">{t(itemTitle)}</h3>
				<div className={cn(s.desc, 'grid2column')}>
					<div>
						<div style={{ display: 'flex' }}>
							<HDIcon />
						</div>
						<span className="caption-m">{t(qualityText)}</span>
					</div>
					<div>
						<div style={{ display: 'flex' }}>
							<FileIcon />
						</div>
						<span className="caption-m">{t(formatsText)}</span>
					</div>
				</div>
				<Hint hintText={t(hintText)} />
			</div>
		</div>
	)
}

export default FileUpload

/* eslint-disable react-hooks/exhaustive-deps */
import {
	LocalVideo,
	useLocalVideo,
	useMeetingManager,
} from 'amazon-chime-sdk-component-library-react'
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js'
import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useShallow } from 'zustand/react/shallow'
import {
	createRecordingContent,
	finishRecording,
	getAttendeeData,
	getMeetingData,
	startRecording,
} from 'api/meeting'
import { addRelation } from 'api/relations'
import Button from 'components/UI/Button'
import Loader from 'components/Loader'
import Card from 'components/Wizard/Card'
import { t, textParser } from 'helpers'
import { usePortfolioStore, useUserStore } from 'store'
import { PROFILE_VALIDATION_STATUS, RELATIONS } from 'types/enums'
import s from './index.module.scss'

const Validation = (): JSX.Element => {
	const user = useUserStore(useShallow((state) => state.user))
	const [validationStatus, setValidationStatus] =
		useState<PROFILE_VALIDATION_STATUS>(PROFILE_VALIDATION_STATUS.OPENED)
	const [loading, setLoading] = useState(false)
	const contentId = useRef('')
	const sessionId = useRef('')
	const meetingManager = useMeetingManager()
	const { isVideoEnabled, setIsVideoEnabled } = useLocalVideo()

	const updateValidationStatus = (status: PROFILE_VALIDATION_STATUS): void => {
		const { updateFields } = usePortfolioStore.getState()
		updateFields([
			{
				Name: 'Customer:Validate:Status',
				Value: status,
			},
		])
	}

	const toggleCamera = async (): Promise<void> => {
		if (isVideoEnabled || !meetingManager.selectedVideoInputDevice) {
			meetingManager.meetingSession?.audioVideo?.stopLocalVideoTile()
			setIsVideoEnabled(false)
		} else {
			await meetingManager.meetingSession?.audioVideo?.startVideoInput(
				meetingManager.selectedVideoInputDevice,
			)
			meetingManager.meetingSession?.audioVideo?.startLocalVideoTile()
			setIsVideoEnabled(true)
		}
	}

	const handleStartRecording = async (): Promise<void> => {
		try {
			await startRecording(sessionId.current, contentId.current)
			setValidationStatus(PROFILE_VALIDATION_STATUS.STARTED)
			updateValidationStatus(PROFILE_VALIDATION_STATUS.STARTED)
		} catch (error) {
			toast.info(t('APP_RECORDING_START_ERROR'))
		}
	}

	const handleStopRecording = async (): Promise<void> => {
		try {
			await finishRecording(sessionId.current)
			setValidationStatus(PROFILE_VALIDATION_STATUS.COMPLETED)
			updateValidationStatus(PROFILE_VALIDATION_STATUS.COMPLETED)
			addRelation({
				relation: RELATIONS.SIDESHOW,
				targetId: contentId.current,
				type: 'Customer',
			})
		} catch (error) {
			toast.info(t('APP_RECORDING_START_ERROR'))
		}
	}

	const leaveSession = (): void => {
		setIsVideoEnabled(false)
		if (meetingManager && meetingManager.audioVideo) {
			meetingManager.audioVideo.stop()

			meetingManager.leave()
		}
	}

	const getSessionData = useCallback(async (id: string): Promise<void> => {
		try {
			const meetingData = await getMeetingData(id)
			contentId.current = meetingData.contentId
			const attendeeData = await getAttendeeData(id)
			const meetingSessionConfiguration = new MeetingSessionConfiguration(
				meetingData,
				attendeeData,
			)
			await meetingManager.join(meetingSessionConfiguration)

			await meetingManager.start()

			toggleCamera()
		} catch (error) {
			console.log('error:', error)
			toast.info(t('APP_GET_SESSION_DATA_ERROR'))
		} finally {
			setLoading(false)
		}
	}, [])

	const createContent = useCallback(async () => {
		setLoading(true)
		const response = await createRecordingContent()
		sessionId.current = response.properties['Content:Process:SessionId']
		getSessionData(response.properties['Content:Process:SessionId'])
	}, [getSessionData])

	useEffect(() => {
		createContent()
		updateValidationStatus(PROFILE_VALIDATION_STATUS.OPENED)
	}, [createContent])

	useEffect(() => {
		if (validationStatus === PROFILE_VALIDATION_STATUS.COMPLETED)
			toast.info(t('APP_RECORDING_FINISHED_MESSAGE'))
	}, [validationStatus])

	useEffect(() => {
		return () => {
			leaveSession()
		}
	}, [])

	return (
		<>
			<Card step="validation" noHint className={s.container}>
				<>
					<div className={s.videoWrapper}>
						<Loader loading={loading} compressed />
						<LocalVideo />
						{isVideoEnabled ? (
							<div className={s.textOverlay}>
								{textParser(
									t('APP_VALIDATION_TEXT')?.replace(
										'[Full Name]',
										user?.ContentTitle || '',
									),
								)?.map((text) => (
									<span key={text} className="caption-m">
										{text}
									</span>
								))}
							</div>
						) : null}
					</div>
					{isVideoEnabled ? (
						<div className={s.recordButton}>
							<Button
								variant="themed"
								onClick={
									validationStatus === PROFILE_VALIDATION_STATUS.STARTED
										? handleStopRecording
										: handleStartRecording
								}>
								{t(
									validationStatus === PROFILE_VALIDATION_STATUS.STARTED
										? 'APP_STOP_RECORDING'
										: 'APP_START_RECORDING',
								)}
							</Button>
						</div>
					) : null}
				</>
			</Card>
		</>
	)
}

export default Validation

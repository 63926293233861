import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import cn from 'classnames'
import StepWrapper from 'components/Wizard/StepWrapper'
import MyNetwork from '../components/MyNetwork'
import AcceptInviteToPoolSidebar from './components/AcceptInviteToPoolSidebar'
import Profile from '../components/Profile'
import useUser from 'hooks/api/useUser'
import { useAppStore, useNetworkStore } from 'store'
import { Step } from 'types/app'
import s from '../index.module.scss'

export const actorNetworkSteps: { [key: string]: Step } = {
	opennetwork: {
		name: 'APP_OPEN_NETWORK',
		key: 'opennetwork',
		main: true,
		substeps: [
			{
				name: 'APP_OPEN_NETWORK',
				key: 'opennetwork',
				component: () => <MyNetwork source="opennetwork-actor" />,
				index: 0,
			},
		],
	},
}

const ActorNetwork = (): JSX.Element => {
	const navigate = useNavigate()
	const [userProfile] = useNetworkStore(
		useShallow((state) => [state.userProfile.user]),
	)
	const { profileId } = useParams()
	const { user: fetchedUser } = useUser(
		!userProfile && profileId ? profileId : '',
	)

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname +
					window.location.search +
					'#' +
					actorNetworkSteps.opennetwork?.key +
					'-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	const showUser = userProfile || fetchedUser

	return (
		<div className={s.container}>
			<AcceptInviteToPoolSidebar />

			{showUser && profileId ? (
				<div className={cn(s.wrapper, 'flex')}>
					<StepWrapper style={{ height: 'auto' }}>
						<Profile user={showUser} />
					</StepWrapper>
				</div>
			) : null}
			<div className={cn(s.wrapper, 'flex')}>
				<StepWizard
					initialStep={0}
					instance={assignStepWizard}
					isHashEnabled={true}
					className={s.wizard}>
					{Object.values(actorNetworkSteps)
						?.map((step) => step.substeps)
						?.flat()
						?.map((step) => (
							<StepWrapper
								style={{ height: 'auto' }}
								key={step.name + '_' + step.index}
								hashKey={step.key + '-' + step.index}
								stepName={step.key + '-' + step.index}>
								<step.component />
							</StepWrapper>
						))}
				</StepWizard>
			</div>
		</div>
	)
}

export default ActorNetwork

export const LikeIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M8.5 17C8.5 17.4922 8.08984 17.875 7.625 17.8203H5.875C5.38281 17.8203 5 17.4375 5 16.9727V10.875C5 10.4102 5.38281 10 5.875 10H7.625C8.08984 10 8.5 10.3828 8.5 10.875V17ZM19 10.9023C19 11.5859 18.4531 12.1602 17.7695 12.1875C17.9883 12.4336 18.125 12.7344 18.125 13.0898C18.125 13.7188 17.6602 14.2383 17.0312 14.375C17.168 14.5664 17.25 14.7852 17.25 15.0312C17.25 15.6328 16.8672 16.125 16.3203 16.2891C16.3477 16.3711 16.375 16.4805 16.375 16.5898C16.375 17.3008 15.7734 17.875 15.0625 17.875H13.4492C12.4922 17.875 11.5898 17.5742 10.8242 17L9.89453 16.3164C9.56641 16.0703 9.375 15.6875 9.375 15.25V10.875C9.375 10.9023 9.375 10.875 9.375 10.875C9.375 10.4922 9.53906 10.1367 9.86719 9.86328L10.3047 9.50781C12.5469 7.73047 11.5352 5.625 13.2578 5.625C14.1055 5.625 14.625 6.30859 14.625 6.96484C14.625 7.375 14.2969 8.55078 13.668 9.58984H17.6875C18.3984 9.58984 19 10.1641 19 10.9023Z"
			fill={color}
		/>
	</svg>
)

export const ArrowLongIcon = ({
	color = 'var(--mono100)',
	width = '68',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 68 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7 12H61M61 12L55.5 6.5M61 12L55.5 17.5"
			stroke={color}
			strokeWidth="2"
		/>
	</svg>
)

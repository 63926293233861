import { InputHTMLAttributes, RefObject, useState } from 'react'
import cn from 'classnames'
import InputLabel from '../InputLabel'
import s from './index.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label?: string
	error?: string
	inputRef?: RefObject<HTMLInputElement>
	startAdornment?: JSX.Element
	endAdornment?: JSX.Element
	variant?: 'filled' | 'default'
}

const Input = ({
	label,
	error,
	inputRef,
	startAdornment,
	endAdornment,
	variant = 'filled',
	...props
}: Props): JSX.Element => {
	const [fieldLoading, setFieldLoading] = useState(false)

	return (
		<div className={s.container}>
			<InputLabel
				label={label}
				status={fieldLoading ? 'loading' : props.value ? 'filled' : 'none'}
			/>
			<input
				className={cn(
					s.input,
					'body1-m',
					{
						[s.error]: error,
						[s.aAdornment]: !!startAdornment,
						[s.eAdornment]: !!endAdornment,
					},
					props.className,
					s[variant],
				)}
				placeholder={props.placeholder}
				{...props}
				autoComplete="off"
				ref={inputRef}
				onFocus={() => {
					setFieldLoading(true)
				}}
				onBlur={(e) => {
					if (props.onBlur) props.onBlur(e)

					setFieldLoading(false)
				}}
			/>
			{startAdornment ? (
				<div className={s.startAdornment}>{startAdornment}</div>
			) : null}
			{endAdornment ? (
				<div className={s.endAdornment}>{endAdornment}</div>
			) : null}
		</div>
	)
}

export default Input

export const RequestIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M17.2227 4.75C18.207 4.75 18.9727 5.54297 18.9727 6.5V14.3203C18.9727 15.25 18.1797 16.043 17.2227 16.043H13.2852L9.86719 18.6133C9.64844 18.75 9.34766 18.6133 9.34766 18.3398V16.0703H6.72266C5.73828 16.0703 4.97266 15.3047 4.97266 14.3477V6.5C4.97266 5.54297 5.73828 4.75 6.72266 4.75H17.2227ZM11.3438 7.59375V10.6562C11.3438 11.0391 11.6172 11.3125 12 11.3125C12.3555 11.3125 12.6562 11.0391 12.6562 10.6562V7.59375C12.6562 7.23828 12.3555 6.9375 12 6.9375C11.6172 6.9375 11.3438 7.23828 11.3438 7.59375ZM12 13.9375C12.4648 13.9375 12.875 13.5547 12.875 13.0625C12.875 12.5977 12.4648 12.1875 12 12.1875C11.5078 12.1875 11.125 12.5977 11.125 13.0625C11.125 13.5547 11.5078 13.9375 12 13.9375Z"
			fill={color}
		/>
	</svg>
)

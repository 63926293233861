import { useShallow } from 'zustand/react/shallow'
import { PlusIcon } from 'assets/icons'
import Input from 'components/UI/Input'
import Sidebar from 'components/Sidebar'
import { useAppStore } from 'store'
import s from './index.module.scss'

const InstructionsSidebar = (): JSX.Element => {
	const [openSidebar, setOpenSidebar, scene, setScene] = useAppStore(
		useShallow((state) => [
			state.addSceneSidebar.openInstructions,
			state.addSceneSidebar.setOpenInstructions,
			state.addSceneSidebar.scene,
			state.addSceneSidebar.setScene,
		]),
	)

	const onDelete = (tag: string): void => {
		setScene({
			instructions: scene.instructions?.filter((t) => t !== tag),
		})
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			additional
			header={{
				title: 'APP_ROLE_INSTRUCTIONS_SIDEBAR_TITLE',
				description: 'APP_ROLE_INSTRUCTIONS_SIDEBAR_DESCRIPTION',
			}}>
			<>
				<Input
					label="APP_INSTRUCTIONS_LABEL"
					name="instructions"
					onChange={(e) => {
						if (e.target.value.endsWith(' ')) {
							setScene({
								instructions: [...scene.instructions, e.target.value?.trim()],
							})
							e.target.value = ''
						}
					}}
				/>
				<div className={s.tagsWrapper}>
					{scene?.instructions
						? scene?.instructions
								?.filter((v) => !!v)
								?.map((tag) => (
									<div key={tag} className={s.tag}>
										<span key={tag} className={'caption-r'}>
											{tag}
										</span>
										<div className={s.remove} onClick={() => onDelete(tag)}>
											<PlusIcon
												width="20"
												height="20"
												style={{ transform: 'rotate(45deg)' }}
											/>
										</div>
									</div>
								))
						: null}
				</div>
			</>
		</Sidebar>
	)
}

export default InstructionsSidebar

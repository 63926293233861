import { ButtonHTMLAttributes } from 'react'
import cn from 'classnames'
import s from './index.module.scss'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'themed' | 'default' | 'filled' | 'outlined'
	textclassname?: string
	theme?: 'black' | 'white'
}

const Button = ({
	variant = 'default',
	children,
	theme,
	...props
}: Props): JSX.Element => (
	<button
		{...props}
		className={cn(
			s[variant],
			s[theme || ''],
			props.textclassname ? props.textclassname : 'body1-b',
			props.className,
		)}
		type="button"
	>
		{children}
	</button>
)

export default Button

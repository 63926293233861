import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import { bindInvites, usersBatchInvite } from 'api/user'
import Sidebar from 'components/Sidebar'
import Button from 'components/UI/Button'
import Textarea from 'components/UI/Textarea'
import { t } from 'helpers'
import { useAppStore, useCastingStore } from 'store'
import { CASTING_STATUS, RELATIONS } from 'types/enums'

const RejectNdaSidebar = (): JSX.Element => {
	const [comment, setComment] = useState(t('APP_REJECT_NDA_DEFAULT'))
	const [openSidebar, setOpenSidebar, reviewNda, setReviewNdaSidebar] =
		useCastingStore(
			useShallow((state) => [
				state.openRejectNdaSidebar,
				state.setOpenRejectNdaSidebar,
				state.openReviewNdaSidebar,
				state.setOpenReviewNdaSidebar,
			]),
		)

	const onClose = (): void => {
		reviewNda?.refresh()
		const { setDocumentViewerSidebar } = useAppStore.getState()
		setDocumentViewerSidebar(null)
		setReviewNdaSidebar(null)
		setOpenSidebar(false)
	}

	const onReject = async (): Promise<void> => {
		if (reviewNda) {
			await addRelation({
				sourceId: reviewNda?.contentId,
				targetId: reviewNda?.userId || '',
				type: 'Content',
				relation: RELATIONS.ACCESS,
				status: CASTING_STATUS.REJECTED,
			})
			await usersBatchInvite({
				sourceId: reviewNda?.contentId,
				customerIds: [reviewNda?.userId],
				message: comment,
				subject: '[YourName] rejected for Casting',
				relation: RELATIONS.ACCESS,
				type: 'Content',
			})
			await bindInvites(reviewNda?.contentId)
		}
		onClose()
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={onClose}
			iconClose
			header={{
				title: 'APP_CASTING_REJECT_NDA_SIDEBAR_TITLE',
				description: 'APP_CASTING_REJECT_NDA_SIDEBAR_DESCRIPTION',
			}}
			style={{ height: '100%' }}>
			<>
				<Textarea
					name="reject_comment"
					label="APP_REJECT_COMMENT"
					defaultValue={t('APP_REJECT_NDA_DEFAULT')}
					onChange={(e) => setComment(e.target.value)}
					rows={15}
				/>

				<div className="grid2column" style={{ marginTop: 'auto' }}>
					<Button onClick={() => setOpenSidebar(false)}>
						{t('APP_CANCEL')}
					</Button>
					<Button variant="themed" onClick={onReject}>
						{t('APP_CONFIRM')}
					</Button>
				</div>
			</>
		</Sidebar>
	)
}

export default RejectNdaSidebar

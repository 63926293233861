import { useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import classNames from 'classnames'
import Candidates from '../Candidates'
import Roles from '../Roles'
import { useOutsideClick } from 'hooks'
import { useCastingStore, useUserStore } from 'store'
import s from './index.module.scss'
import style from '../../index.module.scss'
import { CUSTOMER_ROLE } from 'types/user'

const CastingRoles = (): JSX.Element => {
	const ref = useRef(null)
	const director = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)
	const [open, setOpen] = useCastingStore(
		useShallow((state) => [state.openCastingRoles, state.setOpenCastingRoles]),
	)

	useOutsideClick(ref, () => setOpen(false))

	return (
		<>
			{director ? (
				<>
					<div
						onClick={() => setOpen(true)}
						onMouseOver={() => setOpen(true)}
						className={classNames(style.verticalButton, {
							[style.hideVerticalButton]: open,
						})}
						style={{
							left: '-12px',
						}}
					/>
					<div
						ref={ref}
						style={
							open
								? {
										zIndex: 1,
								  }
								: {}
						}
						className={classNames('castingToolWrapper', s.container, {
							['show']: open,
						})}>
						<div className="grid2column" style={{ gap: '0' }}>
							<Roles />
							<Candidates />
						</div>
					</div>
				</>
			) : null}
		</>
	)
}

export default CastingRoles

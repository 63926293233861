export const FileIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.25 6.5C7.25 5.54297 8.01562 4.75 9 4.75H13.375V8.25C13.375 8.74219 13.7578 9.125 14.25 9.125H17.75V17C17.75 17.9844 16.957 18.75 16 18.75H9C8.01562 18.75 7.25 17.9844 7.25 17V6.5ZM14.25 8.25V4.75L17.75 8.25H14.25Z"
			fill={color}
		/>
	</svg>
)

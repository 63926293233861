import useSwrImmutable from 'swr/immutable'
import { multipleFetcher } from 'api'
import { propertiesParser, actionsParser } from 'helpers/propertiesParser'
import { useAppStore } from 'store'
import { ContentCollection, Content, FetchReturn } from 'types/app'
import { KeyedMutator } from 'swr'

type ReturnData = FetchReturn & {
	contents: Content[] | undefined
	refreshData: KeyedMutator<ContentCollection[]>
}

export default (ids: string[] | undefined | null): ReturnData => {
	const { endpoints } = useAppStore.getState()

	const urls = ids?.length
		? ids?.map((id) =>
				endpoints?.CustomApplicationV3ContentUrl.replace('${contentId}', id),
		  )
		: null

	const { data, error, isLoading, mutate } = useSwrImmutable<
		ContentCollection[]
	>(urls ? urls : null, multipleFetcher)

	const contents =
		data && data?.length
			? data
					?.filter((d) => !!d?.Properties)
					.map(
						(d) =>
							d.Properties &&
							({
								...propertiesParser(d.Properties),
								Actions: actionsParser(d.Actions),
							} as Content),
					)
			: undefined

	return {
		isLoading: isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		contents,
		refreshData: mutate,
	}
}

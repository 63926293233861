import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import { ArrowShortIcon } from 'assets/icons'
import InputLabel from 'components/UI/InputLabel'
import { useProductionStore } from 'store'
import s from './index.module.scss'

interface Props {
	disabled?: boolean
}

const Location = ({ disabled }: Props): JSX.Element => {
	const [locations, openLocationSidebar] = useProductionStore(
		useShallow((state) => [
			state.production?.locations?.filter((l) => !!l),
			state.openLocationSidebar,
		]),
	)

	return (
		<div
			className={s.container}
			onClick={() => {
				if (disabled) return

				const { setOpenLocationSidebar } = useProductionStore.getState()
				setOpenLocationSidebar(true)
			}}>
			<InputLabel
				label="APP_LOCATION_LABEL"
				status={
					openLocationSidebar
						? 'loading'
						: locations?.length
						? 'filled'
						: 'none'
				}
			/>
			<div className={cn('card', s.wrapper)}>
				<span
					style={{ color: disabled ? 'var(--mono400)' : 'var(--mono200)' }}
					className={`${openLocationSidebar ? '' : 'textEllipsis'}`}>
					{locations?.join(', ')}
				</span>
				<div style={{ display: 'flex' }}>
					<ArrowShortIcon color="var(--mono200)" />
				</div>
			</div>
		</div>
	)
}

export default Location

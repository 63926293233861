export const PeopleIcon = ({
	color = 'var(--mono100)',
	width = '25',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M8.78125 7.15625C8.78125 8.00391 8.07031 8.6875 7.25 8.6875C6.40234 8.6875 5.71875 8.00391 5.71875 7.15625C5.71875 6.33594 6.40234 5.625 7.25 5.625C8.07031 5.625 8.78125 6.33594 8.78125 7.15625ZM9.4375 10.1367C8.61719 10.8477 8.125 11.9141 8.125 13.0625C8.125 14.0195 8.42578 14.8672 9 15.5508V16.125C9 16.6172 8.58984 17 8.125 17H6.375C5.88281 17 5.5 16.6172 5.5 16.125V15.4141C4.46094 14.9219 3.75 13.8555 3.75 12.625C3.75 10.957 5.11719 9.5625 6.8125 9.5625H7.6875C8.31641 9.5625 8.94531 9.78125 9.4375 10.1367ZM5.5 11.4766C5.22656 11.7773 5.0625 12.1875 5.0625 12.625C5.0625 13.0898 5.22656 13.5 5.5 13.8008V11.4766ZM16 16.125V15.5508C16.5469 14.8672 16.875 14.0195 16.875 13.0625C16.875 11.9141 16.3555 10.8477 15.5352 10.1367C16.0273 9.78125 16.6562 9.5625 17.3125 9.5625H18.1875C19.8555 9.5625 21.25 10.957 21.25 12.625C21.25 13.8555 20.5117 14.9219 19.5 15.4141V16.125C19.5 16.6172 19.0898 17 18.625 17H16.875C16.3828 17 16 16.6172 16 16.125ZM19.5 13.8008C19.7461 13.5 19.9375 13.0898 19.9375 12.625C19.9375 12.1875 19.7461 11.7773 19.5 11.4766V13.8008ZM19.2812 7.15625C19.2812 8.00391 18.5703 8.6875 17.75 8.6875C16.9023 8.6875 16.2188 8.00391 16.2188 7.15625C16.2188 6.33594 16.9023 5.625 17.75 5.625C18.5703 5.625 19.2812 6.33594 19.2812 7.15625ZM10.75 7.375C10.75 6.41797 11.5156 5.625 12.5 5.625C13.457 5.625 14.25 6.41797 14.25 7.375C14.25 8.35938 13.457 9.125 12.5 9.125C11.5156 9.125 10.75 8.35938 10.75 7.375ZM16 13.0625C16 14.293 15.2617 15.3594 14.25 15.8516V17C14.25 17.4922 13.8398 17.875 13.375 17.875H11.625C11.1328 17.875 10.75 17.4922 10.75 17V15.8516C9.71094 15.3594 9 14.293 9 13.0625C9 11.3945 10.3672 10 12.0625 10H12.9375C14.6055 10 16 11.3945 16 13.0625ZM10.75 14.2383V11.9141C10.4766 12.2148 10.3125 12.625 10.3125 13.0625C10.3125 13.5273 10.4766 13.9375 10.75 14.2383ZM14.25 11.9141V14.2383C14.4961 13.9102 14.6875 13.5273 14.6875 13.0625C14.6875 12.625 14.4961 12.2148 14.25 11.9141Z"
			fill={color}
		/>
	</svg>
)

import useSwrImmutable from 'swr/immutable'
import { multipleFetcher } from 'api'
import { propertiesParser, actionsParser } from 'helpers/propertiesParser'
import { useAppStore } from 'store'
import { ContentCollection, FetchReturn } from 'types/app'
import { User } from 'types/user'

type ReturnData = FetchReturn & {
	users: User[] | undefined
}

export default (ids: string[] | undefined | null): ReturnData => {
	const { endpoints } = useAppStore.getState()

	const urls = ids?.length
		? ids?.map((id) =>
				endpoints?.CustomApplicationV3CustomerUrl.replace('${customerId}', id),
		  )
		: null

	const { data, error, isLoading } = useSwrImmutable<ContentCollection[]>(
		ids?.length && urls ? urls : null,
		multipleFetcher,
	)

	const userData = data?.length
		? data.map(
				(d) =>
					({
						...propertiesParser(d.Properties),
						Actions: actionsParser(d.Actions),
					} as User),
		  )
		: undefined

	return {
		isLoading: isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		users: userData,
	}
}

export const NetworkIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M15.3906 14.7578C15.9648 15.1406 16.375 15.8242 16.375 16.5625C16.375 17.793 15.3906 18.75 14.1875 18.75C12.957 18.75 12 17.793 12 16.5625C12 16.3438 12.0273 16.125 12.082 15.9062L8.11719 12.4336C7.81641 12.5703 7.51562 12.625 7.1875 12.625C5.95703 12.625 5 11.668 5 10.4375C5 9.23438 5.95703 8.25 7.1875 8.25C7.76172 8.25 8.30859 8.49609 8.71875 8.90625L13.75 6.88281C13.7773 5.70703 14.7344 4.75 15.9375 4.75C17.1406 4.75 18.125 5.73438 18.125 6.9375C18.125 7.97656 17.3867 8.85156 16.4297 9.07031L15.3906 14.7578ZM9.26562 11.1211L13.2305 14.5938C13.3672 14.5391 13.5312 14.4844 13.668 14.457L14.707 8.76953C14.5977 8.6875 14.4883 8.60547 14.3789 8.49609L9.34766 10.5195C9.34766 10.7109 9.32031 10.9297 9.26562 11.1211Z"
			fill={color}
		/>
	</svg>
)

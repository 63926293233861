import { HexagonIcon } from 'assets/icons'
import { capitalize, t } from 'helpers'
import { useUserStore } from 'store'
import { SelectOption, ValueLevel } from 'types/app'
import s from './index.module.scss'

interface Props {
	value: ValueLevel
	levelIndex: number
	label: string
	valuesOptions: SelectOption[]
	levelsOptions: SelectOption[]
}

const SkillItem = ({
	value,
	levelIndex,
	levelsOptions,
	label,
	valuesOptions,
}: Props): JSX.Element => (
	<div
		className={s.skill}
		onClick={() => {
			const { setActiveAttribute } = useUserStore.getState()
			setActiveAttribute(`${capitalize(label)}:${value?.Value}`)
		}}>
		<HexagonIcon
			style={{
				zIndex: '1',
			}}
			width="106"
			height="116"
			color={
				levelIndex < 4 ? '#C0C0C0' : levelIndex < 7 ? '#0A43EC' : '#FFD700'
			}
		/>
		<div className={s.wrapper}>
			<span className="caption-r textClamp" style={{ WebkitLineClamp: '2' }}>
				{t(`APP_HEX_${label.toUpperCase()}`)}
			</span>
			<span className={s.value}>
				{valuesOptions?.find((option) => option.value === value?.Value)?.label}
			</span>
			<span></span>
			<span
				className="caption-r textClamp"
				style={{ WebkitLineClamp: '2', color: 'var(--mono300)' }}>
				{levelsOptions?.find((option) => option.value === value?.Level)?.label}
			</span>
		</div>
	</div>
)

export default SkillItem

import cn from 'classnames'
import { PlusIcon } from 'assets/icons'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	label: string
	onClick: () => void
}

const CreateCardPlaceholder = ({ label, onClick }: Props): JSX.Element => (
	<div onClick={onClick} className={cn('card', s.addItemCard)}>
		<PlusIcon color="var(--mono400)" />
		<span style={{ color: 'var(--mono400)' }}>{t(label)}</span>
	</div>
)

export default CreateCardPlaceholder

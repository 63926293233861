import Tooltip from 'components/Tooltip'
import { PLAYER_SPEED } from 'types/enums'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
	speed: number
	onChange: (v: { [key: string]: number }) => void
}

const Speed = ({ videoElement, speed, onChange }: Props): JSX.Element => {
	const onSpeedClick = (): void => {
		const current = PLAYER_SPEED.findIndex((v) => v === speed)
		let next = speed
		if (PLAYER_SPEED[current + 1]) next = PLAYER_SPEED[current + 1]
		else next = PLAYER_SPEED[0]

		if (videoElement) videoElement.playbackRate = next
		onChange({ speed: next })
	}

	return (
		<Tooltip title="APP_SPEED_CONTROL">
			<div className={s.control} onClick={onSpeedClick}>
				<span
					className="body2-m"
					style={{
						color: 'var(--mono300)',
						width: '24px',
						height: '24px',
						alignContent: 'center',
						textAlign: 'center',
					}}>
					{`${speed}x`}
				</span>
			</div>
		</Tooltip>
	)
}

export default Speed

import { useMemo } from 'react'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { ArrowShortIcon, EmptyIcon } from 'assets/icons'
import { optionsParser, t } from 'helpers'
import { usePortfolioStore } from 'store'
import { AttributeCardType, ValueLevel } from 'types/app'
import { PORTFOLIO_TYPES } from 'types/enums'
import s from './index.module.scss'

interface Props {
	generalType: PORTFOLIO_TYPES
	cardData: AttributeCardType
	type: string
}

const AttributeLargeCard = ({
	generalType,
	type,
	cardData,
}: Props): JSX.Element => {
	const { selectors, index = 0 } = cardData
	const values = usePortfolioStore(
		useShallow(
			(state) =>
				(state.cardValues[type] as { [key: string]: string | File }) || {},
		),
	)

	const selectorsData = useMemo(
		() =>
			selectors?.slice(1)?.map((select) => ({
				...select,
				options: optionsParser(t(select.optionName || '')),
			})),
		[selectors],
	)

	const parsed = useMemo(
		() =>
			Object.entries(values)
				.map(([key, value]) => ({
					[key]: selectorsData
						?.find((data) => data.name === key)
						?.options?.filter((option) =>
							Array.isArray(value)
								? (value as string[])?.includes(option.value) ||
								  (value as ValueLevel[])?.find(
										(v) => v?.Value === option.value,
								  )
								: option.value === value,
						)?.[0]?.label,
				}))
				?.reduce(
					(prev, next) => ({
						...prev,
						...next,
					}),
					{},
				),
		[selectorsData, values],
	)

	const file = values[type] instanceof File

	const pinned = values?.[selectors?.[0]?.name]

	return (
		<div className={s.container}>
			<div
				className={s.wrapper}
				onClick={() => {
					const { setModalType, setGeneralModalType, setCardData } =
						usePortfolioStore.getState()
					setGeneralModalType(generalType)
					setModalType(type)
					setCardData(cardData)
				}}>
				<div className={cn(s.media, s[generalType], { [s.pinned]: !!pinned })}>
					{!values[type] ? (
						<div>
							<EmptyIcon />
						</div>
					) : null}
					<img
						src={
							values?.image
								? (values?.image as string)
								: values[type]
								? file && generalType === PORTFOLIO_TYPES.PICTURE
									? URL.createObjectURL(values[type] as File)
									: file
									? '/video-placeholder.png'
									: (values[type] as string)?.startsWith('http')
									? (values[type] as string)
									: '/placeholder.png'
								: '/photo-placeholder.png'
						}
						style={!values[type] ? { filter: 'grayscale(1)' } : {}}
					/>
					{file && generalType === PORTFOLIO_TYPES.SHOWREEL ? (
						<span
							className="caption-m"
							style={{
								position: 'absolute',
								zIndex: 1,
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
							}}>
							{t('APP_VIDEO_UPLOAD')}
						</span>
					) : null}
				</div>
				<div className={s.contentWrapper}>
					<div className={s.title}>
						<h4 className="h4-b">
							{`${t(`APP_${generalType?.toUpperCase()}_CARD`)} #` + (index + 1)}
						</h4>
						<div style={{ display: 'flex' }}>
							<ArrowShortIcon />
						</div>
					</div>
					<div className={s.values}>
						{selectorsData?.map((data) => (
							<div className={s.value} key={data.name}>
								<span className="body1-m" style={{ color: 'var(--mono400)' }}>
									{t(data?.label)}
								</span>
								<span
									className={parsed?.[data?.name] ? 'body1-b' : 'body1-m'}
									style={{
										color: parsed?.[data?.name]
											? 'var(--mono100)'
											: 'var(--mono400)',
									}}>
									{(parsed?.[data?.name] as string) || t('APP_NOT_ADDED_LABEL')}
								</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default AttributeLargeCard

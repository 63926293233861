import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { useEffect } from 'react'
import cn from 'classnames'
import { ArrowShortIcon } from 'assets/icons'
import InputLabel from 'components/UI/InputLabel'
import useRelations from 'hooks/api/useRelations'
import useUsers from 'hooks/api/useUsers'
import { EventBus } from 'helpers/EventBus'
import { useProductionStore } from 'store'
import { Relation } from 'types/app'
import { RELATIONS } from 'types/enums'
import s from './index.module.scss'

const Access = (): JSX.Element => {
	const { productionId } = useParams()
	const [openSidebar] = useProductionStore(
		useShallow((state) => [state.openAccessSidebar]),
	)

	const { data, refresh } = useRelations(RELATIONS.INVITE, productionId || '')
	const { users } = useUsers((data as Relation[])?.map((rel) => rel.targetId))

	useEffect(() => {
		EventBus.$on('refreshAccessField', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshAccessField')
		}
	}, [refresh])

	return (
		<div
			className={s.container}
			onClick={() => {
				const { setOpenAccessSidebar } = useProductionStore.getState()
				setOpenAccessSidebar(true)
			}}>
			<InputLabel
				label="APP_ACCESS_PRODUCTION_LABEL"
				status={users?.length ? 'filled' : 'none'}
			/>
			<div className={cn('card', s.wrapper)}>
				<span
					style={{ color: 'var(--mono200)' }}
					className={`${openSidebar ? '' : 'textEllipsis'}`}>
					{users?.map((u) => u?.ContentTitle)?.join(', ')}
				</span>
				<div style={{ display: 'flex' }}>
					<ArrowShortIcon color="var(--mono200)" />
				</div>
			</div>
		</div>
	)
}

export default Access

export const StarIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M14.543 8.87891L18.4531 9.45312C18.7812 9.50781 19.0547 9.72656 19.1641 10.0547C19.2734 10.3555 19.1914 10.7109 18.9453 10.9297L16.1016 13.7461L16.7852 17.7383C16.8398 18.0664 16.7031 18.3945 16.4297 18.5859C16.1562 18.8047 15.8008 18.8047 15.5 18.668L12 16.7812L8.47266 18.668C8.19922 18.8047 7.81641 18.8047 7.57031 18.5859C7.29688 18.3945 7.16016 18.0664 7.21484 17.7383L7.87109 13.7461L5.02734 10.9297C4.78125 10.7109 4.69922 10.3555 4.80859 10.0547C4.91797 9.72656 5.19141 9.50781 5.51953 9.45312L9.45703 8.87891L11.207 5.24219C11.3438 4.94141 11.6445 4.75 12 4.75C12.3281 4.75 12.6289 4.94141 12.7656 5.24219L14.543 8.87891Z"
			fill={color}
		/>
	</svg>
)

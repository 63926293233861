export const FilterIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '25',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.08203 6.75391C5.27344 6.37109 5.65625 6.125 6.09375 6.125H17.9062C18.3164 6.125 18.6992 6.37109 18.8906 6.75391C19.0547 7.13672 19 7.60156 18.7266 7.92969L13.75 14.0273V17.5C13.75 17.8555 13.5586 18.1562 13.2578 18.293C12.957 18.4297 12.6016 18.4023 12.3281 18.2109L10.5781 16.8984C10.3594 16.7344 10.25 16.4883 10.25 16.1875V14.0273L5.24609 7.92969C4.97266 7.60156 4.91797 7.13672 5.08203 6.75391Z"
			fill={color}
		/>
	</svg>
)

export const InfoFilledIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M12 4.75C15.8555 4.75 19 7.89453 19 11.75C19 15.6328 15.8555 18.75 12 18.75C8.11719 18.75 5 15.6328 5 11.75C5 7.89453 8.11719 4.75 12 4.75ZM12 8.25C11.5078 8.25 11.125 8.66016 11.125 9.125C11.125 9.61719 11.5078 10 12 10C12.4648 10 12.875 9.61719 12.875 9.125C12.875 8.66016 12.4648 8.25 12 8.25ZM13.0938 15.25C13.4492 15.25 13.75 14.9766 13.75 14.5938C13.75 14.2383 13.4492 13.9375 13.0938 13.9375H12.6562V11.5312C12.6562 11.1758 12.3555 10.875 12 10.875H11.125C10.7422 10.875 10.4688 11.1758 10.4688 11.5312C10.4688 11.9141 10.7422 12.1875 11.125 12.1875H11.3438V13.9375H10.9062C10.5234 13.9375 10.25 14.2383 10.25 14.5938C10.25 14.9766 10.5234 15.25 10.9062 15.25H13.0938Z"
			fill={color}
		/>
	</svg>
)

export const InfoOutlinedIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4ZM12 18.5C15.5625 18.5 18.5 15.5938 18.5 12C18.5 8.4375 15.5625 5.5 12 5.5C8.40625 5.5 5.5 8.4375 5.5 12C5.5 15.5938 8.40625 18.5 12 18.5ZM13.25 14.5C13.6562 14.5 14 14.8438 14 15.25C14 15.6875 13.6562 16 13.25 16H10.75C10.3125 16 10 15.6875 10 15.25C10 14.8438 10.3125 14.5 10.75 14.5H11.25V12.5H11C10.5625 12.5 10.25 12.1875 10.25 11.75C10.25 11.3438 10.5625 11 11 11H12C12.4062 11 12.75 11.3438 12.75 11.75V14.5H13.25ZM12 10C11.4375 10 11 9.5625 11 9C11 8.46875 11.4375 8 12 8C12.5312 8 13 8.46875 13 9C13 9.5625 12.5312 10 12 10Z"
			fill={color}
		/>
	</svg>
)

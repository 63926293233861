import useSwrImmutable from 'swr/immutable'
import { relationFetcher } from './useRelationsContent'
import { useAppStore } from 'store'
import { Content } from 'types/app'
import { RELATIONS } from 'types/enums'
import { KeyedMutator } from 'swr'

type Return = {
	isLoading: boolean
	contents: Content[] | undefined
	refresh: KeyedMutator<Content[]>
}

export default (
	contentId: string | undefined,
	relation: RELATIONS,
	bag = false,
): Return => {
	const { endpoints } = useAppStore.getState()
	const relationUrl = endpoints?.CustomApplicationV3ConnectionPostUrl

	const { data, isLoading, mutate } = useSwrImmutable<Content[]>(
		relationUrl && contentId
			? relationUrl + `/${contentId}/Content/${relation}`
			: null,
		(url) => relationFetcher(url, false, bag),
	)

	return {
		contents: data,
		isLoading,
		refresh: mutate,
	}
}

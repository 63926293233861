import { FlagImage } from 'react-international-phone'

interface Props {
	code: string | undefined
}

const CountryFlag = ({ code }: Props): JSX.Element =>
	code ? (
		<div
			style={{
				display: 'flex',
				borderRadius: '50%',
				overflow: 'hidden',
				width: '20px',
				height: '20px',
				position: 'relative',
			}}>
			<FlagImage
				style={{
					width: '30px',
					height: '30px',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				}}
				iso2={code}
			/>
		</div>
	) : (
		<></>
	)

export default CountryFlag

export const TimeIcon = ({
	color = 'var(--mono100)',
	width = '20',
	height = '20',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M16 10.5C16 13.8281 13.3047 16.5 10 16.5C6.67188 16.5 4 13.8281 4 10.5C4 8.83594 4.67969 7.3125 5.78125 6.23438C6.08594 5.92969 6.55469 5.92969 6.85938 6.23438C7.14062 6.53906 7.14062 7.00781 6.83594 7.28906C6.01562 8.10938 5.5 9.25781 5.5 10.5C5.5 12.9844 7.49219 15 10 15C12.4844 15 14.5 12.9844 14.5 10.5C14.5 8.27344 12.8594 6.42188 10.75 6.07031V6.75C10.75 7.17188 10.3984 7.5 10 7.5C9.57812 7.5 9.25 7.17188 9.25 6.75V5.25C9.25 4.85156 9.57812 4.5 10 4.5C13.3047 4.5 16 7.19531 16 10.5ZM10.375 10.1016C10.6094 10.3359 10.6094 10.6875 10.375 10.8984C10.1641 11.1328 9.8125 11.1328 9.60156 10.8984L7.72656 9.02344C7.49219 8.8125 7.49219 8.46094 7.72656 8.22656C7.9375 8.01562 8.28906 8.01562 8.5 8.22656L10.375 10.1016Z"
			fill={color}
		/>
	</svg>
)

import { useState } from 'react'
import { toast } from 'react-toastify'
import { useShallow } from 'zustand/react/shallow'
import { useParams } from 'react-router-dom'
import { DownloadIcon, PlusIcon } from 'assets/icons'
import { partialContentUpdate } from 'api/content'
import { upload } from 'api/aws'
import Sidebar from 'components/Sidebar'
import DragDrop from 'components/UI/DragDrop'
import useContent from 'hooks/api/useContent'
import { t } from 'helpers'
import { useProductionStore } from 'store'

const NdaSidebar = (): JSX.Element => {
	const [openSidebar, setOpenSidebar] = useProductionStore(
		useShallow((state) => [state.openNdaSidebar, state.setOpenNdaSidebar]),
	)
	const [deleted, setDeleted] = useState('')
	const { productionId } = useParams()
	const [file, setFile] = useState<File | null>(null)

	const { content: prodFull, isLoading } = useContent(productionId, 'full')
	const prodNda = deleted
		? null
		: prodFull?.assets?.find(
				(asset) => !asset?.isDeleted && asset.publicUrl?.endsWith('.pdf'),
		  )

	const confirmNda = async (): Promise<void> => {
		try {
			const { setNdaFile, createAsset } = useProductionStore.getState()
			setNdaFile(file)
			if (productionId && file) {
				const uploadedFile = await upload({
					fileObj: file,
					assetType: 'Content',
				})
				await createAsset(
					uploadedFile,
					`NDA - ${file.name}`,
					uploadedFile.originalFileName,
					uploadedFile.fileAssetUrl,
					uploadedFile.cdnUrl,
					productionId,
				)
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			setOpenSidebar(false)
		}
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			header={{
				title: 'APP_NDA_SIDEBAR_TITLE',
				description: 'APP_NDA_SIDEBAR_DESCRIPTION',
			}}
			loading={isLoading}
			buttons={[
				{
					children: t('APP_DONE'),
					onClick: () => {
						if (file) {
							confirmNda()
						} else {
							toast.info(t('APP_FILL_FIELDS'))
						}
					},
				},
			]}>
			<>
				{prodNda ? (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '10px',
						}}>
						<DownloadIcon />
						<a
							className="body1-b"
							href={prodNda?.publicUrl}
							target="_blank"
							download
							style={{
								textDecoration: 'none',
								textTransform: 'none',
								color: 'var(--mono100)',
							}}>
							{t('APP_DOWNLOAD_PRODUCTION_NDA')}
						</a>
					</div>
				) : null}
				<DragDrop
					hint="APP_DRAG_&_DROP"
					name="nda-attachments"
					accept=".doc, .docx, application/pdf"
					onChange={(e) => {
						if (e.target.files?.length) setFile(e.target.files[0])
					}}
				/>
				{file || prodNda ? (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '10px',
						}}>
						<span className="textEllipsis">
							{file?.name || prodNda?.fileName}
						</span>
						<div
							style={{ display: 'flex', cursor: 'pointer' }}
							onClick={() => {
								if (file) {
									setFile(null)
								} else if (prodNda && prodFull?.ContentId) {
									partialContentUpdate(prodFull?.ContentId, {
										assets: prodFull?.assets?.map((asset) => {
											if (asset.id === prodNda.id) {
												return {
													...asset,
													isDeleted: true,
												}
											} else return asset
										}),
									})
									setDeleted(prodNda.id)
								}
							}}>
							<PlusIcon
								style={{
									transform: 'rotate(45deg)',
									marginLeft: 'auto',
								}}
							/>
						</div>
					</div>
				) : null}
			</>
		</Sidebar>
	)
}

export default NdaSidebar

import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import Sidebar from 'components/Sidebar'
import Loader from 'components/Loader'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Message from './components/Message'
import Details from './components/Details'
import NDA from './components/NDA'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useContent from 'hooks/api/useContent'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { useModalStore } from 'store'
import { RELATIONS, SIDEBARS } from 'types/enums'

const AcceptSidebar = (): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()
	const [available, setAvailable] = useState(false)
	const [approve, setApprove] = useState(false)
	const [activeTab, setActiveTab] = useState(0)
	const [modalOpened] = useModalStore(
		useShallow((state) => [!!state.modal?.show]),
	)
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const { search } = useLocation()
	const openSidebar =
		new URLSearchParams(search).get('sidebar') ===
		SIDEBARS.ACCEPT_CASTING_INVITE
	const acceptId = new URLSearchParams(search).get('contentId')
	const { content } = useContent(acceptId)

	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		content?.ContentId,
		'Content',
		'/Lookup',
		true,
	)

	const prod = data?.[RELATIONS.PLAYLIST]?.[0]

	const { content: production } = useContent(prod?.ContentId, 'full')
	const ndaRequired = production?.ContentLegalNDARequired === 'true'

	const onClose = (): void => {
		navigate(window.location.pathname + window.location.hash)
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={onClose}
			iconClose={modalOpened}
			header={{
				title: 'APP_ACCEPT_ROLE_SIDEBAR_TITLE',
				description: 'APP_ACCEPT_ROLE_SIDEBAR_DESCRIPTION',
			}}
			buttons={
				activeTab === 2
					? [
							{
								children: t('APP_REJECT'),
								onClick: () => EventBus.$emit('rejectActorInvite'),
							},
							{
								children: t('APP_ACCEPT'),
								disabled: !approve || !available,
								onClick: () => EventBus.$emit('acceptActorInvite'),
							},
					  ]
					: undefined
			}
			containerStyle={loading ? { height: 'unset' } : {}}>
			<>
				<Loader adapt loading={loading} />
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '20px',
						// height: '100%',
					}}>
					<SidebarTabs
						active={activeTab}
						options={[
							{
								label: 'APP_MESSAGE_TAB',
								value: 0,
							},
							{
								label: 'APP_DETAILS_TAB',
								value: 1,
							},
							{
								label: ndaRequired ? 'APP_NDA_TAB' : 'APP_ACCEPT_TAB',
								value: 2,
							},
						]}
						onChange={(v) => {
							setActiveTab(v as number)
							swiperRef.current?.slideTo(v as number)
						}}
					/>
					<Swiper
						onSwiper={(swiper: SwiperClass) => {
							swiperRef.current = swiper
						}}
						allowTouchMove={false}
						style={{
							width: '100%',
							height: '100%',
						}}>
						<SwiperSlide>
							{activeTab === 0 ? <Message content={content} /> : <></>}
						</SwiperSlide>
						<SwiperSlide>
							<Details content={content} production={production} />
						</SwiperSlide>
						<SwiperSlide>
							<NDA
								content={content}
								production={production}
								setLoading={setLoading}
								onClose={onClose}
								approve={approve}
								available={available}
								setApprove={setApprove}
								setAvailable={setAvailable}
							/>
						</SwiperSlide>
					</Swiper>
				</div>
			</>
		</Sidebar>
	)
}

export default AcceptSidebar
